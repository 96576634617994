import actions from './actions'
import { getErrorAction, getSuccessAction } from '../utility'

const initState = {
  roles: [],
  page: 1,
  loading: false,
  hasNextPage: true,
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.GET_ROLES:
      return {
        ...state,
        loading: true,
      }
    case getSuccessAction(actions.GET_ROLES):
      return {
        ...state,
        roles:
          action.payload.page === 1
            ? action.payload.roles
            : [...state.roles, ...action.payload.roles],
        page: action.payload.page,
        hasNextPage: action.payload.hasNextPage,
        loading: false,
      }
    case getErrorAction(actions.GET_ROLES):
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
