import { lazy } from 'react';



const CustomerPortalConfig = {
  settings: {
    layout: {
        style : 'layout1',
        config: {

            navbar : {
                display : false,

            },
            toolbar: {
                display : false,
            },
            footer : {
                display : false,

            },
            mode   : 'fullwidth'
        }
    },

},
  routes: [
    {
      path: '/customerportal/login',
      component: lazy(() => import('./ClientPortalSignIn')),
    },
    {
      path: '/customerportal',
      component: lazy(() => import('./CustomerPortal')),
    },
    {
      path: '/customeportal-signup/:encryptedText?',
      component: lazy(() => import('./Signup')),
    },
    {
      path:'/customer-portal/signup-success',
      component: lazy(() => import('./SignupSuccess'))
    }, {
      path:'/customer-portal/profile/:id',
      component: lazy(() => import('./ClientProfile'))
    }
  ],
};

export default CustomerPortalConfig
