import React, { useState, useEffect, Fragment, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import TextField from '@mui/material/TextField';
import { Divider, FormControl, Grid, Paper, Tooltip } from '@mui/material';
import moment from 'moment';
import { formatter, constants } from '../../../../../constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import ReactToPrint from "react-to-print";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ModelCustomPdf from './ModelCustomPdf';
import ModelInvoiceEmail from './ModelInvoiceEmail';
import { updateCompanyLogo, getDataFromS3 } from "../../../../main/CompanyList/companyFunctions";
import CircularProgress from '@mui/material/CircularProgress';
import { ModalGenerateInvoicesCss } from "./ModalGenerateInvoices.css";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';


import store from '../../../ID_store/store';
import Check_refresh from 'app/main/Check_refresh';
import ModelUninvoiceForPrint from './ModelUninvoiceForPrint';
import axios from 'axios';
import ModelInvoiceEmailSend from './ModelInvoiceEmailSend';
import FuseLoading from '@fuse/core/FuseLoading';

const TODAY = moment()

const item = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0 },
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const token = localStorage.token

function DatePickerTerms({ setTerms, terms, setDueDates, dueDates, valueTerm, index }) {
  const [date, setDate] = useState();

  useEffect(() => {
    setDate(dueDates[index])
  }, [dueDates]);


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Due Date for Invoice"
        value={date}
        onChange={(valueFinal) => {
          //to due dates invoices per job
          let newDueDates = [...dueDates]
          let newTerms = [...terms]
          //final due date

          const startDate = moment(TODAY, 'YYYY-MM-DD')
          const endDate = moment(moment(valueFinal), 'YYYY-MM-DD')
          //to terms
          const diffDays = moment.duration(endDate.diff(startDate)).asDays()

          let newDate = moment(valueFinal).format('MM/DD/YYYY')
          newTerms[index] = Math.ceil(diffDays)
          newDueDates[index] = newDate
          setTerms(newTerms)
          setDueDates(newDueDates)
          setDate(newDate)
        }
        }
        renderInput={(params) => <TextField {...params} />}

      />
    </LocalizationProvider>
  )
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ModalGenerateInvoices({
  openModalInvoices,
  openModal,
  finalToInvoice,
  rechargedAfterInvoices,
  setMessage,isChecked
}) {

  const [open, setOpen] = useState(false);
  const [uniqueJobs, setUniqueJobs] = useState([]);
  const [toTable, setTotable] = useState([]);
  const [logoCompany, setLogoCompany] = useState('');
  const [notes, setNotes] = useState([]);
  const [terms, setTerms] = useState([]);
  const [compacct, setCompacct] = useState('');
  const [dueDates, setDueDates] = useState([]);
  const [compName, setCompName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaderData, setLoaderData] = useState(true);

  const TableToTable = toTable[0]?.customer?.jobs[0]?.batches[0]
  const [dataForPDF, setDataWithSignature] = useState([]);
  // const ToTablelogo = toTable[0]?.customer?.companyLogo

  const [invoiceNumber, setInvoiceNumber] = useState(0);

  const handleOpen = (open) => {
    setOpen(!open);
    openModal.current()

  };

  useEffect(() => {
    openModalInvoices.current = handleOpen;
  }, [])

  useEffect(async () => {
    if (open) {
      await Check_refresh()
      const IDCOMPANY = store.getState().comp_acct
      const compName = store.getState().compname
      setCompName(compName)
      setCompacct(IDCOMPANY)
    }
  }, [open])

  useEffect(() => {

    if (open && compacct) {
      getData()
    }

  }, [compacct, open])

  const getImage = async (url) => {
    const awsImage = await fetch(`${constants.URLLOCAL}/aws/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': `${token}`,
      },
      body: JSON.stringify({key: url})
    })
    const img = await awsImage.json()
    return img.data?.fileLocation ? img.data.fileLocation : null
  }

  const getData = async () => {
    try{
      setLoaderData(true)
      let _rows = []
      const tickets = finalToInvoice.map(b => b.tickets.map(i => {
        if (i.final) {
          return i.ticketId
        }
      })).flat(3)
      const jobs = await fetch(`${constants.URLLOCAL}/batch/get-all-batches-tickets/${compacct}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': `${token}`,
        },
        body: JSON.stringify({tickets: tickets})
      })
      const jsonJobs = await jobs.json();
  
      if (jsonJobs && jsonJobs.length > 0) {
        jsonJobs[0].customer.jobs.forEach((job, index) => {
          let dueDateSet = moment(TODAY, 'MM/DD/YYYY').add(jsonJobs[0].customer.customerTerm, 'days').format('MM/DD/YYYY');
          jsonJobs[0].customer.jobs[index].newDueDate = dueDateSet;
        })
      }
  
      if (jsonJobs && jsonJobs.length > 0) {
        if (jsonJobs[0].customer && jsonJobs[0].customer.jobs) {
          if(jsonJobs[0].customer.companyLogo){
                jsonJobs[0].customer['blobResLOGO'] = jsonJobs[0].customer.companyLogo; 
          }
        }
      }
      if (jsonJobs.msg) {
        return
      }
      _rows = jsonJobs
      if(_rows && _rows.length > 0){
        // const logo = await getImage(_rows[0].customer.companyLogo)
        const logo = _rows[0].customer.companyLogo
        setLogoCompany(logo ? logo : 'assets/images/logos/loadzlogo.png');
        setTotable(_rows)
        const uniqueJob = [...new Set(_rows[0].customer.jobs.map((job) => job.job))]
        setUniqueJobs(uniqueJob);
        setTerms(Array(uniqueJob.length).fill(_rows[0].customer?.customerTerm))
        setDueDates(Array(uniqueJob.length).fill(moment(TODAY, 'MM/DD/YYYY').add(_rows[0].customer?.customerTerm, 'days').format('MM/DD/YYYY')))
        setLoaderData(false)
        let dataWithSig = _rows;
        for (let j = 0; j < dataWithSig[0].customer.jobs.length; j++) {
          let tempIndex = 0;
          let ele = dataWithSig[0].customer.jobs[j];
          if (ele.batches && ele.batches.length > 0) {
            for (let index = 0; index < ele.batches.length; index++) {
              let batch = ele.batches[index];
              if (batch.info && batch.info.length > 0) {
                for (let i = 0; i < batch.info.length; i++) {
                  let ticket = batch.info[i];
                  ticket.printIndex = tempIndex;
                  tempIndex++;
                  let data = {
                    key: ticket.tkSignature,
                  };
                  let resp = await getDataFromS3(data);
                  if (resp) {
                    batch.info[i].newSignUrl = resp.data.data.fileLocation;
                    let blobRes = await getImageBlobFromsignature(
                      resp.data.data.fileLocation
                    );
                    if (blobRes) {
                      batch.info[i]["blobUrl"] = blobRes;
                    }
                  }                    
                }
              }
            }
          }
        }
        setDataWithSignature(dataWithSig);
      }
      getLastInvoice()
    }
    catch (e){
      setLoaderData(false)
    }
  }

  const componentRef = forwardRef();
  const [openEmail, setOpenEmail] = useState(false);
  const emailhandleOpen = () => setOpenEmail(false);
  useEffect(() => {
    setNotes(Array(uniqueJobs.length).fill(''))
  }, [uniqueJobs])

  const handleChange = (e, index) => {
    const { value } = e.target
    let notesToEdit = [...notes]

    notesToEdit[index] = value

    setNotes(notesToEdit)
  }

  const handleChangeDiscount = (e, jb) => {
    const { value } = e.target
    let toTableCopy = [...toTable]
    const index = toTableCopy[0].customer.jobs.findIndex(x=>x.job == jb)
    if(index != -1){
      toTableCopy[0].customer.jobs[index]['discount'] = value;
      setTotable(toTableCopy)
    }
  }

  const handleChangeTerms = (e, index) => {
    const { value } = e.target
    let termsToEdit = [...terms]

    termsToEdit[index] = value

    setTerms(termsToEdit)
  }

  const getLastInvoice = async () => {
    const lastNumber = await fetch(`${constants.URLLOCAL}/invoices/last-invoice/${compacct}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': `${token}`,
      }
    })
    const jsonLastInoivce = await lastNumber.json()
    setInvoiceNumber(jsonLastInoivce.length > 0 ? Number(jsonLastInoivce[0].invoice_no) + 1 : 1)
  }

  const sendData = async () => {
    setLoading(true)
    let addIndex = toTable
    addIndex[0].customer.jobs.forEach((job, index) => {
      job['discount'] = 0
      job['notes'] = notes[index]
      job['dueDate'] = dueDates[index]
      job['invoice_no'] = index > 0 ? invoiceNumber + index : invoiceNumber
    })

    const customerName = addIndex[0].customer.customerName

    try {
      const invoices = await fetch(`${constants.URLLOCAL}/invoices/create-invoices/${compacct}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': `${token}`,
        },
        body: JSON.stringify({
          invoices: addIndex
        })
      })

      if (invoices.status === 500) {
        setMessage.current(`Oh! Somenthing went wrong, please try again to generate invoice for ${customerName}!`, 'error')
        setLoading(false)
        return
      }
      setOpen(false)
      openModal.current(true)
      rechargedAfterInvoices.current()
      setMessage.current(`The invoices has been generate correctly for customer ${customerName}!`, 'success')
      setLoading(false)
    } catch (error) {
      console.log(error)
      setMessage.current(`Oh! Somenthing went wrong, please try again to generate invoice for ${customerName}!`, 'error')
      setLoading(false)
    }
  }

  const mystyle = {
    display: "none",
  };
  const [storeLogo, setStoreLogo] = useState(null)
  const [blobLogoForUNVoice, setBlobLogoForUNVoice] = useState(null)
  const getS3Location = async (key) => {
    if (key != null && key != "") {
      let data = {
        key: store.getState().photo,
      };
      return await getDataFromS3(data);
    }
  };


  useEffect(async () => {
    let key = store.getState().photo;
    let logoRes = await getS3Location(key);
    if (logoRes && logoRes.data && logoRes.data.data) {
      logoRes.data.data.logoKey = key;
      setStoreLogo(logoRes.data.data);
    }
  }, [store.getState().photo]);

  async function getImageBlobFromUrl(url) {
    try {
      const image = await axios
        .get(url, {
          responseType: "blob",
        })
        .then((response) =>
          window.URL.createObjectURL(new Blob([response.data]))
        );
      return image;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  React.useEffect(async () => {
    if (storeLogo && storeLogo.fileLocation) {
      const image = await getImageBlobFromUrl(storeLogo.fileLocation);
      setBlobLogoForUNVoice(image);
    }
  }, [storeLogo]);

  async function getImageBlobFromsignature(url) {
    try {
      const image = await axios.get(url, {responseType: "blob"}).then((response) =>
        window.URL.createObjectURL(new Blob([response.data]))
      );
      return image;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  console.log("toTable",toTable)
  return (
    <div>
      {isChecked ?
        <BootstrapDialog className={loaderData ? "customloader":''} maxWidth={'xl'} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}>
          {loaderData ? (
            <div>
              <FuseLoading/>
            </div>
          ):(
            <>
              <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleOpen(open)}> Preview invoices
                <div className='invoicePrintbtns' style={{ marginTop: "15px" }}>
                  <ReactToPrint
                    trigger={() => (
                      <div>
                        <button className='invoicePrintbtn' type="button" >
                          <img width="20px" height="20px" src="/assets/images/icons/print-icon-white.svg" />
                        </button>
                      </div>
                    )}
                    content={() => componentRef.current}
                  />
                  <div>
                  { dataForPDF && dataForPDF.length > 0 ? 
                    <PDFDownloadLink
                      document={<ModelCustomPdf
                        toTable={dataForPDF}
                        invoiceNumber={invoiceNumber}
                        TableToTable={TableToTable}
                        notes={notes}
                        blobLogoForUNVoice={blobLogoForUNVoice}
                        compName={compName}
                        dueDates={dueDates}
                        terms={terms}
                      />}
                      fileName={`${TableToTable?.info[0]?.job}-${TableToTable?.info[0]?.job.length > 0 ? invoiceNumber + 1 : invoiceNumber}-${moment().format('MM/DD/YYYY hh:mm:ss')}`}
                    >
                      <div className='invoicePrintbtn' style={{ marginRight: "0" }}>
                        <img width="20px" height="20px" src="/assets/images/icons/pdf-icon-white.svg"/>
                      </div>
                    </PDFDownloadLink>: 
                        <div
                            className="invoicePrintbtn"
                            style={{ marginRight: "0" }}
                          >
                            <CircularProgress  color="success"/>
                          </div>
                  }
                  </div>
                  <div className='invoicePrintbtn' onClick={() => setOpenEmail(true)} >
                    <img width="20px" height="20px" src="/assets/images/icons/email-icon-white.svg" />
                  </div>
                </div>
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <div className="flex w-full max-w-screen-sx">
                  <div className='w-1/3 justify-center items-center'>
                    <Card component={motion.div} variants={item}>
                      <Paper>
                        <Box sx={{display: 'flex', flexWrap: 'wrap', paddingRight: '30px', paddingLeft: '20px', paddingTop: '10px'}}>
                          {
                            uniqueJobs.map((job, index) => (
                              <div className='pb-32'>
                                <Typography variant="h6" gutterBottom> Job No. {job}</Typography>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                  <DatePickerTerms
                                    setTerms={setTerms}
                                    terms={terms}
                                    setDueDates={setDueDates}
                                    dueDates={dueDates}
                                    valueTerm={terms[index]}
                                    index={index} />
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                  <TextField id={Math.random()} label={'Discount'}  ariant="outlined" onChange={(e) => handleChangeDiscount(e, job)} type={'number'} />
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                  <TextField id={Math.random()} label={'Notes'} variant="outlined" value={notes[index]} onChange={(e) => handleChange(e, index)} multiline maxRows={4}/>
                                </FormControl>
                                <Divider />
                              </div>
                            ))
                          }
                        </Box>
                      </Paper>
                    </Card>
                  </div>

                  <div className="w-2/3 ModalGenerateInvoicesPrint" style={{padding: '10px'}}>
                    <Card component={motion.div} variants={item} style={{border: '0', borderRadius: '0', padding: '20px', boxShadow:'none'}}>
                      <Paper className='InvoicesPrint'>
                        {toTable[0]?.customer?.jobs?.map((job, index) => (
                          <div id={`pdf-invoice-${job.job}`}>
                            <Grid className='mainpdfview' container spacing={2} style={{padding: '10px'}}>
                              <Grid className='cstPdf' item xs={12}>
                                <Grid container spacing={2} className='remove-right-space'>
                                  <Grid item xs={6} sx={{margin: 'auto'}}>
                                    <img className="w-full-s" style={{ Width: "auto", height: "95px", }} src={logoCompany} alt="logo" />
                                    <Typography variant="body1">{compName}</Typography>
                                    <Typography variant="body1">{job.comp_address}, {job.comp_city}, {job.comp_state}, {job.comp_zip}</Typography>
                                  </Grid>
                                  <Grid item xs={6} sx={{textAlign: 'end'}}>
                                    <Typography variant="h2" gutterBottom component="div" className='font-bold m-0 mb-10 uppercase big-title' style={{fontSize:'45px'}}>
                                      Invoice # {index === 0 ? invoiceNumber : invoiceNumber + index}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom component="div" className='flex justify-end items-end text-sm font-bold' style={{fontSize:'16px'}}>
                                      Total: <Typography variant="h4" gutterBottom component="div" className='font-bold m-0 ml-10 sub-title' style={{fontSize:'25px', lineHeight: '1',}}>{formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait)}</Typography>
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                  <Grid item xs={4} sx={{margin: 'auto'}}>
                                    <Typography variant="body1" className='font-bold'>Bill to:</Typography>
                                    <Typography variant="body1">{toTable[0]?.customer?.customerName}</Typography>
                                    <Typography variant="body1">
                                      {toTable[0]?.customer?.locationId} {toTable[0]?.customer?.customerCity} {toTable[0]?.customer?.customerState} {toTable[0]?.customer?.customerZip}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4} sx={{margin: 'auto'}}>
                                  <Tooltip title={job.og_job_no? job.og_comp+"-"+job.og_job_no : ""} followCursor={true}><Typography variant="body1"><strong className='font-bold'>Job No:</strong> {job.job}</Typography></Tooltip>
                                    <Typography variant="body1"><strong className='font-bold'> Job Site:</strong> {job.jobDestination}</Typography>
                                    <Typography variant="body1"><strong className='font-bold'> Origin:</strong> {job.jobQuarry}</Typography>
                                  </Grid>
                                  <Grid item xs={4} sx={{textAlign: 'end'}}>
                                    <Typography variant="body1">Date: {moment(job.dateCreated).format('MM/DD/YYYY')}</Typography>
                                    <Typography variant="body1">Terms: {Number(terms[index]).toFixed(0)}</Typography>
                                    <Typography variant="body1"> Due Date: {dueDates[index]}</Typography>
                                  </Grid>
                                </Grid>
                                <>
                                  {job.batches.map(batch => (
                                    <>
                                      <br></br>
                                      <Divider style={{border: '1px solid darkgrey',}}></Divider>
                                      <br />
                                      <Grid container className='topbatchno'>
                                        <Grid item xs={4} >
                                          <Typography variant="body1" > Batch No.: {batch.batch}</Typography>
                                        </Grid>
                                        <Grid item xs={4} >
                                          <Typography variant="body1" style={{ textAlign: "center" }}>
                                            Date {batch.batchDateTimeReal}
                                          </Typography>
                                        </Grid>
                                          <Grid item xs={4}>
                                            <Typography variant="body1" style={{ textAlign: "right" }}>Material: {batch.material}</Typography>
                                          </Grid>
                                        </Grid>
                                        {/* <br></br>
                                        <Divider style={{border: '1px solid #000',}}></Divider> */}
                                        <br></br>
                                        <Grid container>
                                          <Grid item xs={12} className='newinvoice-ui'>
                                            <Paper sx={{ width: '100%', borderRadius:'0', boxShadow: 'none' }}>
                                              <Table style={{ width: "100%", tableLayout: "auto", border: '1px solid #000', }}>
                                              <TableBody>
                                              {['Hauling', 'Material', 'Wait Charge'].map((type, typeIndex) => (
                                                <>
                                                  <> </>
                                                  {batch.info.map((ticket, i) => (
                                                    <>
                                                      {batch.info.length + 1 === i + 1 ? (
                                                        <></>
                                                      ) : (
                                                        <>
                                                          {(typeIndex === 0) ? (
                                                            <>
                                                              {i=== 0 && batch.info.some(x=>x.ticketCR > 0) &&  (
                                                                <>
                                                                  <TableRow key={typeIndex}>
                                                                    <TableCell align="left" width="100%" colSpan={9} style={{fontWeight: 'bold', fontSize: '25px', padding:'10px 20px', border: '1px solid #000', }}>
                                                                      {type}
                                                                    </TableCell>
                                                                  </TableRow>
                                                                  <TableHead width="100%" style={{ display: 'contents' }}>
                                                                  <TableRow>
                                                                    <TableCell align="center" width="11.11%">Ticket No.</TableCell>
                                                                    <TableCell align="center" width="11.11%">Scale No.</TableCell>
                                                                    <TableCell align="center" width="11.11%">Truck</TableCell>
                                                                    <TableCell align="center" width="11.11%">Rate</TableCell>
                                                                    <TableCell align="center" width="11.11%">Qty</TableCell>
                                                                    <TableCell align="center" width="11.11%">Unit</TableCell>
                                                                    <TableCell align="center" width="11.11%">TAX</TableCell>
                                                                    <TableCell align="center" width="11.11%">Total</TableCell>
                                                                    {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                                  </TableRow>
                                                                  </TableHead>
                                                                </>
                                                              )}
                                                              {ticket.ticketCR && ticket.ticketCR > 0 ?
                                                                <TableRow key={ticket.ticket}>
                                                                  <TableCell align="center" width="11.11%">{ticket.ticket}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{ticket.scale}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{ticket.truck}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{ticket.ticketCR / ticket.qty}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{ticket.qty}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{typeIndex != 2 ? batch.unit : 'H'}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{'N'}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{formatter.format(ticket.ticketCR)}</TableCell>
                                                                  {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                                </TableRow>:null
                                                              }
                                                              
                                                            </>
                                                          ) : (typeIndex === 1) ? (
                                                            <>
                                                            
                                                              {i=== 0 && batch.info.some(x=>x.materialCR > 0 ) &&(
                                                                <>
                                                                <TableRow key={typeIndex}>
                                                                  <TableCell align="left" width="100%" colSpan={9} style={{fontWeight: 'bold', fontSize: '25px', padding:'10px 20px', border: '1px solid #000',}}>
                                                                    {type}
                                                                  </TableCell>
                                                                </TableRow>
                                                                <TableHead width="100%" style={{ display: 'contents' }}>
                                                                <TableRow>
                                                                  <TableCell align="center" width="11.11%">Ticket No.</TableCell>
                                                                  <TableCell align="center" width="11.11%">Scale No.</TableCell>
                                                                  <TableCell align="center" width="11.11%">Truck</TableCell>
                                                                  <TableCell align="center" width="11.11%">Rate</TableCell>
                                                                  <TableCell align="center" width="11.11%">Qty</TableCell>
                                                                  <TableCell align="center" width="11.11%">Unit</TableCell>
                                                                  <TableCell align="center" width="11.11%">TAX</TableCell>
                                                                  <TableCell align="center" width="11.11%">Total</TableCell>
                                                                  {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                                </TableRow>
                                                                </TableHead>
                                                                
                                                                </>
                                                              )}
                                                              {ticket.materialCR && ticket.materialCR > 0 ?
                                                                <TableRow key={ticket.ticket}>
                                                                  <TableCell align="center" width="11.11%">{ticket.ticket}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{ticket.scale}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{ticket.truck}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{job.jobMaterialRate}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{ticket.qty}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{typeIndex != 2 ? batch.unit : 'H'}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{job.jobTaxable ? 'T' : 'N'}</TableCell>
                                                                  <TableCell align="center" width="11.11%">{formatter.format(ticket.materialCR)}</TableCell>
                                                                  {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                                </TableRow>:null
                                                              }
                                                              
                                                            </>
                                                          ) : (typeIndex === 2 ) ? (
                                                            <>
                                                              {i=== 0 && batch.info.some(x=>x.waitCR > 0 ) && (
                                                                <>
                                                                <TableRow key={typeIndex}>
                                                                  <TableCell align="left" width="100%" colSpan={9} style={{fontWeight: 'bold', fontSize: '25px', padding:'10px 20px', border: '1px solid #000',}}>
                                                                    {type}
                                                                  </TableCell>
                                                                </TableRow>
                                                              <TableHead width="100%" style={{ display: 'contents' }}>
                                                              <TableRow>
                                                                <TableCell align="center" width="11.11%">Ticket No.</TableCell>
                                                                <TableCell align="center" width="11.11%">Scale No.</TableCell>
                                                                <TableCell align="center" width="11.11%">Truck</TableCell>
                                                                <TableCell align="center" width="11.11%">Rate</TableCell>
                                                                <TableCell align="center" width="11.11%">Qty</TableCell>
                                                                <TableCell align="center" width="11.11%">Unit</TableCell>
                                                                <TableCell align="center" width="11.11%">TAX</TableCell>
                                                                <TableCell align="center" width="11.11%">Total</TableCell>
                                                                {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                              </TableRow>
                                                              </TableHead>
                                                              </>               
                                                              )}
                                                              {ticket.waitCR && ticket.waitCR > 0 ?
                                                              <TableRow key={ticket.ticket}>
                                                                <TableCell align="center" width="11.11%">{ticket.ticket}</TableCell>
                                                                <TableCell align="center" width="11.11%">{ticket.scale}</TableCell>
                                                                <TableCell align="center" width="11.11%">{ticket.truck}</TableCell>
                                                              <TableCell align="center" width="11.11%">{job.waitrate}</TableCell>
                                                                <TableCell align="center" width="11.11%">{Number(ticket.waitTime).toFixed(2)}</TableCell>
                                                                <TableCell align="center" width="11.11%">{'H'}</TableCell>
                                                                <TableCell align="center" width="11.11%">{'N'}</TableCell>
                                                                <TableCell align="center" width="11.11%">{formatter.format(ticket.waitCR)}</TableCell>
                                                                {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                              </TableRow>:null}
                                                            </>
                                                          ) : (<></>)}
                                                        </>)}</>
                                                      ))}
                                                      </>
                                                  ))}
                                                  </TableBody>
                                                </Table>
                                              </Paper>
                                            </Grid>
                                          </Grid>
                                        </>
                                      ))
                                    }
                                  </>
                                  <br></br>
                                  <Grid container spacing={2} className='new-invoice-bottmtable'>
                                  <Grid item xs={7}  className='new-invoice-bottmleft'>
                                    <Typography variant="body1">Notes to customer:</Typography>
                                  </Grid>
                                  <Grid item xs={5} className='new-invoice-bottmright'>
                                    <Table>
                                      <TableRow>
                                          <TableCell align='left'>Total QTY:</TableCell>
                                          <TableCell align='left'>{`${Number(job.jobTotalTickets).toFixed(2)}`}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell align='left'>Total Wait Time:</TableCell>
                                          <TableCell align='left'>
                                            {`${Number(job.jobTotalWait).toFixed(2)}`} 
                                           
                                      {/* {`${formatter.format(
                                       job.jobTotalWait   
                                      )}`} */}
                                       H 
                                          </TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell align='left'>Sub Total Invoice:</TableCell>
                                          <TableCell align='left'>{`${formatter.format(
                                                job.jobTotalHauling + (job.jobTotalMaterial - job.jobTotalTaxesMaterial) + job.jobTotalWait
                                              )}`}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell align='left'>Tax {job.jobTaxable ? job.jobTax > 0 || job.jobTax ? job.jobTax : `8.25%` : 0}% Material:</TableCell>
                                          <TableCell align='left'>{`${formatter.format(
                                                job.jobTotalTaxesMaterial
                                              )}`}</TableCell>
                                      </TableRow>
                                    
                                      { job && job.discount > 0 ?
                                      <>
                                    <TableRow>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>Discount Total</TableCell>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>{` (${formatter.format(job.discount)} )`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}> Invoice Balance</TableCell>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>{`${formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait - job.discount)}`}</TableCell>
                                    </TableRow>
                                    </>
                                    :
                                    <TableRow>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>Total Invoice</TableCell>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>{`${formatter.format(
                                        job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait
                                      )}`}</TableCell>
                                    </TableRow>

                                     }

                                    </Table>
                                  </Grid>
                                </Grid>
                                  <br></br>
                                  <br></br>
                                  <Grid container>
                                    <Grid item xs={12} sx={{
                                      textAlign: '-webkit-right'
                                    }} className='flex justify-end items-end'>
                                      <Typography variant="h6" style={{fontSize:'14px'}}>
                                        Powered by
                                      </Typography>
                                      <img
                                        src='assets/images/logos/loadzlogo.png'
                                        alt="logo"
                                        style={{
                                          width: '140px', marginLeft: '10px'
                                        }} />
                                    </Grid>
                                  </Grid>
                                  <br></br>
                                  <br></br>
                                  <Divider style={{border: '1px solid #ccc',}}></Divider>
                                  <br></br>
                                </Grid>
                              </Grid>
                              <div className='break-before-all'></div>
                              <div className='break-after-all'></div>
                            </div>
                          ))
                        }
                      </Paper>
                    </Card>
                  </div>
                </div>

              </DialogContent>

              <DialogActions>
                {true &&
                  <Button autoFocus onClick={() => sendData()} disabled={loading}> Generate </Button>
                }
                <Button autoFocus onClick={() => handleOpen(open)}>Cancel</Button>
              </DialogActions>
              {openEmail ? (
                <ModelInvoiceEmail
                  open={openEmail}
                  emailhandleOpen={emailhandleOpen}
                  toTable={toTable}
                  compName={compName}
                  terms={terms}
                  invoiceNo={invoiceNumber}
                />
              ) : null}

              <div style={mystyle}>
                <ModelUninvoiceForPrint ref={componentRef}
                  compName={compName}
                  toTable={toTable}
                  notes={notes}
                  invoiceNo={invoiceNumber}
                  terms={terms}
                  uniqueJobs={uniqueJobs}
                  dueDates={dueDates}
                  logo={logoCompany} 
                />
              </div>
            </>
          )}
        </BootstrapDialog>:
      
        <BootstrapDialog maxWidth={'sm'} onClose={() => handleOpen(open)} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} >
          <Box className='px-20'>
            <Button variant="contained" className="absolute top-4 right-4" onClick={() => handleOpen(open)}> Close</Button>
            <Typography
              id="transition-modal-title"
              variant="h4"
              component="h2"
              className="text-center pb-8 mt-20" style={{ fontSize: "22px" }}
            >
              Enter the emails
            </Typography>
            <ModelInvoiceEmailSend
              open={openEmail}
              emailhandleOpen={emailhandleOpen}
              toTable={toTable}
              compName={compName}
              terms={terms}
              invoiceNo={invoiceNumber}
            />
          </Box>
        </BootstrapDialog>
      }
    </div>
  )
}

export default ModalGenerateInvoices;