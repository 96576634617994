import actions from './actions'
import { getErrorAction, getSuccessAction, update } from "../utility";

const initState = {
  jobTickets: [],
  job: null,
  filter: '',
  totalTicket: 0,
  page: 1,
  loading: false,
  hasNextPage: true,
  trucks: [],
  drivers: [],
  metrics: null
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_TICKET_JOBS:
      return {
        ...state,
        loading: true,
      }

    case getSuccessAction(actions.GET_ALL_TICKET_JOBS):
      return {
        ...state,
        jobTickets: action.payload.page === 1 ? action.payload.jobs : [
          ...state.jobTickets,
          ...action.payload.jobs
        ],
        totalTicket: action.payload.totalTicket,
        page: action.payload.page,
        hasNextPage: action.payload.hasNextPage,
        loading: false
      }

    case getErrorAction(actions.GET_ALL_TICKET_JOBS):
      return {
        ...state,
        loading: false
      }

    case actions.SEARCH_JOB_TICKET:
      return {
        ...state,
        loading: true,
      }

    case getSuccessAction(actions.SEARCH_JOB_TICKET):
      console.log('reducer ::', action)
      return {
        ...state,
        jobTickets: action.payload.page === 1 ? action.payload.jobs : [
          ...state.jobTickets,
          ...action.payload.jobs
        ],
        page: action.payload.page,
        hasNextPage: action.payload.hasNextPage,
        loading: false
      }

    case getErrorAction(actions.SEARCH_JOB_TICKET):
      return {
        ...state,
        loading: false
      }

      case actions.SEARCH_JOB_TICKET_DATE:
        return {
          ...state,
          loading: true,
        }
  
      case getSuccessAction(actions.SEARCH_JOB_TICKET_DATE):
        console.log('reducer ::', action)
        return {
          ...state,
          jobTickets: action.payload.page === 1 ? action.payload.jobs : [
            ...state.jobTickets,
            ...action.payload.jobs
          ],
          page: action.payload.page,
          hasNextPage: action.payload.hasNextPage,
          loading: false
        }
  
      case getErrorAction(actions.SEARCH_JOB_TICKET_DATE):
        return {
          ...state,
          loading: false
        }

    case actions.GET_TRUCKS:
      return {
        ...state,
      }

    case getSuccessAction(actions.GET_TRUCKS):
      console.log('reducer trucks::', action)
      return {
        ...state,
        trucks: action.payload.trucks
      }

    case getErrorAction(actions.GET_TRUCKS):
      return {
        ...state,
      }

    case actions.GET_DRIVERS:
      return {
        ...state,
      }

    case getSuccessAction(actions.GET_DRIVERS):
      console.log('reducer drivers::', action)
      return {
        ...state,
        drivers: action.payload.drivers
      }

    case getErrorAction(actions.GET_DRIVERS):
      return {
        ...state,
      }  

    case actions.DELETE_JOB_TICKETS :
      return {
        ...state,
        loading: true
      }
    
    case getSuccessAction(actions.DELETE_JOB_TICKETS) :
      return {
        ...state,
        loading: false
      }

    case getErrorAction(actions.DELETE_JOB_TICKETS) :
      return {
        ...state,
        loading: false
      }
      
    case actions.EDIT_JOB_TICKET:
      return {
        ...state,
        loading: true
      }
      
    case getSuccessAction(actions.EDIT_JOB_TICKET) :
      return {
        ...state,
        loading: false
      }

    case getErrorAction(actions.EDIT_JOB_TICKET) :
      return {
        ...state,
        loading: false
      }

    case actions.GET_METRICS:
      return {
        ...state,
      }
      
    case getSuccessAction(actions.GET_METRICS) :
      return {
        ...state,
        metrics: action?.payload?.metrics ? action.payload.metrics : null 
      }

    case getErrorAction(actions.GET_METRICS) :
      return {
        ...state,
      }

    case actions.TRANSFER_JOB_TICKETS:
      return {
        ...state,
      }

    case getSuccessAction(actions.TRANSFER_JOB_TICKETS):
      return {
        ...state,
      }
    
    case getErrorAction(actions.TRANSFER_JOB_TICKETS):
      return {
        ...state,
      }
    
    case actions.GET_JOB: 
      return {
        ...state,
        job: null
      }
    
    case getSuccessAction(actions.GET_JOB) : 
      return {
        ...state,
        job: action.payload.job
      }

    case getErrorAction(actions.GET_JOB):
      return {
        ...state,
        job: null
      }
      
    default:
      return state
  }
}
