import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { toggleQuickPanel } from './store/stateSlice';
import { connect } from 'react-redux';

function QuickPanelToggleButton({children, user}) {
  const dispatch = useDispatch();

  let permission = true
  // if(!user.permissions) {
  //   permission = false
  // } else {
  //   if (user.permissions.Settings) {
  //     permission = user.permissions.Settings.access
  //   } else {
  //     permission = true
  //   }
  // }


  return (
    permission ?
    <IconButton className="w-40 h-40 maingearbtn" onClick={(ev) => dispatch(toggleQuickPanel())} size="large">
      {children}
    </IconButton>
    :
    null
  )
}

QuickPanelToggleButton.defaultProps = {
  children: <Icon style={{ color: "black" }}  >settings</Icon>,
};

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  }
}
export default connect(mapStateToProps, null)(QuickPanelToggleButton);
