import Checkbox from '@mui/material/Checkbox';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import TableHead from '@mui/material/TableHead';
import { removeUnitTemps } from '../store/JobsTempsSlice';
import { constants } from '../../../../constants';
import Select from '@mui/material/Select';
import { Button, Dialog, DialogContent, DialogContentText, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasPermission } from 'app/main/loadz/utils';

const rows = [

  {
    id: 'job_no',
    align: 'left',
    disablePadding: false,
    label: 'Job No.',
    sort: true,
  },
  {
    id: 'customer_name',
    align: 'left',
    disablePadding: false,
    label: 'Customer',
    sort: true,
  },
  {
    id: 'origin',
    align: 'left',
    disablePadding: false,
    label: 'Origin',
    sort: true,
  },
  {
    id: 'destination',
    align: 'left',
    disablePadding: false,
    label: 'Destination',
    sort: true,
  },
  {
    id: 'material',
    align: 'left',
    disablePadding: false,
    label: 'Material',
    sort: true,
  },
  {
    id: 'ticketcompleted',
    align: 'center',
    disablePadding: false,
    label: 'Qty',
    sort: true,
  },
  {
    id: 'ticketqty',
    align: 'left',
    disablePadding: false,
    label: 'Completed Tickets',
    sort: true,
  },
  {
    id: 'Last_Activity',
    align: 'left',
    disablePadding: false,
    label: 'Last Activity',
    sort: true,
  },
  /* {
    id: 'date_created',
    align: 'left',
    disablePadding: false,
    label: 'Date created',
    sort: true,
  }, */
  /* {
    id: 'visibility',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    sort: true,
  }, */
  {
    id: 'in_progress',
    align: 'left',
    disablePadding: true,
    label: 'In Progress',
    sort: true,
  },
  {
    id: 'Edit',
    align: 'left',
    disablePadding: false,
    label: 'Edit',
    sort: true,
  },
  {
    id: 'Delete',
    align: 'left',
    disablePadding: false,
    label: 'Delete',
    sort: true,
  },

];

function JobTableHeader({
  selectedJobs,
  order,
  onSelectAllClick,
  onRequestSort,
  rowCount,
  onMenuItemClick,
  _showMessage,
  setReData,
  user,
  refreshJobs,
  jobFilter
}) {
  const numSelected = selectedJobs.length;
  const [open, setOpen] = useState(false);
  const [deletejobs, setDeletejobs] = useState(null);
  const history = useHistory();
  const token = localStorage.token
  var permission = false

  if(!user?.permissions) {
    permission = false
  } else {
    if (user.permissions.Jobs) {
      permission = user.permissions.Jobs.edit
    } else {
      permission = true
    }
  }

  const [selectedUnitTempsMenu, setSelectedUnitTempsMenu] = useState(null);

  const dispatch = useDispatch();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function openSelectedUnitTempsMenu(event) {
    setSelectedUnitTempsMenu(event.currentTarget);
  }

  function closeSelectedUnitTempsMenu() {
    setSelectedUnitTempsMenu(null);
  }

  const removeLotJobs = async () => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/jobs/delete-lot`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': `${token}`,
        },
        body: JSON.stringify({ jobsIds: selectedJobs }) // body data type must match "Content-Type" header
      });
      const res = await response.json()
      console.log(res)

      _showMessage(`Jobs were successfully deleted`, 'success')

      if (res.error) {
        _showMessage(`Failed to delete the jobs. Please try again later`, 'error')//TODO improved Message
        return
      }
      history.push('/jobs')
      setReData(true)

    } catch (error) {
      console.log(error)
      _showMessage(`Failed to delete the jobs. Please try again later`, 'error')//TODO improved Message

    }
  }

  const removeJob = async () => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/jobs/delete/${selectedJobs[0]}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': `${token}`,
        },
        //body: JSON.stringify(getValues()) // body data type must match "Content-Type" header
      });
      const res = await response.json()
      console.log(res)

      _showMessage(`The job has been deleted!`, 'success')

      if (res.error) {
        _showMessage(`Oh! please try again, something went wrong`, 'error')
        return
      }

      setReData(true)
    } catch (error) {
      console.log(error)
      _showMessage(`Oh! please try again, something went wrong`, 'error')

    }

  }

  const ArchiveJobs = async () => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/jobs/archive`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': `${token}`,
        },
        body: JSON.stringify({ jobsIds: selectedJobs }) // body data type must match "Content-Type" header
      });
      const res = await response.json()
      console.log("archive",res)

      // _showMessage(`The jobs have been archived`, 'success')

      if (res.msg) {
        
        _showMessage(`The jobs have been archived ${res.jobs}`, 'success')
        await refreshJobs()
        return
      }

      
      setReData(true)

    } catch (error) {
      console.log(error)
      _showMessage(`Oh! please try again, something went wrong`, 'error')

    }
  }

  const UnarchiveJobs = async () => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/jobs/unarchive`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': `${token}`,
        },
        body: JSON.stringify({ jobsIds: selectedJobs }) // body data type must match "Content-Type" header
      });
      const res = await response.json()
      console.log("archive",res)

      // _showMessage(`The jobs have been archived`, 'success')

      if (res.msg) {
        
        _showMessage(`The jobs have been unarchived ${res.jobs}`, 'success')
        await refreshJobs()
        return
      }

      setReData(true)

    } catch (error) {
      console.log(error)
      _showMessage(`Oh! please try again, something went wrong`, 'error')

    }
  }

  const DeleteJobsTrue = async () => {
    console.log("aqui llego",{ jobsIds: deletejobs })
    try {
      const response = await fetch(`${constants.URLLOCAL}/jobs/delete-job`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': `${token}`,
        },
        body: JSON.stringify({ jobsIds: deletejobs }) // body data type must match "Content-Type" header
      });
      const res = await response.json()
      console.log(res.archived)

      refreshJobs()
      setOpen(false);
      setReData(true)

    } catch (error) {
      console.log(error)
      _showMessage(`Oh! please try again, something went wrong`, 'error')

    }
  }

  const DeleteJobs = async () => {
    setDeletejobs(selectedJobs);
    setOpen(true);
  }

  const handleClickDelete = (tk_id) => {
    setOpen(true);
    // setDelete(tk_id);
  };

  const handleCloseDelete = () => {
    // setModalShow(false);
    setOpen(false);
  };

  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        <TableCell padding="none" className="w-40 md:w-64 text-center z-99">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount !== 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
          {numSelected > 0 && (
            <Box>
              <IconButton
                aria-owns={selectedUnitTempsMenu ? 'selectedUnitTempsMenu' : null}
                aria-haspopup="true"
                onClick={openSelectedUnitTempsMenu}
                size="large"
              >

                <Icon>more_horiz</Icon>
              </IconButton>
              <Menu
                id="selectedUnitTempsMenu"
                anchorEl={selectedUnitTempsMenu}
                open={Boolean(selectedUnitTempsMenu)}
                onClose={closeSelectedUnitTempsMenu}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      jobFilter == 'archived' ? UnarchiveJobs() : ArchiveJobs()  
                      
                      onMenuItemClick();
                      closeSelectedUnitTempsMenu();
                    }}
                  >
                    <ListItemIcon className="min-w-40">
                      <Icon>archive</Icon>
                    </ListItemIcon>
                    <ListItemText primary={jobFilter == 'archived' ? "Unarchive" : "Archive"} />
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      DeleteJobs()
                      onMenuItemClick();
                      closeSelectedUnitTempsMenu();
                    }}
                  >
                    <ListItemIcon className="min-w-40">
                      <Icon>delete</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                  </MenuItem>

                </MenuList>
              </Menu>
            </Box>
          )}
        </TableCell>
        {rows && rows.length > 0 && rows.map((row) => {
          if (row.label == 'Edit' && hasPermission('jobs.update') == false) {
            return null
          }
          if (row.label == 'Delete' && (hasPermission('jobs.delete') == false || jobFilter != 'drafts')) {
            return null
          }
          else {
            return (
              <TableCell
                className="p-4 md:p-16"
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'normal'}
                sortDirection={order.id === row.id ? order.direction : false}
              >
                {row.sort && (
                  <Tooltip
                    title="Sort"
                    placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      align='center'
                      active={order.id === row.id}
                      direction={order.direction}
                      onClick={createSortHandler(row.id)}
                      className="font-Regular"
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            );
          }
        }, this)}

        <Dialog
          open={open}
          onClose={handleCloseDelete}
          PaperProps={{
            style: {
              backgroundColor: "black",
              boxShadow: "none",
            },
          }}
          classes={{
            paper: "m-24",
          }}
          maxWidth={"md"}
        >
          <DialogContent>
            <DialogContentText className='h1' color={"#FFFFFF"}>
              Are you sure you want to delete
            </DialogContentText>
            <br />
            <br />
            <div className="flex mx-6">
              <Button
                fullWidth
                style={{ backgroundColor: "#edca33" }}
                onClick={handleCloseDelete}
              >
                <Typography color={"black"}>No</Typography>
              </Button>
              <Button
                fullWidth
                style={{ backgroundColor: "#FF0000" }}
                onClick={DeleteJobsTrue}
              >
                <Typography color={"black"}>Yes</Typography>
              </Button>
            </div>
          </DialogContent>
        </Dialog>

      </TableRow>
    </TableHead>


  );
}

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  }
}
export default connect(mapStateToProps, null)(JobTableHeader);
