import { lazy } from 'react';

const ApplyPaymentConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/apply-payment-view/:encryptedText',
      component: lazy(() => import('./ApplyPaymentView')),
    },
    {
      path: '/confirm-payment/:encryptedText',
      component: lazy(() => import('./ConfirmPayment')),
    },
    {
      path: '/confirm-payment-multi/:encryptedText',
      component: lazy(() => import('./ConfirmPaymentMulti')),
    },
  ],
};

export default ApplyPaymentConfig;
