import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const InspectionAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: [
        '/Inspection/filter/:filterHandle/:InspectionId?',
        '/Inspection/:folderHandle/:InspectionId?',
      ],
      component: lazy(() => import('./InspectionApp')),
    },
    {
      path: '/Inspection',
      component: () => <Redirect to="/Inspection/all" />,
    },
  ],
};

export default InspectionAppConfig;
