import { Button, Icon, Input, Paper, Toolbar, Tooltip } from '@mui/material'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import RolesPermissionAction from 'app/stores/loadz/redux/RolesPermissions/actions'
import { connect } from 'react-redux'

const DataTableToolBar = ({searchRole, getAll}) => {
  const [text, setText] = useState('')

  useEffect(async () => {
    if(text) {
      await searchRole(text, (error) => {
        console.log(error)
      })
    } else {
      getAll({page:1}, (error) => {
        console.log(error)
      })
    }
  }, [text])
  return (
    <div className="flex justify-end items-center job-right mb-32">
      <div className="job-srach">
        <motion.div
          className="new-job-btn desktop"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full  px-8 py-4"
          >
            <Icon color="action">search</Icon>

            <Input
              placeholder="Search"
              className="flex flex-1 mx-8"
              disableUnderline
              fullWidth
              type={'text'}

              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={(ev) => {
                ev.preventDefault()
                setText(ev.target.value)
              }}
              autoFocus
            />
          </Paper>
        </motion.div>
      </div>
      <div className="job-add">
        <motion.div
          className="new-job-btn desktop"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
          <Button
            component={Link}
            to="/roles-permissions/new"
            className="whitespace-nowrap"
            variant="contained"
            color="secondary"
          >
            <span className="hidden sm:flex ">Add New</span>
            <span className="flex sm:hidden">Add New</span>
          </Button>
        </motion.div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  searchRole: (text, onError) =>
    dispatch(RolesPermissionAction.search(text, onError)),
  getAll: (payload, onError) =>
    dispatch(RolesPermissionAction.getAll(payload, onError)),

})

export default connect(null, mapDispatchToProps)(DataTableToolBar)
