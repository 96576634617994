const fuseDark = {
  50: '#e5e6e8',
  100: '#edca33',
  200: '#464e5b',
  300: '#ffffff',
  400: '#000000',
  500: '#edca33',
  600: '#EDCA33',  
  700: '#edca33',
  800: '#000000',
  900: '#0d121b',
  A100: '#5d8eff',
  A200: '#2a6aff',
  A400: '#004af6',
  A700: '#0042dd',
  contrastDefaultColor: 'light',
};
 
export default fuseDark;
