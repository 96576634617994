import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'modules',
    title: '',
    translate: 'Modules',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'dashboard',
        title: 'DASHBOARD',
        translate: 'DASHBOARD',
        type: 'collapse',
        url: '/dashboard',
        children: [
          {
            id: 'requests',
            title: 'REQUEST BOARD',
            type: 'item',
            url: `/requests`,
          },
        ],
      },
      {
        id: 'jobs',
        title: 'JOBS',
        translate: 'JOBS',
        type: 'collapse',
        url: '/jobs',
        children: [
          {
            id: 'jobs_drafts',
            title: 'JOB DRAFTS',
            type: 'item',
            url: `/jobs-drafts`,
          },
        ],
      },
      {
        id: 'customers',
        title: 'CUSTOMERS',
        translate: 'CUSTOMERS',
        type: 'collapse',
        url: '/customers',
        children: [
          {
            id: 'uninvoiced',
            title: 'UNINVOICED TICKETS',
            translate: '',
            type: 'item',
            url: '/uninvoiced',
          },
        ],
      },
    ],
  },
  {
    id: 'Truck',
    title: 'TRUCK',
    translate: 'TRUCK',
    type: 'collapse',
    url: '/truck',
    children: [
      {
        id: 'mileage_log',
        title: 'FUEL LOG',
        type: 'item',
        url: '/mileages',
      },
      {
        id: 'Traffic_Stops',
        title: 'TRAFFIC STOPS',
        type: 'item',
        url: '/dps',
      },
      {
        id: 'addinspections',
        title: 'INSPECTIONS',
        translate: 'INSPECTIONS',
        type: 'item',
        url: '/inspections',
      },
    ],
  },
  {
    id: 'driverlist',
    title: 'DRIVER LIST',
    type: 'collapse',
    url: `/drivers`,
    children: [
      {
        id: 'timesheet',
        title: 'TIME SHEETS',
        type: 'item',
        url: '/timesheet',
      },
    ],
  },
  {
    id: 'dispatch',
    title: 'DISPATCH',
    translate: 'DISPATCH',
    type: 'collapse',
    url: '/dispatch',
    children: [
      {
        id: 'dispatch_template',
        title: 'DISPATCH ASSETS',
        type: 'item',
        url: `/dispatch/template`,
      },
    ],
  },
  {
    id: 'stafflist',
    title: 'USER LIST',
    type: 'item',
    url: `/users`,
  },

  {
    id: 'subcontractors-new',
    title: 'SUBCONTRACTORS',
    type: 'item',
    url: '/sub-contractor',
    tooltip:
      'Subcontractors are independent truck owners who operate under their own authority. They maintain ownership of their trucks and are responsible for their own subscriptions. As a result, subcontractors do not contribute towards your subscription count',
  },

  {
    id: 'owner-operators',
    title: 'OWNER OPERATORS',
    type: 'item',
    url: '/ownerops',
    tooltip:
      'Owner Operators are independent truck owners without their own Loadz subscription. They count as a subscription seat per driver.',
  },
  {
    id: 'company-list',
    title: 'COMPANY LIST',
    type: 'item',
    url: '/companies',
  },
  {
    id: 'subscriptions-management',
    title: 'SUBSCRIPTION',
    type: 'collapse',
    url: '/subscriptions-management',
    children: [
      {
        id: 'subscriptions-companies',
        title: 'SUBSCRIPTION COMPANIES',
        type: 'item',
        url: '/subscriptions-companies',
      },
      {
        id: 'subscriptions-invoices',
        title: 'SUBSCRIPTION INVOICES',
        type: 'item',
        url: '/subscriptions-invoices',
      },
    ],
  },
  {
    id: 'agent',
    title: 'SALES AGENT',
    type: 'item',
    url: '/agent',
  },
];
/* {
      id: 'salary',
      title: 'EARNINGS',
      translate: '',
      type: 'item',
      // icon: 'money',
      url: '/salary'
    }, */

export default navigationConfig;
