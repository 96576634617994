import {
  Box,
  Icon,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

import client from 'client'
import { showMessage } from 'app/store/fuse/messageSlice'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import AppleIcon from '@mui/icons-material/Apple';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';

const ManageDevices = () => {
  const [loading,setLoading] = useState(false)
  const [devices,setDevices] = useState({})
  const [hasNextPage, setHasPage] = useState(false)
  const [page, setPage] = useState(1)
  const listInnerRef = useRef()
  const [scrollX, setScrollX] = useState(0)
  const dispatch = useDispatch()

  useEffect(async () => {
    setDevices({})
    await fetchRoles()
  }, [])

  const onScroll = async (event) => {
    if (listInnerRef.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
        scrollLeft,
        offsetHeight,
      } = listInnerRef.current
      const allow = scrollLeft > scrollX || scrollLeft < scrollX ? false : true
      setScrollX(scrollLeft)
      if (offsetHeight + scrollTop >= scrollHeight && allow) {
        if (hasNextPage) {
          await fetchRoles(page + 1)
        }
      }
    }
  }

  const fetchRoles = async (page = 1) => {
    try {
      setLoading(true)
      const result = await client.get('/devices', {params: {page}})
      console.log('devices',result)

      if (result?.status === 204) {
        setHasPage(false)
        setLoading(false)
        if (page == 1) {
          setDevices({})
        }
        return 
      }

      if (result.data) {
        let incoming_device = page == 1 ? {} : devices ? devices : {}
        //split the data by OS 
        result.data.map((device) => {
          if(incoming_device?.[device?.browser_info?.os])
          {
            incoming_device?.[device?.browser_info?.os].push(device)
          }
          if(!incoming_device?.[device?.browser_info?.os])
          {
            incoming_device[device?.browser_info?.os] = []
            incoming_device?.[device?.browser_info?.os].push(device)
          }
        })

        //const record = page ==1 ? result.data : [...devices, ...result.data]
          
        setDevices(incoming_device)
        //setHasPage(true)
        setPage(page)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
    
  }

  const handleDelete = async (browser_id) => {
    try {
      const result = await client.post('/devices/remove', {browser_id})
      if (result.status === 200) {
        showError('success', 'Record deleted successfully')
        await fetchRoles(1)
      }
    } catch(e) {
      showError('error', 'Failed to delete record')
      console.log(e)
    }
  }

  

  const showError = (status, message) => {
    return dispatch(
      showMessage({
        message: message, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: status,
      })
    )
  }

  if (Object.keys(devices).length == 0) {
    return <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full moble-content"
      >
        <Typography color="textSecondary" variant="h5">
          There are no devices available. 
        </Typography>
      </motion.div>
  }

  return (

    <div onScroll={onScroll} ref={listInnerRef} className='flex flex-col justify-center align-center items-center'>

    
    { Object?.keys(devices)?.map((key) => {
          return(
              <div  className='flex items-center align-center justify-center pt-16 md:w-4/5'>
                <Box border={1} borderRadius={3} className='flex flex-col sm:flex-row sm:w-2/3'>
                  {/**OS Icon */}
                  <div className='flex p-32 items-center justify-start align-center w-full'>
                    {
                      key.toUpperCase() == 'WINDOWS' ? 
                      <div className='flex'>
                        <DesktopWindowsIcon style={{fontSize: '50px'}}/>
                        <Typography className='pl-6' fontWeight='bold' fontSize={14}>{`${devices?.[key].length} session(s) on Windows`}</Typography>
                      </div>
                       
                      : null
                    }
                    {
                      key.includes('Mac') ? 
                      <div className='flex'>
                        <AppleIcon style={{fontSize: '50px'}}/>
                        <Typography className='pl-6' fontWeight='bold' fontSize={14}>{`${devices?.[key].length} session(s) on Mac`}</Typography>
                      </div>
                       
                      : null
                    }
                    {
                      key.toUpperCase() != 'WINDOWS' && !key.includes('Mac') ? 
                      <div className='flex'>
                        <DeviceUnknownIcon style={{fontSize: '50px'}}/>
                        <Typography className='pl-6' fontWeight='bold' fontSize={14}>{`${devices?.[key].length} session(s) on Unknown Device`}</Typography>
                      </div>
                       
                      : null
                    }
                  </div>

                  <div aria-label='div-list' className='flex w-full'>
                    <List className='w-full'>
                      {
                        devices?.[key]?.map((data, index) => {
                        return( <ListItem key={index} className='flex flex-row w-full'>
                          {/**Browser info */}
                            <div className='flex flex-col items-start align-start justify-start w-full'>
                              <Typography fontWeight='bold'>{data?.browser_info?.os}</Typography>
                              <Typography>{data?.browser_info?.name}</Typography>
                              <Typography>{moment(data?.created_at).format("M/DD/YYYY hh:mm a")}</Typography>
                            </div>
                          {/**delete */}
                            <div  className='flex items-end align-end justify-end w-full'>
                              <Icon style={{ color: '#000',cursor:'pointer' }} onClick={(e) => handleDelete(data.browser_id)}>
                                delete
                              </Icon>
                            </div>
                          </ListItem>)
                        })
                      }
                    </List>
                  </div>
                </Box>
              </div> 
              
          )
        })}
      {loading && <LinearProgress />}
    
     
    </div>
  )
}


export default ManageDevices

/**
 * 
 * 
 *     <div className={clsx('flex flex-1 flex-col m-16 p-24 mobile-p newjobdesign')}>

 * <Box sx={{ width: '100%' }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          
          <Grid item xs={10} className="grid-full">
            <Card className="jobtablecard">
              <CardContent className="jobstable">
                <div className="responsive-loads-table">
                  <TableContainer
                    style={{ maxWidth: '100%', maxHeight: '700px' }}
                    onScroll={onScroll}
                    ref={listInnerRef}
                  >
                    <Table
                      stickyHeader
                      size="small"
                      aria-labelledby="tableTitle"
                      className="pb-32"
                    >
                      <TableHeader />
                      <TableBody>
                        {devices.map((row, index) => (
                          <TableRow
                            className="h-72 cursor-pointer"
                            key={index}
                          >
                            <TableCell className="w-40 md:w-64 text-center">
                              {index + 1}
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              {row.browser_info?.name}
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              {row.browser_info?.version}
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              {row.browser_info?.os}
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              <Icon style={{ color: '#000' }} onClick={(e) => handleDelete(row?.browser_id)}>
                                delete
                              </Icon>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {loading && <LinearProgress />}
                    {!hasNextPage ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.1 } }}
                        className="flex flex-1 items-center justify-center h-full moble-content pb-16"
                      >
                        <Typography color="textSecondary" variant="h5">
                          No more Data
                        </Typography>
                      </motion.div>
                    ) : null}
                  </TableContainer>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
 * 
 *     </div>

 */
