import { useState, useEffect, memo } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import { constants, types_locations_jobs_modal } from '../../../constants';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FuseMessage from '@fuse/core/FuseMessage';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Autocomplete, Typography } from '@mui/material';
import Maps from './location/tabs/Maps';
import store from 'app/store';
import client from 'client';
import QuarryPitMap from './location/tabs/QuarryPitMap';

const schema = yup.object().shape({
  l_name: yup
    .string()
    .required('You must enter a locations name')
    .min(1, 'The locations name must be at least 1 character'),
});

function ModalCreateLocations({ isOpen, setIsOpen, props, setData, info, setLocationStatus,data,company_id }) {
  const dispatch = useDispatch();
  const [location, setlocation] = useState({});
  const [open, setOpen] = useState(isOpen);
  const token = localStorage.token;
  const [direction, setDirection] = useState('');
  const [coor, setCoor] = useState('');
  const [hiddeninfo, setHiddenInfo] = useState(true);
  const [query, setQuery] = useState(null);
  const [customerQuery, setCustomerQuery] = useState(null);

  useEffect(() => {
    setOpen(isOpen);
    if (info.l_name) {
      setValue('l_type', info && info.l_type ? info.l_type : 'origin');
      setValue('l_name', info.l_name);
      setValue('l_address', info.l_address);
      setHiddenInfo(false);
    } else {
      setHiddenInfo(true);
      reset(clearForm);
    }
  }, [isOpen]);

  const handleClose = () => {
    reset(clearForm);
    setOpen(false);
    setIsOpen(false);
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      l_name: '',
      l_address: '',
      l_city: '',
      l_state: '',
      l_zip: '',
      l_type: 'Quarry Pit',
      l_latitude: 0,
      l_longitude: 0,
      l_lgcy_x: 0,
      l_lgcy_y: 0,
      capture_method: '',
      company_id: company_id,
    },
    resolver: yupResolver(schema),
  });

  const clearForm = {
    l_name: '',
    l_address: '',
    l_city: '',
    l_state: '',
    l_zip: '',
    l_type: 'Quarry Pit',
    l_latitude: 0,
    l_longitude: 0,
    l_lgcy_x: 0,
    l_lgcy_y: 0,
    capture_method: '',
    company_id: company_id,

  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const { formState, watch, getValues, reset, control, setValue } = methods;
  const { isValid } = formState;

  const handleCreateLocation = async () => {
    const id = store.getState().loadz_auth?.AuthReducer?.user?.user_id;
    const datos = getValues();
    datos.company_id = data ? data.id : id;
    try {
      const response = await client.post(`/locations`, datos);

      if (response.data.error) {
        _showMessage('Something went wrong, please try again!', 'error');
        return;
      }

      _showMessage('The location has been created!', 'success');
      setData(response.data);
      setCustomerQuery(response.data);
      setOpen(false);
      setLocationStatus(false);
      reset(clearForm);
      setIsOpen(false);
    } catch (error) {
      _showMessage('Something went wrong, please try again!', 'error');
      console.log(error);
    }
  };

  const handleUpdateLocation = async () => {
    try {
      setValue('company_id',company_id)
      if (info) {
        const response = await fetch(`${constants.URLLOCAL}/locations/${info.l_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            token: `${token}`,
          },
          body: JSON.stringify(getValues()),
        });
        const res = await response.json();
        if (res.error) {
          _showMessage('Something went wrong, please try again!', 'error');
          return;
        }
        _showMessage('The location has been Update!', 'success');
        setQuery(res);
        reset(clearForm);
        setData(res);
        setOpen(false);
        setIsOpen(false);
      }
    } catch (error) {
      _showMessage('Something went wrong, please try again!', 'error');
      console.log(error);
    }
  };

  const handleMaps = (e) => {
    const { lat, lng, latLng, dir, cit, stat, zip } = e;
    console.log('ee==>>', e);
    setlocation({ lat, lng });
    setValue('l_latitude', lat ? lat : latLng.lat);
    setValue('l_longitude', lng ? lng : latLng.lng);
    setValue('l_address', dir);
    setValue('l_city', cit);
    setValue('l_state', stat);
    setValue('l_zip', zip);
  };

  var latitud = location.lat;
  var longitud = location.lng;
  var latitudmov = coor.lat;
  var longitudmov = coor.lng;

  const handleSelect = async (value) => {
    location.lat = 0;
    location.lng = 0;
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);

    setDirection(value);
    setCoor(latLng);
    setValue('l_latitude', latLng.lat);
    setValue('l_longitude', latLng.lng);

    result.find((object) => {
      setValue('l_address', object.formatted_address);
    });
  };

  const handleOnClick = (evt) => {
    const { lat, lng } = evt;
    setlocation({ lat, lng });
    setValue('l_latitude', lat);
    setValue('l_longitude', lng);
  };

  const { errors } = formState;

  return (
    <div>
      <FuseMessage />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
        sx={{zIndex: 1000}}
      >
        <DialogTitle>Add New Location (Origin / Quarry) </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out this form with the information requested to create an Origin / Quarry
            Pit. You may select what type using the Type input option and select if your location is
            Origin or Quarry Pit.
          </DialogContentText>
          <br />
          <div>
            <div>
              <FormProvider {...methods}>
                <div>
                  <div className="flex -mx-6">
                    <Controller
                      name="l_name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mt-8 mb-16 mx-4"
                          error={!!errors.name}
                          required
                          helperText={errors?.name?.message}
                          label="Name"
                          autoFocus
                          id="l_name"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>

                  <div className="flex -mx-6">
                    <Controller
                      name="l_address"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          hidden={getValues("l_address") ? false : true}
                          fullWidth
                          className="mt-8 mb-16 mx-4"
                          id="l_address"
                          label="Address"
                          variant="outlined"
                          disabled={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="flex -mx-6">
                    <Controller
                      name="l_type"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          freeSolo
                          className="mt-8 mx-4"
                          options={types_locations_jobs_modal}
                          defaultValue={[types_locations_jobs_modal]}
                          value={value}
                          fullWidth
                          // disabled={true}
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Type"
                              label="Type"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <br />
                </div>
              </FormProvider>
            </div>

            {/* className="w-500 h-512" */}
            <div className="w-500 h-512">
              {/* <Maps
                handleMaps={handleMaps}
                query={query}
                customerQuery={customerQuery}
              ></Maps> */}
              <QuarryPitMap
                handleMaps={handleMaps}
                query={query}
                customerQuery={customerQuery}
              />

            </div>
            <br />
            <br />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="whitespace-nowrap mx-4"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            className="whitespace-nowrap mx-4"
            variant="contained"
            color="secondary"
            disabled={!isValid}
            hidden={!hiddeninfo}
            onClick={handleCreateLocation}
          >
            Create
          </Button>

          <Button
            className="whitespace-nowrap mx-4"
            variant="contained"
            color="secondary"
            hidden={hiddeninfo}
            onClick={handleUpdateLocation}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(ModalCreateLocations);
