import { takeLatest, put, fork, all, call } from 'redux-saga/effects'
import actions from './actions'
import client from '../../../../../client'

export function* watchGetTimesheetSidebarDrivers() {
  yield takeLatest(actions.GET_DRIVERS_IN_TIMESHEET_SIDEBAR, function* ({ payload, onSuccess, onError  }) {
    try {
      const {type, compacctnum, archive, name_search} = payload
      if (name_search) {
        const result = yield call(() => client.get(`/driver/search/all/${compacctnum}/${name_search.trim()}/${type}/${archive}`))

        if (onSuccess) {
          onSuccess(result?.data)
        }

      } else {
        const result = yield call(() => client.post(`/driver/all/drivers/${type}/${archive}`, { compacctnum }))

        if (onSuccess) {
          onSuccess(result?.data)
        }
      }
    } catch (error) {
      if (onError) onError(error)
    }
  })
}

export default function* rootSaga() {
  yield all([fork(watchGetTimesheetSidebarDrivers)])
}
