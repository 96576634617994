const actions = {
  GET_DRIVERS_IN_TIMESHEET_SIDEBAR: 'GET_DRIVERS_IN_TIMESHEET_SIDEBAR',

  getDriverInTimesheetSidebar: (payload, onSuccess, onError) => {
    return {
      type: actions.GET_DRIVERS_IN_TIMESHEET_SIDEBAR,
      payload,
      onError,
      onSuccess
    }
  },
}

export default actions
