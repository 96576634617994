import { takeLatest, put, fork, all, call } from 'redux-saga/effects'
import actions from './actions'
import client from '../../../../../client'
import { getErrorAction, getSuccessAction } from '../utility'
import store from 'app/store'
export function* watchGetRoles() {
  yield takeLatest(actions.GET_All, function* ({ payload, onError }) {
    try {
      const result = yield call(() => client.get('/roles-permissions'))
      yield put({
        type: getSuccessAction(actions.GET_All),
        payload: {
          data: Array.isArray(result.data) ? result.data : [],
          page: payload.params?.page || 1,
          hasNextPage: result?.data?.length || false,
        },
      })
    } catch (error) {
      if (onError) onError(error)
      yield put({
        type: getErrorAction(actions.GET_All),
      })
    }
  })
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, function* ({ payload, onError, onSuccess }) {
    try {
      const result = yield call(() =>
        client.post('/roles-permissions/new', payload)
      )
      yield put({
        type: getSuccessAction(actions.CREATE),
      })

      if (onSuccess) {
        onSuccess(result?.data?.message)
      }
    } catch (error) {
      if (onError) onError(error)
      yield put({
        type: getErrorAction(actions.CREATE),
      })
    }
  })
}

export function* watchUpdate() {
  yield takeLatest(actions.UPDATE, function* ({ payload, onError, onSuccess }) {
    try {
      console.log('update saga')
      const result = yield call(() =>
        client.post(`/roles-permissions/update`, payload)
      )

      if (onSuccess) {
        onSuccess(result?.data?.message)
      }
    } catch (error) {
      if (onError) onError(error)
    }
  })
}

export function* watchGetRole() {
  yield takeLatest(actions.GET_ROLE, function* ({ payload, onError }) {
    try {
      const result = yield call(() =>
        client.get(`/roles-permissions/${payload.id}`)
      )
      yield put({
        type: getSuccessAction(actions.GET_ROLE),
        payload: {
          role: result.data.role,
          permissions: result.data.permissions,
        },
      })
    } catch (error) {
      if (onError) onError(error)
      yield put({
        type: getErrorAction(actions.GET_ROLE),
      })
    }
  })
}

export function* watchSearch() {
  yield takeLatest(actions.SEARCH, function* ({ payload, onError }) {
    try {
      const result = yield call(() =>
        client.get(`/roles-permissions/search/${payload.text}`)
      )
      yield put({
        type: getSuccessAction(actions.GET_All),
        payload: {
          data: Array.isArray(result.data) ? result.data : [],
          page: payload.params?.page || 1,
          hasNextPage: result?.data?.length || false,
        },
      })
    } catch (error) {
      if (onError) onError(error)
      yield put({
        type: getErrorAction(actions.GET_All),
      })
    }
  })
}

export function* watchDelete() {
  yield takeLatest(actions.DELETE, function* ({ payload, onSuccess, onError }) {
    try {
      const result = yield call(() =>
        client.post(`/roles-permissions/delete`, payload)
      )

      if (onSuccess) {
        onSuccess(result?.data?.message)
      }
    } catch (error) {
      if (onError) onError(error)
    }
  })
}

export function* watchGetAssignedPermissions() {
  yield takeLatest(actions.GET_ASSIGNED_PERMISSION, function* ({ onError }) {
    try {
      const result = yield call(() => client.get('/roles-permissions/permission/assigned'))
      console.log(result)
      yield put({
        type: 'ASSIGNED_USER_PERMISSION',
        payload: {permission: result.data}
      })

    } catch (error) {
      if (onError) onError(error)
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRoles),
    fork(watchCreate),
    fork(watchGetRole),
    fork(watchUpdate),
    fork(watchSearch),
    fork(watchDelete),
    fork(watchGetAssignedPermissions)
  ])
}
