import { lazy } from 'react';



const AgentPortalConfig = {
  settings: {
    layout: {
        style : 'layout1',
        config: {

            navbar : {
                display : false,

            },
            toolbar: {
                display : false,
            },
            footer : {
                display : false,

            },
            mode   : 'fullwidth'
        }
    },

},
  routes: [
    {
      path: '/agent-activation/:code',
      component: lazy(() => import('./AgentAccountActivation')),
    },
    {
      path: '/agent-portal',
      component: lazy(() => import('./portal/AgentPortal')),
    },
  ],
};

export default AgentPortalConfig
