import { all } from 'redux-saga/effects'
import JobSaga from '../redux/Jobs/saga'
import JobReporSaga from '../redux/JobsReport/saga'
import FuleLogsSaga from '../redux/FuleLog/saga'
import CompletedJobsSaga from '../redux/DashBoard/CompletedJobs/saga'
import AuthSaga from '../redux/Auth/saga'
import RoleSaga from '../redux/Roles/saga'
import PermissionSaga from '../redux/Permissions/saga'
import RolesPermissions from '../redux/RolesPermissions/saga'
import ClientPortal from '../redux/ClientPortal/saga'
import DriversSaga from '../redux/Drivers/saga'
import TimeSheetSidebarDriverSaga from '../redux/TimeSheetSidebarDriver/saga'

export default function* root() {
  yield all([
    AuthSaga(),
    JobSaga(),
    JobReporSaga(),
    FuleLogsSaga(),
    CompletedJobsSaga(),
    RoleSaga(),
    PermissionSaga(),
    RolesPermissions(),
    ClientPortal(),
    DriversSaga(),
    TimeSheetSidebarDriverSaga()
  ])
}
