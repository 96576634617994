import { lazy } from 'react';

const InvitationAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: [
        '/Invitations',
      ],
      component: lazy(() => import('./InvitationApp')),
    },
    
  ],
};

export default InvitationAppConfig;
