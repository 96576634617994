import { takeLatest, put, fork, all, call } from 'redux-saga/effects';
import actions from './actions';
import client from '../../../../../client';
import { getErrorAction, getSuccessAction } from '../utility';

export function* watchGetFuleLogs() {
  yield takeLatest(actions.GET_FULElOGS, function* ({ payload, onError }) {
    try {
      const result = yield payload.isMileageDef
        ? call(() => client.get(`/mileage/def/${payload.id}?page=${payload.page}`))
        : call(() =>
            client.post(`/mileage/all?page=${payload.page}`, { params: { id: payload.id } })
          );
      yield put({
        type: getSuccessAction(actions.GET_FULElOGS),
        payload: {
          fuleLogs: Array.isArray(result.data) ? result.data : [],
          page: payload.page || 1,
          hasNextPage: result?.data?.length || false,
        },
      });
    } catch (error) {
      if (onError) onError(error);

      yield put({
        type: getErrorAction(actions.GET_FULElOGS),
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(watchGetFuleLogs)]);
}
