import axios from 'axios';
import { constants } from './constants';
import history from '@history';
import moment from 'moment-timezone';

const client = axios.create({
  baseURL: constants.URLLOCAL,
  header: {
    'Content-Type': 'applicationS/json',
    Accept: 'application/json',
  },
  // timeout: 30000,
});

client.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.common['token'] = localStorage.getItem('token');
    config.headers.common['timezone'] = moment.tz.guess(true);
    // console.log(config)
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    if (response.data == 'Token Not Found') {
      console.log('Auth Error 401');
      localStorage.removeItem('token');
      history.push({
        pathname: '/',
      });
    } else {
      return response;
    }
  },
  (error) => {
    if (error?.response?.status === 401) {
      console.log('Auth Error 401');
      localStorage.removeItem('token');
      history.push({
        pathname: '/',
      });
    }
    return Promise.reject(error);
  }
);

client.defaults.headers.common['token'] = localStorage.getItem('token');

export default client;
