import { getErrorAction, getSuccessAction } from '../utility';
const actions = {
  GET_Enable_DRIVERS: 'getEnableDrivers',
  GET_DISABLE_DRIVERS: 'getDisableDrivers',
  GET_DRIVER_SALARY: 'getDriverSalary',
  GET_DRIVER_TIMESHEET: 'getDriverTimeSheet',
  SET_SELECTED_DRIVER: 'setSelectedDriver',
  SET_OPEN_MOBILE_SIDECHAT_BAR: 'setOpenMobileSideChatBar',
  SET_OPEN_USER_SIDE_BAR: 'setOpenUserSideBar',
  SET_CONTACT_SIDEBAR_OPEN: 'setContactSidebarOpen',
  SET_DRIVER_PERIOD: 'setDriverPeriod',
  SEARCH_DRIVERS: 'SearchDrivers',
  GET_DRIVER_SINGLE_TIMESHEET: 'getDriverSingleTimeSheet',
  SET_DRIVER_BATCH_DATA: 'setDriverBatchData',
  SET_SALARY_LOADING: 'setSalaryLoading',

  SearchDrivers: (payload, onError) => {
    const data = {
      type: payload.type,
      name_search: payload.name_search,
      params: { page: payload.page },
      compacctnum: payload.compacctnum,
    };
    return {
      type: actions.SEARCH_DRIVERS,
      payload: data,
      onError,
    };
  },

  getEnableDrivers: ({ page, compacctnum }, onError) => {
    const payload = { params: { page }, compacctnum };
    return {
      type: actions.GET_Enable_DRIVERS,
      payload,
      onError,
    };
  },

  getDisableDrivers: ({ page, compacctnum }, onError) => {
    const payload = { params: { page }, compacctnum };
    return {
      type: actions.GET_DISABLE_DRIVERS,
      payload,
      onError,
    };
  },

  getDriverSalary: (payload, onError) => {
    const data = {
      driver_id: payload.driver_id,
      comp_acct: payload.comp_acct,
      params: { page: payload.page },
    };
    return {
      type: actions.GET_DRIVER_SALARY,
      payload: data,
      onError,
    };
  },

  getDriverTimeSheet: (payload, onError) => {
    return {
      type: actions.GET_DRIVER_TIMESHEET,
      payload,
      onError,
    };
  },

  getDriverSingleTimeSheet: (payload, onError) => {
    return {
      type: actions.GET_DRIVER_SINGLE_TIMESHEET,
      payload,
      onError,
    };
  },

  setMobileSideBarOpen: (payload) => {
    return {
      type: actions.SET_OPEN_MOBILE_SIDECHAT_BAR,
      payload,
    };
  },

  setUserSideBarOpen: (payload) => {
    return {
      type: actions.SET_OPEN_USER_SIDE_BAR,
      payload,
    };
  },

  setSelectedDriver: (payload) => {
    return {
      type: actions.SET_SELECTED_DRIVER,
      payload,
    };
  },

  setContactSidebarOpen: (payload) => {
    return {
      type: actions.SET_CONTACT_SIDEBAR_OPEN,
      payload,
    };
  },

  setDriverPeriod: (payload) => {
    return {
      type: actions.SET_DRIVER_PERIOD,
      payload,
    };
  },

  setDriverBatchData: (payload) => {
    return {
      type: actions.SET_DRIVER_BATCH_DATA,
      payload,
    };
  },

  setSalaryLoading: (payload) => {
    return {
      type: actions.SET_SALARY_LOADING,
      payload,
    };
  },
};

export default actions;
