import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { constants } from '../../../../constants'
import axios from 'axios'
import store from 'app/store'
import client from 'client'
//obtiene los camiones asignados y no asignados
export const getContacts = createAsyncThunk(
  'chatApp/contacts/getContacts',
  async (params) => {
    const state = store.getState()
    const comp_id = state.loadz_auth.AuthReducer.user.user_id
    const response = await client.post(`/new-inspections/trucks/all`, {
      comp_id,
    })
    const data = await response.data
    return data
  }
)

const contactsAdapter = createEntityAdapter({})

export const { selectAll: selectContacts, selectById: selectContactById } =
  contactsAdapter.getSelectors((state) => state.NewInspectionsApp.contacts)

function parsear(data) {
  const jsonn = JSON.parse(JSON.stringify(data).replace('idx_trucks_p', 'id'))
  return jsonn
}

const contactsSlice = createSlice({
  name: 'NewInspectionsApp/contacts',
  initialState: contactsAdapter.getInitialState({
    selectedContactId: null,
  }),
  reducers: {
    setSelectedContactId: (state, action) => {
      state.selectedContactId = action.payload
    },
    removeSelectedContactId: (state, action) => {
      state.selectedContactId = null
    },
  },
  extraReducers: {
    [getContacts.fulfilled]: (state, action) => {
      const data = action.payload
      const datos = data.map(parsear)
      contactsAdapter.setAll(state, datos)
    },
  },
})

export const { setSelectedContactId, removeSelectedContactId } =
  contactsSlice.actions

export default contactsSlice.reducer
