import AppBar from '@mui/material/AppBar';
import { styled, alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import GlobalStyles from '@mui/material/GlobalStyles';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Clients from './Clients'
import ClientsList from './ClientsList'
import ClientsData from './ClientsData'
import { ListItemText } from '@mui/material';
import { useState } from 'react';
import { FactCheck } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Tooltip from '@mui/material/Tooltip';

import withReducer from 'app/store/withReducer';
import { useDispatch, useSelector } from 'react-redux';
import reducer from './store';
import { closeMobileChatsSidebar, openMobileChatsSidebar } from './store/sidebarSlice';
import { formatter, constants } from '../../../../../constants';
import ErrorBoundary from 'app/main/ErrorBoundary';

const drawerWidth = 230;
const headerHeight = 200;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100%',
  position: 'relative',
  flex: '1 1 auto',
  height: 'auto',
  backgroundColor: theme.palette.background.default,

  '& .ChatApp-topBg': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: headerHeight,
    // backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
    backgroundColor: theme.palette.primary.dark,
    backgroundSize: 'cover',
    pointerEvents: 'none',
  },

  '& .ChatApp-contentCardWrapper': {
    position: 'relative',
    padding: 24,
    maxWidth: 1400,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    width: '100%',
    minWidth: '0',
    maxHeight: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
  },

  '& .ChatApp-contentCard': {
    display: 'flex',
    position: 'relative',
    flex: '1 1 100%',
    flexDirection: 'row',
    // backgroundImage: 'url("/assets/images/patterns/rain-grey.png")',
    backgroundColor: theme.palette.background.paper,
    minHeight: 0,
    overflow: 'hidden',
  },

  '& .ChatApp-contentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    zIndex: 10,
    background: `linear-gradient(to bottom, ${alpha(theme.palette.background.paper, 0.8)} 0,${alpha(
      theme.palette.background.paper,
      0.6
    )} 20%,${alpha(theme.palette.background.paper, 0.8)})`,
  },

  '& .ChatApp-content': {
    display: 'flex',
    flex: '1 1 100%',
    minHeight: 0,
  },
}));

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    maxWidth: '100%',
    overflow: 'hidden',
    // height: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
}));


function ClientsApp({ 
  rows, 
  openModal,
  isAllJobs,
  setIsAllJobs,
  tickets,
  setTickets,
  jobsToInvoice,
  setJobsToInvoice,
  totalTicketToInvoice,
  setTotalTicketToInvoice,
  infoToInvoice,
  setInfoToInvoice,
  loading,

  setName,
  setEmail,
  setAvatar,
  setCity,
  setAddress,
  setZip,
  setCreditLimit,
  setBalance,
  setPhone,
  setCustomerTerm,
  setCustomerName,
  setCustomer,

  name,
  email,
  address,
  city,
  zip,
  creditLimit,
  phone,
  customerTerm,
  customerName,
  customer,
  balance,
  avatar,

  ticket,
  setTicket,

  handleTicketSearch
}) {

  const mobileChatsSidebarOpen = useSelector(( clientsApp ) => clientsApp.ClientsApp.sidebar.mobileChatsSidebarOpen);
   const dispatch = useDispatch()
 
  
  const [indexJob, setIndexJob] = useState(null)

  const handleClick = () => {
    console.log(indexJob)
    let counterTime = 0
    rows[indexJob].customer.jobs.map((row, i) => {
      setTimeout(() => {
        document.getElementById(`${row.jobToDOM}`).click()
      }, 10)
      counterTime += 100
    })

    setTimeout(() => {
      openModal.current()
    }, counterTime)
  }


  return (
    <ErrorBoundary>
      <GlobalStyles
        styles={(theme) => ({
          '#fuse-main': {
            height: '100vh',
          },
        })}
      />
      <Root>
        <div className="ChatApp-topBg" />

        <div className={clsx('ChatApp-contentCardWrapper', 'container')}>
          <div className={clsx('ChatApp-contentCard', 'shadow rounded-20')}>
            <Hidden mdUp>
              <StyledSwipeableDrawer
                className="h-full absolute z-20"
                variant="temporary"
                anchor="left"
                open={mobileChatsSidebarOpen}
                onOpen={(ev) => { }}
                onClose={() => dispatch(closeMobileChatsSidebar())}
                disableSwipeToOpen
                classes={{
                  paper: 'absolute ltr:left-0 rtl:right-0',
                }}
                style={{ position: 'absolute' }}
                ModalProps={{
                  keepMounted: true,
                  disablePortal: true,
                  BackdropProps: {
                    classes: {
                      root: 'absolute',
                    },
                  },
                }}
              >
                <Clients 
                  handleTicketSearch={handleTicketSearch}
                  ticket={ticket}
                  setTicket={setTicket}
                  loading={loading}
                  rows={rows}
                  setName={setName}
                  setEmail={setEmail}
                  setAvatar={setAvatar}
                  setCity={setCity}
                  setAddress={setAddress}
                  setZip={setZip}
                  setCreditLimit={setCreditLimit}
                  setBalance={setBalance}
                  setPhone={setPhone}
                  setCustomerTerm={setCustomerTerm}
                  setCustomerName={setCustomerName}
                  setInfoToInvoice={setInfoToInvoice}
                  setTickets={setTickets}
                  indexJob={indexJob}
                  setIndexJob={setIndexJob}
                  setCustomer={setCustomer}
                  />
              </StyledSwipeableDrawer>
            </Hidden>
            <Hidden mdDown>
              <StyledSwipeableDrawer
                className="h-full z-20"
                variant="permanent"
                open
                onOpen={(ev) => { }}
                onClose={(ev) => { }}
              >
                <Clients
                  handleTicketSearch={handleTicketSearch}
                  ticket={ticket}
                  setTicket={setTicket}
                  loading={loading}
                  rows={rows}
                  setName={setName}
                  setEmail={setEmail}
                  setAvatar={setAvatar}
                  setCity={setCity}
                  setAddress={setAddress}
                  setZip={setZip}
                  setCreditLimit={setCreditLimit}
                  setBalance={setBalance}
                  setCustomerTerm={setCustomerTerm}
                  setCustomerName={setCustomerName}
                  setInfoToInvoice={setInfoToInvoice}
                  setPhone={setPhone}
                  setTickets={setTickets}
                  indexJob={indexJob}
                  setIndexJob={setIndexJob}
                  setCustomer={setCustomer}
                />
              </StyledSwipeableDrawer>
            </Hidden>


            <main className={clsx('ChatApp-contentWrapper', 'z-10', 'main-uninvoiced-details')}>
              <>
                <AppBar className="w-full " elevation={0} position="static">
                  <Toolbar className="px-16 justify-end">
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={() => dispatch(openMobileChatsSidebar())}
                      className="flex md:hidden"
                      size="large"
                    >
                      <Icon>chat</Icon>
                    </IconButton>
                    <div
                      className="w-full flex flex-col items-start cursor-pointer justify-items-start"
                      role="button"
                      tabIndex={0}
                    >
                      <div className="relative mx-8">
                        <div className="absolute right-0 bottom-0 -m-4 z-10">

                        </div>
                      </div>
                      <h1 className='custom-heading'>Uninvoiced Details</h1>
                    </div>
                  </Toolbar>
                </AppBar>

                <div className="ChatApp-content custom-uninvoiced">
                      {name || customerName ?
                      <div className='new-uninvoiced-details flex items-end justify-between m-16'>
                      
                        <div className='main-column'>
                          <div class='user-detail'> 

                          {customer ?<h4 className='invoice-main-title font-bold'>{customer}</h4> :null}
                            {customerName ? <div className="user-name cst_user">
                              <strong className='mr-3'>Contact Name:</strong>
                              {customerName}
                            </div> :null}
                           

                            {city && address && zip && name ? <div className='user-add cst_user'>
                              <strong className='mr-3'>Address:</strong>
                              {name}, {city}, {address}, {zip}
                            </div> :null}
                           
        
                            {email ? <div className='user-email cst_user'>
                              <strong className='mr-3'>Email:</strong>
                              <a style={{color:"blue"}} href="mailto:gabgarsolution@gmail.com">{email}</a>
                            </div> :null}

                            {phone?  <div className='user-number cst_user'>
                              <strong className='mr-3'>Phone Number:</strong>
                               {phone}
                            </div>:null}
                          </div>
                        </div>

                        <div className='main-column'>
                          <div className='user-price'>
                            {creditLimit ? <div className='user-add'>
                              <strong className='mr-3'>Credit Limit:</strong>
                             {formatter.format(Number(creditLimit))}  
                          </div> :null}
                          {balance ? <div className='user-add'>
                              <strong className='mr-3'>Balance:</strong>
                                 {balance}
                          </div>:null}
                          
                          {customerTerm ?<div className='user-add'>
                              <strong className='mr-3'>Net:</strong>
                              {customerTerm}  
                          </div> :null}
                          
                          </div>
                        </div>
                   
                        <div className='main-column'>
                          <div className='user-edit-detail'>
                            <div className='flex items-end gap-40'>
                              <div className="lodaz-icon">
                              {
                                indexJob !== null &&
                                <Tooltip title="Invoice all jobs" placement="top" style={{ textAlign: "right" }}>
                                  <ListItemText>
                                    <IconButton
                                      onClick={() => handleClick()}>
                                      <FileUploadIcon />
                                    </IconButton>
                                  </ListItemText>
                                </Tooltip>
                              }
                                <span className='icon-title'>Invoice Selected</span>
                              </div>
                              <div className='lodaz-icon'> 
                                  <img src="/assets/images/printer.svg" alt="" className="m-auto"/>
                                  <span className='icon-title'>Archive</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :null}
                  <ClientsData 
                    infoToInvoice={infoToInvoice} 
                    openModal={openModal}
                    isAllJobs={isAllJobs}
                    setIsAllJobs={setIsAllJobs}
                    setTickets={setTickets}
                    jobsToInvoice={jobsToInvoice}
                    setJobsToInvoice={setJobsToInvoice}
                    totalTicketToInvoice={totalTicketToInvoice}
                    setTotalTicketToInvoice={setTotalTicketToInvoice}
                    tickets={tickets}
                  ></ClientsData>
                  {/* <Chat className="flex flex-1 z-10" /> */}
                </div>
              </>


            </main>


          </div>
        </div>
      </Root>
    </ErrorBoundary>
  );
}

// export default ClientsApp;
export default withReducer('ClientsApp', reducer)(ClientsApp);
