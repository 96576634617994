import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { constants } from '../../../../constants';


export const getSalary = createAsyncThunk(
  'drivers/salary/getSalary',
  async (info, {dispatch}) => {
    const {comp_acct,driver_id} = info
    dispatch(setsLoading(true))
    const response = await axios.post(`${constants.URLLOCAL}/salary/single`, {comp_acct,driver_id}, {headers: {'token' : localStorage.getItem('token')}});
    var data = await response.data;
    var {driver_breakdowns} = data
    driver_breakdowns.sort((a,b) => {
      if(b.name < a.name)
      {return 1}
      else{return -1}
    })
    console.log(driver_breakdowns)

    return {driver_breakdowns}
  }
);

/* export const getSalaryEnabled = createAsyncThunk(
  'drivers/salary/getSalary/enabled',
  async (comp_acct) => {
    const response = await axios.post(`${constants.URLLOCAL}/salary/all/enabled`, {comp_acct}, {headers: {'token' : localStorage.getItem('token')}});
    var data = await response.data;
    var {driver_breakdowns} = data
    driver_breakdowns.sort((a,b) => {
      if(b.name < a.name)
      {return 1}
      else{return -1}
    })
    console.log(driver_breakdowns)

    return {driver_breakdowns}
  }
); */

export const getTimeSheets = createAsyncThunk(
  'drivers/salary/getTimeSheets',
  async (info,{dispatch}) => {
    const {comp_acct,driver_id} = info
    dispatch(settLoading(true))
    console.log('getTimeSheets :: ',info )
    const response = await axios.post(`${constants.URLLOCAL}/salary/timesheets/single`, {comp_acct,driver_id}, {headers: {'token' : localStorage.getItem('token')}});
    const data = await response.data;
    console.log('getTimeSheets :: ', data)
    const {shift_breakdowns} = data
    return {shift_breakdowns}
  }
);


/* export const getTimeSheetsEnabled = createAsyncThunk(
  'drivers/salary/getTimeSheets/enabled',
  async (comp_acct) => {
    const response = await axios.post(`${constants.URLLOCAL}/salary/timesheets/enabled`, {comp_acct}, {headers: {'token' : localStorage.getItem('token')}});
    const data = await response.data;
    console.log(data)
    const {shift_breakdowns} = data
    return {shift_breakdowns}
  }
); */

  const salaryAdapter = createEntityAdapter({});

export const { selectAll: selectSalary, selectById: selectSalaryById } =
  salaryAdapter.getSelectors((state) => state.drivers.salary);

  


  function parsear(data) {
    const jsonn = JSON.parse(JSON.stringify(data).replace("id", 'id'));///////
    return jsonn;
     
  }

const salaryslice = createSlice({
  name: 'drivers/salary',
  initialState: salaryAdapter.getInitialState({
    loading: false,
    sloading: null,
    tloading: null,
    searchText: '',
    salaryDialog: {
      props: {
        open: false,
      },
      data: null,
    },
    period : 0,
    tab : 0,
    index : 0
  }),
  reducers: {
    setSalarySearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    openSalaryDialog: (state, action) => {
      state.salaryDialog = {
        props: {
          open: true,
        },
        data: action.payload,
      };
    },

    closeSalaryDialog: (state, action) => {
      state.salaryDialog = {
        props: {
          open: false,
        },
        data: '',
      };
    },
    setPeriod: (state, action) => {
      state.period = action.payload
      
      console.log(state.period)
    },
    setTab: (state, action) => {
      state.tab = action.payload      
    },
    setIndex: (state, action) => {
      state.index = action.payload  
      console.log("NEW INDEX", state.index)    
    },
    clearSalary: (state, action) => {
      salaryAdapter.setAll(state, []);
        state.searchText = '';
    },
    clearTimesheets: (state, action) => {
      state.timesheets= null
      
    },
    setsLoading: (state, action) => {
      state.sloading= action.payload
      
    },
    settLoading: (state, action) => {
      state.tloading= action.payload
      
    },
  },
  extraReducers: {
    [getSalary.fulfilled]: (state, action) => {
      const { driver_breakdowns } = action.payload;
      if (driver_breakdowns && driver_breakdowns.length > 0){
        let datos = driver_breakdowns.map(parsear)
        datos.sort((a,b) => b.name < a.name)
        salaryAdapter.setAll(state, datos);
        state.searchText = '';
        console.log("fulfilled salary")
      }
      else{
        let datos = []
        datos.sort((a,b) => b.name < a.name)
        salaryAdapter.setAll(state, datos);
        state.searchText = '';
        console.log("fulfilled salary")
      }
      state.sloading = false
    },
    [getTimeSheets.fulfilled]: (state, action) => {
      const { shift_breakdowns } = action.payload;
      const datos = shift_breakdowns? shift_breakdowns?.map(parsear)  : []
      state.timesheets = datos;
      console.log("fulfilled timesheets")
      state.tloading = false

    }
   
  },
});

export const {
    openSalaryDialog,
    closeSalaryDialog,
    setSalarySearchText,
    setPeriod,
    setTab,
    setIndex,
    clearSalary,
    clearTimesheets,
    setsLoading,
    settLoading
  } = salaryslice.actions;
  
  export default salaryslice.reducer;