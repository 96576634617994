const actions = {
  GET_FULElOGS : 'getFuleLogs',
  UPDATE_MILEAGE_DEF: 'updateMileageDef',
  UPDATE_MILEAGE_DEF_DATE :'updateMileageDefDate',

  getFuleLogs : ({page, id, isMileageDef}, onError) => {
    const payload = { page, id, isMileageDef}
    return {
      type: actions.GET_FULElOGS,
      payload,
      onError
    }
  },

  checkMileageDef: (isChecked) => {
    const payload = {isChecked}
    return {
      type: actions.UPDATE_MILEAGE_DEF,
      payload
    }
  },

  updateMileageDef: (updateMileage) => {
    const payload = {updateMileage}
    return {
      type: actions.UPDATE_MILEAGE_DEF_DATE,
      payload
    }
  },


  
}

export default actions