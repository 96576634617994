import { useState, useEffect, memo } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControl from '@mui/material/FormControl';
import * as yup from 'yup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FuseMessage from '@fuse/core/FuseMessage';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import {
  Autocomplete,
  Typography,
  Divider,
  Fab,
  InputAdornment,
  InputLabel,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { v4 as uuidv4 } from 'uuid';
import { formatter, states } from '../../../constants';
import { formatPhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useHistory } from 'react-router-dom';
import client from 'client';
const phoneregex = RegExp('[0-9]');

const schema = yup.object().shape({
  customer_name: yup.string().required('You must enter a customer_name').nullable(false),
  //customer_email: yup.string().required(),
});

function ModalCreateCustomer({ isOpen, setIsOpen, data }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isOpen);
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [createLocation, setCreateLocation] = useState(false);
  const token = localStorage.token;

  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), title: '', name: '', email: '', phone: '' },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("InputFields", inputFields);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      setInputFields([...inputFields, { id: uuidv4(), title: '', name: '', email: '', phone: '' }]);
      setValue('contact', inputFields);
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { id: uuidv4(), title: '', name: '', email: '', phone: '' }]);
    setValue('contact', inputFields);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  useEffect(() => {
    // getAllLocations()
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      customer_name: '',
      customer_email: '',
      customer_phone: '',
      credit_limits: formatter.format(Number(100000)),
      contact: [],
      term: '3',
    },
    resolver: yupResolver(schema),
  });

  const clearForm = {
    customer_name: '',
    customer_email: '',
    customer_phone: '',
    l_address: '',
    l_city: '',
    l_state: '',
    l_zip: '',
    contact: [],
    customer_website: '',
    customer_notes: '',
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
          </>
        ), //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const { formState, watch, getValues, reset, setValue, control } = methods;
  const { isValid } = formState;

  const handleCreateCustomer = async () => {
    try {
      var newValues = getValues();
      if (newValues.customer_phone) {
        newValues.customer_phone = parsePhoneNumber(newValues.customer_phone, 'US').nationalNumber;
        newValues.contact.forEach((elem) => {
          if (elem.phone) {
            elem.phone = parsePhoneNumber(elem.phone, 'US').nationalNumber;
          } else {
            elem.phone = '';
          }
        });
      } else {
        newValues.customer_phone = '';
      }

      await client.post(`/customers/new${data ? `/${data.compacctnum}` : ''}`, newValues);

      _showMessage('The customer has been created!', 'success');

      reset(clearForm);
      setOpen(false);
      setIsOpen(false);
      history.push('/jobs/new');
    } catch (error) {
      if (error?.response?.data?.message) {
        _showMessage(error?.response?.data?.message, 'error');
      } else {
        _showMessage(`Oh! please try again, something went wrong`, 'error');
      }
      reset(clearForm);
      setOpen(false);
      setIsOpen(false);
    }
  };

  const { errors } = formState;
  //TODO LINE 197 improved message.
  return (
    <div>
      <FuseMessage />
      <Dialog
        open={open}
        maxWidth={'lg'}
        fullWidth={true}
        onClose={handleClose}
        className="add-new-customer job-notes"
      >
        <DialogTitle>Add new customer</DialogTitle>
        <DialogContent className="add-new-customer-p">
          <DialogContentText>Enter the details below to create a new customer.</DialogContentText>
          <br />
          <FormProvider {...methods}>
            <div>
              <br />
              <div className="flex mt-8 mb-16  mx-2 margin-x-0 form-new-customer">
                <Controller
                  name="customer_name"
                  required
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-2 margin-x-0"
                      helperText={!!errors.customer_name ? 'Please fill with customer name' : ''}
                      label="Customer Name"
                      autoFocus
                      id="customer_name"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />

                <Controller
                  name="customer_email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-2 margin-x-0"
                      label="Customer Email"
                      id="customer_email"
                      variant="outlined"
                      type={'email'}
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="customer_phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value}
                      className="mt-8 mb-16 mx-2 margin-x-0"
                      label="Customer Phone"
                      id="customer_phone"
                      variant="outlined"
                      type={'name'}
                      fullWidth
                      onInput={(e) => {
                        if (!phoneregex.test(e.target.value.slice(-1))) {
                          e.target.value = e.target.value.slice(0, -1);
                        }
                        const newphone = formatPhoneNumber('+1' + e.target.value);
                        // console.log(newphone)

                        if (newphone.length > 1) {
                          e.target.value = newphone;
                          // console.log("PREVIEW", parsePhoneNumber(newphone, "US"))
                          value = newphone;
                          onChange(value);
                        } else {
                          // console.log("NOT NEW PHONE ")
                          e.target.value = e.target.value;
                          onChange(e.target.value);
                        }
                      }}
                      inputProps={{ maxLength: 10 }}
                    />
                  )}
                />
              </div>

              <Typography
                variant="h6"
                gutterBottom
                component="div"
                className="job-notes-heading"
              >
                Address
              </Typography>
              {/* <Controller
        name="location_id"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            className="mt-8 mb-16 mx-2"
            multiple
            freeSolo
            // value={value}
            // getOptionDisabled={(options) => (value ? value.length == 1 ? true : false : null)}
            options={loca}
            onChange={(event, newValue) => {
              setCreateLocation(false)
              newValue.map(r => {
                if (r.value == 0) {
                  setCreateLocation(true)
                }

                // }else{
                //   locationsOrigin.forEach(loc => {
                //     if(loc.l_id === r.value){
                //       console.log(loc)
                //       setLocationQuarry({
                //         name: loc.l_name,
                //         lat: loc.l_latitude,
                //         lng: loc.l_longitude
                //       })
                //       console.log(locationQuarry)
                //     }
                //   })

                // }
              })
              onChange(newValue);
            }}
            fullWidth

            renderInput={(params) => (
              <TextField
                {...params}
                id={loca}
                placeholder="Select location"
                label="Location"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              // error={value.length == 0 ? true : false}
              // helperText={value.length == 0 ? 'Please select quarry pit for this job' : ''}
              />
            )}
            limitTags={1}
          />
        )}
      /> */}

              <div className="flex -mx-6 margin-x-0 form-new-customer">
                {/* <Controller
          name="l_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-8 mb-16 mx-4"
              error={!!errors.name}
              required
              helperText={errors?.name?.message}
              label="Name"
              autoFocus
              id="l_name"
              variant="outlined"
              fullWidth
            />
          )}
        /> */}

                <Controller
                  name="l_address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-4 margin-x-0"
                      id="l_address"
                      label="Address"
                      variant="outlined"
                      fullWidth
                      // disabled={true}
                    />
                  )}
                />

                <Controller
                  name="l_city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-4 margin-x-0"
                      id="l_city"
                      placeholder="City"
                      label="City"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="l_state"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      freeSolo
                      className="mt-8 mb-16 mx-4 margin-x-0 state"
                      options={states}
                      // defaultValue={[states]}
                      value={value}
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select State"
                          label="State"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name="l_zip"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-4 margin-x-0"
                      id="l_zip"
                      label="Zip"
                      variant="outlined"
                      fullWidth
                      type={'name'}
                      onInput={(e) => {
                        if (!phoneregex.test(e.target.value.slice(-1))) {
                          e.target.value = e.target.value.slice(0, -1);
                        }
                      }}
                      inputProps={{ maxLength: 5 }}
                    />
                  )}
                />
              </div>

              <Typography
                variant="h6"
                gutterBottom
                component="div"
                className="job-notes-heading contact-h"
              >
                Contact
              </Typography>

              <Controller
                name="contact"
                control={control}
                render={({ field }) => (
                  <>
                    {inputFields.map((inputField) => (
                      <div
                        key={inputField.id}
                        className="flex mx-6 margin-x-0 form-new-customer"
                      >
                        <TextField
                          name="title"
                          className="mt-8 mb-16 mx-2 margin-x-0"
                          label="Title"
                          type={'text'}
                          variant="outlined"
                          value={inputField.title}
                          onChange={(event) => handleChangeInput(inputField.id, event)}
                          fullWidth
                          InputProps={{
                            shrink: true,
                          }}
                        />

                        <TextField
                          className="mt-8 mb-16 mx-2 margin-x-0"
                          label="Name"
                          type={'text'}
                          name="name"
                          variant="outlined"
                          value={inputField.name}
                          onChange={(event) => handleChangeInput(inputField.id, event)}
                          fullWidth
                          InputProps={{
                            shrink: true,
                          }}
                        />

                        <TextField
                          className="mt-8 mb-16 mx-2 margin-x-0 email"
                          label="Email"
                          type={'email'}
                          name="email"
                          variant="outlined"
                          value={inputField.email}
                          onChange={(event) => handleChangeInput(inputField.id, event)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                            shrink: true,
                          }}
                          fullWidth
                        />

                        <TextField
                          className="mt-8 mb-16 mx-2 margin-x-0 phone"
                          label="Phone"
                          name="phone"
                          type={'name'}
                          variant="outlined"
                          value={inputField.phone}
                          onInput={(e) => {
                            if (!phoneregex.test(e.target.value.slice(-1))) {
                              e.target.value = e.target.value.slice(0, -1);
                            }
                            const newphone = formatPhoneNumber('+1' + e.target.value);
                            // console.log(newphone)
                            if (newphone.length > 1) {
                              e.target.value = newphone;
                              // console.log(parsePhoneNumber(newphone, "US"))
                            }
                            handleChangeInput(inputField.id, e);
                          }}
                          inputProps={{ maxLength: 10 }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                            shrink: true,
                          }}
                          fullWidth
                        />

                        <div
                          className="mt-8 mb-16 mx-2 margin-x-0 customer-addbtn addbtn"
                          style={{ alignSelf: 'center' }}
                        >
                          <InputAdornment position="start">
                            <Tooltip title="Add Contact">
                              <Fab
                                color="primary"
                                size="small"
                                onClick={handleAddFields}
                              >
                                <AddIcon />
                              </Fab>
                            </Tooltip>
                          </InputAdornment>
                        </div>

                        <div
                          className="mt-8 mb-16 mx-2 margin-x-0 remove-btn"
                          style={{ alignSelf: 'center' }}
                        >
                          <InputAdornment position="start">
                            <Tooltip title="Remove Contact">
                              <Fab
                                color="danger"
                                size="small"
                                disabled={inputFields.length === 1}
                                onClick={() => handleRemoveFields(inputField.id)}
                              >
                                <RemoveIcon />
                              </Fab>
                            </Tooltip>
                          </InputAdornment>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              />
              <Divider></Divider>

              <div className="flex mt-8 mb-16  mx-2 margin-x-0 customer_website-mb-0">
                <Controller
                  name="customer_website"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-2 margin-x-0 customer_website"
                      label="Website"
                      id="customer_website"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="flex mt-8 mb-16 margin-b-0 mx-2 margin-x-0 term-credit-limt">
                <FormControl fullWidth>
                  <InputLabel className="term">Term</InputLabel>
                  <Controller
                    id="term"
                    name="term"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        variant="outlined"
                        className="mt-8 mb-16 mx-2 margin-x-0"
                      >
                        <MenuItem
                          key="1"
                          value={'1'}
                        >
                          Net 1
                        </MenuItem>
                        <MenuItem
                          key="2"
                          value={'3'}
                        >
                          Net 3
                        </MenuItem>
                        <MenuItem
                          key="3"
                          value={'7'}
                        >
                          Net 7
                        </MenuItem>
                        <MenuItem
                          key="4"
                          value={'15'}
                        >
                          Net 15
                        </MenuItem>
                        <MenuItem
                          key="5"
                          value={'30'}
                        >
                          Net 30
                        </MenuItem>
                        <MenuItem
                          key="6"
                          value={'45'}
                        >
                          Net 45
                        </MenuItem>
                        <MenuItem
                          key="7"
                          value={'60'}
                        >
                          Net 60
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <Controller
                  name="credit_limits"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-2 margin-x-0 margin-b-0"
                      label="Credit Limit"
                      id="credit_limits"
                      variant="outlined"
                      // type={'number'}
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="flex mt-8 mb-16 mx-2 margin-x-0 margin-b-0">
                <Controller
                  name="customer_notes"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-2 margin-x-0 margin-b-0"
                      label="Customer Notes"
                      id="customer_notes"
                      variant="outlined"
                      type={'number'}
                      fullWidth
                      multiline
                      rows={3}
                    />
                  )}
                />
              </div>

              {/* <div className="flex flex-row flex-wrap">
        <div className="w-full md:w-1/2 p-16 min-h-420 h-420">
          <ReactApexChart
            options={[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]}
            series={[30, 40, 45, 50, 49, 60, 70, 91]}
            type='bar'
            height={500}
          />
        </div>
        <div className="flex w-full md:w-1/2 flex-wrap p-8">
          <div className="w-full sm:w-1/2 p-12">
            <Typography
              className="text-12 font-semibold whitespace-nowrap"
              color="textSecondary"
            >
              hola 
            </Typography>
            <Typography className="text-32 font-semibold tracking-tighter">
              hola 2
            </Typography>
            <div className="h-64 w-full overflow-hidden">
              <ReactApexChart
                options={[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]}
                series={[30, 40, 45, 50, 49, 60, 70, 91]}
                type='bar'
                height={500}
              />
            </div>
          </div>
        </div>
      </div> */}
            </div>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!isValid}
            onClick={handleCreateCustomer}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(ModalCreateCustomer);
