import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ModelInvoiceEmailSend from "./ModelInvoiceEmailSend";
// import { useEffect, useState } from "react";
// import { functionsIn } from "lodash";
// import TicketMultiEmail from "./TicketMultiEmail";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
const ModelInvoiceEmail = (props) => {
  const emailHandleClose = () => props.emailhandleOpen();

  return (
      <div>
      <Modal
       open={props.open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // {...props}
        onClose={emailHandleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <Button
            variant="contained"
            className="absolute top-4 right-4"
            onClick={emailHandleClose}
          >
            Close
          </Button>
          <Typography
            id="transition-modal-title"
            variant="h4"
            component="h2"
            className="text-center pb-8"
          >
            Enter the emails
          </Typography>
          <ModelInvoiceEmailSend {...props} />
        </Box>
      </Modal>
    </div>
   
  );
}

export default ModelInvoiceEmail
