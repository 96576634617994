import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// import GoogleMap from 'google-map-react';
import { useJsApiLoader, GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState, useRef, createRef } from 'react';
import { de } from 'date-fns/esm/locale';

const center = { lat: 30.2715029, lng: -97.8036324 };

function Maps(props) {
  const { destination, quarry, setWaypoint } = props;
  const [locationQuarry, setLocationQuarry] = useState({});
  const [directionRes, setDirectionRes] = useState(null);
  const [mapCopy, setMapCopy] = useState(null);
  const methods = useFormContext();
  const { setValue } = methods;
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');

  const mapRef = createRef();

  // console.log('propspropsprops => ', props);

  const geocoder = new google.maps.Geocoder();

  const geocodePlaceId = async (placeId) => {
    return await geocoder
      .geocode({ placeId: placeId })
      .then(({ results }) => {
        return results;
      })
      .catch((e) => {
        return null;
      });
  };

  const displayNewRoutes = async (geocoded_waypoints, directionsService, directionsRenderer) => {
    let wayPoint = [];
    for (const obj of geocoded_waypoints) {
      let location = await geocodePlaceId(obj.place_id);
      if (location && location.length > 0) {
        let gerometry = location[0]?.geometry.location;
        let lat = gerometry?.lat();
        let lng = gerometry?.lng();
        wayPoint.push({ lat, lng });
        if (wayPoint && wayPoint.length > 0) {
          let data = JSON.stringify(wayPoint);
          setValue('route', data);
        }
      }
    }
  };
  // useEffect(() => {
  //   try {
  //     initMap();
  //   } catch (e) {
  //     console.log("Error in Log", e);
  //   }
  // }, [destination]);

  let mapsAll;
  const initMap = () => {
    var lat_lng = { lat: 30.2715029, lng: -97.8036324 };
    var directionsService = new google.maps.DirectionsService();
    mapsAll = new google.maps.Map(document.getElementById('map'), {
      zoom: 9,
      center: lat_lng,
    });
    setMapCopy(mapsAll);
    var directionsDisplay = new google.maps.DirectionsRenderer({
      draggable: true,
      map: mapsAll,
      markerOptions: {
        clickable: false,
        draggable: false,
      },
    });
    calculateAndDisplayRoute(directionsService, directionsDisplay);
  };

  function calculateAndDisplayRoute(directionsService, directionsDisplay) {
    directionsDisplay.addListener('directions_changed', () => {
      const directions = directionsDisplay.getDirections();
      if (directions && directions.geocoded_waypoints && directions.geocoded_waypoints.length > 0) {
        setDistance(directions.routes[0].legs[0].distance.text);
        let wayPty = [];
        directions.geocoded_waypoints.forEach((element) => {
          if (element) {
            let newWaypty = {
              stopover: false,
              location: { placeId: element.place_id },
            };
            wayPty.push(newWaypty);
          }
        });
        if (wayPty) {
          setWaypoint(wayPty);
        }
        displayNewRoutes(directions.geocoded_waypoints, directionsService, directionsDisplay);
      }
    });
    directionsService.route(
      {
        origin: locationQuarry
          ? new window.google.maps.LatLng(locationQuarry.lat, locationQuarry.lng)
          : null,
        destination: destination
          ? new window.google.maps.LatLng(destination.lat, destination.lng)
          : null,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      function (response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
          setDirectionRes(response);
          setDuration(response.routes[0].legs[0].duration.text);
          setDistance(response.routes[0].legs[0].distance.text);
        } else {
          console.log('Directions request failed due to ' + status);
        }
      }
    );
  }

  useEffect(() => {
    var lat_lng = { lat: 30.2715029, lng: -97.8036324 };
    mapsAll = new google.maps.Map(document.getElementById('map'), {
      zoom: 9,
      center: lat_lng,
    });
    let markerLatlng = {
      lat: quarry && quarry.lat ? parseFloat(quarry.lat) : null,
      lng: quarry && quarry.lng ? parseFloat(quarry.lng) : null,
    };
    const marker = new google.maps.Marker({
      position: markerLatlng,
      map: mapsAll,
    });
    setLocationQuarry(quarry);
    if (quarry?.lat && quarry?.lng) {
      mapsAll.panTo(markerLatlng);
    }

    if (quarry?.lat && quarry?.lng && destination?.lat && destination?.lng && mapsAll) {
      drawRoute(mapsAll);
    }
  }, [quarry, destination]);

  const drawRoute = (mapsAll) => {
    let origin = {
      lat: quarry && quarry.lat ? parseFloat(quarry.lat) : null,
      lng: quarry && quarry.lng ? parseFloat(quarry.lng) : null,
    };

    let _destination = {
      lat: destination && destination.lat ? parseFloat(destination.lat) : null,
      lng: destination && destination.lng ? parseFloat(destination.lng) : null,
    };
    const directionsServiceInstance = new window.google.maps.DirectionsService();

    // Initialize directions renderer
    const directionsRendererInstance = new window.google.maps.DirectionsRenderer();
    directionsRendererInstance.setMap(mapsAll);
    const request = {
      origin: origin, // Quarry location
      destination: _destination, // Destination location
      travelMode: 'DRIVING', // Travel mode
    };
    directionsServiceInstance.route(request, (result, status) => {
      if (status === 'OK') {
        setDistance(result?.routes[0]?.legs[0]?.distance.text);
        let wayPty = [];
        result?.geocoded_waypoints.forEach((element) => {
          if (element) {
            let newWaypty = {
              stopover: false,
              location: { placeId: element.place_id },
            };
            wayPty.push(newWaypty);
          }
        });
        if (wayPty) {
          setWaypoint(wayPty);
        }
        directionsRendererInstance.setDirections(result);
      } else {
        console.error('Error fetching directions:', status);
      }
    });
  };

  return (
    <div className="w-full">
      <Typography className="h2 mb+10"></Typography>
      <div
        id="map"
        className="w-500 h-512"
      ></div>
      <div className="pt-5">{distance ? <strong>Total Distance: {distance}</strong> : null}</div>
    </div>
  );
}

export default Maps;
