import { combineReducers } from '@reduxjs/toolkit';
import jobs from './JobsSlice';
import jobsTemps from './JobsTempsSlice';

const reducer = combineReducers({
  jobs,
  jobsTemps
});

export default reducer;
