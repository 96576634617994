import { lazy } from 'react';

const SalaryAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/salary',
      component: lazy(() => import('./SalaryApp')),
    },
    
  ],
};

export default SalaryAppConfig;
