import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { constants } from '../../../../constants'
import axios from 'axios'
import store from 'app/store'
import client from 'client'
//obtiene los trailers asignados y no asignados
export const gettrailerAssigment = createAsyncThunk(
  'chatApp/contacts/gettrailerAssigment',
  async (params) => {
    const state = store.getState()
    const comp_id = state.loadz_auth.AuthReducer.user.user_id
    const response = await client.post(`/new-inspections/trailer/all`, {
      comp_id,
    })
    const data = await response.data
    return data
  }
)

const contactsAdapter = createEntityAdapter({})

export const { selectAll: selectTrailer, selectById: selectTrailerById } =
  contactsAdapter.getSelectors((state) => state.NewInspectionsApp.trailer)

function parsear(data) {
  const jsonn = JSON.parse(
    JSON.stringify(data).replace('t_p_a_typehaul_id', 'id')
  )
  return jsonn
}

const contactsSlice = createSlice({
  name: 'NewInspectionsApp/contacts',
  initialState: contactsAdapter.getInitialState({
    selectedContactId: null,
  }),
  reducers: {
    setSelectedContact: (state, action) => {
      state.selectedContactId = action.payload
    },
    removeSelectedContactId: (state, action) => {
      state.selectedContactId = null
    },
  },
  extraReducers: {
    [gettrailerAssigment.fulfilled]: (state, action) => {
      const data = action.payload
      const datos = data.map(parsear)
      contactsAdapter.setAll(state, datos)
    },
  },
})

export const { setSelectedContact, removeSelectedContactId } =
  contactsSlice.actions

export default contactsSlice.reducer
