import actions from './actions'
import { getErrorAction, getSuccessAction, update } from "../utility";

const initState = {
  jobs: [],
  filter: '',
  page: 1,
  loading: false,
  hasNextPage: true,
  pending_count: 0,
}

export default function (state = initState, action) {
  switch(action.type) {
    case actions.GET_JOBS:
      return {
        ...state,
        loading: true
      }
    case getSuccessAction(actions.GET_JOBS):
      return {
        ...state,
        jobs: action.payload.page === 1 ? action.payload.jobs : [
          ...state.jobs,
          ...action.payload.jobs
        ],
        page: action.payload.page,
        hasNextPage: action.payload.hasNextPage,
        loading: false
      }
    case getErrorAction(actions.GET_JOBS):
      return {
        ...state,
        loading: false
      }
    
    case actions.SEARCH_JOBS : 
      return {
        ...state,
        loading: true
      }
    case getSuccessAction(actions.SEARCH_JOBS):
      return{
        ...state,
        jobs: action.payload.page === 1 ? action.payload.jobs : [
          ...state.jobs,
          ...action.payload.jobs
        ],
        page: action.payload.page,
        hasNextPage: action.payload.hasNextPage,
        loading: false
      }
    case getErrorAction(actions.SEARCH_JOBS):
      return {
        ...state,
        loading: false
      }

      case actions.GET_PENDING_JOBS : 
      return {
        ...state
      }
    case getSuccessAction(actions.GET_PENDING_JOBS):
      return{
        ...state,
       pending_count: action.payload.count
      }
    case getErrorAction(actions.GET_PENDING_JOBS):
      return {
        ...state
      }
    default:
      return state
  }
}