import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const TypeHaulAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/typesHauls/hauls/:typeHaulId/:typeHaulName?',
      component: lazy(() => import('./typeHaul/TypeHaul')),
    },
    {
      path: '/typesHauls/hauls',
      component: lazy(() => import('./typesHauls/TypesHauls')),
    },
    {
      path: '/typesHauls',
      component: () => <Redirect to="/typesHauls/hauls" />,
    },
  ],
};

export default TypeHaulAppConfig;
