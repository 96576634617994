import { Fragment, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { constants } from '../../../constants';
import FuseLoading from '@fuse/core/FuseLoading';
import moment from 'moment';
import Zero from './Zero';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { red, grey } from '@mui/material/colors';
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@mui/material/TableCell';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import client from 'client';
import AuthAction from 'app/stores/loadz/redux/Auth/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

let idRow = 'progress';

function Row(props) {
  const { row, iden, todayTotal, jobType } = props;
  const [open, setOpen] = useState(false);

  const getStatus = (jobType) => {
    switch (jobType) {
      case 0:
        return 'in-progress';
      case 1:
        return 'completed';
      case 2:
        return 'in delayed';
      default:
        break;
    }
  };

  const getTodayTotal = (todayTotal, jobType) => {
    console.log('todayTotal::', todayTotal);
    switch (jobType) {
      case 0:
        return todayTotal?.progressToday;
      case 1:
        return todayTotal?.completedToday;
      case 2:
        return todayTotal?.delayedToday;
      default:
        break;
    }
  };

  const handleOpen = (open, id) => {
    setOpen(!open);
  };

  const getFormattedOutput = (todayTotal, iden, jobType, row) => {
    if (todayTotal[iden]) {
      const currentTotal = Number(getTodayTotal(todayTotal[iden], jobType)).toFixed(2);
      const deliveredTotal = todayTotal[iden]?.total || 0;
      const fullfillmentTotal = row?.fullfillmentTotal || '';

      if (getStatus(jobType) === 'in-progress') {
        return (
          <>
            <div>{`${currentTotal} (${row.unit}) In-Progress`}</div>
            <div>{`${(deliveredTotal - currentTotal).toFixed(2)} (Delivered) + In Prog. = ${Number(
              deliveredTotal
            ).toFixed(2)}`}</div>
            {/* <div>{fullfillmentTotal}</div> */}
          </>
        );
      } else {
        return (
          <div>{`${currentTotal} (${row.unit}) ${getStatus(
            jobType
          )}, ${deliveredTotal} / ${fullfillmentTotal} Delivered`}</div>
        );
      }
    } else {
      return ``;
    }
  };

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          component="th"
          scope="row"
          className="h1 font-bold"
          data-headtext="Job # "
        >
          <IconButton
            style={{ color: '#000000' }}
            aria-label="expand row"
            size="large"
            onClick={() => handleOpen(open, `${idRow}-${row.job}`)}
            id={`${idRow}-${row.job}`}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.jobType == 'sub'
            ? `${row.companyNumberClon}-${row.job}`
            : `${row.companyNumber}-${row.job}`}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          data-headtext="Material "
        >
          {row.material}
        </TableCell>
        <TableCell
          align="center"
          data-headtext="Ticket Count"
        >
          {row.tickets.length}
        </TableCell>
        <TableCell
          align="left"
          data-headtext="Status"
        >
          {console.log(
            'Dashboard Ticket Status Update::',
            iden,
            todayTotal[iden],
            jobType,
            todayTotal[iden]?.total,
            row?.fullfillmentTotal
          )}
          {/* {`${
            todayTotal[iden]
              ? `${Number(getTodayTotal(todayTotal[iden], jobType)).toFixed(2)} (${
                  row.unit
                }) ${getStatus(jobType)}, ${todayTotal[iden]?.total} / ${
                  row?.fullfillmentTotal ? row.fullfillmentTotal : ''
                } Delivered`
              : ``
          }`} */}
          {getFormattedOutput(todayTotal, iden, jobType, row)}
        </TableCell>
        <TableCell
          align="left"
          data-headtext=" Quarry"
        >
          {row.quarry}{' '}
        </TableCell>
        <TableCell
          align="left"
          data-headtext="Destination"
        >
          {row.destination}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          className="responsive-loads-table"
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
              ></Typography>
              <Table
                size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow style={{ backgroundColor: '#edca33' }}>
                    <TableCell className="font-bold">Ticket No.</TableCell>
                    <TableCell className="font-bold"> Qty</TableCell>
                    <TableCell className="font-bold">Driver</TableCell>
                    <TableCell className="font-bold">Truck No.</TableCell>
                    <TableCell className="font-bold">Scale</TableCell>
                    <TableCell className="font-bold">Date Time Created</TableCell>
                    <TableCell className="font-bold">Date Time Updated</TableCell>
                    <TableCell className="font-bold">Owner</TableCell>
                    <TableCell
                      align="center"
                      className="font-bold"
                    >
                      Wait Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.tickets.map((ticket, i) => (
                    <TableRow key={i}>
                      <TableCell data-headtext="Ticket No.">{ticket.ticket}</TableCell>
                      <TableCell data-headtext="Qty ">{Number(ticket.qty).toFixed(2)}</TableCell>
                      <TableCell data-headtext="Driver">{ticket.driver}</TableCell>
                      <TableCell data-headtext="Truck No.">{ticket.truck}</TableCell>
                      <TableCell data-headtext="Scale">{ticket.scale}</TableCell>
                      <TableCell data-headtext="Date Time Created">
                        {moment(ticket.dateTime).format('LLL')}
                      </TableCell>
                      <TableCell data-headtext="Date Time Updated ">
                        {moment(ticket.dateTimeUpdate).format('LLL')}
                      </TableCell>
                      <TableCell
                        data-headtext="Owner "
                        align="center"
                      >
                        {ticket?.ownerop ? (
                          <Typography className="h3">OP</Typography>
                        ) : ticket.ticketType == 'CLON-SUB' && ticket.cxBindCo ? (
                          <Typography className="h3">SUB</Typography> //here to owner with x
                        ) : ticket.ticketType == 'sub' && ticket.cxBindCo ? (
                          //here to sub with √
                          <Tooltip
                            title="You owner"
                            placement="top"
                          >
                            <CheckIcon style={{ color: 'green', fontSize: 'xx-large' }} />
                          </Tooltip>
                        ) : !ticket.ticketType && !ticket.cxBindCo ? (
                          //here to owner with √
                          <Tooltip
                            title="You owner"
                            placement="top"
                          >
                            <CheckIcon style={{ color: 'green', fontSize: 'xx-large' }} />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        data-headtext="Wait Time< "
                      >
                        {ticket.waitTime ? (
                          <IconButton
                            size="large"
                            onClick={() => {}}
                          >
                            <PauseCircleOutlineIcon
                              sx={{ color: red[600] }}
                            ></PauseCircleOutlineIcon>
                          </IconButton>
                        ) : (
                          <IconButton
                            size="large"
                            onClick={() => {}}
                          >
                            <PauseCircleOutlineIcon
                              style={{ color: '#000000' }}
                            ></PauseCircleOutlineIcon>
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      className="font-bold"
                    >
                      Total
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      className="font-bold"
                    >{`${Number(row.qtyTotal).toFixed(2)} (${row.unit})`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

function JobTable({
  refreshTable,
  ticketCount,
  setJobCount,
  setQuantity,
  jobType,
  compacct,
  logout,
  jobStatus,
}) {
  const [rows, setRows] = useState([]);
  const [zero, setZero] = useState(false);
  const [todayTotal, setTodayTotal] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    try {
      setZero(false);
      setLoading(true);
      const response = await client.get(`/jobs/get-for-dashboard/${jobType}/${compacct}`);
      const data = response.data;

      setJobCount(data?.jobs ? data.jobs.length : 0);
      ticketCount(data?.countTicket ? data.countTicket : 0);
      setQuantity(0);
      setRows(data?.results ? data.results : []);

      if (!data?.results) {
        console.log('set zero');
        setZero(true);
      }

      setTodayTotal(data?.allToday ? data.allToday : []);
      setLoading(false);
    } catch (error) {
      setZero(true);
      setLoading(false);
      setRows([]);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    refreshTable.current = getData;
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (zero) {
    return <Zero value={jobStatus} />;
  }

  if (rows?.length === 0) {
    return <FuseLoading />;
  } else {
    return (
      <TableContainer
        component={Paper}
        className="responsive-loads-table"
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow></TableRow>
          </TableHead>
          <TableBody className="responsive-loads-table">
            <TableRow>
              <TableCell className=" font-bold">Job #</TableCell>
              <TableCell className="font-bold">Material</TableCell>
              <TableCell className="font-bold">Ticket Count</TableCell>
              <TableCell className="font-bold">Status</TableCell>
              <TableCell className="font-bold">Quarry</TableCell>
              <TableCell className="font-bold">Destination</TableCell>
            </TableRow>

            {(rowsPerPage > 0
              ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            )?.map((row, i) => (
              <Row
                key={i}
                row={row}
                iden={i}
                todayTotal={todayTotal}
                jobType={jobType}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={rows?.length ? rows.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthAction.logout()),
});

export default connect(null, mapDispatchToProps)(memo(JobTable));
