import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';

import { parsePhoneNumber } from 'libphonenumber-js';
import client from 'client';
import ModalSubscriptionsPaywalls from 'app/main/subscriptionManagement/ModalSubscriptionsPayWalls';
import store from 'app/store';
const phoneregex = RegExp('[0-9]');

function CustomersHelpersHeader({ id, jsonGetById, closeIt, prop_data,setSelectedCustomer }) {
  const dispatch = useDispatch();
  const methods = useFormContext();
  const [disabled, setDisabled] = useState(false);
  const { formState, watch, getValues, setError, reset, setValue } = methods;
  const { isValid, dirtyFields, errors } = formState;
  const theme = useTheme();
  const history = useHistory();
  const [subscriptionsPaywalls, setSubscriptionsPaywalls] = useState(false);

  const _setError = () => {
    setError('customer_name');
  };

  const clearForm = {
    customer_name: '',
    customer_email: '',
    location_id: 0,
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  async function handleUpdateCustomer() {
    try {
      var newValues = getValues();
      if (newValues.customer_phone) {
        newValues.customer_phone = parsePhoneNumber(newValues.customer_phone, 'US').nationalNumber;

        newValues.contact.forEach((elem) => {
          if (elem.phone) {
            elem.phone = parsePhoneNumber(elem.phone, 'US').nationalNumber;
          } else {
            elem.phone = '';
          }
        });
      } else {
        newValues.customer_phone = '';
      }

      setDisabled(true);
      if (!getValues('visibility')) {
        setValue('visibility', jsonGetById[0].c_visibility);
      }

      await client.put(`/customers/update-customer/${id}`, newValues);
      _showMessage(
        `Customer named "${getValues('customer_name')}" has been successfully updated!`,
        'success'
      ); //TODO improved Message
      setSelectedCustomer(getValues())

      reset(clearForm);
      setDisabled(false);
      _setError();
      closeIt();
      prop_data ? null : history.push('/customers');
    } catch (error) {
      if (error?.response?.data?.message) {
        _showMessage(error?.response?.data?.message, 'error');
      } else {
        _showMessage(
          `An unexpected error occurred while updating the customer. Please try again.`,
          'error'
        ); //TODO improved Message
      }

      setDisabled(false);
    }
  }

  async function handleSaveCustomer() {
    try {
      var newValues = getValues();
      if (newValues.customer_phone) {
        newValues.customer_phone = parsePhoneNumber(newValues.customer_phone, 'US').nationalNumber;
        newValues.contact.forEach((elem) => {
          if (elem.phone) {
            elem.phone = parsePhoneNumber(elem.phone, 'US').nationalNumber;
          } else {
            elem.phone = '';
          }
        });
      } else {
        newValues.customer_phone = '';
      }
      setDisabled(true);

      await client.post(`/customers/new${prop_data ? '/' + prop_data.compacctnum : ''}`, newValues);

      _showMessage(
        `Customer named "${getValues('customer_name')}" has been successfully created!`,
        'success'
      ); //TODO improved Message
      setSelectedCustomer(getValues())


      reset(clearForm);
      setDisabled(false);
      _setError();
      closeIt();
      prop_data ? null : history.push('/customers');
    } catch (error) {
      if (error?.response?.data?.status === 'incomplete_expired') {
        _showMessage(error?.response?.data?.message, 'error');
        paywallSubscriptions();
        return;
      }

      if (error?.response?.data?.message) {
        _showMessage(error?.response?.data?.message, 'error');
      } else {
        _showMessage(
          `An unexpected error occurred while creating the customer. Please try again.`,
          'error'
        ); //TODO improved Message
      }
      reset(clearForm);
      closeIt();
      setDisabled(false);
    }
  }

  const paywallSubscriptions = () => {
    setSubscriptionsPaywalls(true);
  };

  return (
    <div className="flex flex-1 w-full items-center justify-between updatecustomer">
      <div className="flex flex-col items-start max-w-full min-w-0">
        {subscriptionsPaywalls ? (
          <ModalSubscriptionsPaywalls
            paywallSubscriptions={paywallSubscriptions}
            open={subscriptionsPaywalls}
            setSubscriptionsPaywalls={setSubscriptionsPaywalls}
            compacctnum={
              prop_data?.compacctnum
                ? prop_data?.compacctnum
                : store.getState().loadz_auth.AuthReducer.user.company.compacctnum
            }
          ></ModalSubscriptionsPaywalls>
        ) : null}

        <div className="flex items-center max-w-full UpdateCustomer-h">
          <div className="flex flex-col min-w-0 mx-8 sm:mc-16">
            <motion.div
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.3 } }}
            >
              <Typography
                className="text-16 sm:text-20 truncate font-bold"
                color="black"
              >
                {id ? `Update Customer` : 'New Customer'}
              </Typography>
              <Typography
                variant="caption"
                className="font-Regular"
                color="black"
              >
                Customer Details
              </Typography>
            </motion.div>
          </div>
        </div>
      </div>
      <motion.div
        className="flex"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        {/* <Button style={{color:"white"}}
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="third"
          onClick={handleCancelCreateCustomer}
          startIcon={<Icon className="hidden sm:flex">cancel</Icon>}
        >
          Cancel
        </Button> */}

        <Button
          style={{ color: 'white' }}
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="third"
          /**disabled={!isValid || disabled}**/
          onClick={id ? handleUpdateCustomer : handleSaveCustomer}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </motion.div>
    </div>
  );
}

export default CustomersHelpersHeader;
