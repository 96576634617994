import { useTimeout } from '@fuse/hooks'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { Fragment, memo, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import FuseMessage from '@fuse/core/FuseMessage'

import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import { red, yellow, green } from '@mui/material/colors'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import CachedIcon from '@mui/icons-material/Cached'
import moment from 'moment'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FactCheckIcon from '@mui/icons-material/FactCheck'

import Grid from '@mui/material/Grid'
import { Alert, Button, Tooltip } from '@mui/material'
import Counters from './Counters'
import ChartRange from './ChartRange'
import ChartProgress from './charts-components/ChartProgress'
import ChartDelayed from './charts-components/ChartDelayed'
import ChartCompleted from './charts-components/ChartCompleted'

import io, { Socket } from 'socket.io-client'
import { useDispatch } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'

import { constants } from '../../../constants'
import store from '../../main/ID_store/store'

import Snackbar from '@mui/material/Snackbar'
import SplitPane from 'react-split-pane'
import { stylesheet } from './Dashborad.css'
import { injectReducer } from 'app/store'
import JobTable from './JobTable'
import { connect } from 'react-redux'
import AuthReducer from 'app/stores/loadz/redux/Auth'
let SOCKET = null

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const action = (
  <Fragment>
    <Button color="secondary" size="small">
      UNDO
    </Button>
    <IconButton size="small" aria-label="close" color="inherit">
      <CloseIcon fontSize="small" />
    </IconButton>
  </Fragment>
)

function Dashboard({ delay, company }) {
  const compname = company?.compname
  const comp_email = company?.comp_email
  const comp_acct = company?.compacctnum

  //const { compname, comp_email, compacctnum: comp_acct } = company
  const [showLoading, setShowLoading] = useState(!delay)
  const jobTypes = { progress: 0, completed: 1, delay: 2 }
  const [expandedProgress, setExpandedProgress] = useState(false)
  const [expandedDelayed, setExpandedDelayed] = useState(false)
  const [expandedCompleted, setExpandedCompleted] = useState(false)

  const [counters, setCounters] = useState({})

  const [progress, setProgress] = useState(0)
  const [delayed, setDelayed] = useState(0)
  const [completed, setCompleted] = useState(0)

  const [qtyProgress, setQtyProgress] = useState(0)
  const [qtyDelayed, setQtyDelayed] = useState(0)
  const [qtyCompleted, setQtyCompleted] = useState(0)

  const [progressTickets, setProgressTickets] = useState(0)
  const [delayedTickets, setDelayedTickets] = useState(0)
  const [completedTickets, setCompletedTickets] = useState(0)

  const [isProgress, setIsProgress] = useState(false)
  const [isDelayed, setIsDelayed] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)

  const [response, setResponse] = useState('')

  const dispatch = useDispatch()

  const refreshProgress = useRef(null)
  const refreshDelayed = useRef(null)
  const refreshCompleted = useRef(null)
  const refreshGraph = useRef(null)

  const [openSnack, setOpenSnack] = useState(false)
  const [severity, setSeverity] = useState('')
  const [message, setMessage] = useState('')

  const handleExpandClickProgress = () => {
    setExpandedProgress(!expandedProgress)
  }

  const handleExpandClickDelayed = () => {
    setExpandedDelayed(!expandedDelayed)
  }

  const handleExpandClickCompleted = () => {
    setExpandedCompleted(!expandedCompleted)
  }

  const goTo = (id) => {
    var elmntToView = document.getElementById(id)
    elmntToView.scrollIntoView({ behavior: 'smooth' })
  }

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  useEffect(async () => {
    try {
      SOCKET = io(constants.URLSOCKET, {
        withCredentials: true,
        forceNew: true,
      })
      SOCKET.on('connect', () => {
        console.log('connected', SOCKET.id)
        SOCKET.on('fromServer', (data) => {
          let json = JSON.parse(data)
          if (json.company == comp_acct) {
            let message = `Ticket ${
              json.completed
                ? `Completed: ${json.tk_no}`
                : json.inProgress
                ? `In Progress: ${json.tk_no}`
                : `Delayed: ${json.tk_no}`
            }`
            setMessage(message)
            let color = json.completed
              ? 'success'
              : json.inProgress
              ? 'warning'
              : 'error'
            setSeverity(color)
            console.log(json)

            if (data) {
              setOpenSnack(true)
              setTimeout(() => {
                setOpenSnack(false)
              }, 20000)

              refreshCompleted.current()
              refreshProgress.current()
              refreshDelayed.current()

              if (json.completed) {
                refreshGraph.current()
              }
            }
          }
        })
      })

      //en caos de error cerrar todas las conexiones y dejar un intervalo de conexion
    } catch (error) {
      if (_socket) {
        SOCKET.disconnect()
      }
    }
    setTimeout(() => {
      setShowLoading(false)
    }, 1500)
  }, [])

  useEffect(
    () => () => {
      console.log('unmount')
      console.log(SOCKET)
      SOCKET?.disconnect()
      console.log(SOCKET)
    },
    []
  )

  if (showLoading) {
    return (
      <div
        style={{ backgroundColor: '#000000' }}
        className={clsx(
          'flex flex-1 flex-col items-center justify-center p-24',
          !showLoading && 'hidden'
        )}
      >
        {showLoading && (
          <>
            <Typography
              className="text-13 sm:text-20 mb-16"
              color="textSecondary"
            >
              Loading...
            </Typography>
            <LinearProgress
              className="w-192 sm:w-320 max-w-full rounded-2"
              color="secondary"
            />
          </>
        )}
      </div>
    )
  } else {
    return (
      <div
        className={clsx('flex flex-1 flex-col p-24 mobile-p-0 dashboard-table')}
        style={{ backgroundColor: '#000000' }}
      >
        {!showLoading && (
          <>
            <Grid container spacing={2}>
              <Snackbar
                sx={{
                  paddingTop: '50px',
                  color: severity,
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnack}
                autoHideDuration={6000}
              >
                <Alert
                  onClick={() => {}}
                  onClose={handleCloseSnack}
                  severity={severity ? severity : 'warning'}
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>

              <Grid item xs={12}>
                <div className="w-full" style={{ padding: '0 20px' }}>
                  <Typography
                    component="span"
                    className=" h1 font-Regular text-white"
                    align="left"
                  >
                    {compname}
                  </Typography>
                </div>
                <div className="flex w-full mobile-flex-col flex-col md-screens lg:flex-row">
                  <div className="w-full lg:w-3/5  mobile-width-full m-d-screenss">
                    <Card
                      sx={{}}
                      className="rounded-none"
                      style={{
                        backgroundColor: '#000000',
                      }}
                    >
                      <CardHeader title="" subheader="" />
                      <CardContent className="dashboardGraph">
                        <ChartRange
                          refreshGraph={refreshGraph}
                          compacct={comp_acct}
                        />
                      </CardContent>
                    </Card>
                  </div>
                  <div className="flex w-full lg:w-3/5 mobile-width-full">
                    <div className="w-full ">
                      <Card
                        className="rounded-none "
                        style={{
                          backgroundColor: '#000000',
                        }}
                      >
                        <CardHeader title="" subheader="" />
                        <CardContent className="dashboardCounters" sx={{}}>
                          <Counters
                            counters={{ progress, delayed, completed }}
                            goTo={goTo}
                            progressTickets={progressTickets}
                            delayedTickets={delayedTickets}
                            completedTickets={completedTickets}
                          />
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{}} style={{ backgroundColor: '#000000' }}>
                  <CardHeader
                    title="In Progress"
                    className="text-white"
                    subheader=""
                    id="tableProgress"
                  />

                  <CardContent>
                    <JobTable
                      setJobCount={setProgress}
                      refreshTable={refreshProgress}
                      ticketCount={setProgressTickets}
                      setQuantity={setQtyProgress}
                      compacct={comp_acct}
                      jobType={jobTypes.progress}
                      jobStatus="Progress"
                    />
                  </CardContent>
                  <CardActions disableSpacing>
                    <ExpandMore
                      className="moress"
                      expand={expandedProgress}
                      onClick={handleExpandClickProgress}
                      aria-expanded={expandedProgress}
                      aria-label="show more"
                    >
                      <Tooltip
                        title={!expandedProgress ? 'Show Chart' : 'Hide Chart'}
                      >
                        <ExpandMoreIcon />
                      </Tooltip>
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedProgress} timeout="auto" unmountOnExit>
                    <CardContent>
                      <ChartProgress qtyProgress={qtyProgress} />
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{}} style={{ backgroundColor: '#000000' }}>
                  <CardHeader
                    title="Completed Today"
                    className="text-white"
                    subheader=""
                    id="tableCompleted"
                  />

                  <CardContent>
                    <JobTable
                      setJobCount={setCompleted}
                      refreshTable={refreshCompleted}
                      ticketCount={setCompletedTickets}
                      setQuantity={setQtyCompleted}
                      compacct={comp_acct}
                      jobType={jobTypes.completed}
                      jobStatus="Completed"
                    />
                  </CardContent>
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expandedCompleted}
                      onClick={handleExpandClickCompleted}
                      aria-expanded={expandedCompleted}
                      aria-label="show more"
                    >
                      <Tooltip
                        title={!expandedCompleted ? 'Show Chart' : 'Hide Chart'}
                      >
                        <ExpandMoreIcon />
                      </Tooltip>
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedCompleted} timeout="auto" unmountOnExit>
                    <CardContent>
                      <ChartCompleted qtyCompleted={qtyCompleted} />
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{}} style={{ backgroundColor: '#000000' }}>
                  <CardHeader
                    title="Delayed"
                    className="text-white"
                    id="tableDelayed"
                  />

                  <CardContent>
                    <JobTable
                      isDelayed={response.isDelayed}
                      setJobCount={setDelayed}
                      refreshTable={refreshDelayed}
                      ticketCount={setDelayedTickets}
                      setQuantity={setQtyDelayed}
                      compacct={comp_acct}
                      jobType={jobTypes.delay}
                      jobStatus="Delayed"
                    />
                  </CardContent>
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expandedDelayed}
                      onClick={handleExpandClickDelayed}
                      aria-expanded={expandedDelayed}
                      aria-label="show more"
                    >
                      <Tooltip
                        title={!expandedDelayed ? 'Show Chart' : 'Hide Chart'}
                      >
                        <ExpandMoreIcon />
                      </Tooltip>
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedDelayed} timeout="auto" unmountOnExit>
                    <CardContent>
                      <ChartDelayed qtyDelayed={qtyDelayed} />
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    )
  }
}

Dashboard.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

Dashboard.defaultProps = {
  delay: false,
}
injectReducer('loadz_auth', AuthReducer)

const mapStateToProps = (state) => {
  return {
    company: state?.loadz_auth?.AuthReducer?.user?.company,
  }
}

export default connect(mapStateToProps, null)(memo(Dashboard))
