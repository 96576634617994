import { lazy } from 'react';
const TicketPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/jobs/ticket/:id',
      component: lazy(() => import('./TicketPage')),
    },
  ],
};
export default TicketPageConfig;
