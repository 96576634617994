import React from 'react';
import Typography from '@mui/material/Typography';
import styles from './TicketStyle.module.css';
import moment from 'moment';
import { constants } from '../../../constants';

const boderanimate = {
  padding: '20px 30px',
  paddingBottom: '0',
  marginTop: '20px',
};
const TicketMultiplePrint = React.forwardRef((propsa, ref) => {
  const isWaitEndTimeActive = (data) => {
    if (data && data.wait_time == true && (data.tk_status == 0 || data.tk_status == 2)) {
      return true;
    } else if (data && data.tk_status == 1) {
      return false;
    } else {
      return false;
    }
  };

  // console.log('propsa.dataList => ', propsa.dataList);
  return (
    <>
      {propsa && propsa.dataList && propsa.dataList.length > 0 ? (
        <>
          <div ref={ref}>
            {propsa.dataList.map((element, i) => (
              <div className="row">
                <div
                  className={` pdfprintstyle ${styles.animateDefult} ${
                    isWaitEndTimeActive(element) ? styles.boderanimated : null
                  }  col-md-4`}
                >
                  <div style={boderanimate}>
                    <div className="box-pop-details mt-16">
                      <div
                        className={`first-row flex flex-wrap px-20 py-10 ${
                          isWaitEndTimeActive(element) ? 'bg-white' : ''
                        }`}
                        style={{
                          border: isWaitEndTimeActive(element)
                            ? '1px solid #f40000'
                            : '1px solid #e9c917',
                          boxShadow: '0 0 2px #e5c617',
                        }}
                      >
                        <div className="w-1/3 text-center py-8">
                          <div className="imgbox flex flex-wrap h-full mr-16 printsizelogo">
                            {propsa && propsa.compLogo && propsa.compLogo.logoKey ? (
                              <img
                                alt="Signature"
                                src={propsa.compLogo.logoKey}
                                style={{ height: '80px' }}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="w-1/3 text-left py-8 printsizefont">
                          <p className="ticket-date">
                            <strong>Ticket Date: </strong>{' '}
                          </p>
                          <p className="truck">
                            <strong>Truck #: </strong>
                          </p>
                          <p className="job">
                            <strong>Job: </strong>{' '}
                          </p>
                          <p className="delivery-t">
                            <strong className="text-red">Delivery Ticket #: </strong>{' '}
                          </p>
                        </div>
                        <div className="w-1/3 text-left py-8 printsizefont">
                          <p className="ticket-date">
                            <span>
                              {moment(element.date_updated.slice(0, -1)).format('MMM, DD YYYY')}
                            </span>
                          </p>
                          <p className="truck">
                            <span>{element.t_no_truck}</span>
                          </p>
                          <p className="job">
                            <span> {element.job_no}</span>
                          </p>
                          <p className="delivery-t">
                            <span className="text-red">{element.ticket_no}</span>
                          </p>
                        </div>
                      </div>
                      <div className="first-row flex flex-wrap mt-5 mb-5 printsizeaddress">
                        <div
                          className={`text-center w-full p-0 mt-8 mb-8 ${
                            isWaitEndTimeActive(element) ? 'text-white' : ''
                          }`}
                          style={{ fontSize: '14px' }}
                        >
                          {isWaitEndTimeActive(element) ? (
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              viewBox="0 0 96.000000 96.000000"
                              preserveAspectRatio="xMidYMid meet"
                              style={{ display: 'inline-block' }}
                            >
                              <g
                                transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                fill="#ffffff"
                                stroke="none"
                              >
                                <path
                                  d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30
                                                    -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204
                                                    114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174
                                                    -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
                                />
                              </g>
                            </svg>
                          ) : (
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              viewBox="0 0 96.000000 96.000000"
                              preserveAspectRatio="xMidYMid meet"
                              style={{ display: 'inline-block' }}
                            >
                              <g
                                transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                fill="#000000"
                                stroke="none"
                              >
                                <path
                                  d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30
                                                    -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204
                                                    114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174
                                                    -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
                                />
                              </g>
                            </svg>
                          )}
                          {element.compaddress}, {element.compcity}, {element.compstate},{' '}
                          {element.compzip}
                        </div>
                      </div>
                      <div
                        className={`second-row flex flex-wrap mb-16 printsizeheading ${
                          isWaitEndTimeActive(element) ? 'bg-white' : ''
                        } `}
                        style={{
                          border: isWaitEndTimeActive(element)
                            ? '1px solid #f40000'
                            : '1px solid #e9c917',
                          boxShadow: 'rgb(0 0 1 / 11%) 0px 6px 10px',
                        }}
                      >
                        <div
                          className={`w-full text-center p-5 printsizeh ${
                            isWaitEndTimeActive(element) ? 'text-white' : ''
                          }`}
                          style={{
                            background: isWaitEndTimeActive(element) ? '#f40000' : '#edca33',
                            fontSize: '20px',
                          }}
                        >
                          Delivery Ticket
                        </div>
                        <div className="w-full flex flex-wrap p-16 printsizefont printsizedt">
                          <div
                            className="w-full flex flex-wrap mb-10"
                            style={{ borderBottom: '1px solid #000' }}
                          >
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <strong>Customer: </strong>{' '}
                              </p>
                            </div>
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <span>{element.customer_name}</span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="w-full flex flex-wrap mb-10"
                            style={{ borderBottom: '1px solid #000' }}
                          >
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <strong>Quarry Name: </strong>{' '}
                              </p>
                            </div>
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <span>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    viewBox="0 0 96.000000 96.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                    style={{ display: 'inline-block' }}
                                  >
                                    <g
                                      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                      fill="#000000"
                                      stroke="none"
                                    >
                                      <path
                                        d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30
                                                            -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204
                                                            114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174
                                                            -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
                                      />
                                    </g>
                                  </svg>{' '}
                                  <span
                                    className="text-blues"
                                    style={{ color: 'blue' }}
                                  >
                                    {propsa.newLoc && propsa.newLoc.jobQryPit
                                      ? propsa.newLoc.jobQryPit.l_name
                                      : ''}{' '}
                                  </span>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="w-full flex flex-wrap mb-10"
                            style={{ borderBottom: '1px solid #000' }}
                          >
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <strong>Destination Name: </strong>{' '}
                              </p>
                            </div>
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <span>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    viewBox="0 0 96.000000 96.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                    style={{ display: 'inline-block' }}
                                  >
                                    <g
                                      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                      fill="#000000"
                                      stroke="none"
                                    >
                                      <path
                                        d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30
                                                        -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204
                                                        114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174
                                                        -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
                                      />
                                    </g>
                                  </svg>{' '}
                                  <span
                                    className="text-blues"
                                    style={{ color: 'blue' }}
                                  >
                                    {propsa.newLoc && propsa.newLoc.jobDesination
                                      ? propsa.newLoc.jobDesination.l_name
                                      : ''}{' '}
                                  </span>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="w-full flex flex-wrap mb-10"
                            style={{ borderBottom: '1px solid #000' }}
                          >
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <strong>Driver Name: </strong>{' '}
                              </p>
                            </div>
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <span>
                                  {element.user_fname} {element.user_lname}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="w-full flex flex-wrap mb-10"
                            style={{ borderBottom: '1px solid #000' }}
                          >
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <strong>Ticket Date: </strong>{' '}
                              </p>
                            </div>
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <span>
                                  {moment(element.date_updated.slice(0, -1)).format('MMM, DD YYYY')}
                                </span>
                              </p>
                            </div>
                          </div>
                          {isWaitEndTimeActive(element) ? null : (
                            <>
                              {element && element.commissions == true ? (
                                <>
                                  {element && element.duration_time ? (
                                    <div
                                      className="w-full flex flex-wrap mb-10"
                                      style={{ borderBottom: '1px solid #000' }}
                                    >
                                      <div className="w-1/2 text-left p-0">
                                        <p className="py-8">
                                          <strong>Wait Time Duration: </strong>{' '}
                                        </p>
                                      </div>
                                      <div className="w-1/2 text-left p-0">
                                        <p className="py-8">
                                          <span>{element.duration_time}</span>
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {element && element.tk_start ? (
                                    <div
                                      className="w-full flex flex-wrap mb-10"
                                      style={{ borderBottom: '1px solid #000' }}
                                    >
                                      <div className="w-1/2 text-left p-0">
                                        <p className="py-8">
                                          <strong>Ticket Start </strong>{' '}
                                        </p>
                                      </div>
                                      <div className="w-1/2 text-left p-0">
                                        <p className="py-8">
                                          <span>{element.tk_start}</span>
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}
                                  {element && element.tk_end ? (
                                    <div
                                      className="w-full flex flex-wrap mb-10"
                                      style={{ borderBottom: '1px solid #000' }}
                                    >
                                      <div className="w-1/2 text-left p-0">
                                        <p className="py-8">
                                          <strong>Ticket End: </strong>{' '}
                                        </p>
                                      </div>
                                      <div className="w-1/2 text-left p-0">
                                        <p className="py-8">
                                          <span>{element.tk_end}</span>
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}
                                  {element && element.tk_start_end_duration ? (
                                    <div
                                      className="w-full flex flex-wrap mb-10"
                                      style={{ borderBottom: '1px solid #000' }}
                                    >
                                      <div className="w-1/2 text-left p-0">
                                        <p className="py-8">
                                          <strong>Total Ticket Duration:</strong>{' '}
                                        </p>
                                      </div>
                                      <div className="w-1/2 text-left p-0">
                                        <p className="py-8">
                                          <span>{element.tk_start_end_duration}</span>
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>
                          )}

                          <div
                            className="w-full flex flex-wrap mb-10"
                            style={{ borderBottom: '1px solid #000' }}
                          >
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                <strong>Status: </strong>{' '}
                              </p>
                            </div>
                            <div className="w-1/2 text-left p-0">
                              <p className="py-8">
                                {(() => {
                                  if (element.tk_status == 0) {
                                    return <span>IN-progress</span>;
                                  } else if (element.tk_status == 1) {
                                    return <span>Completed</span>;
                                  } else if (element.tk_status == 2) {
                                    return <span>Delayed</span>;
                                  } else {
                                    return <span>N/A</span>;
                                  }
                                })()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`second-row flex flex-wrap mb-16 printsizetab ${
                          isWaitEndTimeActive(element) ? 'bg-white' : ''
                        }`}
                        style={{
                          border: isWaitEndTimeActive(element)
                            ? '1px solid #f40000'
                            : '1px solid #e9c917',
                          boxShadow: 'rgb(0 0 1 / 11%) 0px 6px 10px',
                        }}
                      >
                        <div className="w-full flex flex-wrap p-0 printsizetab2">
                          <table className="w-full">
                            <tbody className="w-full">
                              <tr
                                className="w-full text-center py-10"
                                style={{
                                  background: isWaitEndTimeActive(element) ? '#f40000' : '#edca33',
                                  fontSize: '16px',
                                }}
                              >
                                <th
                                  className={`py-10 ${
                                    isWaitEndTimeActive(element) ? 'text-white' : ''
                                  }`}
                                >
                                  QTY
                                </th>
                                <th
                                  className={`py-10 ${
                                    isWaitEndTimeActive(element) ? 'text-white' : ''
                                  }`}
                                >
                                  UNITS
                                </th>
                                {element.scale === true ? (
                                  <th
                                    className={`py-10 ${
                                      isWaitEndTimeActive(element) ? 'text-white' : ''
                                    }`}
                                  >
                                    Scale No
                                  </th>
                                ) : null}
                                <th
                                  className={`py-10 ${
                                    isWaitEndTimeActive(element) ? 'text-white' : ''
                                  }`}
                                >
                                  MATERIAL
                                </th>
                              </tr>

                              <tr className="w-full text-center py-10">
                                <td className="py-10">
                                  <span
                                    className="py-3 px-16"
                                    style={{
                                      border: '1px solid #111',
                                      backgroundColor: '#f4f4f4',
                                      borderRadius: '5px',
                                    }}
                                  >
                                    {element.tk_qty}
                                  </span>
                                </td>
                                <td className="py-10">{element.unit_name}</td>
                                {element.scale === true ? (
                                  <td className="py-10">{element.tk_scale_no}</td>
                                ) : null}
                                <td className="py-10">{element.name}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className={`second-row flex flex-wrap mb-16 p-5 text-center justify-center printsizesignature ${
                          isWaitEndTimeActive(element) ? 'bg-white' : ''
                        }`}
                        style={{
                          border: isWaitEndTimeActive(element)
                            ? '1px solid #f40000'
                            : '1px solid #e9c917',
                          boxShadow: 'rgb(0 0 1 / 11%) 0px 6px 10px',
                        }}
                      >
                        {element.tk_receievedby_mediaurl && (
                          <img
                            alt="Signature-tag"
                            src={`${constants.URLLOCAL}/aws/signed?image=${element.tk_receievedby_mediaurl}`}
                            style={{ height: '170px' }}
                          />
                        )}
                      </div>

                      <div className="userframe">
                        <div className="userframe-inner">
                          <span>Received By</span>
                          <span> {element.tk_receivedby} </span>
                        </div>
                      </div>

                      {isWaitEndTimeActive(element) ? (
                        <div className="second-row flex flex-wrap mb-0 p-0  text-center justify-center flex-col items-center printsizeendwt">
                          <div
                            className="flex flex-wrap justify-center flex-col py-20 w-3/4 rounded-t-3xl"
                            style={{ background: '#f40000' }}
                          >
                            <h3
                              className="text-white"
                              style={{ fontSize: '25px' }}
                            >
                              End Wait Time
                            </h3>
                            <div className="p-5 bg-white rounded-lg my-5"></div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <Typography className="text-center">
            <img
              className="w-64 m-auto"
              alt="user photo"
              src="assets/images/icons/empty-box.png"
            />
            N/A
          </Typography>
        </>
      )}
    </>
  );
});
export default TicketMultiplePrint;
