import { lazy } from 'react';

const CompaniesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/companies',
      component: lazy(() => import('./CompaniesApp')),
    },
  ],
};

export default CompaniesAppConfig;
