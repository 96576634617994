import { lazy } from 'react';

const SubscriptionsManagementConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: true,
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/subscriptions-management',
      component: lazy(() => import('./SubscriptionsManagement.js')),
    },
    {
      path: '/subscriptions-history',
      component: lazy(() => import('./SubscriptionsHistory.js')),
    },
    {
      path: '/subscriptions-invoices',
      component: lazy(() => import('./SubscriptionsInvoices.js')),
    },
    {
      path: '/subscriptions-companies/:search?',
      component: lazy(() => import('./SubscriptionsCompanies.js')),
    },
  ],
};

export default SubscriptionsManagementConfig;
