import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import UserNavbarHeader from 'app/fuse-layouts/shared-components/UserNavbarHeader';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { styleNavbarStyle1Content } from './NavbarStyle1Content.css';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Button, Card, Icon } from '@mui/material';
import store from '../../../../../main/ID_store/store';
import { useHistory } from 'react-router-dom';
import client from 'client';
import { connect } from 'react-redux'


const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  '& ::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 20px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
      }`,
  },
  '& ::-webkit-scrollbar-thumb:active': {
    boxShadow: `inset 0 0 0 20px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
      }`,
  },
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
  overscrollBehavior: 'contain',
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  background:
    'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 10px',
  backgroundAttachment: 'local, scroll',
}));

function NavbarStyle1Content({className, user}) {
  const theme = useTheme();
  const history = useHistory()
  const [ActiveStep, setActiveStep] = useState(null);
  const { id } = store.getState()

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const dwollaClick = () => {
    const { id } = store.getState()
    if (id) {
      history.replace(`/dwolla/intro`)
    }
  }

  useEffect(() => {
    async function handleSave() {
      const result = await client.get(`/company/${user?.user_id}`);
      setActiveStep(result?.data?.types_steps_checkouts_id);
    }
    handleSave();
  }, [id]);

  return (
    <Root className={clsx('flex flex-auto flex-col overflow-hidden h-full relative dashboaedleftsidebar', className)}>
      <AppBar
        color="primary"
        position="static"
        className="flex flex-row items-center flex-shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0"
      >
        <div className="flex flex-grow flex-shrink-0  justify-end">
          <NavbarToggleButton className="w-40 h-40 p-0" />
          
        </div>
        
      </AppBar>

      <UserNavbarHeader />
      
      <StyledContent className="dashboaednav " option={{ suppressScrollX: true, wheelPropagation: false }}>
        <Navigation layout="vertical" />
      </StyledContent>

      
      

      <AppBar  position="static" className="flex  dashboaedlogo">
        <Logo />
      </AppBar >
    </Root>
  );
}

// export default memo(NavbarStyle1Content);

const mapStateToProps = (state) => {
  return {
    user: state?.loadz_auth?.AuthReducer?.user,
  }
}

export default connect(mapStateToProps, null)(memo(NavbarStyle1Content))
