import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getJobs = createAsyncThunk('JobsApp/jobs/getJobs', async () => {
  const response = await axios.get('http://localhost:5000/jobs/');
  const data = await response.data;

  // console.log(data, "estos es todo data")
  return data;
});

export const getJobsOG = createAsyncThunk('JobsApp/jobs/getJobs', async () => {
  const response = await axios.get('http://localhost:5000/jobs/og');
  const data = await response.data;

  // console.log(data, "estos es todo data")
  return data;
});

export const getJobsSUB = createAsyncThunk('JobsApp/jobs/getJobs', async () => {
  const response = await axios.get('http://localhost:5000/jobs/sub');
  const data = await response.data;

  // console.log(data, "estos es todo data")
  return data;
});

export const getUnits = createAsyncThunk('JobsApp/jobs/getUnits', async () => {
  const response = await axios.get('http://localhost:5000/unitTemps/all');
  const data = await response.data;

  // console.log(data, "estos es todo data")
  return data;
});


export const removeUnitTemps = createAsyncThunk(
  'JobsApp/jobs/removeUnitTemps',
  async (unitTempsIds, { dispatch, getState }) => {
    
    await axios.delete(`http://localhost:5000/unitTemps/${unitTempsIds}`,  unitTempsIds );
    dispatch(getJobs());
    return unitTempsIds;
  }
);

const  jobsAdapters = createEntityAdapter({});
export const { selectAll: jobsTemps, selectById: selectJobsTempById } =
jobsAdapters.getSelectors((state) => state.JobsApp.jobsTemps);


const unitTempsSlice = createSlice({
  name: 'JobsApp/unitTemps',
  initialState: jobsAdapters.getInitialState({
    filter: 'all',
    searchText: '',
  }),
  reducers: {
    setunitTempsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    changeFilter: (state, action) => {
      console.log(action.payload)
      state.filter = action.payload
    },
  },
  extraReducers: {
    [getJobs.fulfilled]:  (state, action) => {
      const  data  = action.payload;
      
      //const datos = data.map(parsear);
      jobsAdapters.setAll(state, data);
    },
    [getJobsOG.fulfilled]:  (state, action) => {
      const  data  = action.payload;
      
      //const datos = data.map(parsear);
      jobsAdapters.setAll(state, data);
    },
    [getJobsSUB.fulfilled]:  (state, action) => {
      const  data  = action.payload;
      
      //const datos = data.map(parsear);
      jobsAdapters.setAll(state, data);
    },
    [removeUnitTemps.fulfilled]: (state, action) =>
    jobsAdapters.removeMany(state, action.payload),
    },

});


export const { setunitTempsSearchText, changeFilter } = unitTempsSlice.actions;

export default unitTempsSlice.reducer;
