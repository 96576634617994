import { useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomersHelper from '../customers-components/CustomersHelpers';
import CloseIcon from '@mui/icons-material/Close';
import ErrorBoundary from 'app/main/ErrorBoundary';
export default function ModalToEditCustomers({
  id,
  setOpen,
  rechargedAfterChanges,
  create,
  refreshCustomers,
  prop_data,
  setSelectedCustomer
}) {
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('xl');
  const [open, setOpenState] = useState(false);

  const handleClickOpen = () => {
    setOpenState(true);
  };

  const handleClose = () => {
    refreshCustomers();
    setOpenState(false);
    console.log(rechargedAfterChanges);
    // rechargedAfterChanges?.current()
  };

  useEffect(() => {
    setOpen.current = handleClickOpen;
  }, []);

  return (
    <ErrorBoundary>
      <Fragment>
        <Dialog
          className="job-notes e-customer create-customer"
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          disabeBackdropClick={true}
          disableEscapeKeyDown
        >
          <DialogTitle>{id ? 'Edit Customers' : 'Create Customers'}</DialogTitle>
          <div className="popup-close">
            <CloseIcon onClick={handleClose} />
          </div>

          <DialogContent className="edit-customers">
            {!create ? (
              <CustomersHelper
              setSelectedCustomer={setSelectedCustomer}
                prop_data={prop_data}
                closeIt={handleClose}
                id={id}
              />
            ) : (
              <CustomersHelper setSelectedCustomer={setSelectedCustomer} closeIt={handleClose} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </ErrorBoundary>
  );
}
