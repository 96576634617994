const actions = {
  GET_ROLES: 'getRoles',

  getRoles: ({ page }, onError) => {
    const payload = { params: { page } }
    return {
      type: actions.GET_ROLES,
      payload,
      onError,
    }
  },
}

export default actions
