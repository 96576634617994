import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const DpsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/dps/logs/:mileageId/:mileageName?',
      component: lazy(() => import('./Log_dps/Log')),
    },
    {
      path: '/dps/logs',
      component: lazy(() => import('./Dps_log/Dps_log')),
    },
    {
      path: '/dps',
      component: () => <Redirect to="/dps/logs" />,
    },
  ],
};

export default DpsAppConfig;
