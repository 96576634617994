import { takeLatest, put, fork, all, call } from 'redux-saga/effects';
import actions from './actions';
import client from '../../../../../client';
import { getErrorAction, getSuccessAction } from '../utility';

//still needs work
export function* watchSearchDrivers() {
  yield takeLatest(actions.SEARCH_DRIVERS, function* ({ payload, onError }) {
    try {
      const uri = `/driver/search/${payload.type}/${payload.name_search}/${
        payload.compacctnum ? payload.compacctnum : null
      }`;
      const result = yield call(() => client.get(uri, { params: payload.params }));

      yield put({
        type: getSuccessAction(actions.SEARCH_DRIVERS),
        payload: {
          drivers: Array.isArray(result?.data?.drivers) ? result.data.drivers : [],
          page: payload.params?.page || 1,
          hasNextPage: result?.data?.drivers?.length ? true : false,
        },
      });
    } catch (error) {
      if (onError) onError(error);
      yield put({
        type: getErrorAction(actions.SEARCH_DRIVERS),
      });
    }
  });
}

export function* watchGetEnableDrivers() {
  yield takeLatest(actions.GET_Enable_DRIVERS, function* ({ payload, onError }) {
    try {
      const uri = `/driver/all/enable/${payload.compacctnum ? payload.compacctnum : null}`;
      const result = yield call(() => client.get(uri, { params: payload.params }));

      yield put({
        type: getSuccessAction(actions.GET_Enable_DRIVERS),
        payload: {
          drivers: Array.isArray(result?.data?.drivers) ? result.data.drivers : [],
          page: payload.params?.page || 1,
          hasNextPage: result?.data?.drivers?.length ? true : false,
        },
      });
    } catch (error) {
      if (onError) onError(error);
      yield put({
        type: getErrorAction(actions.GET_Enable_DRIVERS),
      });
    }
  });
}

export function* watchGetDisableDrivers() {
  yield takeLatest(actions.GET_DISABLE_DRIVERS, function* ({ payload, onError }) {
    try {
      const uri = `/driver/all/disable/${payload.compacctnum ? payload.compacctnum : null}`;
      const result = yield call(() => client.get(uri, { params: payload.params }));

      yield put({
        type: getSuccessAction(actions.GET_Enable_DRIVERS),
        payload: {
          drivers: Array.isArray(result?.data?.drivers) ? result.data.drivers : [],
          page: payload.params?.page || 1,
          hasNextPage: result?.data?.drivers?.length ? true : false,
        },
      });
    } catch (error) {
      if (onError) onError(error);
      yield put({
        type: getErrorAction(actions.GET_Enable_DRIVERS),
      });
    }
  });
}

export function* watchGetDriverSalary() {
  yield takeLatest(actions.GET_DRIVER_SALARY, function* ({ payload, onError }) {
    try {
      const uri = `/salary/single/${payload.driver_id}/${payload.comp_acct}`;
      const result = yield call(() => client.get(uri, { params: payload.params }));
      yield put({
        type: getSuccessAction(actions.GET_DRIVER_SALARY),
        payload: {
          earnings: result.data.driver_breakdowns,
          page: payload.params?.page || 1,
          earningshasNextPage: result?.data?.driver_breakdowns?.length ? true : false,
        },
      });
    } catch (error) {
      if (onError) onError(error);
      yield put({
        type: getErrorAction(actions.GET_DRIVER_SALARY),
      });
    }
  });
}

export function* watchGetDriverTimesheets() {
  yield takeLatest(actions.GET_DRIVER_TIMESHEET, function* ({ payload, onError }) {
    try {
      const uri = '/salary/timesheets/single';
      const result = yield call(() => client.post(uri, payload));

      yield put({
        type: getSuccessAction(actions.GET_DRIVER_TIMESHEET),
        payload: {
          timesheets: result.data.shift_breakdowns,
        },
      });
    } catch (error) {
      if (onError) onError(error);
      yield put({
        type: getErrorAction(actions.GET_DRIVER_TIMESHEET),
      });
    }
  });
}

export function* watchGetDriverSingleTimesheet() {
  yield takeLatest(actions.GET_DRIVER_SINGLE_TIMESHEET, function* ({ payload, onError }) {
    try {
      const uri = '/salary/get-single-timesheet';
      const result = yield call(() => client.post(uri, payload));

      yield put({
        type: getSuccessAction(actions.GET_DRIVER_SINGLE_TIMESHEET),
        payload: {
          timesheets: [result.data],
        },
      });
    } catch (error) {
      if (onError) onError(error);
      yield put({
        type: getErrorAction(actions.GET_DRIVER_SINGLE_TIMESHEET),
      });
    }
  });
}

// export function* watchSearchJobs() {
//   yield takeLatest(actions.SEARCH_JOBS, function* ({ payload, onError }) {
//     console.log(payload)
//     try {
//       const result = yield call(() => client.get(`/jobs/search/${payload.data.text}/${payload.search.search}`))
//       yield put({
//         type: getSuccessAction(actions.GET_JOBS),
//         payload: {
//           jobs: Array.isArray(result.data) ? result.data : [],
//           page: payload.params?.page || 1,
//           hasNextPage: result?.data?.length || false,
//         },
//       })
//     } catch (error) {
//       if (onError) onError(error)

//       yield put({
//         type: getErrorAction(actions.GET_JOBS),
//       })
//     }
//   })
// }

export default function* rootSaga() {
  yield all([
    fork(watchGetEnableDrivers),
    fork(watchGetDriverSalary),
    fork(watchGetDriverTimesheets),
    fork(watchGetDisableDrivers),
    fork(watchSearchDrivers),
    fork(watchGetDriverSingleTimesheet),
  ]);
}
