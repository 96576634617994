import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'

const TableHeader = () => {
  const tableHeading = [
    'No.',
    'Permisssion',
    'Created Date',
    'Update Date',
    'Edit',
  ]
  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        <TableCell padding="none" className="w-40 md:w-64 text-center z-99">
          <Checkbox />
        </TableCell>
        {tableHeading.map((label, index) => (
          <TableCell key={index} className="p-4 md:p-16 text-center">
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
export default TableHeader
