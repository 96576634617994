import TextField from '@mui/material/TextField';

import { useFormContext, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';


function Maps(props) {
  const methods = useFormContext();
  const { control, formState, setValue } = methods;
  const { errors } = formState;
  const [location, setlocation] = useState({});
  const [direction, setDirection] = useState('');
  const [coor, setCoor] = useState('');
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState(9);
  const [center, setCenter] = useState({
    lat: 30.2426319,
    lng: -97.7542349
  })
  var latitud =
    location && location.lat
      ? location.lat
      : props && props.query && props.query.l_latitude
      ? props.query.l_latitude
      : props && props.customerQuery && props.customerQuery.l_latitude;
  var longitud =
    location && location.lng
      ? location.lng
      : props && props.query && props.query.l_longitude
      ? props.query.l_longitude
      : props && props.customerQuery && props.customerQuery.l_longitude;

  // se utilizan para mover maker
  var latitudmov = coor.lat;
  var longitudmov = coor.lng;
  var latval =
    props && props.query && props.query.l_latitude
      ? props && props.query && props.query.l_latitude
      : props && props.customerQuery && props.customerQuery.l_latitude;
  var longval =
    props && props.query && props.query.l_longitude
      ? props && props.query && props.query.l_longitude
      : props && props.customerQuery && props.customerQuery.l_longitude;

  const checkLongName = (objData, id) => {
    if (
      objData &&
      objData.address_components &&
      objData.address_components.length &&
      objData.address_components[id] != undefined &&
      objData.address_components.length == id &&
      objData.address_components[id].long_name
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelect = async (value) => {
    location.lat = 0;
    location.lng = 0;
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);

    setDirection(value);
    setCoor(latLng);

    setValue('l_latitude', latLng.lat);
    setValue('l_longitude', latLng.lng);

    result.find((object) => {
      const dir = object.formatted_address;
      setValue('l_address', dir);
      const cit = checkLongName(object, 2) ? object.address_components[2].long_name : null;
      setValue('l_city', cit);
      const stat = checkLongName(object, 4) ? object.address_components[4].long_name : null;
      setValue('l_state', null ? null : stat);
      const zip = checkLongName(object, 6) ? object.address_components[6].long_name : null;
      setValue('l_zip', null ? null : zip);
      handleMaps({ latLng, dir, cit, stat, zip });
    });

    setZoom(16);
  };

  const handleOnClick = (evt) => {
    const { lat, lng } = evt;
    setlocation({ lat, lng });
    setValue('l_latitude', lat);
    setValue('l_longitude', lng);
    handleMaps({ lat, lng });

    setZoom(16);
  };

  const getMapOptions = () => ({
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: google.maps.ControlPosition.TOP_CENTER,
    },

    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_TOP,
    },
  });

  useEffect(() => {
    setCenter({
      lat: latitudmov ? latitudmov : latval ? Number(latval) : 30.2426319,
      lng: longitudmov ? longitudmov : longval ? Number(longval) : -97.7542349,
    })
  }, [latval, longval, latitudmov, longitudmov])

  const { handleMaps } = props;
  return (
    <div className="w-500 h-512">
      <br />
      <Controller
        name="l_address"
        control={control}
        render={({ field }) => (
          <PlacesAutocomplete
            value={direction}
            onChange={setDirection}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <div className="flex -mx+4">
                  <TextField
                    {...getInputProps()}
                    fullWidth
                    className="mt-8 mb-16 mx-4"
                    id="l_address"
                    label=" Search Address or Coordinates (latitude , longitude)"
                    variant="outlined"
                    type="search"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div>
                  {loading ? <div> .. loading </div> : null}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                    };
                    // console.log(suggestion)
                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        <br />
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      />

      {/* <GoogleMap
        apiKey={process.env.GOOGEL_MAP_API}
        zoom={9}
        defaultZoom={9}
        options={getMapOptions}
        center={[
          latitudmov ? latitudmov : latval ? Number(latval) : 30.2426319,
          longitudmov ? longitudmov : longval ? Number(longval) : -97.7542349,
        ]}
        // onSelect={() => onChange(handleSelect)}
        onClick={handleOnClick}
      >
        <Marker
          text="Current location"
          lat={latitud ? latitud : 30.2426319}
          lng={longitud ? longitud : -97.7542349}
        />
      </GoogleMap> */}

      <GoogleMap
        mapContainerStyle={{
          width: '512px',
          height: '512px'
        }}
        center={center}
        zoom={9}
        defaultZoom={9}
        onClick={(e) => {
          handleOnClick({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
          })
        }}
      >
        <Marker
          position={{lat: latitud ? latitud : 30.2426319, lng: longitud ? longitud : -97.7542349}}
          icon={{
            url: 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png',
            scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
          }}
        />
      </GoogleMap>
    </div>
  );
}

export default Maps;
