import { CircularProgress } from "@mui/material"

const CircularLoader = ({color}) => {
  return (
    <CircularProgress  sx={{
      '& .MuiCircularProgress-svg': {
        color
      }}}
    />
  )
}
CircularLoader.defaultProps = {
  color: "#000000",
}
export default CircularLoader 