import actions from './actions'
import { getErrorAction, getSuccessAction } from '../utility'

const initState = {
  data: [],
  page: 1,
  loading: false,
  hasNextPage: true,
  role: null,
  permissions: [],
  assignedPermissions: []
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.GET_All:
      return {
        ...state,
        loading: true,
      }
    case getSuccessAction(actions.GET_All):
      return {
        ...state,
        data:
          action.payload.page === 1
            ? action.payload.data
            : [...state.data, ...action.payload.data],
        page: action.payload.page,
        hasNextPage: action.payload.hasNextPage,
        loading: false,
      }
    case getErrorAction(actions.GET_All):
      return {
        ...state,
        loading: false,
      }
    case actions.CREATE:
      return {
        ...state,
        loading: true,
      }

    case getSuccessAction(actions.CREATE):
      return {
        ...state,
        loading: false,
      }

    case getErrorAction(actions.CREATE):
      return {
        ...state,
        loading: false,
      }

    case actions.GET_ROLE:
      return {
        ...state,
        loading: true,
        role: null,
        assignedPermissions: []
      }

    case getSuccessAction(actions.GET_ROLE):
      return {
        ...state,
        role: action.payload.role,
        assignedPermissions: action.payload.permissions,
        loading: false,
      }

    case getErrorAction(actions.GET_ROLE):
      return {
        ...state,
        loading: false,
      }

    case actions.CLEAR_ROLE_ASSIGNED_PERMISSION:
      return {
        ...state,
        role: null,
        assignedPermissions: []
      }
    
    case actions.GET_ASSIGNED_PERMISSION:
      console.log('GET_ASSIGNED_PERMISSION')
      return {
        ...state,
      }
    default:
      return state
  }
}
