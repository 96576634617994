export const contact = {
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  position: ''
}

export const stepOneValues = {
  name:  '',
  businessName:  '',
  address:  '',
  city:  '',
  state:  '',
  zip: '',
  phone: '',
  email: '',
  contacts : [
    contact
  ]
}

export const stepTwoValues = {
  isMaterialBroker: false,
  taxRate: '',
  commissionRate: '',
  hourlyWage: '',
  waitRate: '',
  invoiceTime: "23:59",
}

export const driver = {
  first_name: '',
  middle_name: '',
  last_name: '',
  hourlyWage: '',
  commissionRate: '',
  send_to:'',
  isPhonePreferred: 'true'
}

export const stepThreeValues = {
  drivers : [driver]
}

export const truck = {
  truckNumber: '',
  vin: '',
  color: '',
  year: '',
  manufacturer:  '',
  model : '',
  insuranceExpiry: '',
  is_dump_truck: false,
}

export const trailer = {
  type:  '1',
  trailerNumber:  ''
}

export const stepFourValues = {
  trucks : [truck],
  trailers: [trailer]
}

export const webUser = {
  first_name: '',
  last_name:  '',
  communication: 'true',
  send_to: '',
  type: 'select'
}

export const stepFiveValues = {
  webUsers: [webUser]
}
