import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const UnitTempsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/unitTemps/units/:unitTempId/:unitTempName?',
      component: lazy(() => import('./UnitTemp/UnitTemp')),
    },
    {
      path: '/unitTemps/units',
      component: lazy(() => import('./unitTemps/UnitTemps')),
    },
    {
      path: '/unitTemps',
      component: () => <Redirect to="/unitTemps/units" />,
    },
  ],
};

export default UnitTempsAppConfig;
