import React from 'react'
import { Document, View, Page, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { formatter } from '../../../../../constants';
import { ModalGenerateInvoicesCsspdf } from "./ModalGenerateInvoices.css";
import moment from 'moment';
import { textAlign } from '@mui/system';
const TODAY = moment()
const styles = StyleSheet.create({
  flexFirstDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "10px",
    alignItems: "center",
    flexDirection: "row",
    // breakInside: "avoid",
  },
  img: {
    // width: "130px",
    // height: "130px",
    // objectFit: "cover",
    paddingLeft: "20px",
    // breakInside: "avoid",
  },
  rightSideTop: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    // breakInside: "avoid",
  },
  rightSide: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    // breakInside: "avoid",
    width: "33.33%",
  },
  fontMd: {
    fontSize: "12",
    margin: "3px 0",
    textAlign: "right",
    // breakInside: "avoid",
  },
  fontSm: {
    fontSize: "8",
    margin: "2px 0",
    textAlign: "right",
    // breakInside: "avoid",
  },
  Customer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",
    alignItems: "center",
    flexDirection: "row",
    borderTop: "1px solid #cccccc",
    borderBottom: "1px solid #cccccc",
    // breakInside: "avoid",
  },
  leftSideTop: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    // breakInside: "avoid",
    width : "33.33%",
  },
  fontSmtextAlignLeft: {
    fontSize: "10",
    fontWeight: "300",
    margin: "0px 0",
    textAlign: "left",
    // breakInside: "avoid",
  },
  centerSideTop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    // breakInside: "avoid",
    width : "33.33%",
  },
  fontSmtextAlignCenter: {
    fontSize: "10",
    margin: "3px 0",
    textAlign: "center",
    // breakInside: "avoid",
  },
  fontSmtextAlignRight: {
    fontSize: "10",
    margin: "3px 0",
    textAlign: "right",
    // breakInside: "avoid",
  },
  Batch: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",
    marginBottom: 5,
    alignItems: "center",
    flexDirection: "row",
    // breakInside: "avoid",
  },
  tableBorder: {
    border: "1px solid #000",
    borderBottom:"0",
    // borderTopLeftRadius: "10px",
    // borderTopRightRadius: "10px",
    // borderBottomLeftRadius: "10px",
    // borderBottomRightRadius: "10px",
    // boxShadow: "#0000011c 0px 6px 10px",
    // breakInside: "avoid",
    // overflow: "hidden",
  },
  tablehead:{
    borderBottom: "1px solid #000",
  },
  table: {
    textAlign: "center",
    padding: "2px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    // breakInside: "avoid",
  },
  thead: {
    padding: "2px 5px",
    fontSize: "8",
    flex: "1",
    textAlign: "center",
    // breakInside: "avoid",
  },
  orange: {
    // backgroundColor: "rgba(159,89,82,0.7)",
    textAlign: "left",
    padding: "5px",
    fontSize: "12px",
    fontFamily: 'Helvetica-Bold',
    // breakInside: "avoid",
  },
  table2: {
    textAlign: "center",
    padding: "2px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    // borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
    // breakInside: "avoid",
  },
  thead2: {
    padding: "5px",
    fontSize: "8",
    flex: "1",
    textAlign: "center",
    // breakInside: "avoid",
  },
  subTotle: {
    display: "flex",
    padding: "10px 0",
    flexDirection: "row",
    justifyContent: "flex-start",
    // breakInside: "avoid",
  },
  subT: {
    flex: "1",
    padding: "0 10px",
    // breakInside: "avoid",
  },
  line: {
    width: "100%",
    margin: "5px 0",
    borderTop: "1px solid #000",
    // breakInside: "avoid",
  },
  subTotle2: {
    display: "flex",
    padding: "5px 0",
    flexDirection: "column",
    justifyContent: "flex-end",
    border: "1px solid #000",
    // borderTopLeftRadius: "10px",
    // borderTopRightRadius: "10px",
    // borderBottomLeftRadius: "10px",
    // borderBottomRightRadius: "10px",
    // boxShadow: "#0000011c 0px 6px 10px",
    // breakInside: "avoid",
  },

  subT2: {
    fontSize: "8px",
    padding: "2px 5px",
    textAlign: "left",
    // breakInside: "avoid",
  },
  customerFooter: {
    display: "flex",
    padding: "5px",
    flexDirection: "row",
    justifyContent: "flex-start",
    border: "1px solid #cccccc",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    boxShadow: "#0000011c 0px 6px 10px",
    // breakInside: "avoid",
  },
  logoFooter: {
    display: "flex",
    padding: "10px",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    // breakInside: "avoid",
  },
  imgFooter: {
    width: "100px",
    // breakInside: "avoid",
  },
  mainouter: {
    width: "100%",
  },
  mainouter: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "5px",
  },
  main: {
    flexBasis: "32%",
    border: "1px solid #ccc",
    padding: "2px",
    margin: "2px",
  },
  mainWithMargin: {
    flexBasis: "32%",
    border: "1px solid #000",
    padding: "2px",
    margin: "2px",
    // marginTop: "10px",
  },
  header: {
    textAlign: "left",
    border: "1px solid #EDCA33",
    padding: "5px 5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "white",
    // breakInside: "avoid",
  },
  tabletr: {
    flex: "1",
    // breakInside: "avoid",
  },
  logo: {
    textAlign: "center",
    padding: "2px 5px",
    paddingRight:"10px",
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  th: {
    fontFamily: 'Helvetica-Bold',
    padding: "2px 0px",
    fontSize: "5px",
    // breakInside: "avoid",
  },
  thvlu: {
    padding: "2px 0px",
    fontSize: "5px",
  },
  thred: {
    fontWeight: "bold",
    padding: "2px 0px",
    fontSize: "5px",
    color: "red",
  },
  addres: {
    padding: "2px 0",
    textAlign: "center",
    // breakInside: "avoid",
  },
  subheader: {
    textAlign: "center",
    backgroundColor: "#EDCA33",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "3",
    color: "#fff",
    // breakInside: "avoid",
  },
  bgtext: {
    fontWeight: "bold",
    fontSize: "5px",
    textAlign: "center",
    // breakInside: "avoid",
  },
  topheadingREd: {
    fontWeight: "bold",
    fontSize: "5px",
    textAlign: "center",
    width: "100%",
    color: "#fff",
  },
  topheading: {
    fontWeight: "bold",
    fontSize: "7px",
    textAlign: "center",
    width: "100%",
    color: "#000",
    // breakInside: "avoid",
  },
  table: {
    textAlign: "left",
    borderTop: "1px solid #000",
    padding: "2px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    // boxShadow: "#0000011c 0px 6px 10px",
    // backgroundColor: "white",
    // breakInside: "avoid",
  },
  brdr0:{
    borderTop: "0px",
  },
  brdstyle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "start",
    borderBottom: "1px solid #000",
  },
  thborder: {
    fontWeight: "bold",
    padding: "2px 0px",
    fontSize: "5px",
    flex: "1",
    fontFamily: 'Helvetica-Bold',
    // breakInside: "avoid",
  },
  thbordervalue: {
    padding: "2px 0px",
    fontSize: "5px",
    flex: "1",
  },
  thborderred: {
    padding: "2px 0px",
    fontSize: "5px",
    color: "blue",
    flex: "1",
  },
  adr: {
    color: "black",
    fontSize: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "row",
    marginTop: "1px",
  },
  tableb: {
    marginTop: "3px",
    // breakInside: "avoid",
  },
  tabletrb: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    // boxShadow: "#0000011c 0px 6px 10px",
    // breakInside: "avoid",
  },
  thborderb: {
    textAlign: "center",
    backgroundColor: "#EDCA33",
    padding: "3px 5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flex: "1",
    fontSize: "5px",
    fontFamily: 'Helvetica-Bold',
    color: "#000",
    // breakInside: "avoid",
  },
  tabletrbb: {
    padding: "2px 0",
    border: "1px solid #EDCA33",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    boxShadow: "#0000011c 0px 6px 10px",
    backgroundColor: "white",
    // breakInside: "avoid",
  },
  tdb: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flex: "1",
    fontSize: "5px",
    lineHeight: '1',
    width: "100%",
    justifyContent: "center",
    // breakInside: "avoid",
  },
  tdbvalue: {
    border: '1px solid #000',
    backgroundColor: '#f4f4f4',
    color: '#000',
    borderRadius: '2px',
    paddingTop: '5px',
    display: 'flex',
    height: '15px',
    width: '15px',
    minWidth:'15px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign:'center',
    lineHight:'1',
  },
  receivedBy: {
    fontSize: "7px",
    textAlign: "center",
    backgroundColor: "#EDCA33",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: "3",
    color: "#000",
  },
  imgsign: {
    height: "auto",
    width:"60px",
    padding: "5px",
    
  },
  brakeDiv: {
    // breakInside: "avoid",
  },
  logoimg: {
    height: "auto",
    width: "90px",
    paddingLeft: "0px",
  },
  bold: {
    fontWeight: "900",
  },
  tksignPAdding:{
    paddingLeft:"3px"
  },
  //New Layout
  body: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  image: {
    height: 'auto',
    width: '100px',
    marginBottom: '10',
  },
  topFirst:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  innertitle:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems:'end',
    textAlign: 'right',
  },
  bigToptitle:{
    fontSize: '30',
    lineHight: '1',
    textTransform:'uppercase',
    fontWeight: '600',
    fontFamily: 'Helvetica-Bold',
    // fontFamily:'Tahoma, sans-serif',
  },
  fontboldtext:{
    fontFamily: 'Helvetica-Bold',
  },
  subtext:{
    fontSize : 12,
    lineHight: '1',
  },
  subToptitle:{
    fontSize: 14,
    lineHight: '1',
  },
  subToptitlevalue:{
    fontSize: 20,
    lineHight: '1',
    fontFamily: 'Helvetica-Bold',
  },
  topSecond:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:'end',
    marginTop: '5px',
    marginBottom: '5px',
  },
  toptext: {
    fontSize: 10,
    margin:"2px 0"
  },
  subTotleBottom:{
    display:"flex",
    flexDirection: "row",
    justifyContent:"space-between",
    border: "0",
    marginTop:"10px",
  },
  leftnote:{
    width: "60%",
    border: "1px solid #000",
    padding:"5px",
  },
  rightTable:{
    width: "40%",
  },
})
const ModelCustomPdf = (props) => { 
  console.log("propsforTotable",props)
  const TermType = props.terms
  return (
    <Document>
      <Page size="A4">
        {props && props.toTable && props.toTable.length > 0 ? (
          <>
          <Text style={{ fontSize: "12px", width: "98%", textAlign: "right", paddingTop: "5px",color:"#949398" }} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
            {props?.toTable[0]?.customer?.jobs?.map((job, index) => (
              // <View >
              <>
                <View key={index} style={{ padding: "15px", height: "100%", }} break={index > 0 ? true : false}>
                  <View style={styles.flexFirstDiv}>
                    <View style={styles.topFirst}>
                      <View style={styles.topFirstLeft}>
                          {/* {props.blobLogoForUNVoice ? (
                            <Image style={{ height: "auto", width: "100%", maxWidth:"100px"}} src={props.blobLogoForUNVoice} crossorigin="anonymous" />
                            ) : (
                            <Image style={{ height: "auto", width: "100%", maxWidth:"100px"}} source='../assets/images/icons/pin.png' crossorigin="anonymous" />
                          )} */}
                          {props?.toTable[0]?.customer?.blobResLOGO ?

                          <Image style={{ height: "auto", width: "100%", maxWidth: "100px" }} src={{ uri: props?.toTable[0]?.customer?.blobResLOGO, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} crossorigin="anonymous"  /> :
                           <Image style={{ height: "auto", width: "100%", maxWidth: "100px" }} source="assets/images/logos/loadz.png" crossorigin="anonymous" />
                            }
                          <Text style={styles.subtext}>{props.compName}</Text>
                          <Text style={styles.subtext}>{job.comp_address}, {job.comp_city}, {job.comp_state}, {job.comp_zip}</Text>
                      </View>
                      {/* <View style={styles.topFirstRight}>
                          <Text style={styles.bigtitle}>Invoice # {index === 0 ? props.invoiceNumber : props.invoiceNumber + index}</Text>
                          <View style={styles.innertitle}>  
                          <Text style={styles.subtitle}>Total: </Text><Text style={styles.subbigtitle}>{formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait)}</Text>
                        </View>
                      </View> */}
                    </View>
                 
                    {/* <View>
                      {props.blobLogoForUNVoice ? (
                        <Image style={{ height: "auto", width: "100%", maxWidth:"100px"}} src={props.blobLogoForUNVoice} crossorigin="anonymous" />
                        ) : (
                        <Image style={{ height: "auto", width: "100%", maxWidth:"100px"}} source='../assets/images/icons/pin.png' crossorigin="anonymous" />
                      )}
                      <View style={{ margin : "10px 0"}}>
                             <Text style={{ fontSize : "10px"}}>{props.compName}</Text>
                      <Text style={{ fontSize : "10px"}}>{job.comp_address},
                             {job.comp_city},
                              {job.comp_state},
                           {job.comp_zip}
                        </Text>
                       </View>
                    </View> */}
                    <View>
                      <View style={styles.rightSideTop}>
                        <Text style={styles.bigToptitle}>Invoice # {index === 0 ? props.invoiceNumber : props.invoiceNumber + index}</Text>
                        {/* <Text style={styles.fontMd}>Invoice Date: {props.dateStarted?moment(props.dateStarted).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}</Text> */}
                        <Text style={styles.subToptitle}>Total: <Text style={styles.subToptitlevalue}>{formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait)}</Text></Text>
                        {/* <Text style={styles.fontMd}>Invoice Total: {
                                    formatter.format(props.toTable[0]?.customer?.jobs[0]?.jobTotalHauling + props.toTable[0]?.customer?.jobs[0].jobTotalMaterial + props.toTable[0]?.customer?.jobs[0].jobTotalWait)
                                  }</Text> */}
                        {/* {typeof TermType === "number" ?
                         <Text style={styles.fontSm}>Terms: {props?.terms}</Text>:
                         <Text style={styles.fontSm}>Terms: {props?.terms[index]}</Text>} */}
                        {/* {props && props.dueDates ?
                        <Text style={styles.fontSm}>Due Date: {props.dueDates[index]} </Text> 
                        : <Text style={styles.fontSm}>Due Date: {moment(props.invoiceDueDate).format('MM/DD/YYYY')}</Text> 
                        }
                        <Text style={styles.fontSm}>Hauling Total: {formatter.format(job.jobTotalHauling)}</Text>
                        <Text style={styles.fontSm}>Material Total: {formatter.format(job.jobTotalMaterial)}</Text>
                        <Text style={styles.fontSm}>Wait Charge: {formatter.format(job.jobTotalWait)}</Text> */}
                      </View>
                    </View>
                  </View>
                   <View style={styles.topSecond}>    
                    <View style={styles.topSecondinner}>
                    <Text style={[styles.toptext, styles.fontboldtext]}>Bill to: </Text>
                      <Text style={styles.toptext}>{props.toTable[0]?.customer?.customerName}</Text>
                      <Text style={styles.toptext}>{props.toTable[0]?.customer?.locationId},{props.toTable[0]?.customer?.customerCity},{props.toTable[0]?.customer?.customerState},{props.toTable[0]?.customer?.customerZip}</Text>        
                    </View>
                    <View style={styles.topSecondinner}>
                    <Text style={styles.toptext}><Text style={styles.fontboldtext}>Job No:</Text> {job.job}</Text>
                    <Text style={styles.toptext}><Text style={styles.fontboldtext}>Job Site:</Text> {job.jobDestination}</Text>
                    <Text style={styles.toptext}><Text style={styles.fontboldtext}>Origin:</Text> {job.jobQuarry}</Text>
                    </View>
                    <View style={[styles.topSecondinner, {display:"flex", justifyContent:"flex-end", flexDirection:"column", alignItems:"flex-end"}]}>
                          <Text style={[styles.toptext,]}>Date:{props && props.info && props.info.dateCreated ? moment(props.info.dateCreated).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}</Text>
                      {typeof TermType === "number" ?
                         <Text style={[styles.toptext,]}>Terms: {Number(props?.terms).toFixed(0)}</Text>:
                         <Text style={[styles.toptext,]}>Terms: {Number(props?.terms[index]).toFixed(0)}</Text>}
                      {props && props.dueDates ?
                        <Text style={[styles.toptext,]}>Due Date: {props.dueDates[index]}</Text> 
                        : <Text style={[styles.toptext,]}>Due Date: {moment(props.invoiceDueDate).format('MM/DD/YYYY')}</Text> 
                        }
                    </View>
                  </View>
                  <View style={styles.line}></View>
                  <View style={{ breakInside: 'avoid' }}>
                    {job.batches.map((batch, b) => (
                      <>
                        <View key={b} style={styles.Batch}>
                          <Text style={styles.toptext}>Batch No.{" "}{batch.batch}</Text>
                          <Text style={styles.toptext}>Date.{" "}{batch.batchDateTimeReal}</Text>
                          <Text style={styles.toptext}> Material:{" "}{batch.material}</Text>
                        </View>
                        <View style={styles.tableBorder}>
                          
                          <View>
                            {['Hauling', 'Material', 'Wait Charge'].map((type, typeIndex) => (
                              <>
                                {batch.info.map((ticket, i) => (
                                  <>
                                    {batch.info.length + 1 === i + 1 ? (
                                      <></>
                                    ) : (
                                      <>
                                        {(typeIndex === 0) ? (
                                          <>
                                            {i=== 0 && batch.info.some(x=>x.ticketCR > 0) && (
                                              <>
                                              <Text key={`${type}-${typeIndex}`} style={styles.orange}>{type}</Text>
                                              <View style={[styles.table, styles.tablehead]}>
                                                <Text style={styles.thead}>Ticket No.</Text>
                                                <Text style={styles.thead}>Scale No.</Text>
                                                <Text style={styles.thead}>Truck</Text>
                                                <Text style={styles.thead}>Rate</Text>
                                                <Text style={styles.thead}>Qty</Text>
                                                <Text style={styles.thead}>Unit</Text>
                                                <Text style={styles.thead}>TAX</Text>
                                                <Text style={styles.thead}>Total</Text>
                                                {/* <Text style={styles.thead}>Type</Text> */}
                                              </View>
                                              </>
                                            )}
                                            {ticket.ticketCR && ticket.ticketCR > 0 ? (
                                              <View key={ticket.ticket} style={styles.table2}>
                                              <Text style={styles.thead2}>{ticket.ticket}</Text>
                                              <Text style={styles.thead2}>{ticket.scale}</Text>
                                              <Text style={styles.thead2}>{ticket.truck}</Text>
                                              <Text style={styles.thead2}>{job.jobUnitRate}</Text>
                                              <Text style={styles.thead2}>{ticket.qty}</Text>
                                              <Text style={styles.thead2}>{typeIndex != 2 ? batch.unit : 'H'}</Text>
                                              <Text style={styles.thead2}>{'N'}</Text>
                                              <Text style={styles.thead2}>{formatter.format(ticket.ticketCR)}</Text>
                                              {/* <Text style={styles.thead2}>{type}</Text> */}
                                            </View>
                                            ):<></>}
                                            
                                          </>
                                        ) : (typeIndex === 1) ? (
                                          <>
                                            {i=== 0  && batch.info.some(x=>x.materialCR > 0) && (
                                              <>
                                                                                        
                                            <Text key={`${type}-${typeIndex}`} style={styles.orange}>{type}</Text>
                                              <View style={[styles.table, styles.tablehead]}>
                                                <Text style={styles.thead}>Ticket No.</Text>
                                                <Text style={styles.thead}>Scale No.</Text>
                                                <Text style={styles.thead}>Truck</Text>
                                                <Text style={styles.thead}>Rate</Text>
                                                <Text style={styles.thead}>Qty</Text>
                                                <Text style={styles.thead}>Unit</Text>
                                                <Text style={styles.thead}>TAX</Text>
                                                <Text style={styles.thead}>Total</Text>
                                                {/* <Text style={styles.thead}>Type</Text> */}
                                              </View>
                                              </>

                                            )}
                                            {ticket.materialCR && ticket.materialCR > 0 ?(
                                              <View key={ticket.ticket} style={styles.table2}>
                                              <Text style={styles.thead2}>{ticket.ticket}</Text>
                                              <Text style={styles.thead2}>{ticket.scale}</Text>
                                              <Text style={styles.thead2}>{ticket.truck}</Text>
                                              <Text style={styles.thead2}>{job.jobMaterialRate}</Text>
                                              {/* <Text style={styles.thead2}>{parseFloat(ticket.qty).toFixed(2)}</Text> */}
                                              <Text style={styles.thead2}>{ticket.qty}</Text>
                                              <Text style={styles.thead2}>{typeIndex != 2 ? batch.unit : 'H'}</Text>
                                              <Text style={styles.thead2}>{job.jobTaxable ? 'T' : 'N'}</Text>
                                              <Text style={styles.thead2}>{formatter.format(ticket.materialCR)}</Text>
                                              {/* <Text style={styles.thead2}>{type}</Text> */}
                                            </View>
                                            ):<></>}
                                            
                                          </>
                                        ) : (typeIndex === 2) ? (
                                          <>
                                            {i=== 0 && batch.info.some(x=>x.waitCR > 0) &&  (
                                              <>
                                              <Text key={`${type}-${typeIndex}`} style={styles.orange}>{type}</Text>
                                              <View style={[styles.table, styles.tablehead]}>
                                                <Text style={styles.thead}>Ticket No.</Text>
                                                <Text style={styles.thead}>Scale No.</Text>
                                                <Text style={styles.thead}>Truck</Text>
                                                <Text style={styles.thead}>Rate</Text>
                                                <Text style={styles.thead}>Qty</Text>
                                                <Text style={styles.thead}>Unit</Text>
                                                <Text style={styles.thead}>TAX</Text>
                                                <Text style={styles.thead}>Total</Text>
                                                {/* <Text style={styles.thead}>Type</Text> */}
                                              </View>
                                              </>
                                            )}
                                            {ticket.waitCR && ticket.waitCR > 0 ? (
                                              <View key={ticket.ticket} style={styles.table2}>
                                              <Text style={styles.thead2}>{ticket.ticket}</Text>
                                              <Text style={styles.thead2}>{ticket.scale}</Text>
                                              <Text style={styles.thead2}>{ticket.truck}</Text>
                                              <Text style={styles.thead2}>{job.waitrate}</Text>
                                              <Text style={styles.thead2}>{Number(ticket.waitTime).toFixed(2)}</Text>
                                              <Text style={styles.thead2}>{'H'}</Text>
                                              <Text style={styles.thead2}>{'N'}</Text>
                                              <Text style={styles.thead2}>{formatter.format(ticket.waitCR)}</Text>
                                              {/* <Text style={styles.thead2}>{type}</Text> */}
                                            </View>
                                            ):<></>}
                                            
                                          </>
                                        ) : (<></>)}
                                      </>
                                    )}
                                  </>
                                ))}
                              </>
                            ))}
                          </View>
                          {/* <View style={styles.subTotle}>
                            <View style={styles.subT}>
                              <Text style={styles.fontSm}>Sub Totals</Text>
                            </View>
                            <View style={styles.subT}>
                              <Text style={styles.fontSm}>{`${Number(batch.qtyTotal).toFixed(2)}`}</Text>
                            </View>
                            <View style={styles.subT}>
                              <Text style={styles.fontSm}>{`${formatter.format(batch.totalMaterialCost + batch.totalTicketCost + batch.totalWaitTime)}`}</Text>
                            </View>
                          </View> */}
                        </View>
                      </>
                    ))}
                  </View>
                  <View style={[styles.subTotle2, styles.subTotleBottom]}>
                    <View style={styles.leftnote}>
                      <Text style={{ fontSize: "10" }}>Notes to customer:</Text>
                      <Text style={{ fontSize: "10" }}>{props && props?.notes ? props?.notes[index] : ''}</Text>
                    </View>
                    <View style={styles.rightTable}>
                      <Text style={styles.subT2}>Total QTY:{" "} {`${Number(job.jobTotalTickets).toFixed(2)}`} </Text>
                      <View style={styles.line}></View>
                      <Text style={styles.subT2}>Total Wait Time: {`${Number(job.jobTotalWait).toFixed(2)}`}  H</Text>
                      <View style={styles.line}></View>
                      <Text style={styles.subT2}>Sub Total Invoice:{" "}
                        {`${formatter.format(job.jobTotalHauling + (job.jobTotalMaterial - job.jobTotalTaxesMaterial) + job.jobTotalWait)}`}
                      </Text>
                      <View style={styles.line}></View>
                      <Text style={styles.subT2}>Tax {job.jobTaxable ? job.jobTax > 0 || job.jobTax ? job.jobTax : 8.25 : 0}% :{" "}
                        {`${formatter.format(job.jobTotalTaxesMaterial)}`}
                      </Text>
                      <View style={styles.line}></View>
                
                        {job  && job.discount && job.discount > 0 ?
                                      <>
                                  
                        <Text style={[styles.subT2, styles.fontboldtext]}><Text style={{fontWeight:"bold"}}>Discount Total:</Text>{" "}
                        {` ( ${formatter.format(job.discount)} )`}
                      </Text>  <Text style={[styles.subT2, styles.fontboldtext]}><Text style={{fontWeight:"bold"}}>Invoice Balance:</Text>{" "}
                        {`${formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait - job.discount )}`}
                      </Text>
                                    </>
                                    :
                                    <>
                               {props && props.info &&  props.info.balance ?
                                <Text style={[styles.subT2, styles.fontboldtext]}><Text style={{fontWeight:"bold"}}>Balance Invoice:</Text>{" "}
                                  {props.info.balance}
                               </Text>:
                                <Text style={[styles.subT2, styles.fontboldtext]}><Text style={{fontWeight:"bold"}}>Total Invoice:</Text>{" "}
                                    {`${formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait)}`}
                                </Text>}
                              
                                </>
                               }

                    </View>
                  </View>
                  <View style={styles.logoFooter}>
                    <Text style={{ fontSize: "10", marginRight: "5px" }}>Powered by</Text>
                    <Image style={styles.imgFooter} src="assets/images/logos/loadzlogo.png" crossorigin="anonymous" alt="logo" />
                  </View>
                  {/* <View style={{height: "300px",}}></View> */}
                </View>
                <View break></View>
                <View style={styles.mainouter}>
                  <>
                    {job?.batches?.map((batch, b) => (
                      <>
                        {batch?.info?.map((ticket, i) => (
                          <>
                            <View key={i} style={styles.mainWithMargin} break={ticket.printIndex % 9 == 0 ? true : false}>
                              <View style={styles.mainPage}>
                                <View style={styles.maininner}>
                                  <View style={styles.header}>
                                    <View style={styles.tabletr}>
                                      <View style={styles.logo}>
                                        {/* {props.blobLogoForUNVoice ? (
                                          <Image style={{ height: "auto", width: "30px", }} src={props.blobLogoForUNVoice} />
                                        ) : (
                                          <Image style={{ height: "auto", width: "30px",}} source='../assets/images/icons/pin.png' />
                                        )} */}
                                        {props?.toTable[0]?.customer?.blobResLOGO ?
                                          <Image style={{ height: "auto", width: "100%", maxWidth: "100px" }} src={{ uri: props?.toTable[0]?.customer?.blobResLOGO, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} /> :
                                          <Image style={{ height: "auto", width: "100%", maxWidth: "100px" }} source="assets/images/logos/loadz.png" crossorigin="anonymous" />
                                           }
                                      </View>
                                    </View>
                                    <View style={[styles.tabletr, styles.th]}>
                                      <Text style={styles.th}>Ticket Date:{" "}</Text>
                                      <Text style={styles.th}>Truck #:{" "}</Text>
                                      <Text style={styles.th}>Job:{" "}</Text>
                                      <Text style={styles.thred}>Delivery Ticket #:{" "}</Text>
                                    </View>
                                    <View style={styles.tabletr}>
                                      <Text style={styles.thvlu}>{moment(ticket?.dateTime).format("MMM, DD YYYY")}</Text>
                                      <Text style={styles.thvlu}>{ticket?.truck}</Text>
                                      <Text style={styles.thvlu}>{ticket?.job}</Text>
                                      <Text style={styles.thred}>{ticket?.ticket}</Text>
                                    </View>
                                  </View>
                                  <View style={styles.addres}>
                                    <Text style={styles.adr}>
                                      <Image src="../assets/images/icons/pin.png" style={{ width: "5px", height: "5px", marginTop: "2px" }} />
                                      {job?.comp_address}, {job?.comp_city}, {job?.comp_state}, {job?.comp_zip} </Text>
                                  </View>
                                  <View style={styles.subheader}>
                                    <View style={styles.bgtext}>
                                      <Text style={styles.topheading}>Delivery Ticket</Text>
                                    </View>
                                  </View>
                                  <View style={[styles.table, styles.brdr0]}>
                                    <View style={styles.tabletr}>
                                      <View style={styles.brdstyle}>
                                        <Text style={styles.thborder}>Customer:{" "}</Text>
                                        <Text style={styles.thbordervalue}>{props.toTable[0]?.customer?.customerName}</Text>
                                      </View>
                                      <View style={styles.brdstyle}>
                                        <Text style={styles.thborder}>Quarry Name:{" "}</Text>
                                        <Text style={styles.thborderred}>
                                          <Image src="../assets/images/icons/pin.png" style={{ width: "5px", height: "4px", marginTop: "2px" }} />
                                          <Text>{job?.jobQuarry}</Text>
                                        </Text>
                                      </View>
                                      <View style={styles.brdstyle}>
                                        <Text style={styles.thborder}>Destination Name:{" "}</Text>
                                        <Text style={styles.thborderred}>
                                          <Image src="../assets/images/icons/pin.png" style={{ width: "5px", height: "4px", marginTop: "2px" }} />
                                          <Text>{job?.jobDestination}</Text>
                                        </Text>
                                      </View>
                                      <View style={styles.brdstyle}>
                                        <Text style={styles.thborder}>Driver Name:{" "}</Text>
                                        <Text style={styles.thbordervalue}>
                                          <Text>{ticket?.driver}</Text>
                                        </Text>
                                      </View>
                                      <View style={styles.brdstyle}>
                                        <Text style={styles.thborder}>Ticket Update Date:{" "}</Text>
                                        <Text style={styles.thbordervalue}>
                                          <Text>{moment(ticket?.dateTimeUpdate).format("MMM, DD YYYY")}</Text>
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                  <View style={styles.tableb}>
                                    <View style={styles.tabletrb}>
                                      <Text style={styles.thborderb}>QTY</Text>
                                      <Text style={styles.thborderb}>UNITS</Text>
                                      {ticket.scale ? (<Text style={styles.thborderb}>Scale No.</Text>):''}
                                      <Text style={styles.thborderb}>MATERIAL</Text>
                                    </View>
                                    <View style={styles.tabletrbb}>
                                      <View style={styles.tdb}>
                                        <Text style={styles.tdbvalue}>{ticket?.qty}</Text>
                                      </View>
                                      <View style={styles.tdb}>
                                        <Text>{batch?.unit}</Text>
                                      </View>
                                      {ticket.scale?
                                      <View style={styles.tdb}>
                                        <Text >{ticket?.scale}</Text>
                                      </View>:null}
                                      <View style={styles.tdb}>
                                        <Text>{batch?.material}</Text>
                                      </View>
                                    </View>
                                  </View>
                                  <View style={{border: "1px solid #EDCA33", width: "100%", height: "40px", overflow:"hidden",margin:"auto",marginTop:"5px",display: "flex",alignItems:"center",justifyContent:"center",}}>
                                    {ticket.blobUrl ? (
                                      <Image style={styles.imgsign} source={ticket.blobUrl} />
                                    ) : <Image style={styles.imgsign} src="../assets/images/icons/no-signature.jpg" />}
                                  </View>
                                  <View style={styles.receivedBy}>
                                    <Text>Received By</Text>
                                    <Text style={styles.tksignPAdding} >{ticket.tk_receivedby}</Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </>
                        ))}
                      </>
                    ))}
                  </>
                </View>
              </>
              //</View>
            ))}

          </>
        ) : (<></>)}
        
      </Page>
    </Document>
  )
}
export default ModelCustomPdf