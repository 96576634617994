import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { memo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { red } from '@mui/material/colors';

function CounterDelayed(props) {

  return (
    <Paper  className="justify-center rounded-none" id="delayed" style={{
      cursor: 'pointer',backgroundColor:"#000000" , border: 
      "3px solid #edca33"
    }}>
      <div className="flex items-center">
        <Typography className="text-16 px-16 font-Regular " color="#000000">
       
        </Typography>
        
      </div>      
      <div className="text-center ">
        <Typography className="text-56 font-bold text-white ">
          {props.counter}
        </Typography>
        <Typography className="text-16 text-yellow-800 font-Regular">
        Jobs Delayed
        </Typography>

        <Typography className="text-16 text-white font-Regular">
          Ticket Count: {props.tickets}
        </Typography>
      </div>
      
    </Paper>
  );
}

export default memo(CounterDelayed);