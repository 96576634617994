import FuseScrollbars from '@fuse/core/FuseScrollbars';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Row } from '../Batches';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { openMobileChatsSidebar } from './store/sidebarSlice';
import { Paper, Typography } from '@mui/material';

export default function ClientsData({ 
  openModal, 
  isAllJobs,
  setIsAllJobs,
  setTickets,
  jobsToInvoice,
  setJobsToInvoice,
  totalTicketToInvoice,
  setTotalTicketToInvoice,
  tickets,
  infoToInvoice
}) {
 const dispatch= useDispatch()
  return (
    <div className={clsx('flex flex-col relative', 'flex flex-1 z-10')}>
      <FuseScrollbars className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex flex-col ">
          {
            infoToInvoice?.jobs?.length > 0 ? (
              <Row 
                row={infoToInvoice} 
                openModal={openModal} 
                isAllJobs={isAllJobs}
                setIsAllJobs={setIsAllJobs}
                setTickets={setTickets}
                jobsToInvoice={jobsToInvoice}
                setJobsToInvoice={setJobsToInvoice}
                totalTicketToInvoice = {totalTicketToInvoice}
                setTotalTicketToInvoice = {setTotalTicketToInvoice}
                tickets={tickets}

              ></Row>
            ) : (
              <> 
            




                  <div className="flex flex-col flex-1 items-center justify-center p-24 ">
        <Paper className="rounded-full p-48 md:p-64 shadow-xl">
          <img src="assets/images/logos/inspection.png" className="w-128 m-32" color="secondary">
          </img>
        </Paper>
        <Typography variant="h6" className="mt-24 mb-12 text-32 font-700" color="black">
          Subcontractors Info
        </Typography>
        <Typography
          className="hidden md:flex px-16 pb-24 text-16 text-center"
          color="textSecondary"
        >
          Select a subcontractor to view Uninvoices info...
        </Typography>
        <Button
                    variant="outlined"
                    color="third"
                    className="flex md:hidden"
                    onClick={() => dispatch(openMobileChatsSidebar())}
                  >
                    Select a subcontractor to view Uninvoices info...
                  </Button>

      </div>







              </>
            )
          }
        </div>
      </FuseScrollbars>
    </div>
  )
}