import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { constants } from '../../../../constants';
import store from 'app/store';

export const getRequests = createAsyncThunk('requests/request/getRequests', async (comp_acct) => {
  //getting only driver requests for now need a slice for each user type
  const state = store.getState();
  const store_user = state.loadz_auth.AuthReducer.user;

  const response = await axios.post(
    `${constants.URLLOCAL}/requests/drivers`,
    { comp_acct: store_user.company.compacctnum },
    { headers: { token: localStorage.getItem('token') } }
  );
  const data = await response.data;

  const requests = data;
  return { requests };
});

export const getDispatcherRequests = createAsyncThunk(
  'requests/request/getDispatcherRequests',
  async (comp_acct) => {
    const state = store.getState();
    const store_user = state.loadz_auth.AuthReducer.user;
    //getting only driver requests for now need a slice for each user type

    const response = await axios.post(
      `${constants.URLLOCAL}/requests/dispatchers`,
      { comp_acct: store_user.company.compacctnum },
      { headers: { token: localStorage.getItem('token') } }
    );
    const data = await response.data;

    const requests = data;
    return { requests };
  }
);

export const getCloneRequests = createAsyncThunk(
  'requests/request/getCloneRequests',
  async (comp_acct) => {
    const state = store.getState();
    const store_user = state.loadz_auth.AuthReducer.user;
    //getting only driver requests for now need a slice for each user type

    const response = await axios.post(
      `${constants.URLLOCAL}/requests/clone`,
      { comp_acct: store_user.company.compacctnum },
      { headers: { token: localStorage.getItem('token') } }
    );
    const data = await response.data;

    const requests = data;
    return { requests };
  }
);

const requestAdapter = createEntityAdapter({});

export const { selectAll: selectRequest, selectById: selectRequestById } =
  requestAdapter.getSelectors((state) => state?.requests?.request);

function parsear(data) {
  const jsonn = JSON.parse(JSON.stringify(data).replace('req_id', 'id')); ///////
  return jsonn;
}

const requestslice = createSlice({
  name: 'requests/request',
  initialState: requestAdapter.getInitialState({
    disp_requests: [],
    request: [],
    searchText: '',
    request_load: [],
    clone_requests: [],
  }),
  reducers: {
    load: (state, action) => {
      if (state.request_load.length > 0) {
        state.request_load = [];
      } else {
        state.request_load = [1];
      }
    },
    setRequestSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getRequests.fulfilled]: (state, action) => {
      const { requests } = action.payload;
      if (requests && requests.length > 0) {
        const datos = requests.map(parsear);
        requestAdapter.setAll(state, datos);
        state.request = datos;
        state.searchText = '';
      }
    },
    [getDispatcherRequests.fulfilled]: (state, action) => {
      const { requests } = action.payload;
      if (requests && requests.length > 0) {
        const datos = requests.map(parsear);
        state.disp_requests = datos;
        state.searchText = '';
      }
    },
    [getCloneRequests.fulfilled]: (state, action) => {
      const { requests } = action.payload;

      if (requests && requests.length > 0) {
        const datos = requests.map(parsear);
        state.clone_requests = datos;
        state.searchText = '';
      } else {
        state.clone_requests = [];
      }
    },
  },
});

export const { load } = requestslice.actions;

export default requestslice.reducer;
