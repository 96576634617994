import { takeLatest, put, fork, all, call } from 'redux-saga/effects';
import actions from './actions'
import client from '../../../../../../client';
import { getErrorAction, getSuccessAction } from "../../utility";

export function* watchGetCompletedJobs() {
  yield takeLatest(actions.COMPLETED_JOBS, function* ({ payload }) {
    console.log(payload)
    try {
      const uri = `/jobs/get-for-dashboard/${payload.data.jobType}/${payload.data.id}`
      const result = yield call(() => client.get(uri))
      console.log('get-for-dashboard :: ',result)
      yield put({
        type: getSuccessAction(actions.COMPLETED_JOBS),
        payload: {
          jobs: Array.isArray(result.data.results) ? result.data.results: [],
          countTicket: result?.data?.countTicket ? result.data.countTicket : 0,
          page: payload.params?.page || 1,
          allToday: result.data.allToday,
          hasNextPage: result?.data?.results?.length ? true : false
        }
      })
    } catch (error) {
      yield put({
        type: getErrorAction(actions.COMPLETED_JOBS),
      })
    }
  })
}


export default function* rootSaga() {
  yield all([
    fork(watchGetCompletedJobs),
  ]);
}