import {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { constants } from '../../../../../constants';
import store from '../../../ID_store/store';
import moment from 'moment'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from 'framer-motion';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SummaryModal from './SummaryModal';
import { styled } from '@mui/material/styles';

const editNums = (num) => {
    var newnum = num
    if(num.split(".").length == 2)
    {
        if(num.split(".")[1].length < 2)
        {newnum = num+"0"}
    }
    else
    {
        newnum = num+".00"
    }

    return newnum
}


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    
    //change cursor
    "&:hover":{
      cursor: 'pointer'
    }
  }));


function TicketTypeRow({tickets, ticket_type}){
    const [open,setOpen] = useState(false)
    return(
        <>
        <TableRow>
            <TableCell sx={{borderBottom:'none',paddingBottom:0, paddingTop:0}} className='flex flex-row items-center'>
                <Typography variant='h6'>{ticket_type == 'company' ? `Subcontractor (${tickets.length})` : ticket_type == 'og' ? `Company (${tickets.length})` : ticket_type == 'owner operator' ? `Owner Operator (${tickets.length})` : "Error"}</Typography> 
                <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {setOpen(!open)}}
                >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
        </TableRow>

        <TableRow>
            <TableCell colSpan={9}  sx={{borderBottom:'none',paddingTop:0,paddingBottom:0}}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tk No.</TableCell>
                                    <TableCell>Tk Cr.</TableCell>
                                    <TableCell>Tk Cost</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    tickets.map((elem) => {
                                        return(
                                            <TableRow>
                                                <TableCell>{elem.tk_no}</TableCell>
                                                <TableCell> <div className='flex flex-row'><Typography sx={{color:'crimson'}}>{elem.oldtk_cr}</Typography><ArrowForwardIcon sx={{fontSize:20,color:'gray'}}/><Typography sx={{color:'green'}}>{elem.tk_cr}</Typography></div> </TableCell>
                                                <TableCell> <div className='flex flex-row'><Typography sx={{color:'crimson'}}>{elem.oldcost}</Typography><ArrowForwardIcon sx={{fontSize:20,color:'gray'}}/><Typography sx={{color:'green'}}>{elem.cost}</Typography></div> </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    
                </Collapse>
            </TableCell>
        </TableRow>
        </>
    )


}

function InvoiceRow({invoice, invoice_num}){
    const [open,setOpen] = useState(false)
    return(
        <>
        <TableRow>
            <TableCell sx={{borderBottom:'none',paddingBottom:0, paddingTop:0}} className='flex flex-row items-center'>
                <Typography variant='h6'>{invoice_num}</Typography> 
                <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {setOpen(!open)}}
                >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
        </TableRow>

        <TableRow>
            <TableCell colSpan={9}  sx={{borderBottom:'none',paddingBottom:0,paddingTop:1}}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>

                        <Table>
                            <TableHead></TableHead>
                            <TableBody>
                                {
                                    Object.keys(invoice).map((key) => {
                                        return(
                                            <TicketTypeRow tickets={invoice[key]} ticket_type={key}/>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    
                </Collapse>
            </TableCell>
        </TableRow>
        </>
    )


}

function InvoicesRow({invoices}){
    const [open,setOpen] = useState(false)
    return(
        <>
        <TableRow>
            <TableCell sx={{borderBottom:'none',paddingBottom:0}} className='flex flex-row items-center'>
                <Typography variant='h5'>Invoices</Typography> 
                <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {setOpen(!open)}}
                >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
        </TableRow>

        <TableRow>
            <TableCell   sx={{borderBottom:'none'}}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>

                        <Table>
                            <TableHead></TableHead>
                            <TableBody>
                                {
                                    Object.keys(invoices).map((key) => {
                                        return(
                                            <InvoiceRow invoice={invoices[key]} invoice_num={key}/>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    
                </Collapse>
            </TableCell>
        </TableRow>
        </>
    )


}

function UninvoicedRow({ticket_types}){
    const [open,setOpen] = useState(false)
    return(
        <>
        <TableRow>
            <TableCell sx={{borderBottom:'none'}} className='flex flex-row items-center'>
                <Typography variant='h5'>Uninvoiced</Typography> 
                <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {setOpen(!open)}}
                >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
        </TableRow>

        <TableRow>
            <TableCell colSpan={9}  sx={{borderBottom:'none'}}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>

                        <Table>
                            <TableHead></TableHead>
                            <TableBody>
                                {
                                    Object.keys(ticket_types).map((key) => {
                                        return(
                                            <TicketTypeRow tickets={ticket_types[key]} ticket_type={key}/>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    
                </Collapse>
            </TableCell>
        </TableRow>
        </>
    )


}
function Row ({elem, inst}){
    const [open,setOpen] = useState(false)

    return(

           
        <>
        
        <StyledTableRow  onClick={() => {inst.retro ? setOpen(!open) : null}} sx={{}}>
            <TableCell>{moment(inst.date).format("MM/DD/YYYY LT")}</TableCell>
            <TableCell>{inst.type}{`${inst.deleted ? " (deleted)": inst.added ? " (added)": '' }`}</TableCell>
            <TableCell><div className='flex flex-row'><Typography color={"crimson"}>${editNums(inst.oldcost)}</Typography> <ArrowForwardIcon sx={{fontSize:20,color:'gray'}}/> <Typography color={"green"}>${editNums(inst.newcost)}</Typography></div></TableCell>
            {
                inst.retro ? 
                <TableCell>
                    Retroactive
                    <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {setOpen(!open)}}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                :
                <TableCell>Going Forward</TableCell>
            }
            <TableCell><div className='flex flex-row'><Typography color={inst.retro ? +inst.newcost > +inst.oldcost ? 'green' : ( +inst.newcost == +inst.oldcost ? 'black' : 'crimson') : 'black'}>{inst.retro ? +inst.newcost > +inst.oldcost ? `$${editNums(inst.change.toString())}`: `$${editNums(inst.change.toString())}` : `$${editNums(inst.change.toString())}`}</Typography><div className='flex flex-row w-full h-full items-start'>{inst.retro ? +inst.newcost > +inst.oldcost ? <ArrowDropUpIcon sx={{color:'green',fontSize:20}}/> : ( +inst.newcost == +inst.oldcost ? null : <ArrowDropDownIcon sx={{color:'crimson',fontSize:20}}/> ) : null}</div></div></TableCell>
            <TableCell>{inst.updated_by}</TableCell>
        </StyledTableRow>

        <TableRow>
            <TableCell colSpan={9} sx={{borderBottom:2}}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                        <Typography variant="h5">Summary</Typography>

                        <Table>
                            <TableHead></TableHead>
                            <TableBody>

                                
                                <InvoicesRow invoices={elem.retro_data.fixed_invoices_tickets}/>
                                <UninvoicedRow ticket_types={elem.retro_data.fixed_tks_noinvoice}/>
                            </TableBody>
                        </Table>
                    </Box>
                    
                </Collapse>
            </TableCell>
        </TableRow>
        </>
           
            
         
    )
}

function EditSummaryTable ({BillingHistory}) {//7


    return(
        <>
        
        <div>
        {
            BillingHistory.length > 0 ? 
                <>
                <div className='flex flex-row items-center pb-12'><Typography color={'crimson'} variant='subtitle1'>Previous</Typography> <ArrowForwardIcon sx={{fontSize:20}}/> <Typography color={'green'} variant='subtitle1'>Current</Typography></div>

                <TableContainer>
                    <Table stickyHeader>
                        <TableHead >
                            <TableRow >
                                <TableCell sx={{borderBottom:2}}><Typography fontWeight={'bold'}>Date</Typography></TableCell>
                                <TableCell sx={{borderBottom:2}}><Typography fontWeight={'bold'}>Type</Typography></TableCell>
                                <TableCell sx={{borderBottom:2}}><Typography fontWeight={'bold'}>Amount</Typography></TableCell>
                                <TableCell sx={{borderBottom:2}}><Typography fontWeight={'bold'}>Retroactive</Typography></TableCell>
                                <TableCell sx={{borderBottom:2}}><Typography fontWeight={'bold'}>Changes in $</Typography></TableCell>
                                <TableCell sx={{borderBottom:2}}><Typography fontWeight={'bold'}>Updated By</Typography></TableCell>
                            </TableRow>
                            
                        </TableHead>

                        <TableBody>
                            {
                                BillingHistory.map((elem,idx) => {
                                    return(
                                        elem.fixed_history.map(inst => {
                                        return(
                                            <Row elem={elem} inst={inst}/>
                                   
                                        )
                                        })
                                    )
                                    
                                    
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                </>
                :
                <Typography>No Data</Typography>
            
        }
        
        </div>
        </>
        
      
    )
}
 export default EditSummaryTable
