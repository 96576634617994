
import AuthGuard from 'app/main/AuthGuard'
import ManageDevices from './ManageDevices'

const ManageDeviceConfig = {
  settings: {},
  routes: [
    {
      path: '/manage-devices',
      component: () => AuthGuard(<ManageDevices />),
    }
  ],
}

export default ManageDeviceConfig
