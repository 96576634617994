import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../main/ID_store/store';
import { ROLES, constants } from '../../../constants';
import { Link, useHistory } from 'react-router-dom';
import { userLoggedOut } from '../../auth/store/userSlice';
import { injectReducer } from 'app/store';
import { connect } from 'react-redux';
import AuthReducer from 'app/stores/loadz/redux/Auth';
import AuthAction from 'app/stores/loadz/redux/Auth/actions';
import client from 'client';
import ErrorBoundary from 'app/main/ErrorBoundary';
import { hasPermission } from 'app/main/loadz/utils';
import ButtonLog from 'app/main/ButtonLog';

function UserMenu({ user, resetAuth }) {
  const dispatch = useDispatch();
  const [jsonGetById, setJsonGetById] = useState([]);
  const userProfile = useSelector(({ auth }) => auth.user);
  const [profileImg, setProfileImg] = useState('');
  let history = useHistory();
  const [userMenu, setUserMenu] = useState(null);

  function sendToProfile() {
    history.push('/profile');
  }
  function sendToAccount() {
    history.push('/account');
  }
  function sendToHistory() {
    // history.push('/subscriptions-company');
    history.push('/subscriptions-history');
  }

  const handleSearchUserById = async () => {
    if (user?.user_id && user?.user_type != ROLES.super_admin) {
      try {
        const userInfo = await client.get(`/users/${user.user_id}`);
        setJsonGetById(userInfo.data);
      } catch (error) {
        console.log('errorerror => ', error);
        // history.push('/profile')
      }
    }
  };

  useEffect(() => {
    handleSearchUserById();
  }, []);

  useEffect(async () => {
    if (jsonGetById?.profile_img) {
      if (jsonGetById.profile_img.includes('http')) {
        setProfileImg(jsonGetById.profile_img);
      } else {
        setProfileImg(`${constants.URLLOCAL}/aws/signed?image=${jsonGetById.profile_img}`);
      }
      console.log(jsonGetById.profile_img);
    }
  }, [jsonGetById?.profile_img]);

  useEffect(() => {
    if (user?.profile_img) {
      const profile = user.profile_img.includes('http')
        ? user.profile_img
        : `${constants.URLLOCAL}/aws/signed?image=${user.profile_img}`;
      setProfileImg(profile);
    }
  }, [user]);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  async function LogOff() {
    // logs user off to prevent auto redirect to dahsboard
    history.replace('/login');
    await ButtonLog({ button_name: 'LogOut' });
    localStorage.removeItem('token');
    await store.dispatch({ type: 'LOG_OUT' });
    dispatch(userLoggedOut());
    await resetAuth();
    //resetLocalPersist()
    
  }

  const resetLocalPersist = () => {
    const storage = localStorage.getItem('persist:root');
    const localStore = JSON.parse(storage);
    const loadz_auth = JSON.stringify({
      AuthReducer: { user: null, loading: false, onboardingSteps: null, credentials: null },
    });
    localStorage.setItem('persist:root', JSON.stringify({ ...localStore, loadz_auth }));
    console.log(JSON.parse(localStorage.getItem('persist:root')));
  };

  return (
    <ErrorBoundary>
      {user?.user_type == 'company' ? (
        <Button
          className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
          onClick={userMenuClick}
          color="inherit"
        >
          <div className="flex flex-col mx-4 items-end">
            <Typography
              component="span"
              className="font-Regular "
            >
              {user?.company?.compname}
            </Typography>
            <Typography
              className="text-11 font-Regular capitalize"
              color="textSecondary"
            >
              {user?.user_type}
            </Typography>
            <Typography
              className="text-11 font-Regular capitalize"
              color="textSecondary"
            ></Typography>
          </div>
          <div className="bg-dark p-3 rounded-ss-full userbg">
            <h1 style={{ color: '#fff' }}>{user?.company?.compname.slice(0, 1)}</h1>
          </div>
        </Button>
      ) : (
        <Button
          className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
          onClick={userMenuClick}
          color="inherit"
        >
          <div className="flex flex-col mx-4 items-end">
            <Typography
              component="span"
              className="font-Regular "
            >
              {user?.user_type == ROLES.super_admin
                ? user?.user_fname
                : `${user?.staff?.staff_fname} ${user?.staff?.staff_lname}`}
              {/* {user?.staff?.staff_fname} {user?.staff?.staff_lname} */}
            </Typography>
            <Typography
              className="text-11 font-Regular capitalize"
              color="textSecondary"
            >
              {user?.user_type}
            </Typography>
            <Typography
              className="text-11 font-Regular capitalize"
              color="textSecondary"
            ></Typography>
          </div>
          <div className="bg-dark p-3 rounded-ss-full userbg">
            <h1 style={{ color: '#fff' }}>
              {user?.user_type == ROLES.super_admin
                ? user?.user_fname.slice(0, 1)
                : user?.staff?.staff_fname.slice(0, 1)}
            </h1>
          </div>
        </Button>
      )}
      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8 login-profile-main',
        }}
      >
        <>
          {user?.user_type == ROLES.super_admin ? (
            <div
              className="login-profile"
              style={{ backgroundColor: '#FFFFFF' }}
            >
              <MenuItem
                onClick={LogOff}
                style={{ backgroundColor: '#FFFFFF' }}
              >
                <ListItemIcon
                  className="min-w-40"
                  style={{ backgroundColor: '#FFFFFF' }}
                >
                  <Icon>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </div>
          ) : (
            <div
              className="login-profile"
              style={{ backgroundColor: '#FFFFFF' }}
            >
              {hasPermission('jobs.update') && (
                <>
                  {/* <MenuItem
                    component={Link}
                    to="/profile"
                    role="button"
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    <ListItemIcon
                      className="min-w-40"
                      style={{ backgroundColor: '#FFFFFF' }}
                    >
                      {profileImg ? (
                        <img
                          style={{ width: '20px', height: '20px', borderRadius: '40px' }}
                          className=" avatar w-96 h-72 p-0 box-content"
                          alt="user photo"
                          src={profileImg}
                        />
                      ) : (
                        <img
                          style={{ width: '20px', height: '20px', borderRadius: '40px' }}
                          className=" avatar w-96 h-72 p-0 box-content"
                          alt="user photo"
                          src={userProfile.data.photoURL}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      onClick={sendToProfile}
                      role="button"
                      primary="My Profile"
                    />
                  </MenuItem> */}
                  <MenuItem onClick={sendToAccount}>
                    <ListItemIcon
                      className="min-w-40"
                      style={{ backgroundColor: '#FFFFFF' }}
                    >
                      <img src="assets/images/icons/user-icon.svg" />
                    </ListItemIcon>
                    <ListItemText
                      role="button"
                      primary="My Account"
                    />
                  </MenuItem>
                  <MenuItem
                  disabled
                  hidden
                    //onClick={sendToHistory}
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    <ListItemIcon
                      className="min-w-40"
                      style={{ backgroundColor: '#FFFFFF' }}
                    >
                      <Icon>account_balance</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Linked Banks" />
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={LogOff}
                style={{ backgroundColor: '#FFFFFF' }}
              >
                <ListItemIcon
                  className="min-w-40"
                  style={{ backgroundColor: '#FFFFFF' }}
                >
                  <Icon>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </div>
          )}
        </>
      </Popover>
    </ErrorBoundary>
  );
}

injectReducer('loadz_auth', AuthReducer);

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth.AuthReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  resetAuth: () => dispatch(AuthAction.resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
