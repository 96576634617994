import { lazy } from 'react'
import { Redirect } from 'react-router-dom'

const MileageAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/mileages/logs/:mileageId/:mileageName?',
      // component: lazy(() =>
      //   import('app/main/loadz/view/mileages/CreateUpdate')
      // ),
      component: lazy(() => import('./mileage/Mileage')),
    },
    {
      path: '/mileages/logs',
      component: lazy(() => import('./mileages/Mileages')),
    },
    {
      path: '/mileages',
      component: () => <Redirect to="/mileages/logs" />,
    },
  ],
}

export default MileageAppConfig
