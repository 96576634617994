import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { Box } from '@mui/system';
import { closeMobileChatsSidebar } from './store/sidebarSlice';
import { useDispatch } from 'react-redux';
import { formatter } from '../../../../../constants';
const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  ...(active && {
    backgroundColor: theme.palette.background.paper,
  }),
}));

function ClientsList({ clients }) {
  const dispatch = useDispatch()
  return (
    <StyledListItem
      button
      className="px-16 py-12 min-h-92"
      active={0}
      onClick={() => dispatch(closeMobileChatsSidebar())}
    >
      <div className="relative">
        <div className="absolute right-0 bottom-0 -m-4 z-10">
        </div>

        <Avatar src={null} alt={null}>
          {clients && clients?.customerName ? clients.customerName.charAt(0) : null}
        </Avatar>
      </div>

      <ListItemText
        classes={{
          root: 'min-w-px px-16',
          primary: 'font-medium text-14',
          secondary: 'truncate',
        }}
        primary={clients?.customerName}
        secondary={
          <>
            <Typography
              sx={{ display: 'inline',fontWeight:"bold" }}
              component="span"
              variant="body2"
            >
              Balance
            </Typography>
            {`: ${clients?.balanceTotal ? formatter.format(clients.balanceTotal) : "$0.00"}`}
          </>
        }
      />
    </StyledListItem>
  );
}

export default ClientsList;
