import Roles from './role/Roles'
import Permissions from './permission/Permissions'
import CreateEditRole from './role/CreateEditRole'
import AuthGuard from 'app/main/AuthGuard'
import RolesPermissions from './RolesPermissions'
import CreateUpdateData from './Manage/CreateUpdateData'
import { lazy } from 'react'

const RolesPermissionConfig = {
  settings: {},
  routes: [
    {
      path: '/roles/:id',
      component: () => AuthGuard(<CreateEditRole />),
    },
    {
      path: '/roles',
      component: () => AuthGuard(<Roles />),
    },
    {
      path: '/permissions',
      component: () => AuthGuard(<Permissions />),
    },
    {
      path: '/roles-permissions/:id',
      component: () => AuthGuard(<CreateUpdateData />),
    },
    {
      path: '/roles-permissions',
      component: () => AuthGuard(<RolesPermissions />),
    },
  ],
}

export default RolesPermissionConfig
