import { Box, Dialog, Typography } from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'
import { useEffect, useState } from 'react'
import client from 'client'
import { v4 as uuid } from 'uuid'
import CircularLoader from './CircularLoader'
import store from 'app/store'
import { showMessage } from 'app/store/fuse/messageSlice'
import { useDispatch } from 'react-redux'
import { constants } from '../../../../constants'
import { postImage,postImages3,postProfileImage } from 'app/main/Register_v2/CompanyRegisterForms/CompanyRegisterFunctions';
import { getDataFromS3 } from "app/main/CompanyList/companyFunctions";

const ImageUploaderDialog = ({isOpen, setOpen, key, setKey, bucketType,type,prop_data}) => {

  const fileTypes = ['JPEG', 'PNG', 'JPG']
  const GET_PRE_SIGNED_URL = '/aws/pre-signed-url'
  const state = store.getState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [preview, setPreview] =useState(null)
  const _showMessage = (msg) => {
    dispatch(
      showMessage({
        message: (<>
          <Typography>{msg}</Typography>
        </>),//text or html
        autoHideDuration: 6000,//ms

        anchorOrigin: {
          vertical: 'top',//top bottom
          horizontal: 'right'//left center right
        },
        variant: "error" //success error info warning null
      }))
  }

  const getS3Location = async (key) => {
    if (key != null && key != "") {
      let data = {
        key: key,
      };
      return await getDataFromS3(data);
    }
  }

  const onChange = async (file) => {
    setLoading(true)

    const user_id = prop_data ? prop_data.id : state?.loadz_auth?.AuthReducer?.user?.user_type == 'company' ? state?.loadz_auth?.AuthReducer?.user?.user_id : state?.loadz_auth?.AuthReducer?.user?.staff?.staff_u_id
    const reader = new FileReader();
    reader.onload = async () => {
      const res = await postProfileImage({
          baseString: `${btoa(reader.result)}`,
          company_id: user_id,
          fileType: "company",
          fileName: file.name.replace(/ /g, ""),
      })
      if (res.status) {
        const data = {type: type ? type : null ,arrData: [{key: res.data.key}]}
        const response = await postImages3(data) 
        console.log(response.data.fileLocation[0]) 
        setKey(response.data.fileLocation[0])
      }
      setLoading(false)

      if (!loading) setOpen(false)
    }

    reader.readAsBinaryString(file)
  }

  /* const onChange = async (file) => {
    setLoading(true)
    const user_id = state?.loadz_auth?.AuthReducer?.user?.user_id
    try {
      let url = URL.createObjectURL(file)
      setPreview(url)

      const newKey = uuid()
      const params = { key: newKey, bucketType, user_id }
      console.log(params)
      const result = await client.post(GET_PRE_SIGNED_URL, params)
      if (result?.data?.key) {
        console.log(result?.data?.url)
        const uploadResult = await client.put(result.data.url, file)

        if (uploadResult?.status == 200) {
          setKey(result?.data?.key)
        }
        setLoading(false)

        if (!loading) setOpen(false)
      }
    } catch (error) {
      setLoading(false)
      setOpen(false)
      _showMessage("Something Went wrong! Please try again")
      console.log(error)
    }
  } */

  return (
    <Dialog
    open={isOpen}
    onClose={() => {
      if (!loading) {
        setOpen(false)
      }
    }}
  >
    <FileUploader
      children={
        <div className="popup-image">
          <Box
            sx={{
              width: 300,
              height: 300,
              border: '5px dashed lightblue',
              borderRadius: 20,
              pl: 10,
              pr: 23,
              pt: 20,
              pb: 20,
            }}
          >
            <div className="flex flex-col sm:flex sm:flex-row pb-32">
              {preview && <img
                src={preview}
                style={{ maxHeight: '300px', maxWidth: '300px' }}
              />}
              &nbsp;
              <div className="file-format">
                <div>{fileTypes[0]}</div>
                <div>,{fileTypes[1]} </div>
                <div>,{fileTypes[2]} </div>
              </div>
            </div>
          </Box>
        </div>
      }
      handleChange={onChange}
      name="file"
      types={fileTypes}
    />
  </Dialog>
  )
}

export default ImageUploaderDialog;