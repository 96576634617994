import FuseScrollbars from '@fuse/core/FuseScrollbars';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClientsList from './ClientsList';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import FuseUtils from '@fuse/utils';  
import AppBar from '@mui/material/AppBar';
import { de } from 'date-fns/locale';
import ErrorBoundary from 'app/main/ErrorBoundary';
import FuseLoading from '@fuse/core/FuseLoading';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { grey } from '@mui/material/colors';


function Clients({
  rows,
  setName,
  setEmail,
  setAvatar,
  info,
  setTickets,
  indexJob,
  setIndexJob,
  setInfoToInvoice,
  setAddress,
  setCity,
  setZip,
  setCreditLimit,
  setBalance,
  setPhone,
  setCustomerTerm,
  setCustomer,
  setCustomerName,
  loading,
  ticket,
  setTicket,
  handleTicketSearch
}) {
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  const [user, setUser] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [searchText, setSearchText] = useState('');
  const [statusMenuEl, setStatusMenuEl] = useState(null);
  const [moreMenuEl, setMoreMenuEl] = useState(null);

  function handleMoreMenuClick(event) {
    setMoreMenuEl(event.currentTarget);
  }

  function handleMoreMenuClose(event) {
    setMoreMenuEl(null);
  }

  function handleStatusMenuClick(event) {
    event.preventDefault();
    event.stopPropagation();
    setStatusMenuEl(event.currentTarget);
  }

  function handleStatusSelect(event, status) {
    event.preventDefault();
    event.stopPropagation();
    // dispatch(
    //   updateUserData({
    //     ...user,
    //     status,
    //   })
    // );
    setStatusMenuEl(null);
  }

  function handleStatusClose(event) {
    event.preventDefault();
    event.stopPropagation();
    setStatusMenuEl(null);
  }

  function handleSearchText(event) {
    setSearchText(event.target.value);
  }

  const handleResetTickets = () => {
    setTickets([])
  }

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const buttonRef = useRef(null);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      buttonRef.current.click();
    }
  };

  return (
    <div className="flex flex-col flex-auto h-full clientpage">
                <AppBar position="static" color="default" elevation={0}>
                 <Toolbar className="px-16">
                  <Paper className="flex p-4 items-center w-full px-8 py-4 shadow">
                  <Icon color="action">search</Icon>

                  <Input
                    placeholder="Search Customers"
                    className="flex flex-1 px-8"
                    disableUnderline
                    fullWidth
                    value={searchText}
                    inputProps={{
                      'aria-label': 'Search',
                    }}
                    onChange={handleSearchText}
                  />
                  </Paper>
                 </Toolbar>
                </AppBar>
      <FuseScrollbars className="overflow-y-auto flex-1">
        <List className="w-full">
          <motion.div
            className="flex flex-col flex-shrink-0 px-10"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <motion.div variants={item}>

              <div className="flex flex-row items-center justify-center">
                <Input
                  value={ticket}
                  style={{ height: '100%', color: 'black' }}
                  className="flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-1/2"
                  disableUnderline
                  fullWidth
                  type="number"
                  placeholder="Search Tickets"
                  onChange={(e) => {
                    if (RegExp('[0-9]').test(e.target.value.slice(-1)) == false) {
                      e.target.value = e.target.value.slice(0, -1);
                    }
                    setTicket(e.target.value.replace(/\s/g, ''));
                  }}
                  onKeyPress={handleKeyPress}
                />
                <Tooltip
                  title="Search"
                  placement="right"
                  style={{ flex: 'none' }}
                >
                  <IconButton
                    onClick={() => {
                      handleTicketSearch();
                    }}
                    ref={buttonRef}
                  >
                    <SearchIcon sx={{ color: grey[900] }} />
                  </IconButton>
                </Tooltip>
              </div>
            </motion.div>
          </motion.div>

          {useMemo(() => {
            function getFilteredArray(arr, _searchText) {
              if (_searchText.length === 0) {
                return arr;
              }
              return FuseUtils.filterArrayByString(arr, _searchText);
            }
            const filteredRows= getFilteredArray([...rows], searchText);
           
            

            return (
              <motion.div
                className="flex flex-col flex-shrink-0 px-10"
                variants={container}
                initial="hidden"
                animate="show"
              >

                {
                  loading == true ? 
                  <FuseLoading/>
                  :
                  filteredRows.map((row, i) => (
                    <motion.div variants={item} key={i} onClick={
                      () => {
                        setCustomer(row?.customer?.customerName)
                        setName(row.customerAddress)
                        setEmail(row.customer.customerEmail)
                        setCity(row?.customer?.customerCity)
                          setAddress(row?.customer?.customerState)
                          setZip(row?.customer?.customerZip)
                          setCreditLimit(row?.creditLimits)
                          setCustomerTerm(row?.customer.customerTerm)
                          setBalance(row?.customerBalance)
                          {row && row.customer && row.customer.customerContact && row.customer.customerContact.length >0 ?
                            
                            setPhone(row.customer.customerContact[0].phone) :""
                          }
                          {row && row.customer && row.customer.customerContact && row.customer.customerContact.length >0 ?

                            setCustomerName(row.customer.customerContact[0].name):""
                          }
                        setAvatar(null)
                        handleResetTickets()
                        setIndexJob(i)
                        setInfoToInvoice(row.customer)
                      }
                    }>
                      <ErrorBoundary>
                        <ClientsList
                          clients={row.customer}
                        />
                      </ErrorBoundary>
                    </motion.div>
                  ))
                }

              </motion.div>
            );
          }, [contacts, user, searchText, dispatch, isMobile,rows])}
        </List>
      </FuseScrollbars>
    </div>
  );
}

export default Clients;
