import FuseSearch from '@fuse/core/FuseSearch';
import { ThemeProvider } from '@mui/material/styles';
import FuseShortcuts from '@fuse/core/FuseShortcuts';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import { SwipeableDrawer } from '@mui/material';
import { Box } from '@mui/system';

const drawerBleeding = 0;
function ToolbarLayout1(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const navbar = useSelector(({ fuse }) => fuse.navbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  function HomeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  }

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const linkYoutube = (link) => {
    window.open(link);
  };

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{ backgroundColor: toolbarTheme.palette.background.paper }}
        position="static"
      >
        <Toolbar className="p-0 min-h-64 md:min-h-64">
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <Hidden lgDown>
              <FuseShortcuts />
            </Hidden>
          </div>

          <Hidden mdDown>
            <div className="flex items-center px-8 h-full overflow-x-auto">
              <LanguageSwitcher />
              <AdjustFontSize />
              <FullScreenToggle />

              <Button
                onClick={() =>
                  linkYoutube('https://www.youtube.com/channel/UCsIaBjzrNMCDpqWQ5dLmhzA')
                }
                style={{ color: 'black' }}
              >
                <YouTubeIcon />
              </Button>

              <FuseSearch />
              <QuickPanelToggleButton />
              <UserMenu />
            </div>
          </Hidden>

          <Hidden mdUp>
            <div className="flex px-16 pr-0 items-center justify-center mobileusermenu">
              <div className="flex flex-1 px-16 pr-0">
                <UserMenu />
              </div>

              <Box sx={{ textAlign: 'center' }}>
                <Button onClick={toggleDrawer(true)}>
                  <Icon
                    className="text-32 arrow-icon"
                    color="black"
                  >
                    {' '}
                    {'expand_more'}{' '}
                  </Icon>
                </Button>
              </Box>
            </div>
          </Hidden>

          <SwipeableDrawer
            className="onmobiledrawr"
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div className="flex items-center justify-start flex-col p-32 h-full overflow-x-auto mobiletogglebtns">
              <FuseSearch />

              <Button
                onClick={() =>
                  linkYoutube('https://www.youtube.com/channel/UCsIaBjzrNMCDpqWQ5dLmhzA')
                }
                style={{ color: 'black' }}
              >
                <YouTubeIcon />
              </Button>

              <FullScreenToggle />
              <QuickPanelToggleButton />
              <LanguageSwitcher />
              <AdjustFontSize />
            </div>
          </SwipeableDrawer>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden lgDown>
                {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
              </Hidden>
              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
