import { lazy } from 'react';




//sets the pages display settings

const ConfirmDispatchAppConfig = {
  settings: {
    layout: {
        style : 'layout1',
        config: {
          
            navbar : {
                display : false,
                
            },
            toolbar: {
                display : false,
            },
            footer : {
                display : false,
                
            },
            mode   : 'fullwidth'
        }
    },
    
},
  routes: [
    {
      path: '/dispatch-confirm/:id/:key/:driver_id',
      component: lazy(() => import('./ConfirmDispatchApp')),
    },
  ],
};

export default ConfirmDispatchAppConfig
