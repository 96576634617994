import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';

import ComingSoonPageConfig from 'app/main/Register/ComingSoonPageConfig';
import ErrorPageConfig from 'app/main/404/Error404PageConfig';
import FuseLoading from '@fuse/core/FuseLoading';
import Error404Page from 'app/main/404/Error404Page';
import InvitationAppConfig from 'app/main/Invitations/InvitationAppConfig';
import EmployeeRegisterConfig from 'app/main/Register_v2/EmployeeRegisterForms/EmployeeRegisterConfig';
import ResendConfig from 'app/main/Register_v2/EmployeeRegisterForms/ResendConfig';
import OTPConfig from 'app/main/Register_v2/EmployeeRegisterForms/OTPConfig';
import QuickCompLoginConfig from 'app/main/Login_v2/EmployeeLoginForms/QuickCompLoginConfig';
import SubscriptionViewConfig from 'app/main/Register_v2/SubscriptionViewConfig';

import TermsViewConfig from 'app/main/Register_v2/TermsViewConfig';
import PrivacyConfig from 'app/main/Register_v2/PrivacyConfig';
import Login_v2Config from 'app/main/Login_v2/Login_v2Config';
import ForgotPasswordConfig from 'app/main/ForgotPassword/ForgotPasswordConfig';
import ResetErrorConfig from 'app/main/ForgotPassword/ResetErrorConfig';
import TempScreenConfig from 'app/main/TempScreen/TempScreenConfig';
import ViewDriverConfig from 'app/main/ViewDriver/ViewDriverConfig';
import DriversAppConfig from 'app/main/DriverList/DriversAppConfig';
import appsConfigs from 'app/main/appsConfigs';
import CompaniesAppConfig from 'app/main/CompanyList/CompaniesAppConfig';
import SalaryAppConfig from 'app/main/Salary/SalaryAppConfig';
import TimeSheetAppConfig from 'app/main/TimeSheets/TimeSheetAppConfig';
import DwollaAppConfig from 'app/main/Dwolla/DwollaAppConfig';
import UserTypeConfig from 'app/main/Register/UserTypeConfig';
import ApplyPaymentConfig from 'app/main/Register_v2/Utils/ApplyPaymentConfig';
import ApplyPaymentSubscriptionsConfig from 'app/main/subscriptionManagement/paymentSubscriptions/ApplyPaymentSubscriptionsConfig';
import RolesPermissionConfig from 'app/main/loadz/view/roles-permission/RolesPermissionConfig';
import CustomerPortalConfig from 'app/main/Register_v2/Utils/ApplyPaymentTabs/CustomerPortal/CustomerPortalConfig';
import CustomerSettingsConfig from 'app/main/Register_v2/Utils/ApplyPaymentTabs/CustomerPortal/settings/CustomerSettingsConfig';
import MakePaymentConfig from 'app/main/Register_v2/Utils/ApplyPaymentTabs/CustomerPortal/payments/MakePaymentConfig';
import PaymentCheckoutConfig from 'app/main/Register_v2/Utils/ApplyPaymentTabs/CustomerPortal/payments/PaymentCheckoutConfig';
import CompanyInvoicesConfig from 'app/main/Register_v2/Utils/ApplyPaymentTabs/CustomerPortal/Invoices/CompanyInvoicesConfig';
import PaidPaymentConfig from 'app/main/Register_v2/Utils/ApplyPaymentTabs/CustomerPortal/payments/PaidPaymentConfig';
import ViewSingleInvoiceConfig from 'app/main/Register_v2/Utils/ApplyPaymentTabs/CustomerPortal/payments/viewInvoice/ViewSingleInvoiceConfig';
import DriverAccoutConfig from 'app/main/driver-delete/DriverAccoutConfig';
import ManageDeviceConfig from 'app/main/loadz/view/manage-devices/ManageDeviceConfig';
import AgentPortalConfig from 'app/main/agents/AgentPortalConfig';
//to add a new page to the routes the Config file must go here

//not sure if we need to add routes down below, so far it has worked by adding the routes and not adding them
const maint = false;
if (maint == true) {
  const routeConfigs = [ComingSoonPageConfig];

  var routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
    {
      exact: true,
      path: '/',
      component: () => <Redirect to="/coming-soon/test" />,
    },
    {
      component: () => <Redirect to="/coming-soon/test" />,
    },
  ];
}

if (maint == false) {
  const routeConfigs = [
    ...appsConfigs,
    Login_v2Config,
    EmployeeRegisterConfig,
    ForgotPasswordConfig,
    ComingSoonPageConfig,
    ResetErrorConfig,
    ErrorPageConfig,
    TempScreenConfig,
    InvitationAppConfig,
    ViewDriverConfig,
    ResendConfig,
    OTPConfig,
    QuickCompLoginConfig,
    SubscriptionViewConfig,
    MakePaymentConfig,
    PaymentCheckoutConfig,
    PaidPaymentConfig,
    ViewSingleInvoiceConfig,
    CompanyInvoicesConfig,
    ApplyPaymentConfig,
    CustomerPortalConfig,
    CustomerSettingsConfig,
    TermsViewConfig,
    PrivacyConfig,
    UserTypeConfig,
    SalaryAppConfig,
    TimeSheetAppConfig,
    DwollaAppConfig,
    RolesPermissionConfig,
    CompaniesAppConfig,
    ApplyPaymentSubscriptionsConfig,
    DriverAccoutConfig,
    ManageDeviceConfig,
    AgentPortalConfig
    //DriversAppConfig
  ]; //multiple config files go here

  //the comment below was already here
  var routes = [
    // if you want to make whole app auth protected by default change defaultAuth for example:
    // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
    // The individual route configs which has auth option won't be overridden.
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
    {
      exact: true,
      path: '/',
      component: () => <Redirect to="/login" />,
    },

    {
      path: '/loading',
      exact: true,
      component: () => <FuseLoading />,
    },
    {
      path: '/404',
      component: () => <Error404Page />,
    },
    {
      component: () => <Redirect to="/404" />,
    },
  ];
}

export default routes;
