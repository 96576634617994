import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  Typography,
  Grid,
} from '@mui/material';
import react, { forwardRef, useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import axios from 'axios';
import { formatter, constants } from '../../../../../constants';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactToPrint from 'react-to-print';
import { StyleSheet } from './ModelBalance.css';
import Switch from '@mui/material/Switch';
import client from 'client';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
function ModelBalance({ onClose, selectedValue, open, user }) {
  const [balanceData, setBalanceData] = useState(null);
  const [balanceByGroup, setBalanceByGroup] = useState([]);
  const [grandTotal, setGrandTotal] = useState(null);
  const [showExpanded, setshowExpanded] = useState(false);
  const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  };
  // console.log('data====>',balanceData)

  const customerBalance = async () => {
    if (open) {
      const comp_acct = localStorage.getItem('comp_acct');
      const response = await client.get(
        `${constants.URLLOCAL}/invoices/customers/balance/${user.company.compacctnum}`
      );
      //  console.log("responsedatadata",response.data.data)
      let arrayByAge = [];
      if (response && response.data && response.data.data) {
        response.data.data.forEach((element) => {
          const duedata = element.due_date;
          if (moment()._d > moment(duedata)._d) {
            const val = moment().diff(moment(duedata), 'days');
            element.aging = val;
            arrayByAge.push(element);
          } else {
            const valSecond = moment(duedata).diff(moment(), 'days');
            element.aging = valSecond;
            arrayByAge.push(element);
          }
        });
      }
      console.log('arrayByAge', arrayByAge);
      let summaryArray = [];
      let group1total = 0,
        group2total = 0,
        group3total = 0,
        group4total = 0,
        group5total = 0;
      arrayByAge.forEach((element) => {
        let amount = 0;
        if (element?.invoice_balance?.startsWith('$')) {
          amount = parseFloat(element.invoice_balance.replace(/,/g, '').split('$')[1]);
        } else if (element?.invoice_balance?.startsWith('-$')) {
          amount = -parseFloat(element.invoice_balance.replace(/,/g, '').split('-$')[1]);
        } else {
          amount = parseFloat(element.invoice_balance.replace(/,/g, ''));
        }
        if (summaryArray.length == 0) {
          let obj = {
            client_id: element.client_id,
            customer_name: element.customer_name,
            group1: 0,
            group2: 0,
            group3: 0,
            group4: 0,
            group5: 0,
            total: 0,
          };
          if (element.aging == 0) {
            obj['group1'] = amount;
            group1total = group1total + amount;
          } else if (element.aging >= 1 && element.aging <= 30) {
            obj['group2'] = amount;
            group2total = group2total + amount;
          } else if (element.aging >= 31 && element.aging <= 60) {
            obj['group3'] = amount;
            group3total = group3total + amount;
          } else if (element.aging >= 61 && element.aging <= 90) {
            obj['group4'] = amount;
            group4total = group4total + amount;
          } else if (element.aging > 90) {
            obj['group5'] = amount;
            group5total = group5total + amount;
          }
          obj['total'] = amount;
          obj['tickets'] = [element];

          summaryArray.push(obj);
        } else {
          let customerIndex = summaryArray.findIndex((x) => x.client_id == element.client_id);
          if (customerIndex == -1) {
            let obj = {
              client_id: element.client_id,
              customer_name: element.customer_name,
              group1: 0,
              group2: 0,
              group3: 0,
              group4: 0,
              group5: 0,
              total: 0,
            };
            if (element.aging == 0) {
              obj['group1'] = amount;
              group1total = group1total + amount;
            } else if (element.aging >= 1 && element.aging <= 30) {
              obj['group2'] = amount;
              group2total = group2total + amount;
            } else if (element.aging >= 31 && element.aging <= 60) {
              obj['group3'] = amount;
              group3total = group3total + amount;
            } else if (element.aging >= 61 && element.aging <= 90) {
              obj['group4'] = amount;
              group4total = group4total + amount;
            } else if (element.aging > 90) {
              obj['group5'] = amount;
              group5total = group5total + amount;
            }
            obj['total'] = amount;
            obj['tickets'] = [element];
            summaryArray.push(obj);
          } else {
            summaryArray[customerIndex]['total'] = summaryArray[customerIndex]['total'] + amount;
            summaryArray[customerIndex]['tickets'].push(element);
            if (element.aging == 0) {
              summaryArray[customerIndex]['group1'] =
                summaryArray[customerIndex]['group1'] + amount;
              group1total = group1total + amount;
            } else if (element.aging >= 1 && element.aging <= 30) {
              summaryArray[customerIndex]['group2'] =
                summaryArray[customerIndex]['group2'] + amount;
              group2total = group2total + amount;
            } else if (element.aging >= 31 && element.aging <= 60) {
              summaryArray[customerIndex]['group3'] =
                summaryArray[customerIndex]['group3'] + amount;
              group3total = group3total + amount;
            } else if (element.aging >= 61 && element.aging <= 90) {
              summaryArray[customerIndex]['group4'] =
                summaryArray[customerIndex]['group4'] + amount;
              group4total = group4total + amount;
            } else if (element.aging > 90) {
              summaryArray[customerIndex]['group5'] =
                summaryArray[customerIndex]['group5'] + amount;
              group5total = group5total + amount;
            }
          }
        }
      });
      if (summaryArray && summaryArray.length > 0) {
        console.log('group1total', group1total);
        setBalanceData(summaryArray.sort(dynamicSort('customer_name')));
        setBalanceByGroup({
          group1t: group1total.toFixed(2),
          group2t: group2total.toFixed(2),
          group3t: group3total.toFixed(2),
          group4t: group4total.toFixed(2),
          group5t: group5total.toFixed(2),
        });
        let result = 0;
        summaryArray.forEach((element) => {
          result += element.total;
        });
        setGrandTotal(result.toFixed(2));
      }
    }
  };

  useEffect(async () => {
    customerBalance();
  }, [open]);
  const handleClosebalance = () => {
    onClose(selectedValue);
    setshowExpanded(false);
  };

  const checkDays = (agingValue, DueDate, updatedDate, createdDate) => {
    // console.log("agingValue", agingValue)
    // console.log("DueDate", DueDate)
    let st = moment()._d;
    let ed = moment(DueDate)._d;
    if (st > ed) {
      return <TableCell sx={{ color: 'red', padding: '0' }}>{`${agingValue}`}</TableCell>;
    } else {
      return `${agingValue}`;
    }
  };

  const handleconins = () => {
    setshowExpanded(!showExpanded);
  };

  const componentRef = forwardRef();

  function handleBeforePrint() {
    const height = componentRef.current.offsetHeight;
    const pageHeight = window.innerHeight;
    const pages = Math.ceil(height / pageHeight);
    const lastPage = pages - 1;
  }
  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClosebalance}
      open={open}
      className="common-modal"
    >
      <div className="customer-balance-modal">
        <div className="flex items-center w-full customer-modal-topbar">
          <ReactToPrint
            onBeforePrint={handleBeforePrint}
            trigger={() => (
              <button
                className="invoicePrintbtn customer-balance-btn"
                type="button"
              >
                <img
                  width="20px"
                  height="20px"
                  src="/assets/images/icons/print-icon-white.svg"
                />
              </button>
            )}
            content={() => componentRef.current}
          />

          <div className="flex items-center w-full ml-48">
            <Switch
              checked={showExpanded}
              onChange={handleconins}
            />
            <p>Expand Balance Sheet</p>
          </div>
        </div>
        {showExpanded ? (
          <>
            <div ref={componentRef && showExpanded ? componentRef : null}>
              <DialogTitle
                align="center"
                className="customer-balance-heading"
              >
                CUSTOMER BALANCE & AGING SUMMARY
              </DialogTitle>
              <Typography
                align="center"
                variant="h6"
                style={{ fontSize: '16px' }}
                className="customer-balance-heading"
              >
                <b className="customer-balance-heading">As of :</b> {moment().format('MM/DD/YYYY')}
                {/* this is the formatter in line no 236 which we are using globally for formatting the currency */}
              </Typography>
              <DialogContent className="customer-balance">
                <TableContainer component={Paper}>
                  <Table
                    style={{ width: '100%' }}
                    aria-label="simple table"
                    className="customer-balance-table"
                  >
                    <TableHead
                      width="100%"
                      style={{ display: 'contents' }}
                    >
                      <TableRow>
                        <TableCell className="font-bold">Customer</TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          Current
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          1 - 30
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          31 - 60
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          61 - 90
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          {'>'} 90
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          TOTAL
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {balanceData &&
                        balanceData
                          .filter((users) => users.total !== 0)
                          .map((row, i) => (
                            <>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ width: '100%' }}
                              >
                                <TableRow
                                  key={i}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                  >
                                    {row.customer_name}
                                  </TableCell>

                                  {/* <TableCell align="right">{`$${(row.group1).toFixed(2)}`}</TableCell>
                                  <TableCell align="right">{`$${(row.group2).toFixed(2)}`}</TableCell>
                                  <TableCell align="right">{`$${(row.group3).toFixed(2)}`}</TableCell>
                                  <TableCell align="right">{`$${(row.group4).toFixed(2)}`}</TableCell>
                                  <TableCell align="right">{`$${(row.group5).toFixed(2)}`}</TableCell>
                                  <TableCell align="right">{`$${(row.total).toFixed(2)}`}</TableCell> */}

                                  <TableCell align="right">{`${formatter.format(
                                    row.group1
                                  )}`}</TableCell>
                                  <TableCell align="right">{`${formatter.format(
                                    row.group2
                                  )}`}</TableCell>
                                  <TableCell align="right">{`${formatter.format(
                                    row.group3
                                  )}`}</TableCell>
                                  <TableCell align="right">{`${formatter.format(
                                    row.group4
                                  )}`}</TableCell>
                                  <TableCell align="right">{`${formatter.format(
                                    row.group5
                                  )}`}</TableCell>
                                  <TableCell align="right">{`${formatter.format(
                                    row.total
                                  )}`}</TableCell>

                                  {/* <TableCell align="right">{`$${(row.group1).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group2).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group3).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group4).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group5).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.total).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell> */}
                                </TableRow>
                              </AccordionSummary>
                              <AccordionDetails>
                                {/* <div>CUSTOMER Invoice SUMMARY</div> */}
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                  className="customer-table"
                                >
                                  <TableHead
                                    width="100%"
                                    style={{ display: 'contents' }}
                                  >
                                    <TableRow>
                                      <TableCell>Invoice No.</TableCell>
                                      <TableCell>Days OverDue</TableCell>
                                      <TableCell>Due Date</TableCell>
                                      <TableCell>Amount Due</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row &&
                                      row.tickets &&
                                      row.tickets.length > 0 &&
                                      row.tickets.map((element) => {
                                        return element.invoice_balance == '$0.00' ? null : (
                                          <>
                                            <TableRow>
                                              <TableCell>{element.invoice_no}</TableCell>
                                              <TableCell>
                                                {checkDays(
                                                  element.aging,
                                                  element.due_date,
                                                  element.date_updated,
                                                  element.date_created
                                                )}
                                              </TableCell>
                                              <TableCell>
                                                {' '}
                                                {moment(element.due_date).format('LLL')}
                                              </TableCell>
                                              <TableCell>{element.invoice_balance}</TableCell>
                                            </TableRow>
                                          </>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </AccordionDetails>
                            </>
                          ))}
                    </TableBody>
                  </Table>
                  <div className="grandtotal">
                    <span>Grand Total:</span>
                    {/* <span>{`$ ${balanceByGroup.group1t}`}</span>
                        <span>{`$ ${balanceByGroup.group2t}`}</span>
                        <span>{`$ ${balanceByGroup.group3t}`}</span>
                        <span>{`$ ${balanceByGroup.group4t}`}</span>
                        <span>{`$ ${balanceByGroup.group5t}`}</span>
                        <span>{`$ ${grandTotal}`}</span> */}
                    {/* <span>{`$ ${balanceByGroup.group1t ? balanceByGroup.group1t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span>
                      <span>{`$ ${balanceByGroup.group2t ? balanceByGroup.group2t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : '' }`}</span>
                      <span>{`$ ${balanceByGroup.group3t ? balanceByGroup.group3t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span>
                      <span>{`$ ${balanceByGroup.group4t ? balanceByGroup.group4t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span>
                      <span>{`$ ${balanceByGroup.group5t ? balanceByGroup.group5t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span>
                      <span>{`$ ${grandTotal ? grandTotal.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span> */}

                    <span>{`${
                      balanceByGroup.group1t ? formatter.format(balanceByGroup.group1t) : ''
                    }`}</span>
                    <span>{`${
                      balanceByGroup.group2t ? formatter.format(balanceByGroup.group2t) : ''
                    }`}</span>
                    <span>{`${
                      balanceByGroup.group3t ? formatter.format(balanceByGroup.group3t) : ''
                    }`}</span>
                    <span>{`${
                      balanceByGroup.group4t ? formatter.format(balanceByGroup.group4t) : ''
                    }`}</span>
                    <span>{`${
                      balanceByGroup.group5t ? formatter.format(balanceByGroup.group5t) : ''
                    }`}</span>
                    <span>{`${grandTotal ? formatter.format(grandTotal) : ''}`}</span>
                  </div>
                </TableContainer>
              </DialogContent>
              <div className="page-break"></div>
              <div className="fixedtoprintin-footer">
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: '-webkit-right',
                    }}
                    className="flex justify-start items-center p-20"
                  >
                    <Typography
                      variant="h6"
                      style={{ fontSize: '16px' }}
                      className="px-32"
                    >
                      <b className="font-bold">As of :</b> {moment().format('MM/DD/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: '-webkit-right',
                    }}
                    className="flex justify-end items-center p-20 px-32"
                  >
                    <Typography
                      variant="h6"
                      style={{ fontSize: '14px' }}
                    >
                      Powered by
                    </Typography>
                    <img
                      src="assets/images/logos/loadzlogo.png"//TODO Confogire the logo here, variable
                      alt="logo"
                      style={{
                        width: '140px',
                        marginLeft: '10px',
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        ) : (
          <>
            <div ref={componentRef && !showExpanded ? componentRef : null}>
              <DialogTitle
                align="center"
                className="customer-balance-heading"
              >
                CUSTOMER BALANCE & AGING SUMMARY 2
              </DialogTitle>
              <div className="popup-close">
                <CloseIcon onClick={handleClosebalance} />
              </div>
              <Typography
                align="center"
                variant="h6"
                style={{ fontSize: '16px' }}
                className="customer-balance-heading"
              >
                <b className="customer-balance-heading">As of :</b> {moment().format('MM/DD/YYYY')}
              </Typography>

              <DialogContent className="customer-balance">
                <TableContainer component={Paper}>
                  <Table
                    style={{ width: '100%' }}
                    aria-label="simple table"
                    className="customer-balance-table"
                  >
                    <TableHead
                      width="100%"
                      style={{ display: 'contents' }}
                    >
                      <TableRow>
                        <TableCell className="font-bold">Customer</TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          Current
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          1 - 30
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          31 - 60
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          61 - 90
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          {'>'} 90
                        </TableCell>
                        <TableCell
                          align="right"
                          className="font-bold"
                        >
                          TOTAL
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {balanceData &&
                        balanceData
                          .filter((users) => users.total !== 0)
                          .map((row, i) => (
                            <>
                              <Accordion
                                style={{ width: '100%' }}
                                className="customeraccordion"
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{ width: '100%' }}
                                >
                                  <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                    >
                                      {row.customer_name}
                                    </TableCell>
                                    {/* <TableCell align="right">{`$${(row.group1).toFixed(2)}`}</TableCell> 
                                    <TableCell align="right">{`$${(row.group2).toFixed(2)}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group3).toFixed(2)}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group4).toFixed(2)}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group5).toFixed(2)}`}</TableCell>
                                    <TableCell align="right">{`$${(row.total).toFixed(2)}`}</TableCell>  */}

                                    <TableCell align="right">{`${formatter.format(
                                      row.group1
                                    )}`}</TableCell>
                                    <TableCell align="right">{`${formatter.format(
                                      row.group2
                                    )}`}</TableCell>
                                    <TableCell align="right">{`${formatter.format(
                                      row.group3
                                    )}`}</TableCell>
                                    <TableCell align="right">{`${formatter.format(
                                      row.group4
                                    )}`}</TableCell>
                                    <TableCell align="right">{`${formatter.format(
                                      row.group5
                                    )}`}</TableCell>
                                    <TableCell align="right">{`${formatter.format(
                                      row.total
                                    )}`}</TableCell>

                                    {/* /////////////////////////////////////////////////////// */}

                                    {/* <TableCell align="right">{`$${(row.group1).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group2).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group3).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group4).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.group5).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell align="right">{`$${(row.total).toFixed(2).toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")}`}</TableCell> */}
                                  </TableRow>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {/* <div>CUSTOMER Invoice SUMMARY</div> */}
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                    className="customer-table"
                                  >
                                    <TableHead
                                      width="100%"
                                      style={{ display: 'contents' }}
                                    >
                                      <TableRow>
                                        <TableCell>Invoice No.</TableCell>
                                        <TableCell>Days OverDue</TableCell>
                                        <TableCell>Due Date</TableCell>
                                        <TableCell>Amount Due</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row &&
                                        row.tickets &&
                                        row.tickets.length > 0 &&
                                        row.tickets.map((element) => {
                                          return element.invoice_balance == '$0.00' ? null : (
                                            <>
                                              <TableRow>
                                                <TableCell>{element.invoice_no}</TableCell>
                                                <TableCell>
                                                  {checkDays(
                                                    element.aging,
                                                    element.due_date,
                                                    element.date_updated,
                                                    element.date_created
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  {' '}
                                                  {moment(element.due_date).format('LL')}
                                                </TableCell>
                                                <TableCell>{element.invoice_balance}</TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        })}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ))}
                    </TableBody>
                  </Table>
                  <div className="grandtotal">
                    <span>Grand Total:</span>
                    {/* <span>{`$ ${balanceByGroup.group1t}`}</span>
                        <span>{`$ ${balanceByGroup.group2t}`}</span>
                        <span>{`$ ${balanceByGroup.group3t}`}</span>
                        <span>{`$ ${balanceByGroup.group4t}`}</span>
                        <span>{`$ ${balanceByGroup.group5t}`}</span>
                        <span>{`$ ${grandTotal}`}</span> */}

                    {/* <span>{`$ ${balanceByGroup.group1t ? balanceByGroup.group1t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span>
                      <span>{`$ ${balanceByGroup.group2t ? balanceByGroup.group2t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : '' }`}</span>
                      <span>{`$ ${balanceByGroup.group3t ? balanceByGroup.group3t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span>
                      <span>{`$ ${balanceByGroup.group4t ? balanceByGroup.group4t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span>
                      <span>{`$ ${balanceByGroup.group5t ? balanceByGroup.group5t.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span>
                      <span>{`$ ${grandTotal ? grandTotal.toLocaleString().replace(/\B(?=(\d{2})+(?!\d))/g, ",") : ''}`}</span> */}

                    <span>{`${
                      balanceByGroup.group1t ? formatter.format(balanceByGroup.group1t) : ''
                    }`}</span>
                    <span>{`${
                      balanceByGroup.group2t ? formatter.format(balanceByGroup.group2t) : ''
                    }`}</span>
                    <span>{`${
                      balanceByGroup.group3t ? formatter.format(balanceByGroup.group3t) : ''
                    }`}</span>
                    <span>{`${
                      balanceByGroup.group4t ? formatter.format(balanceByGroup.group4t) : ''
                    }`}</span>
                    <span>{`${
                      balanceByGroup.group5t ? formatter.format(balanceByGroup.group5t) : ''
                    }`}</span>
                    <span>{`${grandTotal ? formatter.format(grandTotal) : ''}`}</span>
                  </div>
                </TableContainer>
              </DialogContent>

              <div
                className="fixedtoprintin-footer"
                break-inside
                style={{ pageBreakAfter: 'always' }}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: '-webkit-right',
                    }}
                    className="flex justify-start items-center p-20"
                  >
                    <Typography
                      variant="h6"
                      style={{ fontSize: '16px' }}
                      className="px-32"
                    >
                      <b className="font-bold">As of :</b> {moment().format('MM/DD/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: '-webkit-right',
                    }}
                    className="flex justify-end items-center p-20 px-32"
                  >
                    <Typography
                      variant="h6"
                      style={{ fontSize: '14px' }}
                    >
                      Powered by
                    </Typography>
                    <img
                      src="assets/images/logos/loadzlogo.png"
                      alt="logo"
                      style={{
                        width: '140px',
                        marginLeft: '10px',
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};

export default connect(mapStateToProps, null)(ModelBalance);
