import { useState, useEffect, memo } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import { constants, states } from '../../../constants';
import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FuseMessage from '@fuse/core/FuseMessage';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Autocomplete, Typography } from '@mui/material';
import Maps from '../../main/Jobs/jobs-components/Maps/Maps';
import MapsDestination from './location/tabs/MapsDestination';
import { useHistory } from 'react-router-dom';
import { Refresh } from '@mui/icons-material';
import DestinationMap from './location/tabs/DestinationMap';
import client from 'client';
import Checkbox from '@mui/material/Checkbox';

const schema = yup.object().shape({
  l_name: yup
    .string()
    .required('You must enter a locations name')
    .min(1, 'The locations name must be at least 1 character'),
});

function ModalCreateLocations({
  isOpen,
  setIsOpen,
  setNewLocation,
  querryPit,
  waypoint,
  checkDes,
  props,
  id,
  company_id,
  locationDestination,
}) {
  const dispatch = useDispatch();
  const [location, setlocation] = useState({});
  const [open, setOpen] = useState(isOpen);
  const [newDesi, setNewDesi] = useState(null);
  const token = localStorage.token;
  const history = useHistory();

  const [jsonGetById, setJsonGetById] = useState([]);

  const [newDestination, setNewDestination] = useState(null);

  const SearchOneLocations = async () => {
    if (id) {
      try {
        const _getLocationsById = await fetch(`${constants.URLLOCAL}/locations/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: `${token}`,
          },
        });
        const _jsonGetById = await _getLocationsById.json();
        setJsonGetById(_jsonGetById);

        setValue('l_id', _jsonGetById.l_id);
        setValue('l_name', _jsonGetById.l_name);
        setValue('l_address', _jsonGetById.l_address);
      } catch (error) {
        history.push('/jobs');
      }
    }
  };

  useEffect(() => {
    setOpen(isOpen);
    SearchOneLocations();
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      l_id: '',
      l_name: '',
      l_address: '',
      l_city: '',
      l_state: '',
      l_zip: '',
      l_type: 'Destination',
      l_latitude: 0,
      l_longitude: 0,
      l_lgcy_x: 0,
      l_lgcy_y: 0,
      capture_method: '',
    },
    resolver: yupResolver(schema),
  });

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
          </>
        ), //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const { formState, watch, getValues, reset, control, setValue } = methods;
  const { isValid } = formState;

  const handleUpdateLocation = async () => {
    try {
      console.log(createNew);

      createNew ? setValue('company_id', company_id) : setValue('company_id', null);
      console.log(getValues());

      const response = await client.put(`${constants.URLLOCAL}/locations/${id}`, getValues());
      const res = response.data;
      if (res.error) {
        _showMessage('Something went wrong, please try again!', 'error');
        return;
      }
      _showMessage('The location has been updated!', 'success');
      setOpen(false);
      setIsOpen(false);
      setNewLocation({
        ...newDestination,
        lat: res.l_latitude,
        lng: res.l_longitude,
        l_address: res.l_address,
        l_id: res.l_id,
        name: res.l_name,
        company_id: createNew ? res.company_id : null,
      });
    } catch (error) {
      _showMessage('Something went wrong, please try again!', 'error');
      console.log(error);
    }
  };

  const handleCreateLocation = async () => {
    console.log(company_id);
    try {
      let create_data = JSON.parse(JSON.stringify(getValues()));
      createNew ? (create_data.company_id = company_id) : (create_data.company_id = null);
      console.log(createNew);
      console.log(create_data);

      const response = await client.post(
        `${constants.URLLOCAL}/locations/${createNew ? company_id : ''}`,
        create_data
      );
      const res = response.data;
      if (res.error) {
        _showMessage('Something went wrong, please try again!', 'error');
        return;
      }
      _showMessage('The location has been created!', 'success');
      setOpen(false);
      setIsOpen(false);
      console.log('res: ', res);
      setNewLocation({
        ...newDestination,
        lat: res.l_latitude,
        lng: res.l_longitude,
        l_address: res.l_address,
        l_id: res.l_id,
        name: res.l_name,
        company_id: createNew ? res.company_id : null,
      });
    } catch (error) {
      _showMessage('Something went wrong, please try again!', 'error');
      console.log(error);
    }
  };

  const handleMaps = (e) => {
    const { lat, lng, latLng, dir, cit, stat, zip } = e;

    setlocation({ latLng, dir });
    setNewDesi({ latLng, dir });
    let desti = {
      lat: latLng && latLng.lat ? latLng.lat : '',
      lng: latLng && latLng.lng ? latLng.lng : '',
      l_Address: dir,
    };
    //setNewLocation(desti);
    setNewDestination(desti);
    setValue('l_latitude', latLng && latLng.lat ? latLng.lat : '');
    setValue('l_longitude', latLng && latLng.lng ? latLng.lng : '');
    setValue('l_address', dir);
    setValue('l_city', cit);
    setValue('l_state', stat);
    setValue('l_zip', zip);
  };

  const { errors } = formState;

  const [createNew, setCreateNew] = useState(false);

  useEffect(() => {
    if (locationDestination) {
      setValue('l_name', locationDestination.name ? locationDestination.name : '');
      setValue('l_latitude', locationDestination.lat ? locationDestination.lat : '');
      setValue('l_longitude', locationDestination.lng ? locationDestination.lng : '');
      setValue('l_address', locationDestination.l_address ? locationDestination.l_address : '');
      setValue('l_city', locationDestination.l_city ? locationDestination.l_city : '');
      setValue('l_state', locationDestination.l_state ? locationDestination.l_state : '');
      setValue('l_zip', locationDestination.l_zip ? locationDestination.l_zip : '');
      setlocation({ dir: locationDestination.l_address ? locationDestination.l_address : '' });
      setNewDesi({ latLng: { lat: locationDestination.lat, lng: locationDestination.lng } });

      locationDestination?.company_id ? setCreateNew(true) : setCreateNew(false);
    } else {
      setValue('l_name', '');
      setValue('l_latitude', 0);
      setValue('l_longitude', 0);
      setValue('l_address', '');
      setValue('l_city', '');
      setValue('l_state', '');
      setValue('l_zip', '');
      setlocation({});
      setNewDesi(null);
      setCreateNew(false);
    }
  }, [locationDestination]);

  return (
    <div>
      <FuseMessage />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
        sx={{ zIndex: 1000 }}
      >
        <DialogTitle>
          {locationDestination ? `Update Destination` : `Create Destination`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill this form with the information for your destination
          </DialogContentText>
          <div>
            <div>
              <FormProvider {...methods}>
                <div>
                  <br />
                  <div className="flex -mx-6">
                    <Controller
                      name="l_name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mt-8 mb-16 mx-4"
                          error={!!errors.name}
                          required
                          helperText={errors?.name?.message}
                          label="Name"
                          autoFocus
                          id="l_name"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>

                  <div className="flex -mx-6">
                    <Controller
                      name="l_address"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          hidden={location?.dir && getValues('l_address') ? false : true}
                          className="mt-8 mb-16 mx-4"
                          label="Address"
                          autoFocus
                          id="l_address"
                          variant="outlined"
                          fullWidth
                          disabled={true}
                        />
                      )}
                    />
                  </div>
                </div>
              </FormProvider>
            </div>
            <div className="w-500 h-512">
              {/* <MapsDestination
                coordenadas={location}
                handleMaps={handleMaps}
                waypoint={waypoint}
                desi={newDesi}
                qPit={querryPit}
                setDesi={checkDes}
              ></MapsDestination> */}
              <DestinationMap
                coordenadas={location}
                handleMaps={handleMaps}
                waypoint={waypoint}
                desi={newDesi}
                qPit={querryPit}
                setDesi={checkDes}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex pl-24 align-center items-center w-full justify-start">
            <label className="font-bold">Save for future use?</label>
            <Checkbox
              sx={{ padding: '0px 0px 0px 0px' }}
              defaultChecked={createNew}
              value={createNew}
              onChange={(e) => {
                setCreateNew(e.target.checked);
              }}
            />
          </div>

          <Button
            className="whitespace-nowrap mx-4"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            className="whitespace-nowrap mx-4"
            variant="contained"
            color="secondary"
            onClick={locationDestination ? handleUpdateLocation : handleCreateLocation}
          >
            {locationDestination ? `Update` : `Create`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(ModalCreateLocations);
