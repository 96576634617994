const actions = {
  CUSTOMER_LOGIN: 'customerLogin',
  CUSTOMER_LOGOUT: 'customerLogout',
  VERIFY_TOKEN: 'verifyToken',

  customerLogin: (payload, onError, onSuccess) =>{
    return {
      type: actions.CUSTOMER_LOGIN,
      payload,
      onError,
      onSuccess
    }
  },

  customerLogout: () =>{
    return {
      type: actions.CUSTOMER_LOGOUT,
    }
  },

  verifyClientToken: (onSuccess, onError) => { 
    return {
      type: actions.VERIFY_TOKEN,
      onSuccess, 
      onError
    }
  }
}

export default actions
