import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Paper,
  Typography,
  Box
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import client from 'client';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { formatter } from '../../../../../../constants';
import moment from 'moment/moment';
import CloseIcon from '@mui/icons-material/Close';

const CommissionMatrixHistoryModal = ({ open, onClose, jsonGetById }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [matrixData, setMatrixData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open && jsonGetById?.length) {
      const {job_info_id, job_billing} = jsonGetById[0]
      if (job_billing?.job_billing_form && job_info_id) {
        setLoading(true)
        client.post('/jobs/job-commission-matrix', {
          job_id: job_info_id, 
          job_billing_id: job_billing?.job_billing_form
        }).then(result => {
          if (result?.status === 200) {
            setLoading(false)
            setMatrixData(result.data)
          }
        }).catch(e => {
          setLoading(false)
          console.log(e)
        })
      }
    }
  }, [open, jsonGetById])

  const handleRowExpand = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Agent Commission History
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading 
          ? <FuseLoading /> 
          : <TableContainer >
              <Table>
                <TableHead sx={{ backgroundColor: 'black', color: 'white' }}>
                  <TableRow>
                    <TableCell />
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Agent</TableCell>
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Commission Type</TableCell>
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Commission Amount</TableCell>
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Created By</TableCell>
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Created At</TableCell>
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Updated By</TableCell>
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Updated At</TableCell>
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Deleted By</TableCell>
                    <TableCell style={{color: 'white', fontWeight: 'bold'}}>Deleted At</TableCell>
                    {/* Add more columns as needed */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matrixData.map((matrix) => (
                    <React.Fragment key={matrix.id}>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            onClick={() => handleRowExpand(matrix.id)}
                          >
                            {expandedRows[matrix.id] ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </TableCell>
                        <TableCell>{matrix?.first_name} {matrix?.last_name}</TableCell>
                        <TableCell>{matrix?.commission_type === 'fixed' ? 'Fixed' : 'Percentage'}</TableCell>
                        <TableCell>{matrix?.commission_type ? matrix?.commission_type === 'fixed' ? formatter?.format(matrix?.commission_amount) : `${matrix?.commission_amount}%` : '0.00'}</TableCell>
                        <TableCell>{matrix.created_by_user}</TableCell>
                        <TableCell>{matrix?.created_at ? moment(matrix.created_at).format('LLL') : ''}</TableCell>
                        <TableCell>{matrix.updated_by_user}</TableCell>
                        <TableCell>{matrix?.updated_at ? moment(matrix.updated_at).format('LLL') : ''}</TableCell>
                        <TableCell>{matrix.deleted_by_user}</TableCell>
                        <TableCell>{matrix?.deleted_at ? moment(matrix.deleted_at).format('LLL') : ''}</TableCell>
                        {/* Add more cells as needed */}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                          <Collapse in={expandedRows[matrix.id]} timeout="auto" unmountOnExit>
                            <Box margin={2}>
                              <Typography variant="h6" gutterBottom>
                                History
                              </Typography>
                              <Table size="small">
                                <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                                  <TableRow>
                                    <TableCell>Agent</TableCell>
                                    <TableCell>Commission Type</TableCell>
                                    <TableCell>Commission Amount</TableCell>
                                    <TableCell>Updated By</TableCell>
                                    <TableCell>Updated At</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {matrix.history.map((history, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{history?.first_name} {history?.last_name}</TableCell>
                                      <TableCell>{history?.commission_type === 'fixed' ? 'Fixed' : 'Percentage'}</TableCell>
                                      <TableCell>{history?.commission_type ? history?.commission_type === 'fixed' ? formatter?.format(history?.commission_amount) : `${history?.commission_amount}%` : '0.00'}</TableCell>
                                      <TableCell>{history?.updated_by_user}</TableCell>
                                      <TableCell>{history?.updated_at ? moment(history.updated_at).format('LLL') : ''}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        }
      </DialogContent>
    </Dialog>
  );
};

export default CommissionMatrixHistoryModal;
