import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';

export const getUnitTemp = createAsyncThunk('JobsApp/unitTemp/getUnitTemp', async (params) => {

  // console.log(params, "este essssssssssssssssssssssssssssssss")
  const response = await axios.get(`http://localhost:5000/unitTemps/${params.unitTempId}/${params.unitTempName}`, params);//TODO: why is this localhost? 
  const data = await response.data;
  // console.log(data,"es mi data")
  return data === undefined ? null : data;

});

export const removeUnitTemp = createAsyncThunk(
  'eCommerceApp/product/removeUnitTemp',
  async (val, { dispatch, getState }) => {
    const { id } = getState().JobsApp.unitTemp;
    await axios.post('http://localhost:5000/unitTemps', { id });

    return id;
  }
);

export const createJob = createAsyncThunk(
  'JobsApp/jobs/new',
  async (jobData, { dispatch, getState }) => {
    const { jobs } = getState().JobsApp;

    console.log(jobData)


    // console.log(unitTemp)
    // console.log("arriba es unitTemp y abajo es unitTemps")
    // console.log(jobData)
    const response = await axios.post('http://localhost:5000/jobs/new', {
      //...unitTemp,
      ...jobs,
      ...jobData
    });
    const data = await response.data;
    
    return data;
  }
);

export const editUnitTemp = createAsyncThunk(

  'JobsApp/unitTemp/editUnitTemp',
  async (val, { dispatch, getState }) => {

  
    // console.log(val,"esto es val de unit temps ")
    await axios.put(`http://localhost:5000/unitTemps/${val.id}`, val);
    return val;
  }
);

const JobsSlice = createSlice({
  name: 'JobsApp/unitTemp',
  initialState: null,
  reducers: {
    resetUnitTemp: () => null,
    createJob: {
      reducer: (state, action) => action.payload,
      prepare: (event) => ({
        payload: {
          job_no: 0,
          job_c_id: 0,
          quarry_pit: '',
          originLatitude: '',
          originLongitude: '',
          j_destination: '',
          destinationLatitude: '',
          destinationLongitude: '',
          unit_id: 0,
          materials: [],
          material_cost_rate: 0,
          billperunit: 0,
          material_invoice_rate: 0,
          j_fullfillment_warning: 0,
          j_fullfillment_total: 0,
          j_notes: ''
        },
      }),
    },
  },
  extraReducers: {
    [getUnitTemp.fulfilled]: (state, action) => action.payload,
    //[editUnitTemp.fulfilled]: (state, action) => action.payload,
    [createJob.fulfilled]: (state, action) => action.payload,
    //[removeUnitTemp.fulfilled]: (state, action) => null,
  },
});


export const { newUnitTemp, resetUnitTemp } = JobsSlice.actions;

export default JobsSlice.reducer;
