import axios from 'axios'
import { constants } from './constants'
import history from '@history'
import store from 'app/store/index'
import { showMessage } from 'app/store/fuse/messageSlice'
const client = axios.create({
  baseURL: constants.URLLOCAL,
  header: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 30000,
})

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      console.log('Auth Error 401 in client portal')
      localStorage.removeItem('client-token')
    }
    return Promise.reject(error);
  }
)

client.defaults.headers.common['token'] = localStorage.getItem('client-token')

export default client
