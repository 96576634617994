import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { memo, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { red } from '@mui/material/colors';
import { constants } from '../../../../constants';
import { blue, green, orange } from '@mui/material/colors';
import Check_refresh from 'app/main/Check_refresh';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

function Grafica({ data, user }) {
  const [week, setweek] = useState([]);
  const [series, setSeries] = useState([]);
  const history = useHistory();
  const token = localStorage.token;
  const compacctnum = user?.company?.compacctnum;

  const [chartData, setData] = useState({
    series: [],
    options: {
      chart: {
        id: 'area-datetime',
        type: 'area',
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      fill: {
        colors: [green[500], blue[500], orange[500], red[500]],
      },
      dataLabels: {
        enabled: false,
        colors: [green[500], blue[500], orange[500], red[500]],
      },
      stroke: {
        curve: 'smooth',
        colors: [green[500], blue[500], orange[500], red[500]],
      },
      xaxis: {
        type: 'datetime',
        categories: week,
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
      },
      legend: {
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        labels: {
          useSeriesColosr: true,
        },
      },
      markers: {
        colors: [green[500], blue[500], orange[500], red[500]],
      },
    },
  });

  const getGraphicData = async () => {
    if (data) {
      const result = await Check_refresh();
      if (!result) {
        return history.replace('/login');
      }
      try {
        const _getJobById = await fetch(
          `${constants.URLLOCAL}/jobs/ticket-gli/${data}/${compacctnum}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              token: `${token}`,
            },
          }
        );
        const _jsonGetById = await _getJobById.json();

        if (_jsonGetById.msg) {
          return;
        }
        let _materialCr = [];
        let _ticketCr = [];
        let _waitTimeCr = [];
        let _ticketComm = [];
        let material_cr = 0;
        let ticket_cr = 0;
        let wait_cr = 0;
        let employee_ticket_cost = 0;

        _jsonGetById.forEach((element) => {
          material_cr = element.material_cr
            ? Number(element.material_cr.replace(/[^0-9\.]/g, ''))
            : 0;
          ticket_cr = element.ticket_cr ? Number(element.ticket_cr.replace(/[^0-9\.]/g, '')) : 0;
          wait_cr = element.wait_cr ? Number(element.wait_cr.replace(/[^0-9\.]/g, '')) : 0;
          employee_ticket_cost = element.employee_ticket_cost
            ? Number(element.employee_ticket_cost.replace(/[^0-9\.]/g, ''))
            : 0;

          week.push(moment(element.date_created).format('MM-DD-YYYY'));
          _materialCr.push(material_cr ? material_cr : 0);
          _ticketCr.push(ticket_cr ? ticket_cr : 0);
          _waitTimeCr.push(wait_cr ? wait_cr : 0);
          _ticketComm.push(employee_ticket_cost ? employee_ticket_cost : 0);
        });
        setweek(week);

        setSeries([
          {
            name: 'Hauling Sales',
            data: _ticketCr,
          },
          {
            name: 'Material Sales',
            data: _materialCr,
          },
          {
            name: 'Wait Time Charges',
            data: _waitTimeCr,
          },
          {
            name: 'Employee Commission',
            data: _ticketComm,
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getGraphicData();
  }, []);

  useEffect(() => {}, [series, week]);

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
      >
        <div className="flex flex-row ">
          <Typography
            className="text-32 font-semibold leading-none"
            color="inherit"
          >
            {chartData.today}
          </Typography>
        </div>
      </AppBar>
      <div className="py-0 jobgraph">
        <ReactApexChart
          options={chartData.options}
          series={series}
          type="area"
          height={'300px'}
        />
      </div>
    </>
  );
}
// injectReducer(AuthReducer)

// injectReducer(AuthReducer)
const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(memo(Grafica));
