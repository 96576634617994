import { lazy } from 'react';

const DispatchAppConfig = {
  settings: {
    layout: {
        style : 'layout1',
        config: {
          
           
            footer : {
                display : false,
                
            },
        }
    },
    
},
  routes: [ 
    {
        path: '/dispatch/template',
        component: lazy(() => import('./UnitApp/DispatchManagementApp')),
    },
    {
      path: '/dispatch',
      component: lazy(() => import('./DispatchApp/DispatchManagement')),
    },
   
    
  ],
};

export default DispatchAppConfig;
