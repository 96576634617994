import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { constants, formatter, changeCurrency, ipayNet } from '../../../../constants';
import { ConfirmCC } from 'checkout-loadz';
import client from 'client';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

var retrievedObject = localStorage.getItem('loginTypeData');
const loginTypeData = JSON.parse(retrievedObject);

export default function ModalPreview({
  openPreview,
  customersToPay,
  referencePayment,
  paymentMethod,
  checkdata,
  sendDataToApply,
  payments = [],
  setFeesPay,
  prop_data,
}) {
  const [totalWFees, setTotalWFees] = useState(0);
  const [fees, setFees] = useState({});
  const [open, setOpen] = useState(false);
  const [identifier, setIdentifier] = useState('95eefbe3-95b0-4871-a339-310c318e9ee6'); // TODO variable? ipaynet
  const [loading, setLoading] = useState(false);
  const paymentSubmitCC = useRef(null);
  const dispatch = useDispatch();

  useEffect(async () => {
    openPreview.current = handleModal;
  }, []);

  useEffect(async () => {
    if (open) {
      let total = 0;
      payments.forEach((payment) => {
        if (typeof payment?.payment === 'number') {
          payment.payment = formatter.format(payment?.payment);
        }
        const newNumber = changeCurrency(payment?.payment || '$0');
        total += newNumber;
      });
      const totalFee = await calculateFeesCC(total);
      setTotalWFees(totalFee.total);
      setFees(totalFee);
    }
  }, [open]);

  useEffect(() => {
    paymentSubmitCC.current = usioPaymentSubmitCC;
  }, [fees]);

  const calculateFeesCC = async (total) => {
    const response = await fetch(`${constants.URLSECURE}/api/usio/calculate-fees`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        X_API_KEY: ipayNet.X_API_KEY,
        project: ipayNet.project,
        identifier,
      },
      body: JSON.stringify({
        amount: total,
      }),
    });

    if (response.status !== 200) {
      return;
    }

    const _jsonFees = await response.json();
    setFeesPay(_jsonFees);
    return _jsonFees;
  };

  const getCardType = (numberCard) => {
    // Eliminar espacios en blanco y guiones del número de tarjeta
    var numberClean = numberCard.replace(/[^\d]/g, '');

    // Verificar el tipo de tarjeta
    if (/^5[1-5]/.test(numberClean)) {
      return 'MSTR';
    } else if (/^4/.test(numberClean)) {
      return 'VISA';
    } else if (/^3[47]/.test(numberClean)) {
      return 'AMEX';
    } else if (/^6(?:011|5)/.test(numberClean)) {
      return 'DISC';
    } else {
      return 'VISA';
    }
  }; //TODO if we sell on international level JCB and a few others possible cards. So these may need to originate from a File. For now LOW Priority.

  const usioPaymentSubmitCC = async (data) => {
    const typeCard = getCardType(data.card_number);
    const selectedInvoiceIds = payments
      .filter((item) => item.select)
      .map((item) => item.invoiceId)
      .join(',');

    const selectedInvoiceNo = payments
      .filter((item) => item.select)
      .map((item) => item.invoiceId)
      .join(',');

    const selectedJobNo = payments
      .filter((item) => item.select)
      .map((item) => item.jobNo)
      .join(',');

    if (data.terms == true) {
      const result = await client.post('/company/compacct', {
        id: customersToPay.compacctNum,
      });
      const dataID = await result.data;

      const response = await fetch(`${constants.URLSECURE}/api/usio/payment-cc`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          X_API_KEY: ipayNet.X_API_KEY,
          project: ipayNet.project,
          identifier,
        },
        body: JSON.stringify({
          userId: customersToPay.clientId, // quien esta pagando
          referenceId: dataID.user_id, // aqui va a quien le estan pagando
          AccountCode1: selectedInvoiceNo, //invoice number
          AccountCode2: '', //id job
          AccountCode3: selectedJobNo, //job number
          AdditionalSearch: selectedInvoiceIds, //id invoice
          CardNumber: data.card_number,
          CardType: typeCard ? typeCard : 'VISA',
          CVV: data.cv_code,
          ExpDate: data.exp_month + data.exp_year,
          fees: fees,
          Amount: fees.total.toFixed(2),
          FirstName: data.Card_Name,
          LastName: data.Card_Name,
          EmailAddress: data.EmailAddress,
          Address1: data.Address1,
          City: data.City,
          State: data.State,
          Zip: data.Zip,
          Country: 'US',
        }),
      });

      if (response.status !== 200) {
        const errorMessage = await response.text();
        _showMessage(errorMessage, 'error');
        setTimeout(() => {
          setLoading(false);
        }, 4000);
        return;
      }

      const jsonPaymentCC = await response.json();
      sendDataToApply(jsonPaymentCC);
      _showMessage(jsonPaymentCC.Confirmation, 'success');

      setTimeout(() => {
        setLoading(false);
      }, 4000);
    } else {
      _showMessage('Please accept the Terms and conditions', 'error');
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
          </>
        ), //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <BootstrapDialog
        maxWidth={'xl'}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleModal}
        >
          Preview
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="items-center justify-center h-full"
          >
            {paymentMethod == 1 || paymentMethod == 3 ? (
              <>
                <ConfirmCC
                  payments={payments}
                  test={false}
                  paymentSubmitCC={paymentSubmitCC}
                  totalWFees={totalWFees}
                  fees={fees}
                  setLoading={setLoading}
                  loading={loading}
                />
              </>
            ) : (
              <>
                <table
                  className="text-left table-fixed"
                  width="700"
                  border="0"
                  align="center"
                  cellPadding="0"
                  cellspacing="0"
                  style={{ border: '1px solid #000000', margin: '0px auto' }}
                >
                  <thead>
                    <tr align="center">
                      <td
                        className=""
                        style={{ padding: '15px 0px', background: '#000' }}
                      >
                        <img
                          src="assets/images/logos/yellow_loadz_logo.svg"
                          style={{ width: '130px' }}
                        />
                      </td>
                    </tr>
                  </thead>
                  <tbody style={{ padding: '30px' }}>
                    <tr align="center">
                      <td
                        style={{
                          padding: '20px 0px 20px',
                          color: '#000',
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '16px',
                        }}
                      >
                        <h4 style={{ fontSize: '14px', fontWeight: '600', margin: '0' }}>
                          Total Payment Amount
                        </h4>
                        <h2
                          style={{ fontSize: '36px', fontWeight: '700', margin: '4px 0 4px -40px' }}
                        >
                          <img
                            src="assets/images/tick.svg"
                            style={{
                              maxWidth: '36px',
                              marginRight: '4px',
                              display: 'inline-block',
                            }}
                          />
                          {`${
                            checkdata &&
                            checkdata.amountReceivedToApply &&
                            formatter.format(checkdata.amountReceivedToApply)
                          }`}
                        </h2>
                        <h3 style={{ fontSize: '14px', fontWeight: '700', margin: '0' }}>
                          From &nbsp; '
                          {checkdata &&
                            checkdata.dataClientReceipt &&
                            checkdata.dataClientReceipt.clientName}{' '}
                          '&nbsp; To &nbsp; '{loginTypeData && loginTypeData.Copm_name}'
                        </h3>
                      </td>
                    </tr>
                    {customersToPay &&
                    customersToPay.payments &&
                    customersToPay.payments.filter((user) => user.select === true).length == 1 ? (
                      <tr>
                        {customersToPay &&
                          customersToPay.payments &&
                          customersToPay.payments
                            .filter((user) => user.select === true)
                            .map((element) => {
                              return (
                                <>
                                  <td
                                    align="center"
                                    style={{ borderTop: '1px solid #D4D2D2', padding: '10px 0' }}
                                  >
                                    <table
                                      width="500"
                                      border="0"
                                      style={{ borderSpacing: '0', marginLeft: '48px' }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: '700',
                                              textAlign: 'left',
                                              padding: '0 0 4px',
                                            }}
                                          >
                                            Payment Details
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              textAlign: 'left',
                                            }}
                                          >
                                            Job No. :
                                          </td>
                                          <td
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              textAlign: 'left',
                                            }}
                                          >
                                            {element.jobNo}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              textAlign: 'left',
                                              width: '271px',
                                            }}
                                          >
                                            Invoice No.:
                                          </td>
                                          <td
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              textAlign: 'left',
                                            }}
                                          >
                                            {element.invoiceNo}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              textAlign: 'left',
                                            }}
                                          >
                                            Total Amount:
                                          </td>
                                          <td
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              textAlign: 'left',
                                            }}
                                          >{`${formatter.format(element.openBalance)}`}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </>
                              );
                            })}
                      </tr>
                    ) : (
                      <tr>
                        <td
                          align="center"
                          style={{ borderTop: '1px solid #D4D2D2', padding: '10px 0' }}
                        >
                          <table
                            width="445"
                            border="0"
                            style={{ borderSpacing: '0', marginLeft: '0' }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    textAlign: 'left',
                                    padding: '0 0 4px',
                                  }}
                                >
                                  Payment Details
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="">
                                    <div className="flex justify-between">
                                      <div
                                        className="w-3/5"
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: '600',
                                          textAlign: 'left',
                                        }}
                                      >
                                        Invoice No.
                                      </div>
                                      <div
                                        className="w-2/5"
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: '600',
                                          textAlign: 'left',
                                        }}
                                      >
                                        Payment Applied
                                      </div>
                                    </div>

                                    {customersToPay &&
                                      customersToPay.payments &&
                                      customersToPay.payments
                                        .filter((user) => user.select === true)
                                        .map((element) => {
                                          return (
                                            <>
                                              {element.select === true ? (
                                                <div className="flex justify-between">
                                                  <div
                                                    className="w-3/5"
                                                    style={{
                                                      fontSize: '16px',
                                                      fontWeight: '400',
                                                      textAlign: 'left',
                                                    }}
                                                  >
                                                    {element.invoiceNo}
                                                  </div>
                                                  {element?.originalAmount
                                                    ?.toString()
                                                    .startsWith('$') ? (
                                                    <div
                                                      className="w-2/5"
                                                      style={{
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        textAlign: 'left',
                                                      }}
                                                    >
                                                      $
                                                      {element.originalAmount
                                                        .toString()
                                                        .split('$')[1]
                                                        .replace(/^0+/, '')}{' '}
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="w-2/5"
                                                      style={{
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        textAlign: 'left',
                                                      }}
                                                    >
                                                      $
                                                      {element.originalAmount
                                                        .toString()
                                                        .replace(/^0+/, '')}{' '}
                                                    </div>
                                                  )}
                                                </div>
                                              ) : null}
                                            </>
                                          );
                                        })}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flex justify-between">
                                    <div
                                      className="w-3/5"
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        textAlign: 'left',
                                      }}
                                    >
                                      Sub Total
                                    </div>
                                    <div
                                      className="w-2/5"
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        textAlign: 'left',
                                      }}
                                    >{`${
                                      checkdata &&
                                      checkdata.amountReceivedToApply &&
                                      formatter.format(checkdata.amountReceivedToApply)
                                    }`}</div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flex justify-between">
                                    <div
                                      className="w-3/5"
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        textAlign: 'left',
                                      }}
                                    >
                                      Total Paid:
                                    </div>
                                    <div
                                      className="w-2/5"
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        textAlign: 'left',
                                      }}
                                    >{`${
                                      checkdata &&
                                      checkdata.amountReceivedToApply &&
                                      formatter.format(checkdata.amountReceivedToApply)
                                    }`}</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td
                        align="center"
                        style={{
                          borderTop: '1px solid #D4D2D2',
                          borderBottom: '1px solid #D4D2D2',
                          padding: '10px 0',
                        }}
                      >
                        <table
                          width="500"
                          border="0"
                          style={{ borderSpacing: '0', marginLeft: '48px' }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '700',
                                  textAlign: 'left',
                                  padding: '0 0 4px',
                                }}
                              >
                                Payment Status:
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  textAlign: 'left',
                                  width: '271px',
                                }}
                              >
                                Status:
                              </td>
                              <td
                                style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                              >
                                Preview
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  textAlign: 'left',
                                  width: '271px',
                                }}
                              >
                                Payment Method:
                              </td>
                              <td
                                style={{ fontSize: '16px', fontWeight: '400', textAlign: 'left' }}
                              >
                                {checkdata &&
                                checkdata.paymentMethod &&
                                checkdata.paymentMethod == '2'
                                  ? 'Online ACH'
                                  : ''}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <table
                          border="0"
                          style={{
                            background: '#fff',
                            borderSpacing: '0',
                            width: '90%',
                            padding: '15px 65px',
                            margin: '13px 0 25px',
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: '13px',
                                  textAlign: 'center ',
                                  lineHight: '19px',
                                  fontWeight: '600',
                                  paddingBottom: '10px',
                                  paddingTop: '5px',
                                }}
                              >
                                If you need help regarding this invoice please contact your vendor
                                directly
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot style={{ padding: '0 0 16px' }}>
                    <tr>
                      <td style={{ textAlign: 'center' }}>
                        <img
                          src="assets/images/logos/loadzlogo.png"
                          style={{ width: '130px', margin: '0 auto' }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="center"
                        style={{
                          padding: '18px 0px 10px',
                          fontSize: '14px',
                          color: '#000',
                          fontWeight: '600',
                        }}
                      >
                        {' '}
                        We Make Paper Ticketing Obsolete -{' '}
                        <a
                          href="#"
                          style={{ textDecoration: 'none' }}
                        >
                          Learn More
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="center"
                        style={{
                          padding: '0px 0 20px',
                          fontSize: '10px',
                          color: '#000',
                          fontWeight: '700',
                        }}
                      >
                        {' '}
                        Copyright © 2022 Loadz.com, Inc. All rights reserved.{' '}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={handleModal}
            sx={{
              border: '1px solid black',
              mr: 1,
              backgroundColor: '#000000',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
