import { useState, useEffect, memo } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import client from '../../../client';
import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FuseMessage from '@fuse/core/FuseMessage';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Autocomplete, Typography } from '@mui/material';
import new_store from 'app/store';
import { useHistory } from 'react-router-dom';

const schema = yup.object().shape({
  customer_name: yup.string().required().nullable(false),
});

function ModalCreateMaterials({ isOpenModal, setIsOpenModal,getAllMaterials }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isOpenModal);
  const token = localStorage.token;
  const [createCategorie, setCreateCategorie] = useState(false);

  useEffect(() => {
    setOpen(isOpenModal);
  }, [isOpenModal]);

  const handleClose = () => {
    setOpen(false);
    setIsOpenModal(false);
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
    },
    resolver: yupResolver(schema),
  });

  const _clearForm = {
    name: '',
    description: '',
  };

  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);

  const getAllCategories = async () => {
    const id = new_store.getState().loadz_auth?.AuthReducer?.user?.user_id;
    let categories = [];
    try {
      const _categories = await client.post('/catmaterials/all', { id });

      if (_categories.data.message) {
        _showMessage(_categories.data.message, 'error');
        return;
      }

      categories = _categories.data.map((e) => {
        return { value: e.id_cat, label: e.name_cat };
      });
      setCategories(categories);
    } catch (error) {
      categories = [{ value: 0, label: 'Create categories' }];
    }
  };

  useEffect(() => {
    if (createCategorie) {
      setValue('category', []);
    }
  }, [createCategorie]);

  useEffect(() => {
    getAllCategories();
    const { name_cat, status_cat, datecreated_cat, createdby, id_cat } = categories;
    setValue('category', categories);
  }, []);

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
          </>
        ), //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const { formState, watch, getValues, reset, control, setValue } = methods;
  const { isValid } = formState;

  const handleCreateMaterial = async () => {
    const id = new_store.getState().loadz_auth?.AuthReducer?.user?.user_id;
    const datos = getValues();
    datos.company_id = id;
    try {
      const response = await client.post(`/materials/`, datos);

      if (response.data.message) {
        _showMessage('Failed to create the material. Please review your input and try again', 'error');//TODO Improved Message
        return;
      }

      _showMessage('Material successfully created!', 'success');//TODO Improved Message

      setOpen(false);

      reset(_clearForm);
      setIsOpenModal(false);
      getAllMaterials();
    } catch (error) {
      _showMessage('An unexpected error occurred. Please try again later', 'error');//TODO Improved Message
      console.log(error);
    }
  };
  const { errors } = formState;

  return (
    <div>
      <FuseMessage />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Add new Material</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill this form with the information requested for create a Material
          </DialogContentText>
          <br />
          <FormProvider {...methods}>
            <div className="flex -mx-6">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-8 mb-16"
                    error={!!errors.name}
                    required
                    helperText={errors?.name?.message}
                    label="Name"
                    autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>

            <Controller
              name="category"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  className="mt-8 mb-16"
                  multiple
                  freeSolo={value.length == 1 ? false : true}
                  options={categories}
                  // getOptionDisabled={(options) => (value.length == 1 ? true : false)}
                  value={value}
                  onChange={(event, newValue) => {
                    setCreateCategorie(false);
                    newValue.map((r) => {
                      if (r.value == 0) {
                        setCreateCategorie(true);
                      }
                    });
                    onChange(newValue);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={categories}
                      placeholder="Select"
                      label="Categories"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={
                        value.length == 0 ? 'Please select a category for this material' : ''
                      }//TODO improved message
                    />
                  )}
                  limitTags={1}
                />
              )}
            />

            <div className="flex -mx-6">
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-8 mb-16"
                    id="description"
                    label="Description"
                    type="text"
                    multiline
                    rows={5}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isValid}
            onClick={handleCreateMaterial}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(ModalCreateMaterials);
