import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const sidebarAdapter = createEntityAdapter({});

export const { selectAll: selectReport, selectById: selectReportById } =
  sidebarAdapter.getSelectors((state) => state.clientsApp.sidebar);

const sidebarSlice = createSlice({
  name: 'clientsApp/sidebar',
  initialState: sidebarAdapter.getInitialState({
    mobileChatsSidebarOpen: false,
    userSidebarOpen: false,
    contactSidebarOpen: false,
    selectedUser: null
  }),
  reducers: {
    setSelectedUserId: (state, action) => {
      state.selectedUser = action.payload;
      console.log("selected user has been set")
      console.log(state.selectedUser)
    },
    removeSelectedUserId: (state, action) => {
      state.selectedUser = null;
    },
    openMobileChatsSidebar: (state, action) => {
      state.mobileChatsSidebarOpen = true;
    },
    closeMobileChatsSidebar: (state, action) => {
      state.mobileChatsSidebarOpen = false;
    },
    openUserSidebar: (state, action) => {
      state.userSidebarOpen = true;
    },
    closeUserSidebar: (state, action) => {
      state.userSidebarOpen = false;
    },
    openContactSidebar: (state, action) => {
      state.contactSidebarOpen = true;
    },
    closeContactSidebar: (state, action) => {
      state.contactSidebarOpen = false;
    },
  },
});

export const {
  openMobileChatsSidebar,
  closeMobileChatsSidebar,
  openUserSidebar,
  closeUserSidebar,
  openContactSidebar,
  closeContactSidebar,
  setSelectedUserId,
  removeSelectedUserId
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
