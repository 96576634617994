import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const ECommerceAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/e-commerce/materials/:productId',
      component: lazy(() => import('./product/Product')),
    },
    {
      path: '/e-commerce/materials',
      component: lazy(() => import('./products/Products')),
    },
    {
      path: '/e-commerce',
      component: () => <Redirect to="/e-commerce/materials" />,
    },
  ],
};

export default ECommerceAppConfig;
