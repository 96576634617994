import {
  Grid,
  Card,
  CardContent,
  Paper,
  Icon,
  Input,
  Button,
} from '@mui/material'
import { motion } from 'framer-motion'

const PermissionHeader = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      className="mt-8 mb-36"
    >
      <Grid item xs={12} sm={12} md={12} className="top-full-grid">
        <Card
          sx={{ minWidth: 275 }}
          className="w-full firstsec flex justify-end"
        >
          <CardContent className="topjobcard">
            <div className="flex items-center job-right">
              <div className="job-srach">
                <motion.div
                  className="new-job-btn desktop"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                >
                  <Paper
                    component={motion.div}
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                    className="flex items-center w-full  px-8 py-4"
                  >
                    <Icon color="action">search</Icon>

                    <Input
                      placeholder="Search Job"
                      className="flex flex-1 mx-8"
                      disableUnderline
                      fullWidth
                      type={'text'}
                      inputProps={{
                        'aria-label': 'Search Job',
                      }}
                      onChange={(ev) => {
                        ev.preventDefault()
                      }}
                      autoFocus
                    />
                  </Paper>
                </motion.div>
              </div>
              <div className="job-add">
                <motion.div
                  className="new-job-btn desktop"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                >
                  <Button
                    className="whitespace-nowrap"
                    variant="contained"
                    color="secondary"
                  >
                    <span className="hidden sm:flex ">Add New Permission</span>
                    <span className="flex sm:hidden">Add New Permission</span>
                  </Button>
                </motion.div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PermissionHeader
