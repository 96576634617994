import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const AssinglogAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      
      path:[ '/assinglog/:id',
      '/assinglog/drivers',
      '/assinglog/trailer'],
      component: lazy(() => import('./AssinglogApp')),
    },
    {
      path: '/assinglog',
      component: () => <Redirect to="/assinglog/all" />,
    },
  ],
};

export default AssinglogAppConfig;
