import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const TrailerAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: [
        '/Typehaul/filters/:filterHandle/:TypehaulId?',
        '/Typehaul/:folderHandle/:TypehaulId?',
        '/Typehaul/Typehaul/:filterHandle/:TypehaulId?',
      ],
      component: lazy(() => import('./T_p_TypehaulApp')),
    },
    {
      path: '/Typehaul',
      component: () => <Redirect to="/Typehaul/all" />,
    },
  ],
};

export default TrailerAppConfig;
