import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import _ from '@lodash';

function JobsHelpersHeaderBilling({
  id,
  jsonGetById,
  setEditJobBillingOpen,
  setJob_id,
  job_id,
  data,
}) {
  const theme = useTheme();

  return (
    <div className="flex sm:flex-row flex-col w-full items-center justify-between newjob-next">
      <div className="lg:w-3/6 md:w-3/6">
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
          {data ? (
            <Typography
              className="flex items-center sm:mb-12 backjobs"
              role="button"
              color="black"
              onClick={() => {
                setEditJobBillingOpen(false);
              }}
            >
              <Icon className="text-20">
                {theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}
              </Icon>
              <span className="sm:flex mx-4 font-medium">{'Back to Jobs Overview'}</span>
            </Typography>
          ) : (
            <Typography
              className="flex items-center sm:mb-12 backjobs"
              component={Link}
              role="button"
              to="/jobs-drafts"
              color="black"
            >
              <Icon className="text-20">
                {theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}
              </Icon>
              <span className="sm:flex mx-4 font-medium">{'Back to Jobs Overview'}</span>
            </Typography>
          )}
        </motion.div>

        <div className="">
          <div className="flex sm:flex-col flex-row min-w-0 mx-8 sm:mc-16 mobile-mt-0">
            <motion.div
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.3 } }}
              className="sm:mt-0 mt-32 mobile-mt-0"
            >
              <Typography
                variant="h4"
                color="black"
                className="jobheading"
              >
                {id || job_id
                  ? `Job No. ${jsonGetById.length > 0 ? jsonGetById[0].job_no : null}`
                  : 'New Job'}
              </Typography>
              <Typography
                variant="caption"
                className="job-billing"
                color="black"
              >
                Job Billing Details
              </Typography>
            </motion.div>
          </div>
        </div>
      </div>
      {/* <motion.div
        className="flex sm:flex-row mb-32 right buttons"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        <Button
          style={{color:"white"}} 
          className="whitespace-nowrap mx-4 "
          variant="contained"
          color="third"
          onClick={handleCancelCreateJob}
          startIcon={<Icon className="hidden sm:flex">cancel</Icon>}
        >
          Cancel
        </Button>
 
        <Button
          style={{color:"white"}}
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="third"
          disabled={!isValid || disabled}
          onClick={jsonGetById.length > 0 ?  jsonGetById[0].job_billing ? handleUpdateJob : f : null}
        >
          {jsonGetById.length > 0 ?  jsonGetById[0].job_billing ? 'Update' : 'Create' : 'Create'}
        </Button>
      </motion.div> */}
    </div>
  );
}

export default JobsHelpersHeaderBilling;
