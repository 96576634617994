import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { formatter, changeCurrency, PERMISSION_MODEL } from '../../../constants';
import FuseLoading from '@fuse/core/FuseLoading';
import moment from 'moment';
import { LinearProgress } from '@mui/material';
import CustomersApp from './NewCustomersComponents/CustomersApp';
import Tooltip from '@mui/material/Tooltip';
import Check_refresh from '../Check_refresh';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useHistory } from 'react-router-dom';
import _ from '@lodash';
import ModalAfterPay from '../Payments/components/ModalAfterPay';
import { connect } from 'react-redux';
import ErrorBoundary from '../ErrorBoundary';
import client from 'client';
import { hasPermission } from '../loadz/utils';
let jobsToFill = [];
import TableFooter from '@mui/material/TableFooter'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PreviewIcon from '@mui/icons-material/Preview'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'

function TablePaginationActions(props) {
  const theme = useTheme();
  const history = useHistory();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function RowInvoices(props) {
  const {
    row,
    openModalViewInvoice,
    setTickets,
    setInfo,
    openModalEditInvoice,
    handleApplyPaymentInvoice,
    viewInvoice,
    payments,
  } = props;

  useEffect(() => {
    viewInvoice.current = handleClickView;
  }, []);

  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  });

  const clear = () => {
    setTickets([]);
    setInfo({});
  };

  const handleClickView = (tickets, info) => {
    clear();
    setTickets(tickets);
    setInfo(info);
    openModalViewInvoice.current(false);
  };

  const handleClickEdit = (tickets, info) => {
    clear();
    setTickets(tickets);
    setInfo(info);
    openModalEditInvoice.current(false);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function handleRequestSort(event, property) {
    const id = property;
    let direction = 'desc';

    if (order.id === property && order.direction === 'desc') {
      direction = 'asc';
    }

    setOrder({
      direction,
      id,
    });
  }

  const checkDays = (a, b) => {
    let st = moment()._d;
    let ed = moment(b)._d;
    if (st > ed) {
      // debugger
      let finalDataFormCurrent = moment().diff(moment(a), 'days');
      //  debugger
      return <TableCell sx={{ color: 'red' }}>{`${finalDataFormCurrent}`}</TableCell>;
    } else {
      // debugger
      let finalDataFromDue = moment().diff(moment(a), 'days');
      //  debugger
      return `${finalDataFromDue}`;
    }
  };

  return (
    <Box
      border={1}
      sx={{ backgroundColor: '#f9f9f9', margin: 1, width: '100%' }}
    >
      <Table
        sx={{ tableLayout: 'auto' }}
        size="small"
        aria-label="purchases"
      >
        <TableHead>
          <TableRow>
            {payments?.length > 0 ? (
              <>
                <TableCell
                  align="center"
                  sortDirection={order.id === 'invoiceNo' ? order.direction : false}
                >
                  <Tooltip
                    title="Sort"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      align="center"
                      active={order.id === 'invoice_no'}
                      direction={order.direction}
                      onClick={createSortHandler('invoice_no')}
                      className="font-Regular"
                    >
                      Invoice No.
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                {/* <TableCell align="center">Invoice No.</TableCell> */}
                <TableCell align="center">Value of Payment</TableCell>
                <TableCell align="center">Reference</TableCell>
                <TableCell align="center">Payment Date</TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: payments.payment_status ? '#ffcccc' : '' }}
                >
                  Status Pay
                </TableCell>
              </>
            ) : (
              <>
                <TableCell align="center">Invoice No.</TableCell>
                <TableCell align="center">Total Hauling</TableCell>
                <TableCell align="center">Total Material</TableCell>
                <TableCell align="center">Total Material Taxes</TableCell>
                <TableCell align="center">Total Wait Time</TableCell>
                <TableCell
                  align="center"
                  sortDirection={order.id === 'balance' ? order.direction : false}
                >
                  <Tooltip
                    title="Sort"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      align="center"
                      active={order.id === 'balance'}
                      direction={order.direction}
                      onClick={createSortHandler('balance')}
                      className="font-Regular"
                    >
                      Balance
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Tickets</TableCell>
                <TableCell align="center">Due Date</TableCell>
                <TableCell align="center">Aging</TableCell>
                <TableCell align="center">Date Time Updated</TableCell>
                <TableCell align="center">Edit</TableCell>

                {hasPermission(PERMISSION_MODEL.customersViewInvoice) && (
                  <TableCell align="center">View Invoice</TableCell>
                )}
                {hasPermission(PERMISSION_MODEL.customersApplyPayment) && (
                  <TableCell align="center">Pay</TableCell>
                )}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {payments?.length > 0 ? (
            <>
              {_.orderBy(
                payments,
                [
                  (o) => {
                    switch (order.id) {
                      case 'invoice_no': {
                        return o[order.id];
                      }
                    }
                  },
                ],
                [order.direction]
              ).map((payment, i) => (
                <>
                  {payments.length + 1 === i + 1 ? (
                    <></>
                  ) : (
                    <>
                      <TableRow key={payment.id}>
                        <TableCell
                          data-headtext="Invoice No."
                          align="center"
                        >
                          {payment.invoice_no}
                        </TableCell>
                        <TableCell
                          data-headtext="Value of Payment"
                          align="center"
                        >
                          {formatter.format(payment.amount)}
                        </TableCell>
                        {/* <TableCell data-headtext="Payment Source" align="center">{payment.payment_source}</TableCell> */}
                        <TableCell
                          data-headtext="Reference"
                          align="center"
                        >
                          {payment.reference ? payment.reference : 'N/A'}
                        </TableCell>
                        <TableCell
                          data-headtext="Payment Date"
                          align="center"
                        >
                          {moment(payment.date_created).format('LLL')}
                        </TableCell>
                        <TableCell
                          data-headtext="Payment Date"
                          align="center"
                          style={{ backgroundColor: payment.status ? '#ffcccc' : '' }}
                        >
                          {payment.status == true ? 'RETURN' : 'Paid'}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              {_.orderBy(
                row,
                [
                  (o) => {
                    switch (order.id) {
                      case 'balance': {
                        return o[order.id];
                      }
                    }
                  },
                ],
                [order.direction]
              ).map((invoice, i) => (
                <>
                  {row.length + 1 === i + 1 ? (
                    <></>
                  ) : (
                    <>
                      <TableRow key={invoice.invoice}>
                        <TableCell
                          data-headtext="Invoice No."
                          align="center"
                        >
                          {invoice.invoice}
                        </TableCell>
                        <TableCell
                          data-headtext="Total Hauling"
                          align="center"
                        >
                          {invoice.hauling}
                        </TableCell>
                        <TableCell
                          data-headtext="Total Material"
                          align="center"
                        >
                          {invoice.material}
                        </TableCell>
                        <TableCell
                          data-headtext="Total Material Taxes"
                          align="center"
                        >
                          {invoice.taxesMaterial}
                        </TableCell>
                        <TableCell
                          data-headtext="	Total Wait Time"
                          align="center"
                        >
                          {invoice.wait}
                        </TableCell>
                        <TableCell
                          data-headtext="Balance"
                          align="center"
                        >
                          {invoice.balance}
                        </TableCell>
                        <TableCell
                          data-headtext="Total"
                          align="center"
                        >
                          {invoice.total}
                        </TableCell>
                        <TableCell
                          data-headtext="Tickets"
                          align="center"
                        >
                          {invoice.ticketCount}
                        </TableCell>
                        <TableCell
                          data-headtext="Tickets"
                          align="center"
                        >
                          {moment(invoice.dueDate).format('LL')}
                        </TableCell>
                        {changeCurrency(invoice.balance) != 0 ? (
                          <Tooltip
                            title={`Due Date : ${moment(invoice.dueDate).format('LLL')}`}
                            placement="left"
                          >
                            <TableCell
                              align="center"
                              data-headtext="Aging"
                              className="aging"
                            >
                              {checkDays(invoice.dateCreated, invoice.dueDate)}
                            </TableCell>
                          </Tooltip>
                        ) : (
                          <TableCell
                            align="center"
                            data-headtext="Aging"
                            className="aging"
                          >
                            -
                          </TableCell>
                        )}

                        {/* <TableCell align="center">{moment(invoice.dateCreated).format('LLL')}</TableCell> */}
                        <TableCell
                          data-headtext="Date Time Updated"
                          align="center"
                        >
                          {moment(invoice.dateUpdated).format('LLL')}
                        </TableCell>

                        <TableCell
                          data-headtext="Edit"
                          align="center"
                        >
                          <Tooltip
                            title="Edit Invoice"
                            placement="top"
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleClickEdit(invoice.ticketsGli, {
                                  invoice: invoice.invoice,
                                  hauling: invoice.hauling,
                                  material: invoice.material,
                                  taxesMaterial: invoice.taxesMaterial,
                                  wait: invoice.wait,
                                  total: invoice.total,
                                  balance: invoice.balance,
                                  dueDate: invoice.dueDate,
                                  dateCreated: invoice.dateCreated,
                                  notes: invoice.notes,
                                  discount: invoice.discount,
                                  reconcile: invoice.reconcile,
                                })
                              }
                            >
                              {/* <EditIcon /> */}
                              <img
                                src="./assets/images/edit.svg"
                                style={{ height: '20px' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        {hasPermission(PERMISSION_MODEL.customersViewInvoice) && (
                          <TableCell
                            data-headtext="View invoice"
                            align="center"
                          >
                            <Tooltip
                              title="View invoice"
                              placement="top"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleClickView(invoice.ticketsGli, {
                                    invoice: invoice.invoice,
                                    hauling: invoice.hauling,
                                    material: invoice.material,
                                    taxesMaterial: invoice.taxesMaterial,
                                    wait: invoice.wait,
                                    total: invoice.total,
                                    balance: invoice.balance,
                                    dueDate: invoice.dueDate,
                                    dateCreated: invoice.dateCreated,
                                    notes: invoice.notes,
                                    reconcile: invoice.reconcile,
                                  })
                                }
                              >
                                {/* <PreviewIcon /> */}
                                <img
                                  src="./assets/images/Doller-file.svg"
                                  style={{ height: '20px' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                        {hasPermission(PERMISSION_MODEL.customersApplyPayment) && (
                          <TableCell
                            data-headtext="Pay"
                            align="center"
                          >
                            <Tooltip
                              title="Pay"
                              placement="top"
                            >
                              <IconButton
                                disabled={changeCurrency(invoice.balance) === 0 ? true : false}
                                aria-label="payments"
                                onClick={() => {
                                  handleApplyPaymentInvoice(invoice.invoiceId);
                                }}
                              >
                                {/* <PreviewIcon /> */}
                                <img
                                  src="./assets/images/money.svg"
                                  style={{ height: '20px' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  )}
                </>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </Box>
  );
}

export function Row(props) {
  const {
    listLoading,
    hasNextPage,
    page,
    getPerCustomer,
    getPaymentPerCustomer,
    row,
    openModalViewInvoice,
    setTickets,
    setInfo,
    openModalEditInvoice,
    handleApplyPaymentInvoice,
    setShowInvoiceInApply,
    infoPayments,
    prop_data,
    tabValue,
    hasGlobalSearched,
    hasGlobalTicketSearched,
    invoiceDate1,
        invoiceDate2,
        setInvoiceDate1,
        setInvoiceDate2,
        handleDateSearch,
        getPerCustomerDate,
        sorting,
        setSorting,
        SortColumn,
        grandTotalHauling,
        grandTotalWait,
        grandTotal,
        grandTotalBalance,
        grandTotalMaterial,
        tableLoading
  } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = (open) => {
    setOpen(!open);
  };

  const [scrollX, setScrollX] = useState(0);
  const listInnerRef = useRef();

  const viewInvoice = useRef(null);

  const onScroll = async (event) => {
    console.log('SCROLLING');
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight, scrollLeft, offsetHeight } =
        listInnerRef.current;

      const allow = scrollLeft > scrollX || scrollLeft < scrollX ? false : true;
      setScrollX(scrollLeft);
      if (offsetHeight + scrollTop + 10 >= scrollHeight && allow) {
        console.log('Customer invoice limit', hasNextPage && !listLoading);
        if( !hasGlobalTicketSearched){if (hasNextPage && !listLoading) {
          /* This will be triggered after hitting the last element. API call should be made here while implementing pagination. */
          if (tabValue !== 2) {
            if(invoiceDate1 && invoiceDate2)
            {
              await getPerCustomerDate(page + 1);
            }
            else{await getPerCustomer(page + 1);}
          } else if (tabValue === 2) {
            await getPaymentPerCustomer(page + 1);
          }
        }}
      }
    }
  };

  useEffect(() => {
    if (row?.length > 0) {
      const map = new Map();
      //row.invoices
      //-> item.invoices = []
      row.forEach((invoice) => {
          map.set(invoice.invoiceId, () =>
            viewInvoice.current(invoice.ticketsGli, {
              invoice: invoice.invoice,
              hauling: invoice.hauling,
              material: invoice.material,
              taxesMaterial: invoice.taxesMaterial,
              wait: invoice.wait,
              total: invoice.total,
              balance: invoice.balance,
              dueDate: invoice.dueDate,
              dateCreated: invoice.dateCreated,
              notes: invoice.notes,
              discount: invoice.discount,
              reconcile: invoice.reconcile,
            })
          );
      });
      setShowInvoiceInApply(map);
    }

    if (!Array.isArray(row)) {
      const map = new Map();
      Object.keys(row).map((key) => {
        //row.invoices
        //-> item.invoices = []
        row?.[key]?.forEach((invoice) => {
            map.set(invoice.invoiceId, () =>
              viewInvoice.current(invoice.ticketsGli, {
                invoice: invoice.invoice,
                hauling: invoice.hauling,
                material: invoice.material,
                taxesMaterial: invoice.taxesMaterial,
                wait: invoice.wait,
                total: invoice.total,
                balance: invoice.balance,
                dueDate: invoice.dueDate,
                dateCreated: invoice.dateCreated,
                notes: invoice.notes,
                discount: invoice.discount,
                reconcile: invoice.reconcile,
              })
            );
        });
      });
      setShowInvoiceInApply(map);
    }
  }, [row]);
  // h-max-344

   
  

  let localgrandTotalHauling = 0
  let localgrandTotalWait = 0
  let localgrandTotal = 0
  let localgrandTotalBalance = 0
  let localgrandTotalMaterial = 0
  
  

  return (
    <>
        {tableLoading && <Box sx={{width: '100%' }}><LinearProgress sx={{width: '100%', height: 5}}/></Box>}
        <TableContainer className="w-full overflow-x-scroll overflow-y-scroll" style={{width: '100%', maxHeight: 'calc(90vh - 250px)'}} onScroll={listLoading ? null : onScroll} ref={listInnerRef}>
          <Table style={{ width: '100%' }}  stickyHeader={true}>
            <TableHead>
              {infoPayments?.payment?.length > 0 ? (
                <TableRow sx={{ borderBottom: 2 }}>
                  <TableCell
                    align="center"
                    className="p-8"
                  ></TableCell>
                  <TableCell
                    align="center"
                    className="p-8 "
                  >
                    Invoice Count
                  </TableCell>
                  <TableCell
                    align="center"
                    className="p-8 "
                  >
                    Payment Source
                  </TableCell>
                  <TableCell
                    align="center"
                    className="p-8 "
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    align="center"
                    className="p-8 "
                  >
                    Confirmation #
                  </TableCell>
                  <TableCell
                    align="center"
                    className="p-8 "
                  >
                    Amount Received
                  </TableCell>
                  <TableCell
                    align="center"
                    className="p-8 "
                  >
                    Payment Date
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow sx={{borderBottom:2}}> 
                  
                <TableCell onClick={() => {SortColumn(sorting.invoice.active == false ? 'asc' : sorting.invoice.order == 'asc' ? 'desc': 'asc' ,'invoice' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Invoice No.{sorting.invoice.active == true ? sorting.invoice.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell onClick={() => {SortColumn(sorting.job.active == false ? 'asc' : sorting.job.order == 'asc' ? 'desc': 'asc' ,'job' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Job no.{sorting.job.active == true ? sorting.job.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell onClick={() => {SortColumn(sorting.dateUpdated.active == false ? 'asc' : sorting.dateUpdated.order == 'asc' ? 'desc': 'asc' ,'dateUpdated' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Date Updated{sorting.dateUpdated.active == true ? sorting.dateUpdated.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell onClick={() => {SortColumn(sorting.dueDate.active == false ? 'asc' : sorting.dueDate.order == 'asc' ? 'desc': 'asc' ,'dueDate' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Due Date{sorting.dueDate.active == true ? sorting.dueDate.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell onClick={() => {SortColumn(sorting.totalHauling.active == false ? 'asc' : sorting.totalHauling.order == 'asc' ? 'desc': 'asc' ,'totalHauling' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Hauling{sorting.totalHauling.active == true ? sorting.totalHauling.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell onClick={() => {SortColumn(sorting.totalWait.active == false ? 'asc' : sorting.totalWait.order == 'asc' ? 'desc': 'asc' ,'totalWait' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Wait Time{sorting.totalWait.active == true ? sorting.totalWait.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell onClick={() => {SortColumn(sorting.material.active == false ? 'asc' : sorting.material.order == 'asc' ? 'desc': 'asc' ,'material' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Material w/Tax{sorting.material.active == true ? sorting.material.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell onClick={() => {SortColumn(sorting.grandTotal.active == false ? 'asc' : sorting.grandTotal.order == 'asc' ? 'desc': 'asc' ,'grandTotal' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Grand Total{sorting.grandTotal.active == true ? sorting.grandTotal.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell onClick={() => {SortColumn(sorting.balancePending.active == false ? 'asc' : sorting.balancePending.order == 'asc' ? 'desc': 'asc' ,'balancePending' )}} sx={{borderColor:'black', borderBottom:2,backgroundColor:'white',":hover":{cursor: 'pointer'}}} className=" text-center py-12 top-head">
                  Balance{sorting.balancePending.active == true ? sorting.balancePending.order == 'asc' ? <ArrowUpwardIcon fontSize='8px'/> : <ArrowDownwardIcon fontSize='8px'/> : null}
                </TableCell>

                <TableCell sx={{borderColor:'black', borderBottom:2,backgroundColor:'white'}} className=" text-center py-12 top-head">
                  Edit
                </TableCell>

                <TableCell sx={{borderColor:'black', borderBottom:2,backgroundColor:'white'}} className=" text-center py-12 top-head">
                  View
                </TableCell>

                <TableCell sx={{borderColor:'black', borderBottom:2,backgroundColor:'white'}} className=" text-center py-12 top-head">
                  Pay
                </TableCell>

              </TableRow>
              )}
            </TableHead>

            <TableBody className="w-full">
              {infoPayments?.payment?.length > 0 ? (
                <>
                  {infoPayments?.payment?.map((payment) => {
                    return (
                      <ViewPayments
                        payment={payment}
                        row={[]}
                        payments={payment?.payments || []}
                        openModalViewInvoice={openModalViewInvoice}
                        setTickets={setTickets}
                        setInfo={setInfo}
                        openModalEditInvoice={openModalEditInvoice}
                        handleApplyPaymentInvoice={handleApplyPaymentInvoice}
                        viewInvoice={viewInvoice}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {row.map((invoice) => {
                     localgrandTotalHauling += parseFloat(invoice.hauling?.replace(/\$|,/g, ''))
                     localgrandTotalWait += parseFloat(invoice.wait?.replace(/\$|,/g, ''))
                     localgrandTotal += parseFloat(invoice.total?.replace(/\$|,/g, ''))
                     localgrandTotalBalance += parseFloat(invoice.balance?.replace(/\$|,/g, ''))
                     localgrandTotalMaterial += (parseFloat(invoice.material?.replace(/\$|,/g, ''))+parseFloat(invoice.taxesMaterial?.replace(/\$|,/g, '')))
                    return (
                      <ViewInvoices
                        invoice={invoice}
                        row={row}
                        payments={[]}
                        openModalViewInvoice={openModalViewInvoice}
                        setTickets={setTickets}
                        setInfo={setInfo}
                        openModalEditInvoice={openModalEditInvoice}
                        handleApplyPaymentInvoice={handleApplyPaymentInvoice}
                        viewInvoice={viewInvoice}
                      />
                    );
                  })}
                </>
              )}
            </TableBody>
            
            { 
            tabValue == 0 || tabValue == 1? 
            <TableFooter style={{position:'sticky', bottom:0, zIndex:2,backgroundColor:'white'}}>
              <TableRow>
                <TableCell sx={{borderColor:'black', borderTop:2}} align='center'><Typography color={'black'} fontSize={14} sx={{fontWeight:'bold'}}>Total</Typography></TableCell>
                <TableCell sx={{borderColor:'black', borderTop:2}}></TableCell>
                <TableCell sx={{borderColor:'black', borderTop:2}}></TableCell>
                <TableCell sx={{borderColor:'black', borderTop:2}}></TableCell>

                <TableCell align='start' sx={{padding:'0px 10px 0px 10px',borderColor:'black', borderTop:2}}>
                  <div className='flex'>
                    <div className='flex w-full justify-start align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>$</Typography></div>
                    <div className='flex w-full justify-end align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>{grandTotalHauling ? formatter.format(grandTotalHauling).replace('$', '') : formatter.format(localgrandTotalHauling).replace('$', '')}</Typography></div>
                  </div>
                </TableCell>

                <TableCell align='start' sx={{padding:'0px 10px 0px 10px',borderColor:'black', borderTop:2}}>
                  <div className='flex'>
                    <div className='flex w-full justify-start align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>$</Typography></div>
                    <div className='flex w-full justify-end align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>{grandTotalWait ? formatter.format(grandTotalWait).replace('$', '') : formatter.format(localgrandTotalWait).replace('$', '')}</Typography></div>
                  </div>
                </TableCell>

                <TableCell align='start' sx={{padding:'0px 10px 0px 10px',borderColor:'black', borderTop:2}}>
                  <div className='flex'>
                    <div className='flex w-full justify-start align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>$</Typography></div>
                    <div className='flex w-full justify-end align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>{grandTotalMaterial ? formatter.format(grandTotalMaterial).replace('$', '') : formatter.format(localgrandTotalMaterial).replace('$', '')}</Typography></div>
                  </div>
                </TableCell>

                <TableCell align='start' sx={{padding:'0px 10px 0px 10px',borderColor:'black', borderTop:2}}>
                  <div className='flex'>
                    <div className='flex w-full justify-start align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>$</Typography></div>
                    <div className='flex w-full justify-end align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>{grandTotal ? formatter.format(grandTotal).replace('$', '') : formatter.format(localgrandTotal).replace('$', '')}</Typography></div>
                  </div>
                </TableCell>

                <TableCell align='start' sx={{padding:'0px 10px 0px 10px',borderColor:'black', borderTop:2}}>
                  <div className='flex'>
                    <div className='flex w-full justify-start align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>$</Typography></div>
                    <div className='flex w-full justify-end align-center'><Typography sx={{fontWeight:'bold'}} color={'black'} fontSize={13}>{grandTotalBalance ? formatter.format(grandTotalBalance).replace('$', '') : formatter.format(localgrandTotalBalance).replace('$', '')}</Typography></div>
                  </div>
                </TableCell>

                <TableCell sx={{borderColor:'black', borderTop:2}}></TableCell>
                <TableCell sx={{borderColor:'black', borderTop:2}}></TableCell>
                <TableCell sx={{borderColor:'black', borderTop:2}}></TableCell>


              </TableRow>
            </TableFooter>
            : null
            }
          </Table>
        </TableContainer>
    </>
  );
}

const ViewPayments = ({
  payment,
  row,
  payments,
  openModalViewInvoice,
  setTickets,
  setInfo,
  openModalEditInvoice,
  handleApplyPaymentInvoice,
  viewInvoice,
}) => {
  const [opencollapse, setOpenCollapse] = useState(false);
  return (
    <>
      <TableRow key={payment?.id}>
        <TableCell
          className="top-data job__data"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setOpenCollapse(!opencollapse);
          }}
        >
          {opencollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
        <TableCell className="top-data text-center">
          <Typography sx={{ fontSize: 'large' }}>{`${payment?.payments?.length}`}</Typography>
        </TableCell>
        <TableCell className="top-data text-center">
          <Typography
            sx={{ fontSize: 'large' }}
          >{`${payment?.payments?.[0]?.payment_source}`}</Typography>
        </TableCell>
        <TableCell className="top-data text-center">
          <Typography sx={{ fontSize: 'large' }}>{`${
            payment?.payments?.[0]?.reference || 'N/A'
          }`}</Typography>
        </TableCell>
        <TableCell className="top-data text-center">
          <Typography sx={{ fontSize: 'large' }}>{`${
            payment?.payments?.[0]?.confirmation_no || 'N/A'
          }`}</Typography>
        </TableCell>
        <TableCell className="top-data text-center">
          <Typography sx={{ fontSize: 'large' }}>
            {formatter.format(payment?.amount_received)}
          </Typography>
        </TableCell>
        <TableCell className="top-data text-center">
          <Typography sx={{ fontSize: 'large' }}>
            {moment(payment?.date_created).format('LLL')}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={7}>
          <Collapse
            in={opencollapse}
            timeout="auto"
            unmountOnExit
          >
            <RowInvoices
              row={[]}
              payments={payment?.payments || []}
              openModalViewInvoice={openModalViewInvoice}
              setTickets={setTickets}
              setInfo={setInfo}
              openModalEditInvoice={openModalEditInvoice}
              handleApplyPaymentInvoice={handleApplyPaymentInvoice}
              viewInvoice={viewInvoice}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ViewInvoices = ({
  row,
  payments,
  openModalViewInvoice,
  setTickets,
  setInfo,
  openModalEditInvoice,
  handleApplyPaymentInvoice,
  viewInvoice,
  invoice
}) => {
  return (
      <RowOnlyInvoices
        invoice={invoice}
        payments={[]}
        openModalViewInvoice={openModalViewInvoice}
        setTickets={setTickets}
        setInfo={setInfo}
        openModalEditInvoice={openModalEditInvoice}
        handleApplyPaymentInvoice={handleApplyPaymentInvoice}
        viewInvoice={viewInvoice}
      />
  );
};

function RowOnlyInvoices(props) {
  const {
    row,
    openModalViewInvoice,
    setTickets,
    setInfo,
    handleApplyPaymentInvoice,
    invoice,
    openModalEditInvoice,
  } = props
  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  })

  const clear = () => {
    setTickets([])
    setInfo({})
  }

  const handleClickView = (tickets, info) => {
    clear()
    setTickets(tickets)
    setInfo(info)
    openModalViewInvoice.current(false)
  }

  const handleClickEdit = (tickets, info) => {
    clear()
    setTickets(tickets)
    setInfo(info)
    openModalEditInvoice.current(false)
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  function handleRequestSort(event, property) {
    const id = property
    let direction = 'desc'

    if (order.id === property && order.direction === 'desc') {
      direction = 'asc'
    }

    setOrder({
      direction,
      id,
    })
  }
  return (
    <TableRow key={invoice.invoice}>
  
    <TableCell
      data-headtext="Invoice No."
      align="center"
     size='small'
     padding='none'
     
    >
      {invoice.invoice}
    </TableCell>

    <TableCell
      data-headtext="Job No."
      align="center"
     size='small'
     padding='none'
     
    >
      <Tooltip placement='top' title={invoice.og_job_no ? invoice.og_comp+"-"+invoice.og_job_no : ""} followCursor={true}><Typography sx={{fontSize:14, fontWeight:'bold'}}>{`${invoice.job}`}</Typography></Tooltip>
    </TableCell>

    <TableCell
      data-headtext="Date Updated"
      align="center"
     size='small'
     padding='none'
     
    >
      {moment(invoice.dateUpdated).format("MM/DD/YYYY")}
    </TableCell>

    <TableCell
      data-headtext="Due Date"
      align="center"
     size='small'
     padding='none'
     
    >
      {moment(invoice.dueDate).format("MM/DD/YYYY")}
    </TableCell>

    <TableCell
      data-headtext="Total Hauling"
      align="right"
     size='small'
     padding='none'
     
    >
      <div className='flex'>
        <div className='flex w-full justify-start align-center'>$</div>
        <div className='flex w-full justify-end align-center'>{invoice.hauling?.replace('$', '')}</div>
      </div>
    </TableCell>

    <TableCell data-headtext="Total Wait Time" align="right" size='small' padding='none' >
      <div className='flex'>
        <div className='flex w-full justify-start align-center'>$</div>
        <div className='flex w-full justify-end align-center'>{invoice.wait?.replace('$', '')}</div>
      </div>
    </TableCell>

    <TableCell data-headtext="Total Wait Time" align="right" size='small' padding='none' >
      <div className='flex'>
        <div className='flex w-full justify-start align-center'>$</div>
        <div className='flex w-full justify-end align-center'>{formatter.format(Number(invoice.material?.replace(/\$|,/g, ''))+Number(invoice.taxesMaterial?.replace(/\$|,/g, ''))) }</div>
      </div>
    </TableCell>

    <TableCell data-headtext="Grand Total" align="right" size='small' padding='none' >
      <div className='flex'>
        <div className='flex w-full justify-start align-center'>$</div>
        <div className='flex w-full justify-end align-center'>{invoice.total?.replace('$', '')}</div>
      </div>
    </TableCell>

    <TableCell data-headtext="Balance Pending" align="right" size='small' padding='none' >
      <div className='flex'>
        <div className='flex w-full justify-start align-center'>$</div>
        <div className='flex w-full justify-end align-center'>{invoice.balance?.replace('$', '')}</div>
      </div>
    </TableCell>

    <TableCell
      data-headtext="Edit"
      align="center"
      size='small'
      padding='none'
    >
      <Tooltip
        title="Edit Invoice"
        placement="top"
      >
        <IconButton
          aria-label="delete"
          onClick={() =>
            handleClickEdit(invoice.ticketsGli, {
              invoice: invoice.invoice,
              hauling: invoice.hauling,
              material: invoice.material,
              taxesMaterial: invoice.taxesMaterial,
              wait: invoice.wait,
              total: invoice.total,
              balance: invoice.balance,
              dueDate: invoice.dueDate,
              dateCreated: invoice.dateCreated,
              notes: invoice.notes,
              discount: invoice.discount,
              reconcile: invoice.reconcile,
            })
          }
        >
          {/* <EditIcon /> */}
          <img
            src="./assets/images/edit.svg"
            style={{ height: '20px' }}
          />
        </IconButton>
      </Tooltip>
    </TableCell>

    { hasPermission(PERMISSION_MODEL.customersViewInvoice) &&
    <TableCell data-headtext="View invoice" align="center" size='small' padding='none' >
      <Tooltip title="View invoice" placement="top">
        <IconButton
          aria-label="delete"
          onClick={() =>
            handleClickView(invoice.ticketsGli, {
              invoice: invoice.invoice,
              hauling: invoice.hauling,
              material: invoice.material,
              taxesMaterial: invoice.taxesMaterial,
              wait: invoice.wait,
              total: invoice.total,
              balance: invoice.balance,
              dueDate: invoice.dueDate,
              dateCreated: invoice.dateCreated,
              notes: invoice.notes,
            })
          }
        >
          <PreviewIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
    }

    { hasPermission(PERMISSION_MODEL.customersApplyPayment) &&
    <TableCell
      data-headtext="Pay"
      align="center"
     size='small'
     padding='none'
     
    >
      <Tooltip title="Pay" placement="top">
        <IconButton
          disabled={
            changeCurrency(invoice?.balance ? invoice?.balance : '$0.00') === 0
              ? true
              : false
          }
          aria-label="payments"
          onClick={() => {
            console.log(invoice)

            handleApplyPaymentInvoice(invoice.invoiceId)
          }}
        >
          <LocalAtmIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
    }

    </TableRow>
  )
}

function NewCustomers({ user, data }) {
  const prop_data = data;
  const [rows, setRows] = useState([]);
  const [zero, setZero] = useState(false);

  const openModalInvoice = useRef(null);
  const rechargedAfterChanges = useRef(null);
  const setMessage = useRef(null);
  const [isAllJobs, setIsAllJobs] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [jobsToInvoice, setJobsToInvoice] = useState([]);
  const [infoToInvoice, setInfoToInvoice] = useState([]);
  const history = useHistory();

  const [finish, setFinish] = useState(false);
  const [scrollNextPage, setScrollNextPage] = useState(true);
  const [totalTicketToInvoice, setTotalTicketToInvoice] = useState([]);

  // const [infoCustomer, setInfoCustomer] = useState([])
  const [balanceReciptdata, setBalanceReciptdata] = useState(null);
  // const {data,amountReceivedToApply}= balanceReciptdata
  const dispatch = useDispatch();
  //TODO check on the line 820 for Balance Receipt. Where is this shown ?
  const _showMessage = (msg, color, dataClientReceipt) => {
    setBalanceReciptdata(dataClientReceipt);
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
            {msg === 'Update balance succesfully!' ? (
              <button onClick={handleClickOpen}> Balance Receipt </button>
            ) : null}
          </>
        ), //text or html
        autoHideDuration: 12000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const token = localStorage.token;

  const getData = async () => {
    setScrollNextPage(true);
    setFinish(false);
    let _rows = [];
    setInfoToInvoice([]);
    setRows([]);
    // setInfoCustomer([])
    setTickets([]);
    setJobsToInvoice([]);
    setTotalTicketToInvoice([]);
    await getCustomers();
    setFinish(true);
  };

  const getCustomers = async (page = 1) => {
    const result = await client.get(
      `/invoices/customers/${prop_data ? prop_data.compacctnum : user.company.compacctnum}`,
      {
        params: { page },
      }
    );
    if (result?.status == 200 && Array.isArray(result.data)) {
      const data = page == 1 ? result.data : [...rows, ...result.data];
      setInfoToInvoice(data);
      setRows(data);
    }
  };

  useEffect(async () => {
    const result = await Check_refresh();
    if (!result) {
      return history.replace('/login');
    }
    setMessage.current = _showMessage;
    getData();
    rechargedAfterChanges.current = getData;
  }, [data]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
    return (
      <ErrorBoundary>
        <CustomersApp
        finish={finish}
          prop_data={prop_data}
          refreshCustomers={getData}
          rows={rows}
          // setInfoCustomer={setInfoCustomer}
          // infoCustomer={infoCustomer}
          rechargedAfterChanges={rechargedAfterChanges}
          setMessage={setMessage}
          setRows={setRows}
          scrollNextPage={scrollNextPage}
          setScrollNextPage={setScrollNextPage}
        />

        <ModalAfterPay
          prop_data={prop_data}
          open={open}
          handleClose={handleClose}
          balanceReciptdata={balanceReciptdata}
          formatter={formatter}
          isCustomer={true}
        />
      </ErrorBoundary>
    );
  
}

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};

export default connect(mapStateToProps, null)(memo(NewCustomers));
