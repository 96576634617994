const actions = {
  GET_All: 'getAll',
  CREATE: 'create',
  GET_ROLE: 'getRole',
  UPDATE: 'update',
  SEARCH: 'search',
  CLEAR_ROLE_ASSIGNED_PERMISSION: 'clearRoleAssignedPermission',
  GET_PERMISSIONS: 'getPermissions',
  DELETE: 'delete',
  GET_ASSIGNED_PERMISSION: 'getAssignedPermission',

  getAll: ({ page }, onError) => {
    const payload = { params: { page } }
    return {
      type: actions.GET_All,
      payload,
      onError,
    }
  },
  create: (payload, onSuccess, onError) => {
    return {
      type: actions.CREATE,
      payload,
      onError,
      onSuccess,
    }
  },

  getRole: (id, onError) => {
    return {
      type: actions.GET_ROLE,
      payload: { id },
      onError,
    }
  },
  update: (payload, onSuccess, onError) => {
    console.log('update action')
    return {
      type: actions.UPDATE,
      payload,
      onError,
      onSuccess,
    }
  },

  search: (text, onError) => {
    const payload = { text }
    return {
      type: actions.SEARCH,
      payload,
      onError,
    }
  },

  clearRoleAssignPermission: () => {
    return {
      type: actions.CLEAR_ROLE_ASSIGNED_PERMISSION,
    }
  },

  delete: (payload, onSuccess, onError) => {
    return {
      type: actions.DELETE,
      payload,
      onSuccess,
      onError,
    }
  },

  getAssignedPermission: (payload, onError) => {
    console.log('GET_ASSIGNED_PERMISSION')
    return {
      type: actions.GET_ASSIGNED_PERMISSION,
      onError,
    }
  },
  
}

export default actions
