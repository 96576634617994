import store from "app/store";
export  const formatePhoneNumber = (value) => {
  if (!value) 
    return value;

  const phoneNumber = value.replace(/[^\d]/g, "")
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`
}

export const hasPermission = (requiredPermission) => {
  const state = store.getState()
  const permission = state.loadz_auth?.AuthReducer?.user?.permission ? state.loadz_auth?.AuthReducer?.user?.permission : []

  if (permission.length == 0)
    return false

  
  if (permission.includes('*.*') || permission.includes(requiredPermission)) {
    return true
  }

  //check for generic permission
  if (requiredPermission.includes('.')) {
    const genericPermission =  requiredPermission.split('.')[0] + '.*'
    
    if (permission.includes(genericPermission)){
      return true
    }
  }
  return false
}

export const getBrowserInfo =  () => {
  const userAgent = navigator.userAgent;
  let name = '';
  let version = '';
  let os = '';

  if (userAgent.indexOf('Chrome') !== -1) {
    // Chrome
    name = 'Chrome';
    version = userAgent.match(/Chrome\/([\d.]+)/)[1];
  } else if (userAgent.indexOf('Firefox') !== -1) {
    // Firefox
    name = 'Firefox';
    version = userAgent.match(/Firefox\/([\d.]+)/)[1];
  } else if (userAgent.indexOf('Safari') !== -1) {
    // Safari
    name = 'Safari';
    version = userAgent.match(/Version\/([\d.]+)/)[1];
  } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
    // Internet Explorer
    name = 'Internet Explorer';
    version = userAgent.match(/(MSIE\s|rv:)([\d.]+)/)[2];
  } else {
    // Other browsers
    name = 'Unknown';
    version = 'N/A';
  }

  if (userAgent.indexOf('Windows') !== -1) {
    os = 'Windows';
  } else if (userAgent.indexOf('Mac') !== -1) {
    os = 'Mac OS X';
  } else if (userAgent.indexOf('Linux') !== -1) {
    os = 'Linux';
  } else if (userAgent.indexOf('Android') !== -1) {
    os = 'Android';
  } else if (userAgent.indexOf('iOS') !== -1) {
    os = 'iOS';
  } else {
    os = 'Unknown';
  }

  return {name, version, os}
}


