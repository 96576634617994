import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { memo, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { green } from '@mui/material/colors';
import FactCheckIcon from '@mui/icons-material/FactCheck';

function CounterCompleted(props) {

  useEffect(() => {
    console.log("COUNTER ", props.tickets)
  }, [])

  return (

    <Paper className="justify-center rounded-none" id="completed" style={{
      cursor: 'pointer' , backgroundColor:"#000000" , border: 
      "3px solid #edca33"
    }}>
      <div className="flex items-center">
        <Typography className="text-16 px-16 font-Regular " color="#000000">
       
        </Typography>
        
      </div> 
      <div className="text-center ">
        {/* <FactCheckIcon sx={{ color: green[800], fontSize: 50 }} aria-label="recipe" /> */}
        <Typography className="text-56 font-bold  text-white ">
          {props.counter}
        </Typography>
        <Typography className="text-16 text-yellow-800 font-Regular">
          Jobs Completed
        </Typography>

        <Typography className="text-16 text-white font-Regular">
          Ticket Count: {props.tickets}
        </Typography>
      </div>
      
    </Paper>


  );
}

export default memo(CounterCompleted);