const skyBlue = {
  50: '#e4fafd',
  100: '#FFFFFF',
  200: '#000000',
  300: '#000000',
  400: '#43daf1',
  500: '#edca33',
  600: '#000000',
  700: '#000000',
  800: '#14c2e7',
  900: '#0cb7e2',
  A100: '#FFFFFF',
  A200: '#daf7ff',
  A400: '#a7ecff',
  A700: '#8de6ff',
  contrastDefaultColor: 'dark',
};
 
export default skyBlue;
