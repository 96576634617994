import { lazy } from 'react';
const ManageVideosConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/manageVideos',
      component: lazy(() => import('./ManageVideos')),
    },
    {
      path: '/report-payment-master',
      component: lazy(() => import('./ReportPaymentMaster')),
    },
  ]

};
export default ManageVideosConfig;
