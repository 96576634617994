import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error('Error:', error)
    console.error('Error Info:', info)
    
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      // You can customize the error message that is shown to the user
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary;