import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import reducer from '../../store';
import JobsHelpersHeader from './JobsHelpersHeader';
import CreateJob from './tabs/CreateJob';
import Container from '@mui/material/Container';
import FuseMessage from '@fuse/core/FuseMessage';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Typography } from '@mui/material';
import client from 'client';
const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 0,
    height: 0,
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      minHeight: 0,
      height: 0,
    },
  },
  '& .FusePageCarded-content': {
    overflow: 'auto !important',
  },
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  job_c_id: yup.array().required(),
  quarry_pit: yup.array().required(),
  j_destination: yup.array().required(),
  j_fullfillment_warning: yup.number().required(),
  po: yup.number().required(),
});

function JobsHelpers({ data, job_id, setEditJobOpen, setEditJobBillingOpen, setJob_id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const routeParams = useParams();
  const { id } = routeParams;
  const [tabValue, setTabValue] = useState(0);
  const [noUnitTemp, setNoUnitTemp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jsonGetById, setJsonGetById] = useState([]);
  const token = localStorage.token;
  const [onChangeText, setOnChangeText] = useState('');
  const [onInputText, setOnInputText] = useState('');

  function handleTabChange(event, value) {
    setTabValue(value);
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      // job_no: null,
      job_c_id: [],
      quarry_pit: [],
      j_destination: null,
      unit: 0,
      materials: [],
      material_cost_rate: null,
      billperunit: null,
      material_invoice_rate: null,
      j_fullfillment_warning: null,
      j_fullfillment_total: null,
      j_notes: '',
      notification: [],
      po: null,
      j_status: true,
      scale: true,
      // job_no: 0,
    },
    resolver: yupResolver(schema),
  });

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const handleSearchJobById = async () => {
    if (id || job_id) {
      setLoading(true);
      try {
        const _getJobById = await client.get(`/jobs/by-id/${job_id ? job_id : id}`);
        const _jsonGetById = await _getJobById.data;

        if (_jsonGetById.msg || _jsonGetById.length == 0) {
          console.log('sads');
          data ? setEditJobOpen(false) : history.push('/jobs');
          setLoading(false);
        }

        setJsonGetById(_jsonGetById);

        setLoading(false);
      } catch (error) {
        data ? setEditJobOpen(false) : history.push('/jobs');
        setLoading(false);
      }
    }
  };

  const { setValue, setError } = methods;

  useEffect(() => {
    handleSearchJobById();
    if (id || job_id) {
      return;
    }
    setError('quarry_pit');
    setError('j_destination');
  }, [job_id]);

  if ((id || job_id) && loading) {
    return <FuseLoading></FuseLoading>;
  }

  return (
    <FormProvider {...methods}>
      <FuseMessage />
      <Root
        content={
          <div className="addjobsec ">
            <Container maxWidth="xl">
              <JobsHelpersHeader
                setJob_id={setJob_id}
                setEditJobBillingOpen={setEditJobBillingOpen}
                setEditJobOpen={setEditJobOpen}
                job_id={job_id}
                data={data}
                id={id}
                jsonGetById={id || job_id ? jsonGetById : []}
                onChangeText={onChangeText}
                onInputText={onInputText}
              />
              <CreateJob
                setJob_id={setJob_id}
                setEditJobBillingOpen={setEditJobBillingOpen}
                setEditJobOpen={setEditJobOpen}
                job_id={job_id}
                prop_data={data}
                jsonGetById={jsonGetById}
                onChangeText={onChangeText}
                setOnChangeText={setOnChangeText}
                onInputText={onInputText}
                setOnInputText={setOnInputText}
              />
            </Container>
          </div>
        }
        innerScroll
      />
    </FormProvider>
  );
}

export default withReducer('JobsApp', reducer)(JobsHelpers);
