import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { getDataFromS3 } from '../../main/CompanyList/companyFunctions';
import { connect } from 'react-redux';
import ImageUploaderDialog from 'app/main/loadz/components/ImageUploaderDialog';
import AuthAction from 'app/stores/loadz/redux/Auth/actions';
import { constants } from '../../../constants';
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.secondary,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),

    bottom: 0,
    background: theme.palette.background.secondary,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

const UserNavbarHeader = ({ user, setCompanyLogo }) => {
  const [logo, setLogo] = useState('');
  useEffect(async () => {
    if (user?.user_type != 'super-admin' && user?.company?.logo) {
      // let resp = await getS3Location(user.company.logo)
      const profile = user?.company?.logo.includes('https')
        ? user?.company?.logo
        : `${constants.URLLOCAL}/aws/signed?image=${user?.company?.logo}`;
      setLogo(profile);
    }
  }, [user]);

  const [isOpen, setOpen] = useState(false);
  const [key, setKey] = useState(null);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getS3Location = async (key) => {
    if (key != null && key != '') {
      let data = {
        key: key,
      };
      return await getDataFromS3(data);
    }
  };

  useEffect(async () => {
    if (key) {
      let payload = {
        id: user?.user_id,
        logo: key,
      };
      try {
        // await client.put(`/company/updateCompanyLogo`, info);
        await setCompanyLogo(payload, (error) => {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [key]);

  return (
    <StyledAppBar
      elevation={0}
      position="static"
      color="primary"
      className="h-1/6 user relative flex flex-col items-center justify-center pt-24  z-0 shadow-0 border-0"
    >
      <div className=" flex items-start justify-center absolute bottom-0 -mb-44 h-full">
        <div
          className="flex justify-center m-auto"
          style={{ width: '260px', height: '130px', marginTop: '30px' }}
        >
          <div className="userimg flex justify-center items-center">
            <img
              onClick={handleOpen}
              src={
                user?.user_type == 'super-admin'
                  ? '/assets/images/logos/black_white_loadz.png'
                  : logo
                  ? logo
                  : 'assets/images/avatars/profile.jpg'
              }
              alt="user photo"
            />
          </div>
        </div>
        <ImageUploaderDialog
          isOpen={isOpen}
          setOpen={handleClose}
          bucketType="company"
          key={key}
          setKey={setKey}
        />
      </div>
    </StyledAppBar>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setCompanyLogo: (payload, onError) => dispatch(AuthAction.setCompanyLogo(payload, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserNavbarHeader);
