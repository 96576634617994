import { Redirect } from "react-router-dom"
import { useDispatch } from 'react-redux'
import store from 'app/store'

const AuthGuard = (Component) => {
  const token = localStorage.getItem('token')
  // const state = store.getState()
  // const user = state?.loadz_auth?.AuthReducer?.user
  // console.log(Component, user?.reg_step, user?.user_type)
  if(token) {
    // if(user?.reg_step !== 'complete') {
    //   return <Redirect to='/subscription/company/2' />
    // }
    return Component 
  }

  store.dispatch({ type: 'LOG_OUT' })
  return <Redirect to="/login" />
}

export default AuthGuard