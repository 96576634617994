import { Typography } from '@mui/material'
import { motion } from 'framer-motion'

const EmptyTableMessage = ({ data }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.1 } }}
      className="flex flex-1 items-center justify-center h-full moble-content"
    >
      <Typography color="textSecondary" variant="h5">
        There are no {data}, please create one!
      </Typography>
    </motion.div>
  )
}

export default EmptyTableMessage
