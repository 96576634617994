import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Container } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import client from 'client';
const token = localStorage.token;

export default function ModalToReconcileAllCustomers({
  dataToReconcile,
  openModalReconcile,
  setOpenSnack,
  setMessageAlert,
  constants,
  setColorAlert,
  rechargedAfterChanges,
}) {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const [disableButton, setDisableButton] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    openModalReconcile.current = setOpen;
    setDisableButton(false);
  }, []);

  const handleCloseDialog = () => {
    setOpen(false);
  };
  useEffect(() => {
    setDisableButton(false);
    if (open) {
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      });
    } else {
    }
  }, [open]);

  const handleUpdate = async () => {
    setDisableButton(true);
    let dataToSend = new Map();

    dataToReconcile.forEach((value, key) => {
      value.jobs.forEach((job, key) => {
        job.invoices.forEach((invoice, key) => {
          console.log(document.getElementById(`input-${invoice.invoicesId}`).value);
          const valueInput = document.getElementById(`input-${invoice.invoicesId}`).value;
          if (valueInput !== '') {
            dataToSend.set(invoice.invoicesId.toString(), valueInput);
          }
        });
      });
    });

    const reconcile = await client.put(
      `/invoices/customers/update-all-reconcile`,
      Object.fromEntries(dataToSend)
    );

    if (reconcile.status === 200) {
      setOpenSnack(true);
      setColorAlert('success');
      setMessageAlert('Invoices have been reconciled successfully!'); //TODO improved Message
      setTimeout(() => {
        setOpenSnack(false);
      }, 8000);
      setOpen(false);
      rechargedAfterChanges.current();
      return;
    }
    setColorAlert('error');
    setMessageAlert('Failed to reconcile invoices. Please try again!'); //TODO improved Message
    setTimeout(() => {
      setOpenSnack(false);
    }, 8000);
    setDisableButton(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        onBackdropClick={() => {}}
        hideBackdrop={false}
        maxWidth={'lg'}
        className="recouncile-modal"
      >
        <DialogTitle id="scroll-dialog-title">Reconcile Invoices</DialogTitle>
        <div className="popup-close">
          <CloseIcon onClick={handleClose} />
        </div>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <Container fixed>
              <>
                <form onSubmit={(e) => e.preventDefault()}>
                  {dataToReconcile.map((item, index) => (
                    <>
                      <h3>Customer Name: {item.customerName}</h3>
                      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {item.jobs.map((job, index) => (
                          <>
                            <h4>Job No: {job.jobOg ? job.jobOg : job.jobNo}</h4>
                            <List>
                              {job.invoices.map((invoice, index) => (
                                <>
                                  <ListItem disablePadding>
                                    <ListItemText>
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                      >
                                        Invoice No: {invoice.invoiceNo}
                                      </Typography>
                                    </ListItemText>
                                    <Box
                                      component="form"
                                      sx={{
                                        '& > :not(style)': { m: 1, width: '25ch' },
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <TextField
                                        id={`input-${invoice.invoicesId}`}
                                        label="Write Reference"
                                        variant="outlined"
                                      />
                                    </Box>
                                  </ListItem>
                                </>
                              ))}
                            </List>
                            <Divider />
                          </>
                        ))}
                      </List>
                    </>
                  ))}
                </form>
              </>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={disableButton}
            onClick={handleUpdate}
            sx={{ backgroundColor: 'black' }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
