import { useState, useEffect, memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import {
  Button,
  FormControl,
  FormLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import client from 'client';
import store from 'app/store';
import { showMessage } from 'app/store/fuse/messageSlice';

function ModalSpecialRate({
  isOpenModalSpecial,
  setIsOpenModalSpecial,
  setDataSpecial,
  inputFields,
  setInputFields,
  bill_invoice_rate
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const methods = useFormContext();
  const [open, setOpen] = useState(isOpenModalSpecial);
  const [contra, setcontra] = useState([]);
  const [ownerOperator, setOwner] = useState([]);
  const [subs, setSubs] = useState([]);
  const [ownerlist, setOwnerlist] = useState([]);
  const [position, setPosition] = useState(0);
  const [subcontractor, setSubcontractor] = useState({});
  const { control, formState, setValue, getValues, reset } = methods;
  const [inputFieldsSpecial, setInputFieldsSpecial] = useState([
    {
      identifi: uuidv4(),
      subcontractor_comp_id: '',
      unit_rate: 0,
      company_id: '',
      hourly_rate: 0,
      date_created: new Date(),
    },
  ]);
  const state = store.getState();
  const user = state?.loadz_auth?.AuthReducer?.user;

  useEffect(() => {
    setOpen(isOpenModalSpecial);
    getAllcontra();
    getAllOwner();
  }, [isOpenModalSpecial]);

  const handleClose = () => {
    setOpen(false);
    setIsOpenModalSpecial(false);
    setInputFieldsSpecial([
      {
        identifi: uuidv4(),
        subcontractor_comp_id: '',
        unit_rate: 0,
        company_id: '',
        hourly_rate: 0,
        date_created: new Date(),
      },
    ]);
  };

  const getAllcontra = async () => {
    const compacctnum = user?.company?.compacctnum;
    try {
      const subs = await client.post(`/props/get-subcon`, { compacctnum });
      const jsonSubs = await subs.data;
      const subcons = jsonSubs.subcon;
      const opt_subs = subcons.map((e) => {
        return {
          compacctnum: e.compacctnum,
          value: e.user_id,
          label: e.compname,
          type: e.relation,
        };
      });
      setSubs(opt_subs);
      setcontra(jsonSubs.subcon);
    } catch (error) {}
  };

  const getAllOwner = async () => {
    const compacctnum = user?.company?.compacctnum;
    try {
      const subs = await client.post(`/ownerop/get`, { comp_acct: compacctnum });
      const jsonSubs = await subs.data;
      const subcons = jsonSubs.ownerops;
      const opt_owner = subcons.map((e) => {
        return { compacctnum: e.compacctnum, value: e.user_id, label: e.compname, type: e.ownerop };
      });
      setOwnerlist(opt_owner);
      setOwner(jsonSubs.ownerops);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeInput = (identifi, event) => {
    console.log('bill_invoice_rate',bill_invoice_rate)
    const compacctnum = user?.company?.compacctnum;
    const newInputFields = inputFieldsSpecial.map((i) => {
      if (identifi === i.identifi) {
        i[event.target.name] = event.target.value;
        i.company_id = compacctnum;
      }
      setInputFieldsSpecial([
        ...inputFieldsSpecial,
        {
          identifi: uuidv4(),
          subcontractor_comp_id: '',
          unit_rate: '',
          company_id: compacctnum,
          hourly_rate: 0,
          date_created: new Date(),
        },
      ]);
      setValue('subcontractor', inputFieldsSpecial);
      return i;
    });
    setInputFieldsSpecial(newInputFields);
  };

  const handleCreate = async () => {
    console.log(inputFieldsSpecial);
    if (
      inputFieldsSpecial[0].unit_rate == '' ||
      inputFieldsSpecial[0].subcontractor_comp_id == ''
    ) {
      _showMessage('Please fill in all the fields', 'error');
      return;
    }

    if(inputFieldsSpecial[0].unit_rate < bill_invoice_rate)
    {_showMessage('The rate entered is lower than the Unit Invoice Rate', 'warning');}
    if(inputFieldsSpecial[0].unit_rate > bill_invoice_rate)
    {_showMessage('The rate entered is greater than the Unit Invoice Rate', 'warning');}

    setInputFields([...inputFields, ...inputFieldsSpecial]);
    setDataSpecial(inputFieldsSpecial);
    handleClose();
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Add New Special Rates</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControl
            className="customSelect"
            fullWidth
            style={{ marginRight: '10px' }}
          >
            <FormLabel>Owner Operator / Subcontractor</FormLabel>
            <Controller
              name="subcontractor_comp_id"
              id="subcontractor_comp_id"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  variant="outlined"
                  required
                  value={
                    inputFieldsSpecial[position]
                      ? inputFieldsSpecial[position].subcontractor_comp_id
                      : ''
                  }
                  onChange={(event) =>
                    handleChangeInput(
                      inputFieldsSpecial[position] ? inputFieldsSpecial[position].identifi : '',
                      event
                    )
                  }
                >
                  <ListSubheader style={{ backgroundColor: '#edca33' }}>
                    <strong>Subcontractor</strong>
                  </ListSubheader>
                  {contra.map((field) => (
                    <MenuItem
                      value={field.user_id}
                      key={field.compacctnum}
                    >
                      {field.compname}
                    </MenuItem>
                  ))}

                  <ListSubheader style={{ backgroundColor: '#edca33' }}>
                    <strong>Owner Operator</strong>
                  </ListSubheader>
                  {ownerOperator.map((field) => (
                    <MenuItem
                      value={field.user_id}
                      key={field.compacctnum}
                    >
                      {field.compname}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <FormControl
            className="customSelect"
            fullWidth
          >
            <FormLabel>Rate</FormLabel>
            <br />
            <TextField
              margin="dense"
              className="customTextField"
              name="unit_rate"
              variant="outlined"
              required
              fullWidth
              value={inputFieldsSpecial[position] ? inputFieldsSpecial[position].unit_rate : 0}
              onChange={(event) =>
                handleChangeInput(
                  inputFieldsSpecial[position] ? inputFieldsSpecial[position].identifi : '',
                  event
                )
              }
              InputProps={{ shrink: true }}
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreate}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ModalSpecialRate);
