import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from '@lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import reducer from '../../store';
import JobsHelpersHeaderBilling from './JobsHelpersHeaderBilling';
import JobsHelpersFooterBilling from './JobsHelpersFooterBilling';
import CreateJobBilling from './tabs/CreateJobBilling';
import Container from '@mui/material/Container';
import FuseMessage from '@fuse/core/FuseMessage';
import { formatter } from '../../../../../constants';
import client from 'client';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    overflow: 'auto !important',
  },
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  // unit_id: yup.number().required(),
  // materials: yup.array().required(),
  // billperunit: yup.number().required(),
  // material_invoice_rate: yup.number().required(),
  // bill_invoice_rate: yup.number().required(),
  // material_cost_rate: yup.number().required()
});

function JobsHelpersBilling({ job_id, data, setJob_id, setEditJobBillingOpen }) {
  const history = useHistory();
  const routeParams = useParams();
  const { id } = routeParams;
  const [loading, setLoading] = useState(false);
  const [jsonGetById, setJsonGetById] = useState([]);
  const [commissionMatrix, setCommissionMatrix] = useState([])
  const [commissionMatrixChanged, setCommissionMatrixChanged] = useState(false)

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      unit_id: [],
      materials: [],
      material_cost_rate: formatter.format(0.0),
      billperunit: formatter.format(0.0),
      material_invoice_rate: formatter.format(0.0),
      bill_invoice_rate: formatter.format(0.0),
      contractor_rate_per_qty: 0,
      contractors: [],
      driver_hourly_rate: formatter.format(0.0),
      material_broker: true,
      commissions: true,
      taxable: true,
      rate_owner: formatter.format(0.0),
      rate_sub: formatter.format(0.0),
      customer_hauling: true,
    },
    resolver: yupResolver(schema),
  });

  const { setError, clearErrors } = methods;

  function handleSearchJobByIdClose() {
    setEditJobBillingOpen(false);
    setJob_id('');
  }

  const handleSearchJobById = async () => {
    if (id || job_id) {
      setLoading(true);
      try {
        const _getJobById = await client.get(`/jobs/by-id/${job_id ? job_id : id}`);
        const _jsonGetById = await _getJobById.data;

        if (_jsonGetById.msg || _jsonGetById.length == 0) {
          data ? handleSearchJobByIdClose() : history.push('/jobs');
          setLoading(false);
        }

        setJsonGetById(_jsonGetById);

        if (_jsonGetById.length > 2) { // to get commission matrix of job_billing
          const {commissionMatrix: _commissionMatrix} = _jsonGetById[2]
          if (_commissionMatrix?.length) {
            setCommissionMatrix(_commissionMatrix)
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        data ? handleSearchJobByIdClose() : history.push('/jobs');
        setLoading(false);
      }
    }
  };

  const [deletespecial, setDeleteSpecial] = useState([]);
  const job_billing_history = useRef(null);
  const scrollToBottom = () => {
    job_billing_history.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    handleSearchJobById();
    scrollToBottom();
  }, [job_id]);

  useEffect(() => {
    if (jsonGetById.length > 0 && jsonGetById[0].job_billing) {
      clearErrors();
      return;
    } else {
      setError('unit_id');
      setError('materials');
      setError('billperunit');
      setError('material_invoice_rate');
      setError('bill_invoice_rate');
    }
  }, [jsonGetById, job_id]);

  if ((id || job_id) && loading) {
    return <FuseLoading></FuseLoading>;
  }

  return (
    <FormProvider {...methods}>
      <FuseMessage />
      <Root
        header={
          <JobsHelpersHeaderBilling
            job_id={job_id}
            setJob_id={setJob_id}
            setEditJobBillingOpen={setEditJobBillingOpen}
            data={data}
            id={id}
            jsonGetById={id || job_id ? jsonGetById : []}
          />
        }
        content={
          <Container>
            <CreateJobBilling
              data={data}
              setJob_id={setJob_id}
              setEditJobBillingOpen={setEditJobBillingOpen}
              jsonGetById={jsonGetById}
              deletespecial={deletespecial}
              setDeleteSpecial={setDeleteSpecial}
              commissionMatrix={commissionMatrix}
              setCommissionMatrix={setCommissionMatrix}
              setCommissionMatrixChanged={setCommissionMatrixChanged}
            />
            <JobsHelpersFooterBilling
              job_id={job_id}
              setEditJobBillingOpen={setEditJobBillingOpen}
              setJob_id={setJob_id}
              data={data}
              id={id}
              deletespecial={deletespecial}
              jsonGetById={id || job_id ? jsonGetById : []}
              job_billing_history={job_billing_history}
              commissionMatrix={commissionMatrix}
              commissionMatrixChanged={commissionMatrixChanged}
            />
          </Container>
        }
        innerScroll
      />
    </FormProvider>
  );
}

export default withReducer('JobsApp', reducer)(JobsHelpersBilling);
