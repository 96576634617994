import { lazy } from 'react';
import Jobs from './Jobs';
import AuthGuard from '../AuthGuard';

//sets the pages display settings


const routeOrigin = '/jobs-drafts'

const TicketPage = lazy(() => AuthGuard(import('../Ticket_jobs/TicketPage')))
const JobsHelpersBilling = lazy(() => AuthGuard(import('./jobs-components/jobs-helpers-billing/JobsHelpersBilling')))
const JobsHelpers = lazy(() => AuthGuard(import('./jobs-components/jobs-helpers/JobsHelpers')))

const JobsDraftsConfig = {
  settings: {},
  routes: [
    {
      path: `${routeOrigin}/new`,
      component: JobsHelpers,
    },
    {
      path: `${routeOrigin}/by-id/:id`,
      component: JobsHelpers,
    },
    {
      path: `${routeOrigin}/billing/new/:id`,
      component: JobsHelpersBilling,
    },
    {
      path: `${routeOrigin}/billing/by-id/:id`,
      component: JobsHelpersBilling,
    },
    {
      path: `${routeOrigin}/ticket/:id`,
      component: TicketPage,
    },
    {
      path: routeOrigin,
      component: () => AuthGuard(<Jobs/>),
    },
  ],
};

export default JobsDraftsConfig;
