import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const TruckAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: [
        '/Truck/filters/:filterHandle/:TruckId?',
        '/Truck/:folderHandle/:TruckId?',
        '/Truck/Truckdetails/:filterHandle/:TruckId?',
      ],
      component: lazy(() => import('./TruckApp')),
    },
    {
      path: '/Truck',
      component: () => <Redirect to="/Truck/all" />,
    },
  ],
};

export default TruckAppConfig;
