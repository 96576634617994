import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormLabel, Grid, TextField, Typography } from '@mui/material';
import { constants } from '../../../../constants';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import new_store from 'app/store';
const moment = require('moment');

const defaultValues = {
  startDate: '',
  endDate: '',
};

export default function ModalPaymentReport({ isOpen, setIsOpen, prop_data }) {
  const [open, setOpen] = useState(isOpen);
  const dispatch = useDispatch();
  const token = localStorage.token;
  const { control, getValues, reset } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
    reset(defaultValues);
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg,
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: color,
      })
    );
  };

  const handleClickPaymentReport = async () => {
    const id = prop_data
      ? prop_data?.id
      : new_store.getState().loadz_auth?.AuthReducer?.user?.user_id;
    const { startDate, endDate } = getValues();
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');

    if (start == 'Invalid date' || end == 'Invalid date') {
      _showMessage('Please select a valid date range.', 'error');
      return;
    }

    try {
      const response = await fetch(`${constants.URLLOCAL}/reports/report-loadz/0`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: `${token}`,
        },
        body: JSON.stringify({
          reference: id,
          startDate: start,
          endDate: end,
        }),
      });

      const responseLocation = await response.json();

      const report = await fetch(`${constants.URLLOCAL}/payments/payments-report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: `${token}`,
        },
        body: JSON.stringify({
          data: responseLocation.reportSubLocation,
        }),
      });

      if (report.status === 200) {
        const blob = await report.blob();
        saveAs(blob, 'Payment_Report.xlsx');
        return;
      }
    } catch (error) {
      console.log(error);
      _showMessage(
        'No payment records found for the chosen date range. Please select a different range or try again.',
        'error'
      ); //TODO update this message
      return;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <DialogTitle>Credit & Bank Transfer Settlement Report</DialogTitle>

        <DialogContent>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}
          >
            <div
              style={{
                padding: '0px 15px 10px 10px',
              }}
            >
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value}
                    type="date"
                    required
                    onChange={onChange}
                    renderInput={(_props) => (
                      <>
                        <FormLabel
                          style={{ display: 'flex', justifyContent: 'flex-end' }}
                          required
                        >
                          Start Date{' '}
                        </FormLabel>

                        <TextField
                          label="Start Date"
                          required
                          {..._props}
                          fullWidth
                          variant="outlined"
                        />
                      </>
                    )}
                  />
                )}
              />
            </div>

            <div
              style={{
                padding: '0px 15px 10px 10px',
              }}
            >
              <Controller
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value}
                    type="date"
                    required
                    onChange={onChange}
                    renderInput={(_props) => (
                      <>
                        <FormLabel
                          style={{ display: 'flex', justifyContent: 'flex-end' }}
                          required
                        >
                          End Date{' '}
                        </FormLabel>

                        <TextField
                          label="End Date"
                          className="mx-4"
                          required
                          {..._props}
                          fullWidth
                          variant="outlined"
                        />
                      </>
                    )}
                  />
                )}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              border: '1px solid black',
              mr: 1,
              backgroundColor: '#EECB33',
            }}
            onClick={handleClose}
          >
            <Typography style={{ color: '#000000' }}>Cancel</Typography>
          </Button>
          <Button
            sx={{
              border: '1px solid black',
              mr: 1,
              backgroundColor: '#EECB33',
            }}
            onClick={handleClickPaymentReport}
          >
            <Typography style={{ color: '#000000' }}>Download</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
