import { useState, useEffect, memo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import client from 'client';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ModalCreateMaterials({ id, isOpen, setIsOpen }) {
  const [open, setOpen] = useState(isOpen);
  const [batches, setbatches] = useState([]);
  const token = localStorage.token;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [zero, setZero] = useState(false);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState(false);
  const [batchesinfo, setbatchesinfo] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = async () => {
    let _rows = [];
    // console.log("Busca batches del jobs")
    const jobs = await client.get(`/batch/get-one-batches/${id}`);

    const jsonJobs = await jobs.data;

    if (jsonJobs && jsonJobs.length > 0) {
      setbatches(jsonJobs[0]?.batches);
    }
    setbatchesinfo(true);
  };

  useEffect(() => {
    setOpen(isOpen);
    getData();
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const searchBatches = () => {
    setSearch(true);
    //    getData()
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="job-notes batch-dialog"
      >
        <DialogTitle>
          Batch
          <div>
            <Typography onClick={handleClose}>
              <CloseIcon />
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          {/* <Button color="secondary" onClick={searchBatches}>Search</Button> */}
          {!batchesinfo ? (
            <div className="flex flex-col flex-1 items-center justify-center p-24 ">
              <Typography
                className="hidden md:flex px-16 pb-24 text-16 text-center"
                color="textSecondary"
              >
                Presione search!..
              </Typography>
            </div>
          ) : (
            <>
              <Grid>
                <br />
                <Paper sx={{ width: '100%', height: '100%' }}>
                  <TableContainer sx={{ maxHeight: 10000 }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableRow>
                        <StyledTableCell style={{ top: 57 }}>Batch No.</StyledTableCell>
                        <StyledTableCell style={{ top: 57 }}>Ticket Count.</StyledTableCell>
                        <StyledTableCell style={{ top: 57 }}>Package Hauling.</StyledTableCell>
                        <StyledTableCell style={{ top: 57 }}>Package Material.</StyledTableCell>
                        <StyledTableCell style={{ top: 57 }}>Package Wait Time.</StyledTableCell>
                      </TableRow>
                      {batches && batches.length > 0 ? (
                        <TableBody>
                          {batches
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((n) => {
                              return (
                                <StyledTableRow>
                                  <StyledTableCell
                                    className="p-3 md:p-12"
                                    component="th"
                                    scope="row"
                                  >
                                    {n.batch}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className="p-4 md:p-16"
                                    component="th"
                                    scope="row"
                                  ></StyledTableCell>

                                  <StyledTableCell
                                    className="p-4 md:p-16"
                                    component="th"
                                    scope="row"
                                  >
                                    {n.totalTicketCost}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className="p-4 md:p-16"
                                    component="th"
                                    scope="row"
                                  >
                                    {n.totalMaterialCost}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className="p-4 md:p-16"
                                    component="th"
                                    scope="row"
                                  >
                                    {n.totalWaitTime}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                  {/* <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    // count={batches.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}
                </Paper>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(ModalCreateMaterials);
