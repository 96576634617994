import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormContext, Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import Maps from '../../Maps/Maps';
import { memo, useEffect, useState } from 'react';
import ModalCreateCustomer from 'app/main/Customers/ModalCreateCustomer';
import { Divider, Fab, FormControlLabel, InputAdornment, Tooltip, Typography } from '@mui/material';
import { constants } from '../../../../../../constants';
import ModalCreateLocations from 'app/main/locations/ModalCreateLocationsQuarryPit';
import ModalCreateLocationsDestination from 'app/main/locations/ModalCreateLocationsDestination';
import AddIcon from '@mui/icons-material/Add';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import { connect } from 'react-redux';
import client from 'client';
import store from 'app/store';

function CreateJob({
  jsonGetById,
  user,
  setJob_id,
  setEditJobBillingOpen,
  setEditJobOpen,
  job_id,
  prop_data,
  onChangeText,
  setOnChangeText,
  onInputText,
  setOnInputText,
}) {
  const [locations, setLocations] = useState([]);
  const [locationsDestination, setLocationsDestination] = useState([]);
  const [locationsOrigin, setLocationsOrigin] = useState([]);
  const [locationStatus, setLocationStatus] = useState(true);
  const [locationsDesi, setLocationsDesi] = useState([]);
  const [createCustomer, setCreateCustomer] = useState(false);
  const [waypoint, setWaypoint] = useState([]);
  const [createLocation, setCreateLocation] = useState(false);
  const [createLocationDes, setCreateLocationDes] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const [isOpenLocationDesi, setIsOpenLocationDesi] = useState(false);
  const [isOpenLocationDes, setIsOpenLocationDes] = useState(false);
  const [verd, setverd] = useState();
  const [updateOrigin, setUpdateOrigin] = useState(false);
  const [updateOriginhidden, setUpdateOriginHidden] = useState(false);
  const [customers, setCustomers] = useState([]);
  const token = localStorage.token;
  const [locationQuarry, setLocationQuarry] = useState(null);
  const [locationDestination, setLocationDestination] = useState(null);
  const [checkDesi, setCheckDesi] = useState(false);
  const dispatch = useDispatch();
  const [loadMap, setLoadMap] = useState(false);
  const [checked, setChecked] = useState(false);
  const [jobtype, setJobtype] = useState(null);
  const [data, setData] = useState(null);
  const [ltype, setLtype] = useState([]);
  const tooltipAlert = ltype.l_type;
  const [origin, setOrigin] = useState([]);
  const methods = useFormContext();
  const { control, formState, setValue, getValues, setError } = methods;
  const { errors } = formState;
  const [scale, setScale] = useState(
    jsonGetById && jsonGetById.length > 0
      ? jsonGetById?.[0]?.scale
        ? true
        : false
      : user?.company?.use_tk_scale
      ? true
      : false
  );
  const [showCustName, setShowCustName] = useState(
    jsonGetById && jsonGetById.length > 0
      ? jsonGetById?.[0]?.job_show_cust_on_tks
        ? true
        : false
      : user?.company?.show_cust_on_tks
      ? true
      : false
  );
  const [idlocations, setIdlocations] = useState();
  const history = useHistory();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      full_warning: false,
      fulf_total: false,
      first_load: false,
      delays: false,
      name: '',
      email: '',
      phone: '',
    },
  ]);

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        (i[event.target.name] = event.target.value),
          (i[event.target.email] = event.target.value),
          (i[event.target.phone] = event.target.value);
      }
      setInputFields([
        ...inputFields,
        {
          id: uuidv4(),
          full_warning: false,
          fulf_total: false,
          first_load: false,
          delays: false,
          name: '',
          email: '',
          phone: '',
        },
      ]);
      setValue('notification', inputFields);
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInputwarning = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        (i.full_warning = event.target.checked), console.log(i.full_warning);
      }
      setInputFields([
        ...inputFields,
        {
          id: uuidv4(),
          full_warning: false,
          fulf_total: false,
          first_load: false,
          delays: false,
          name: '',
          email: '',
          phone: '',
        },
      ]);
      setValue('notification', inputFields);
      return i;
    });
    setInputFields(newInputFields);
    console.log(newInputFields);
  };

  const handleChangeInputfulf_total = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        (i.fulf_total = event.target.checked), console.log(i.fulf_total);
      }
      setInputFields([
        ...inputFields,
        {
          id: uuidv4(),
          full_warning: false,
          fulf_total: false,
          first_load: false,
          delays: false,
          name: '',
          email: '',
          phone: '',
        },
      ]);
      setValue('notification', inputFields);
      return i;
    });
    setInputFields(newInputFields);
    console.log(newInputFields);
  };

  const handleChangeInputfirst_load = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        (i.first_load = event.target.checked), console.log(i.first_load);
      }
      setInputFields([
        ...inputFields,
        {
          id: uuidv4(),
          full_warning: false,
          fulf_total: false,
          first_load: false,
          delays: false,
          name: '',
          email: '',
          phone: '',
        },
      ]);
      setValue('notification', inputFields);
      return i;
    });
    setInputFields(newInputFields);
    console.log(newInputFields);
  };

  const handleChangeInputdelays = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        (i.delays = event.target.checked), console.log(i.delays);
      }
      setInputFields([
        ...inputFields,
        {
          id: uuidv4(),
          full_warning: false,
          fulf_total: false,
          first_load: false,
          delays: false,
          name: '',
          email: '',
          phone: '',
        },
      ]);
      setValue('notification', inputFields);
      return i;
    });
    setInputFields(newInputFields);
    console.log(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        full_warning: false,
        fulf_total: false,
        first_load: false,
        delays: false,
        name: '',
        email: '',
        phone: '',
      },
    ]);
    setValue('notification', inputFields);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    setValue('notification', values);
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const getAllCustomers = async () => {
    let customer = [];
    try {
      console.log('CUSTOMERS');
      const _customers = await client.get(`/customers/${prop_data?.id}`);
      const jsonCustomers = await _customers.data;

      customer = jsonCustomers.map((e) => {
        return { value: e.customers_id, label: e.customer_name };
      });
      customer.unshift({ value: 0, label: 'Create customer' });
      setCustomers(customer);
    } catch (error) {
      console.log(error);
      customer = [{ value: 0, label: 'Create customer' }];
    }
  };

  const getAllLocations = async () => {
    const { user_id: id } = user;
    let loca = [];
    let desti = [];
    try {
      const _locations = await client.post(`/locations/all`, {
        id: prop_data ? prop_data?.id : id,
      });
      const _locationsDesi = await client.post(`/locations/locations-desitination/all`, {
        id: prop_data?.id ? prop_data?.id : id,
      });
      const jsonLocations = await _locations.data;
      const jsonLocationsDesi = await _locationsDesi.data;
      setLocationsOrigin(jsonLocations);
      setLocationsDesi(jsonLocationsDesi);
      loca = [];
      desti = [];

      let filter = jsonLocations.filter((e) => e.l_type.includes('Quarry Pit'));
      let filterDestination = jsonLocationsDesi.filter((e) => e.l_type.includes('Destination'));

      loca = filter.map((e) => {
        return { value: e && e.l_id ? e.l_id : null, label: e.l_name, type: e.l_type };
      });

      desti = filterDestination.map((e) => {
        return { value: e && e.l_id ? e.l_id : null, label: e.l_name };
      });
      loca.unshift({ value: 0, label: 'Create location' });
      desti.unshift({ value: 0, label: 'Create Destination' });
      setLocations(loca);
      setLocationsDestination(desti);
    } catch (error) {
      loca = [{ value: 0, label: 'Create location' }];
      desti = [{ value: 0, label: 'Create Destination' }];
      setLocations(loca);
      setLocationsDestination(desti);
    }
  };

  const handleCreateLocationOrigin = async () => {
    //
    //TODO Confirmar si esta es para Quarry Pit y Origins o solamente para QuarryPits.
    const { user_id: id } = user;
    const company_id = prop_data?.id ? prop_data?.id : id;

    const l_name = getValues('quarry_pit')?.[0]
      ? getValues('quarry_pit')?.[0]
      : origin?.length
      ? origin
      : null;
    if (!l_name) {
      _showMessage('Please select a location!', 'error');
      return;
    }
    try {
      const response = await client.post(`${constants.URLLOCAL}/locations/`, {
        l_name,
        company_id,
      });
      const res = await response.data;
      if (res && res.l_id) {
        setValue('quarry_pit', [
          {
            value: res ? res.l_id : null,
            label: res ? res.l_name : null,
            type: res ? res.l_type : null,
          },
        ]);
        setLtype(res);
      }
      console.log(res);
      if (res.error) {
        _showMessage('Failed to update the origin location. Please try again', 'error'); //TODO Improved Message
        return;
      }
      _showMessage('Origin Quarry Pit location successfully updated!', 'success'); //TODO Improved Message
      setUpdateOrigin(true);
    } catch (error) {
      _showMessage('Failed to update the origin location. Please try again', 'error'); //TODO Improved Message
    }
  };

  const handleCreateLocation = async () => {
    try {
      const response = await client.post(
        `/locations/${prop_data ? prop_data?.id : ''}`,
        getValues()
      );
      const res = await response.data;
      if (res && res.l_id) {
        setIdlocations(res.l_id);
        setValue('j_destination', res.l_id);
      }
      console.log(res);
      if (res.error) {
        setverd(true);
        _showMessage('Failed to add or update the destination location. Please try again', 'error'); //TODO Improved Message
        return;
      }
      _showMessage('Destination location updated successfully!', 'success'); //TODO Improved Message
      // reset(clearForm)
      setverd(true);
    } catch (error) {
      setverd(true);
      _showMessage(
        'Unexpected error while processing the destination location. Please try again.',
        'error'
      ); //TODO Improved Message
    }
  };

  const handleChange = (event) => {
    setChecked(checked ? false : true);
    setValue('visibility', checked);
  };

  const handle = (e) => {
    setValue('scale', e.target.checked);
    setScale(e.target.checked);
  };

  const handleShow = (e) => {
    setValue('job_show_cust_on_tks', e.target.checked);
    setShowCustName(e.target.checked);
  };

  useEffect(async () => {
    getAllCustomers();
    getAllLocations();

    if (jsonGetById.length > 0) {
      console.log('jsonGetById == ', jsonGetById);
      const {
        customer_name,
        customers_id,
        j_destination,
        j_fullfillment_total,
        j_fullfillment_warning,
        j_notes,
        j_origin,
        quarry_pit,
        visibility,
        po,
        scale,
        notification,
        job_type,
        job_show_cust_on_tks,
      } = jsonGetById[0];
      setJobtype(job_type);

      setValue('job_c_id', []);

      if (jsonGetById[0]) {
        setValue('job_c_id', [{ value: customers_id, label: customer_name }]);
        setValue('quarry_pit', [
          {
            value: quarry_pit ? quarry_pit.l_id : j_origin.l_id,
            label: quarry_pit ? quarry_pit.l_name : j_origin.l_name,
            type: quarry_pit ? quarry_pit.l_type : j_origin.l_type,
          },
        ]);
        setValue('j_fullfillment_warning', j_fullfillment_warning);
        setValue('j_fullfillment_total', j_fullfillment_total);
        setValue('po', po);
        setIdlocations(j_destination && j_destination.l_id ? j_destination.l_id : null);
        setLtype(quarry_pit);
        setValue('scale', scale);
        setValue('job_show_cust_on_tks', job_show_cust_on_tks);
        setScale(scale);
        setValue('j_notes', j_notes);
        setverd(true);
        setUpdateOrigin(true);
        setValue('j_destination', j_destination && j_destination.l_id ? j_destination.l_id : null);
        setValue('l_name', j_destination.l_name);
        setChecked(visibility ? false : true);
        setOnInputText(j_destination.l_name);
        setOnChangeText(j_destination.l_name);
        if (notification.length >= 1) {
          setInputFields(notification);
          setValue('notification', notification);
        }

        console.log('j_destination', j_destination);

        setTimeout(() => {
          setLocationQuarry({
            name: quarry_pit ? quarry_pit.l_name : j_origin.l_name,
            lat: quarry_pit ? quarry_pit.l_latitude : j_origin.l_latitude,
            lng: quarry_pit ? quarry_pit.l_longitude : j_origin.l_longitude,
          });
          setLocationDestination({
            name: j_destination.l_name,
            lat: j_destination.l_latitude,
            lng: j_destination.l_longitude,
            l_id: j_destination.l_id,
            company_id: j_destination.company_id,
          });
        }, 2000);
      }
    } else {
      setValue(
        'scale',
        jsonGetById && jsonGetById.length > 0
          ? jsonGetById?.[0]?.scale
            ? true
            : false
          : user?.company?.use_tk_scale
          ? true
          : false
      );
      setValue(
        'job_show_cust_on_tks',
        jsonGetById && jsonGetById.length > 0
          ? jsonGetById?.[0]?.job_show_cust_on_tks
            ? true
            : false
          : user?.company?.show_cust_on_tks
          ? true
          : false
      );
    }
  }, [jsonGetById]);

  useEffect(() => {
    setIsOpen(false);
    if (createCustomer) {
      setIsOpen(true);
      //setValue('job_c_id', []);
    }
  }, [createCustomer]);

  useEffect(async () => {
    !isOpen ? setCreateCustomer(false) : null;
    getAllCustomers();
  }, [isOpen]);

  useEffect(() => {
    setIsOpenLocation(false);
    if (createLocation) {
      setIsOpenLocation(true);
      setValue('quarry_pit', []);
    }
  }, [createLocation]);

  useEffect(() => {
    setIsOpenLocationDes(false);
    if (createLocationDes) {
      setIsOpenLocationDes(true);
      setValue('quarry_pit', []);
    }
  }, [createLocationDes]);

  useEffect(async () => {
    !isOpenLocationDes ? setCreateLocationDes(false) : null;
    getAllLocations();
  }, [isOpenLocationDes]);

  useEffect(async () => {
    !isOpenLocation ? setCreateLocation(false) : null;
    getAllLocations();

    if (isOpenLocation == false) {
      if (data) {
        setValue('quarry_pit', [
          { value: data ? data.l_id : null, label: data ? data.l_name : null, type: data.l_type },
        ]);
        setLtype(data);
        setLocationQuarry({
          name: data.l_name,
          lat: data.l_latitude,
          lng: data.l_longitude,
          l_Address: data.l_address,
          l_City: data.l_city,
          l_State: data.l_state,
          l_Type: data.l_type,
        });
      }
    }
  }, [isOpenLocation, data]);

  const openDestination = (event) => {
    setIsOpenLocationDes(true);
    setCheckDesi(true);
    if (createLocationDes) {
      setIsOpenLocationDes(true);
    }
    !isOpenLocationDes ? setCreateLocationDes(false) : null;
  };

  const openOrigin = (event) => {
    setIsOpenLocation(true);
  };

  const setNewDestinationLocation = (data) => {
    console.log('setNewDestinationLocation data : ', data);
    setValue('l_name', data ? data.name : null);
    setDestiAutoComplete(data ? data.name : null);
    setIdlocations(data ? data.l_id : null);
    setLocationDestination(data);
  };

  useEffect(() => {
    if (locationDestination) {
      setValue('l_name', locationDestination ? locationDestination.name : null);
      setOnChangeText(locationDestination ? locationDestination.name : null);
      setOnInputText(locationDestination ? locationDestination.name : null);
    }
  }, [locationDestination]);

  const [destiAutoComplete, setDestiAutoComplete] = useState(null);

  useEffect(() => {
    setDestiAutoComplete(getValues('l_name'));
  }, [getValues('l_name')]);

  useEffect(() => {
    setValue('j_destination', idlocations);
  }, [idlocations]);

  return (
    <div>
      <ModalCreateCustomer
        data={prop_data}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></ModalCreateCustomer>

      <ModalCreateLocations
        company_id={prop_data ? prop_data.id : store.getState().loadz_auth.AuthReducer.user.user_id}
        data={prop_data}
        isOpen={isOpenLocation}
        setIsOpen={setIsOpenLocation}
        setData={setData}
        info={ltype}
        setLocationStatus={setLocationStatus}
      ></ModalCreateLocations>

      <ModalCreateLocationsDestination
        company_id={prop_data ? prop_data.id : store.getState().loadz_auth.AuthReducer.user.user_id}
        id={idlocations}
        isOpen={isOpenLocationDes}
        setIsOpen={setIsOpenLocationDes}
        setNewLocation={setNewDestinationLocation}
        locationDestination={locationDestination}
        querryPit={locationQuarry}
        checkDes={checkDesi}
        waypoint={waypoint}
      ></ModalCreateLocationsDestination>

      <div className="flex w-full max-w-screen-sx justify-center newjob mb-20">
        <div className="w-1/2	newjob-form">
          <div className="flex mx-0 flex-wrap items-center">
            {jsonGetById.length > 0 && (
              <div className="mb-10 disable-job">
                <Typography className="font-Regular text-12">Disable Job</Typography>
                <Controller
                  name="visibility"
                  type="checkbox"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      disabled={jobtype == 'sub' ? true : false}
                      checked={checked}
                      onClick={(ev) => {
                        handleChange(ev);
                      }}
                    />
                  )}
                />
              </div>
            )}

            <Controller
              name="job_c_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  style={
                    jsonGetById.length > 0
                      ? {
                          paddingLeft: 20,
                        }
                      : {}
                  }
                  disabled={jobtype == 'sub' ? true : false}
                  className="w-1/2 mt-0 mb-0 customers px-5 pr-0 select-coustmer"
                  multiple
                  freeSolo={value.length == 1 ? false : true}
                  getOptionDisabled={(options) => (value.length == 1 ? true : false)}
                  options={customers}
                  value={value}
                  onChange={(event, newValue) => {
                    setCreateCustomer(false);
                    console.log(newValue);
                    newValue.map((r) => {
                      if (r.value == 0) {
                        setCreateCustomer(true);
                      } else {
                        onChange(newValue);
                      }
                    });
                    if (!newValue.length) {
                      onChange(newValue);
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <>
                      <InputLabel htmlFor="input-with-icon-adornment"> Select customer </InputLabel>
                      <TextField
                        disabled={jobtype == 'sub' ? true : false}
                        {...params}
                        autoFocus
                        id={'customers'}
                        placeholder="Select customer"
                        className="w-1/2 mt-2 mb-16 mx-0 margin-x-0 pr-10"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  )}
                  limitTags={1}
                />
              )}
            />

            <div className="w-1/2 flex-col mx-0 margin-x-0 px-5 purchase-oder">
              <Controller
                name="po"
                control={control}
                render={({ field }) => (
                  <>
                    <InputLabel htmlFor="input-with-icon-adornment"> Purchase Order </InputLabel>
                    <Tooltip
                      title="“Purchase Order is an optional field. Examples : “PO-1A95”, “#15” , “80-19”, “101”."
                      placement="top"
                    >
                      <TextField
                        {...field}
                        disabled={jobtype == 'sub' ? true : false}
                        className="mt-2 mb-16 mx-0 margin-x-0"
                        id="po"
                        placeholder="Purchase Order"
                        fullWidth
                      />
                    </Tooltip>
                  </>
                )}
              />
            </div>

            {jsonGetById && jsonGetById.length > 0 && (
              <TextField
                className="mx-0 margin-x-0 custom-textfeild pl-10"
                placeholder="Job No."
                type={'number'}
                value={jsonGetById[0].job_no}
                disabled={true}
                variant="outlined"
                fullWidth
              />
            )}
          </div>

          <div className="flex mx-0 flex-wrap">
            <div className="w-1/2 flex justify-center destination-right mt-8 mb-16 px-7">
              <div className="flex flex-col margin-x-0 w-full">
                <Controller
                  name="quarry_pit"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={
                        jobtype == 'sub' ? true : !getValues('job_c_id').length ? true : false
                      }
                      multiple
                      freeSolo={value && value.length == 1 ? false : true}
                      getOptionDisabled={(options) => (value && value.length == 1 ? true : false)}
                      options={locations}
                      value={value}
                      onBlur={(e) => {
                        setOrigin(e.target.value), console.log(e.target.value);
                      }}
                      onChange={(event, newValue) => {
                        console.log('newValue', newValue);
                        // if(!newValue.length)
                        // {
                        //   setIdlocations(null);
                        //   setLocationDestination(null);
                        //   setValue('l_name',null);
                        // }
                        setCreateLocation(false);
                        setUpdateOriginHidden(false);
                        setUpdateOrigin(false);
                        newValue.map((r) => {
                          if (r.value == 0) {
                            setLtype([]);
                            setCreateLocation(true);
                          } else {
                            locationsOrigin.forEach((loc) => {
                              if (loc && loc.l_id === r.value) {
                                setLocationQuarry({
                                  name: loc.l_name,
                                  lat: loc.l_latitude,
                                  lng: loc.l_longitude,
                                  l_Address: loc.l_address,
                                  l_City: loc.l_city,
                                  l_State: loc.l_state,
                                  l_Type: loc.l_type,
                                });
                                setLtype([]);
                                setUpdateOriginHidden(true);
                              }
                            });
                          }
                        });
                        onChange(newValue);
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <>
                          <InputLabel htmlFor="input-with-icon-adornment">
                            {' '}
                            Select Quarry Pit or Create an Origin{' '}
                          </InputLabel>
                          <TextField
                            disabled={
                              jobtype == 'sub' ? true : !getValues('job_c_id').length ? true : false
                            }
                            {...params}
                            id={'locations'}
                            placeholder="Select Quarry Pit or Create an Origin"
                            className="mt-2 mb-0 mx-0 selectqp margin-x-0"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </>
                      )}
                      limitTags={1}
                    />
                  )}
                />
              </div>

              {!updateOrigin && (
                <>
                  {locationStatus ? (
                    <div
                      className="mt-8 mb-16 mx-0 margin-x-0 location-btn"
                      style={{ alignSelf: 'center' }}
                    >
                      <InputAdornment position="start">
                        <Tooltip title="Create Origin">
                          <Fab
                            /* disabled={jobtype == 'sub' ? true : false} */
                            disabled={
                              jobtype == 'sub' ? true : !getValues('job_c_id').length ? true : false
                            }
                            color="primary"
                            size="small"
                            onClick={handleCreateLocationOrigin}
                            hidden={updateOriginhidden}
                          >
                            <AddIcon />
                          </Fab>
                        </Tooltip>
                      </InputAdornment>
                    </div>
                  ) : (
                    <>
                      {tooltipAlert &&
                      tooltipAlert !== null &&
                      tooltipAlert !== undefined &&
                      tooltipAlert == 'Quarry Pit' ? null : (
                        <div
                          className="mt-8 mb-16 mx-0 margin-x-0 location-btn"
                          style={{ alignSelf: 'center' }}
                        >
                          <InputAdornment position="start">
                            <Tooltip
                              title={
                                tooltipAlert && tooltipAlert !== undefined
                                  ? `Update ${tooltipAlert}`
                                  : 'Update Origin'
                              }
                            >
                              <Fab
                                disabled={
                                  jobtype == 'sub'
                                    ? true
                                    : !getValues('job_c_id').length
                                    ? true
                                    : false
                                }
                                color="primary"
                                size="small"
                                onClick={openOrigin}
                                hidden={updateOriginhidden}
                              >
                                <EditLocationAltIcon />
                              </Fab>
                            </Tooltip>
                          </InputAdornment>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {updateOrigin && (
                <>
                  {tooltipAlert &&
                  tooltipAlert !== null &&
                  tooltipAlert !== undefined &&
                  tooltipAlert == 'Quarry Pit' ? null : (
                    <div
                      className="mt-8 mb-16 mx-0 margin-x-0 location-btn"
                      style={{ alignSelf: 'center' }}
                    >
                      <InputAdornment position="start">
                        <Tooltip
                          title={
                            tooltipAlert && tooltipAlert !== undefined
                              ? `Update ${tooltipAlert}`
                              : 'Update Origin'
                          }
                        >
                          <Fab
                            disabled={
                              jobtype == 'sub' ? true : !getValues('job_c_id').length ? true : false
                            }
                            color="primary"
                            size="small"
                            onClick={openOrigin}
                            hidden={updateOriginhidden}
                          >
                            <EditLocationAltIcon />
                          </Fab>
                        </Tooltip>
                      </InputAdornment>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="w-1/2 flex justify-center destination-right mt-8 mb-16 px-5 pl-10">
              <div className="flex flex-col margin-x-0 w-full">
                <Autocomplete
                  disabled={
                    jobtype == 'sub'
                      ? true
                      : !locationQuarry || !getValues('quarry_pit').length
                      ? true
                      : false
                  }
                  freeSolo={true}
                  value={destiAutoComplete}
                  options={locationsDestination}
                  onInput={(e) => {
                    setOnInputText(e.target.value);
                    //console.log('e.target.value',e.target.value) insert a useMemo?
                    if (e.target.value != onChangeText) {
                      setIdlocations(null);
                      setLocationDestination(null);
                    }
                  }}
                  onChange={(event, newValue) => {
                    //console.log('newValue type', typeof newValue)
                    //console.log('newValue type', newValue)

                    if (newValue && typeof newValue == 'object') {
                      newValue && newValue.label
                        ? setOnChangeText(newValue.label)
                        : setOnChangeText('');
                    }
                    if (newValue == null) {
                      setOnChangeText('');
                    }
                    if (newValue && typeof newValue == 'string') {
                      setOnChangeText(newValue);
                    }

                    setIdlocations(null);
                    setLocationDestination(null);
                    if (newValue) {
                      if (newValue.value == 0) {
                        setIsOpenLocationDes(true);
                        setValue('l_name', null);
                      }
                      if (newValue.value != 0) {
                        locationsDesi.forEach((loc) => {
                          if (loc && loc.l_id === newValue.value) {
                            console.log('loc', loc);
                            setLocationDestination({
                              name: loc.l_name,
                              lat: loc.l_latitude,
                              lng: loc.l_longitude,
                              l_address: loc.l_address,
                              l_city: loc.l_city,
                              l_state: loc.l_state,
                              l_type: loc.l_type,
                              l_zip: loc.l_zip,
                              l_id: loc.l_id,
                              company_id: loc.company_id,
                            });
                            setIdlocations(loc.l_id);
                          }
                        });
                        setValue('l_name', newValue.label);
                      }
                    } else {
                      setValue('l_name', null);
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <>
                      <InputLabel htmlFor="input-with-icon-adornment">Destination</InputLabel>
                      <TextField
                        disabled={jobtype == 'sub' ? true : false}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                            }
                          },
                        }}
                        id={'locationsDestination'}
                        placeholder="Destination"
                        className="mt-2 mb-0 mx-0 selectqp margin-x-0"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  )}
                />
              </div>

              {locationDestination ? (
                <div
                  className="mt-8 mb-16 mx-0 margin-x-0 location-btn flex flex-row justify-end w-full pr-10"
                  style={{ alignSelf: 'center' }}
                >
                  <InputAdornment position="start">
                    <Tooltip title="Update Destination">
                      <Fab
                        disabled={jobtype == 'sub' ? true : false}
                        color="primary"
                        size="small"
                        onClick={() => {
                          setIsOpenLocationDes(true);
                        }}
                      >
                        <EditLocationAltIcon />
                      </Fab>
                    </Tooltip>
                  </InputAdornment>
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex mx-0 margin-x-0 flex-wrap">
            <div className="w-1/2 flex flex-col mx-0 margin-x-0 px-5 pr-10 fullwidth">
              <Controller
                name="j_fullfillment_warning"
                control={control}
                render={({ field }) => (
                  <>
                    <InputLabel htmlFor="input-with-icon-adornment">
                      {' '}
                      FulFillment Warning{' '}
                    </InputLabel>
                    <Tooltip
                      title="Fulfillment Warnings alert you when the delivered quantity matches or exceeds the set warning value." /* TODO Review before Live. Improved Message */
                      placement="right-start"
                    >
                      <TextField
                        {...field}
                        disabled={jobtype == 'sub' ? true : false}
                        className="mt-2 mb-0 mx-0 margin-x-0"
                        // label="FulFillment Warning"
                        placeholder="FulFillment Warning"
                        type={'number'}
                        id="j_fullfillment_warning"
                        variant="outlined"
                        fullWidth
                      />
                    </Tooltip>
                  </>
                )}
              />
            </div>
            <div className="w-1/2 flex flex-col mx-0 margin-x-0 px-5 pl-10 fullwidth">
              <Controller
                name="j_fullfillment_total"
                control={control}
                render={({ field }) => (
                  <>
                    <InputLabel htmlFor="input-with-icon-adornment"> FulFillment Total </InputLabel>
                    <Tooltip
                      title="Fulfillment Totals alert you when the delivered quantity meets or exceeds the specified total value." /* TODO Review before Live. Improved Message */
                      placement="right-start"
                    >
                      <TextField
                        {...field}
                        disabled={jobtype == 'sub' ? true : false}
                        className="mt-2 mb-0 mx-0 margin-x-0"
                        // label="FulFillment Total"
                        id="j_fullfillment_total"
                        placeholder="FulFillment Total"
                        variant="outlined"
                        fullWidth
                        type={'number'}
                      />
                    </Tooltip>
                  </>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col mx-0 margin-x-0 px-5 mt-16">
            <Controller
              className="notes-f"
              name="j_notes"
              control={control}
              render={({ field }) => (
                <>
                  {/* <InputLabel htmlFor="input-with-icon-adornment"> Notes </InputLabel> */}
                  <TextField
                    {...field}
                    disabled={jobtype == 'sub' ? true : false}
                    className="mt-2 mb-16 mx-0 margin-x-0 notes customnotes"
                    id="j_notes"
                    label="Notes"
                    placeholder="Notes"
                    error={!!errors.j_notes}
                    helperText={errors?.j_notes?.message}
                    // variant="outlined"
                    // fullWidth
                    multiline
                    // rows={6}
                  />
                </>
              )}
            />
          </div>
          <div className="flex mx-6 checkbox-text">
            <Tooltip
              title="If enabled, Scale Tickets will mandate drivers to provide a Scale No. and offer an option to upload the Scale Ticket image." //TODO improved Message. Review before LIVE.
              placement="top"
            >
              <FormControlLabel
                // label="Scale"
                // label="Enable tickets on this job to require the Scale Ticket on the Loadz E-Ticketing mobile app."
                label="Scale Ticket Number Required ? "
                control={
                  //
                  <Checkbox
                    disabled={jobtype == 'sub' ? true : false}
                    checked={scale}
                    onChange={handle}
                  />
                }
              />
            </Tooltip>

            <Tooltip
              title="If enabled, Tickets will display the job's customer name." //TODO improved Message. Review before LIVE.
              placement="top"
            >
              <FormControlLabel
                // label="Scale"
                // label="Enable tickets on this job to require the Scale Ticket on the Loadz E-Ticketing mobile app."
                label="Display Customer Name on Driver Tickets ? "
                control={
                  <Checkbox
                    //disabled={jobtype == 'sub' ? true : false}
                    checked={showCustName}
                    onChange={handleShow}
                  />
                }
              />
            </Tooltip>
          </div>
        </div>
        <div
          className="w-1/2	newjob-map"
          style={{ paddingLeft: 20 }}
        >
          <Maps
            destination={locationDestination}
            setWaypoint={setWaypoint}
            quarry={locationQuarry}
            setLoadMap={setLoadMap}
          ></Maps>
        </div>
      </div>
      <Divider></Divider>
      {/* <div className='flex mx-6 notification-h margin-x-0 mt-32'>
        <Typography variant="h6" gutterBottom component="div" className='font-bold'>
          Notifications
        </Typography>
      </div> */}

      <div className="notification-content">
        <Controller
          name="notification"
          control={control}
          render={({ field }) => (
            <>
              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <div className="flex mx-0 notification-content-inner margin-x-0 mt-32 items-center flex-wrap">
                    <div className="flex mx-0 notification-h margin-x-0 mr-16">
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        className="font-bold m-0"
                      >
                        Notifications
                      </Typography>
                    </div>
                    <Tooltip title="Enable to send alert when fulfillment warning is triggered. ">
                      <FormControlLabel
                        name="full_warning"
                        label="Fulfillment Warning" //link to read page
                        value={inputField.full_warning}
                        control={
                          <Checkbox
                            disabled={jobtype == 'sub' ? true : false}
                            checked={inputField.full_warning}
                            onChange={(event) => handleChangeInputwarning(inputField.id, event)}
                          />
                        }
                      />
                    </Tooltip>

                    <Tooltip title="Enable to send alert when fulfillment total is triggered">
                      <FormControlLabel
                        name="fulf_total"
                        label="Fulfillment Total" //link to read page
                        value={inputField.fulf_total}
                        control={
                          <Checkbox
                            disabled={jobtype == 'sub' ? true : false}
                            checked={inputField.fulf_total}
                            onChange={(event) => handleChangeInputfulf_total(inputField.id, event)}
                          />
                        }
                      />
                    </Tooltip>

                    <Tooltip title="Enable to send alert when the first ticket is completed on this Job. ">
                      <FormControlLabel
                        name="first_load"
                        label="First Load of the Day" //link to read page
                        value={inputField.first_load}
                        control={
                          <Checkbox
                            disabled={jobtype == 'sub' ? true : false}
                            checked={inputField.first_load}
                            onChange={(event) => handleChangeInputfirst_load(inputField.id, event)}
                          />
                        }
                      />
                    </Tooltip>

                    <Tooltip title=" Enable to send alert when a ticket is set to the delayed status. ">
                      <FormControlLabel
                        name="delays"
                        label="Delays" //link to read page
                        value={inputField.delays}
                        control={
                          <Checkbox
                            disabled={jobtype == 'sub' ? true : false}
                            checked={inputField.delays}
                            onChange={(event) => handleChangeInputdelays(inputField.id, event)}
                          />
                        }
                      />
                    </Tooltip>
                  </div>

                  <div className="flex mx-0 notification-content-inner notification-feilds bottom margin-x-0 mt-24 flex-wrap">
                    <div className="flex flex-col mx-0 pr-10 notification-inner">
                      <InputLabel htmlFor="input-with-icon-adornment"> Name </InputLabel>
                      <TextField
                        {...field}
                        disabled={jobtype == 'sub' ? true : false}
                        className="mt-2 mb-16 mx-0 margin-x-0"
                        // label="Name"
                        placeholder="Name"
                        type={'text'}
                        name="name"
                        variant="outlined"
                        value={inputField.name}
                        onChange={(event) => handleChangeInput(inputField.id, event)}
                        fullWidth
                        InputProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="flex flex-col mx-0 pr-10 notification-inner">
                      <InputLabel htmlFor="input-with-icon-adornment"> Email </InputLabel>
                      <TextField
                        {...field}
                        disabled={jobtype == 'sub' ? true : false}
                        className="mt-2 mb-16 mx-0 email margin-x-0"
                        // label="Email"
                        placeholder="Email"
                        name="email"
                        variant="outlined"
                        value={inputField.email}
                        onChange={(event) => handleChangeInput(inputField.id, event)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>,
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </div>
                    <div className="flex flex-col mx-0 pr-10 notification-inner">
                      <InputLabel htmlFor="input-with-icon-adornment"> Phone </InputLabel>
                      <TextField
                        {...field}
                        disabled={jobtype == 'sub' ? true : false}
                        className="mt-2 mb-16 mx-0 phone margin-x-0"
                        // label="Phone"
                        placeholder="Phone"
                        name="phone"
                        type={'number'}
                        variant="outlined"
                        value={inputField.phone}
                        onChange={(event) => handleChangeInput(inputField.id, event)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>,
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </div>
                    <div className="add-remobe-btn">
                      <div
                        className="mt-0 mb-16 mx-2 addbtn margin-x-0"
                        style={{ alignSelf: 'center' }}
                      >
                        <InputLabel
                          htmlFor="input-with-icon-adornment"
                          className="invisible"
                        >
                          add
                        </InputLabel>
                        <InputAdornment position="start">
                          <Tooltip title="Add Notification">
                            <Fab
                              disabled={jobtype == 'sub' ? true : false}
                              color="primary"
                              size="small"
                              onClick={handleAddFields}
                            >
                              <AddIcon />
                            </Fab>
                          </Tooltip>
                        </InputAdornment>
                      </div>

                      <div
                        className="mt-0 mb-16 mx-2 remove-btn margin-x-0"
                        style={{ alignSelf: 'center' }}
                      >
                        <InputLabel
                          htmlFor="input-with-icon-adornment"
                          className="invisible"
                        >
                          Rem
                        </InputLabel>
                        <InputAdornment position="start">
                          <Tooltip title="Remove Notification">
                            <Fab
                              color="danger"
                              size="small"
                              disabled={inputFields.length === 1 || jobtype == 'sub' ? true : false}
                              onClick={() => handleRemoveFields(inputField.id)}
                            >
                              <RemoveIcon />
                            </Fab>
                          </Tooltip>
                        </InputAdornment>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};
export default connect(mapStateToProps)(memo(CreateJob));
