import { lazy } from 'react';

const UnitCostConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/unit-cost/:job/:comp_id',
      component: lazy(() => import('./UnitCost')),
    },
  ],
};

export default UnitCostConfig;
