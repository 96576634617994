import { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useFormContext, Controller } from 'react-hook-form';

const DestinationMap = (props) => {

  const methods = useFormContext();
  const { control, formState, setValue } = methods;

  const mapRef = useRef(null)
  const {coordenadas, handleMaps, waypoint, desi, qPit} = props

  const [source, setSource] = useState(null)
  const [destination, setDestination] = useState(null)
  const [map, setMap] = useState(null)
  const [service, setService] = useState(null)
  const [renderer, setRenderer] = useState(null)

  useEffect(() => {
    setService(new window.google.maps.DirectionsService())
    setRenderer(new window.google.maps.DirectionsRenderer())
  }, [])

  useEffect(() => {
    if (qPit && map) {
      console.log(`updating source from prop`, qPit)
      const _m = new window.google.maps.Marker({
        position: {
          lat: qPit?.lat ? Number(qPit.lat) : 30.2426319,
          lng: qPit?.lng ? Number(qPit.lng) : -97.7542349
        },
        map,
      });
      setSource(_m)
    }
  }, [qPit, map])

  useEffect(() => {
    if (desi && map) {
      console.log(`updating destination from prop`, desi)
      setDestinationMarker(
        desi?.latLng?.lat ? Number(desi.latLng.lat) : 30.2426319,
        desi?.latLng?.lng ? Number(desi.latLng.lng) : -97.7542349
      )
    }
  }, [desi, map])

  const checkLongName = (objData, id) => {
    if (
      objData &&
      objData.address_components &&
      objData.address_components.length &&
      objData.address_components[id] != undefined &&
      objData.address_components.length == id &&
      objData.address_components[id].long_name
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onMapClicked = (event) => {
    console.log(
      event.latLng.lat(),
      event.latLng.lng()
    )

    setDestinationMarker(
      event.latLng.lat(),
      event.latLng.lng()
    )

    setValue('l_latitude', event.latLng.lat());
    setValue('l_longitude', event.latLng.lng());

    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: event.latLng}, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let object = results[0];
        handleAddressChange(object,{lat: event.latLng.lat(), lng: event.latLng.lng()})
      }
    });
  }

  useEffect(() => {
    if (!mapRef.current) return

    const _map = new window.google.maps.Map(mapRef.current, {
      zoom: 12,
      center: { lat: 30.2426319, lng: -97.7542349},
    })

    _map.addListener('click', onMapClicked)
    setMap(_map)

    // return () => {
    //   window.google.maps.event.removeListener(onMapClicked)
    // }
  }, [mapRef])

  useEffect(() => {
    if (map) {
      renderer.setMap(map)
    }
  }, [map])

  const setDestinationMarker = (lat, lng) => {
    if (destination) destination.setMap(null)

    const marker = new window.google.maps.Marker({
      position: {
        lat,
        lng
      },
      map,
    });
    setDestination(marker)
  }

  useEffect(() => {
    if (source && destination && map) {
      source.setMap(null)
      destination.setMap(null)
      service.route({
        origin: source.getPosition(),
        destination: destination.getPosition(),
        travelMode: window.google.maps.TravelMode.DRIVING,
        // waypoints: waypoint && waypoint.length > 0 ? waypoint : [],
        avoidTolls: true,
        // optimizeWaypoints: true,
      }).then(result=> {
        renderer.setDirections(result)
      })
    }
  }, [source, destination, map]);

  const search = () => {
    const input = document.getElementById('autocomplete-input');
    const autocomplete = new window.google.maps.places.Autocomplete(input);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry || !map) return;

      const position = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}

      if (destination) destination.setMap(null)

      const marker = new window.google.maps.Marker({
        position,
        map,
      });

      setDestination(marker)
      handleAddressChange(place, position)

    });
  };

  const handleAddressChange = (place, position) => {
    const dir = place.formatted_address;
    setValue('l_address', dir);
    const cit = checkLongName(place, 2) ? place.address_components[2].long_name : null;
    setValue('l_city', cit);
    const stat = checkLongName(place, 4) ? place.address_components[4].long_name : null;
    setValue('l_state', null ? null : stat);
    const zip = checkLongName(place, 6) ? place.address_components[6].long_name : null;
    setValue('l_zip', null ? null : zip);

    handleMaps({ latLng: { lat: position.lat, lng: position.lng}, dir, cit, stat, zip });
  }

  return (
    <div className="App">
      <Controller
        name="l_address"
        control={control}
        render={({ field }) => (
          <TextField
            id="autocomplete-input"
            label="Search Address or Coordinates (latitude , longitude)"
            variant="outlined"
            fullWidth
            onChange={search}
            style={{marginBottom: '16px'}}
          />
        )}
      />
      <div ref={mapRef} style={{ height: 512, width: '100%' }}></div>
    </div>
  );
}

export default DestinationMap
