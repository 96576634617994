import TextField from '@mui/material/TextField';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import { useFormContext, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GoogleMap, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';

function Marker({ text }) {
  return (
    <Tooltip
      title={text}
      placement="top"
    >
      <Icon className="text-green">location_on</Icon>
    </Tooltip>
  );
}

function MapsDestination(props) {
  const methods = useFormContext();
  const { control, formState, setValue } = methods;
  const [directionRes, setDirectionRes] = useState(null);
  const { errors } = formState;
  const [map, setMap] = useState();
  const [location, setlocation] = useState({});
  const [direction, setDirection] = useState('');
  const [coor, setCoor] = useState('');
  const [zoom, setZoom] = useState(9);

  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: 'AIzaSyBchBnwZ5pPht6DfEezq8uCjyHCODqpjb4',
  // });

  var lat = 0;
  var lng = 0;

  if (props.coordenadas.l_latitude && props.coordenadas.l_longitude != null) {
    const a = props.coordenadas.l_latitude;
    const b = props.coordenadas.l_longitude;
    lat = parseFloat(a);
    lng = parseFloat(b);
  } else {
    const a = '30.2426319';
    const b = '-97.7542349';
    lat = parseFloat(a);
    lng = parseFloat(b);
  }

  const checkLongName = (objData, id) => {
    if (
      objData &&
      objData.address_components &&
      objData.address_components.length &&
      objData.address_components[id] != undefined &&
      objData.address_components.length == id &&
      objData.address_components[id].long_name
    ) {
      return true;
    } else {
      return false;
    }
  };
  var latitud = location.lat;
  var longitud = location.lng;
  var latitudmov = coor.lat;
  var longitudmov = coor.lng;
  let directionsDisplay;
  const handleSelect = async (value) => {
    location.lat = 0;
    location.lng = 0;
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    setDirection(value);
    setCoor(latLng);
    setValue('l_latitude', latLng.lat);
    setValue('l_longitude', latLng.lng);

    result.find((object) => {
      const dir = object.formatted_address;
      setValue('l_address', null ? null : dir);
      const cit = checkLongName(object, 2) ? object.address_components[2].long_name : null;
      setValue('l_city', null ? null : cit);
      const stat = checkLongName(object, 4) ? object.address_components[4].long_name : null;
      setValue('l_state', null ? null : stat);
      const zip = checkLongName(object, 6) ? object.address_components[6].long_name : null;
      setValue('l_zip', null ? null : zip);

      handleMaps({ latLng, dir, cit, stat, zip });
    });
    setZoom(16);
    try {
      if (props.qPit) {
        const directionsService = new google.maps.DirectionsService();
        directionsDisplay = new google.maps.DirectionsRenderer({
          draggable: true,
        });
        displayRoute(
          props.qPit ? new window.google.maps.LatLng(props.qPit.lat, props.qPit.lng) : null,
          latLng ? new google.maps.LatLng(latLng.lat, latLng.lng) : null,
          directionsService,
          directionsDisplay
        );
      }
    } catch (e) {
      console.log('error => ', e);
    }
  };

  useEffect(async () => {
    const directionsService = new google.maps.DirectionsService();
    directionsDisplay = new google.maps.DirectionsRenderer({
      draggable: true,
    });
    displayRoute(
      props.qPit ? new window.google.maps.LatLng(props.qPit.lat, props.qPit.lng) : null,
      props.desi && props.desi.latLng
        ? new google.maps.LatLng(props.desi.latLng.lat, props.desi.latLng.lng)
        : null,
      directionsService,
      directionsDisplay,
      props.waypoint
    );
  }, [props.qPit, props.desi, props.waypoint]);

  function displayRoute(
    origin,
    destination,
    service = google.maps.DirectionsService,
    display = google.maps.DirectionsRenderer,
    waypoint
  ) {
    service
      .route({
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoint && waypoint.length > 0 ? waypoint : [],
        avoidTolls: true,
        optimizeWaypoints: true,
      })
      .then((result) => {
        setDirectionRes(result);
        display.setDirections(result);
      })
      .catch((e) => {
        console.log('Could not display directions due to: ' + e);
      });
  }

  // if(props && props.setDesi === true){
  // 	if(props.qPit){
  // 		const directionsService = new google.maps.DirectionsService();
  // 		directionsDisplay = new google.maps.DirectionsRenderer({ draggable:true });
  // 		directionsDisplay.addListener("directions_changed", () => {
  // 			const directions = directionsDisplay.getDirections();
  // 		});
  // 		displayRoute(
  // 			props.qPit ? new window.google.maps.LatLng(props.qPit.lat, props.qPit.lng) : null,
  // 			props.desi ? new google.maps.LatLng(props.desi.latLng.lat, props.desi.latLng.lng): null,
  // 			directionsService,
  // 			directionsDisplay
  // 		)
  // 	}
  // }

  // const handleOnClick = (evt) => {
  //   const { lat, lng } = evt;
  //   setlocation({ lat, lng });
  //   setValue("l_latitude", lat);
  //   setValue("l_longitude", lng);
  //   handleMaps({ lat, lng });
  //   setZoom(16);
  // };
  const handleOndbClick = (evt) => {
    let destiObj = {
      lat: evt.latLng.lat(),
      lng: evt.latLng.lng(),
    };
    setlocation({ lat: destiObj.lat, lng: destiObj.lng });
    setValue('l_latitude', destiObj.lat);
    setValue('l_longitude', destiObj.lng);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: destiObj }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let object = results[0];
        const dir = object.formatted_address;
        setValue('l_address', null ? null : dir);
        const cit = checkLongName(object, 2) ? object.address_components[2].long_name : null;
        setValue('l_city', null ? null : cit);
        const stat = checkLongName(object, 4) ? object.address_components[4].long_name : null;
        setValue('l_state', null ? null : stat);
        const zip = checkLongName(object, 6) ? object.address_components[6].long_name : null;
        setValue('l_zip', null ? null : zip);

        handleMaps({ latLng: destiObj, dir, cit, stat, zip });
      }
    });
    // handleMaps({ latLng: destiObj });
    setZoom(9);
    const directionsService = new google.maps.DirectionsService();
    directionsDisplay = new google.maps.DirectionsRenderer({
      draggable: true,
    });
    displayRoute(
      props.qPit ? new window.google.maps.LatLng(props.qPit.lat, props.qPit.lng) : null,
      destiObj ? new window.google.maps.LatLng(destiObj.lat, destiObj.lng) : null,
      directionsService,
      directionsDisplay,
      props.waypoint
    );
  };

  const getMapOptions = () => ({
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: google.maps.ControlPosition.TOP_CENTER,
    },

    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_TOP,
    },
  });

  let { handleMaps } = props;

  const center = {
    lat: latitudmov ? lat : 30.2426319,
    lng: longitudmov ? lng : -97.7542349,
  };
  return (
    <>
      <div className="w-500 h-512">
        <br />
        <Controller
          name="l_address"
          control={control}
          render={({ field }) => (
            <PlacesAutocomplete
              value={direction}
              onChange={setDirection}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <div className="flex -mx+6">
                    <TextField
                      {...getInputProps()}
                      fullWidth
                      className="mt-8 mb-16 mx+6"
                      id="l_address"
                      label="Search Address or Coordinates (latitude , longitude)"
                      variant="outlined"
                      type="search"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div>
                    {loading ? <div> .. loading </div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                      };
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          <br />
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          )}
        />
        <GoogleMap
          zoom={zoom}
          options={getMapOptions}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={center}
          // onClick={handleOnClick}
          // onDblClick={handleOndbClick }
          onClick={handleOndbClick}
          onLoad={(map) => setMap(map)}
          onSelect={() => onChange(handleSelect)}
        >
          {directionRes && (
            <DirectionsRenderer
              routeIndex="12"
              id="setMp"
              directions={directionRes}
              options={{
                draggable: false,
                map: map,
              }}
            />
          )}
          <Marker
            text="Current location"
            lat={latitud ? latitud : latitudmov ? latitudmov : lat}
            lng={longitud ? longitud : longitudmov ? longitudmov : lng}
          />
        </GoogleMap>
      </div>
    </>
  );
}

export default MapsDestination;
