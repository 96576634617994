import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const TruckdetailsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: [
        '/Truckdetails/filters/:filterHandle/:TruckdetailsId?',
        '/Truckdetails/:folderHandle/:TruckdetailsId?',
      ],
      component: lazy(() => import('./TruckdetailsApp')),
    },
    {
      path: '/Truckdetails',
      component: () => <Redirect to="/Truckdetails/all" />,
    },
  ],
};

export default TruckdetailsAppConfig;
