import { lazy } from 'react';
import { DriverDeleteAccount } from './DriverDeleteAccount';


const DriverAccoutConfig = {
  settings: {
    layout: {
        style : 'layout1',
        config: {

            navbar : {
                display : false,

            },
            toolbar: {
                display : false,
            },
            footer : {
                display : false,

            },
            mode   : 'fullwidth'
        }
    },

},
  routes: [
    {
      path: '/request-account-deletion',
      component: DriverDeleteAccount,
    },
  ],
};

export default DriverAccoutConfig
