import { takeLatest, put, fork, all, call } from 'redux-saga/effects'
import actions from './actions'
import client from '../../../../../client'
import { getErrorAction, getSuccessAction } from '../utility'

export function* watchGetRoles() {
  yield takeLatest(actions.GET_ROLES, function* ({ payload, onError }) {
    try {
      const result = yield call(() => client.get('/roles'))
      yield put({
        type: getSuccessAction(actions.GET_ROLES),
        payload: {
          roles: Array.isArray(result.data) ? result.data : [],
          page: payload.params?.page || 1,
          hasNextPage: result?.data?.length || false,
        },
      })
    } catch (error) {
      if (onError) onError(error)
      yield put({
        type: getErrorAction(actions.GET_ROLES),
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(watchGetRoles)])
}
