import { Formik, Form, Field } from 'formik';
import {
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@mui/material';
import new_store from 'app/store';
import { useEffect, useState } from 'react';
import { constants, ipayNet, states } from '../../../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import client from 'client';

const methodPaymentSchema = Yup.object().shape({
  card_number: Yup.string()
    .required('Card number is required')
    .matches(/^\d+$/, 'Credit card number must contain only digits')
    .test('test-card-number', 'Invalid credit card number', (value) => {
      if (!value) {
        return false;
      }
      const isAmex = value.startsWith('34') || value.startsWith('37');
      const isValidAmex = isAmex && value.length === 15;
      const isValidLength = value.length >= 13 && value.length <= 19;
      const isValidLuhn = checkLuhn(value);

      return (isValidAmex || isValidLength) && isValidLuhn;
    }),
  exp_month: Yup.string()
    .required('Expiry month is required')
    .matches(/^(0[1-9]|1[0-2])$/, 'Expiry month must be a valid 2-digit number between 01 and 12'),
  exp_year: Yup.number()
    .required('Expiry year is required')
    .min(new Date().getFullYear(), 'Expiry year must be this year or later'),
  cv_code: Yup.number().required('CVV number is required'),
  email: Yup.string().required('Email address is required').email('Invalid email address'),
  address1: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  zip: Yup.string()
    .required('Zip code is required')
    .matches(/^\d{5}$/, 'Invalid zip code'),
  card_Name: Yup.string().required('Required'),
  card_LastName: Yup.string().required('Required'),
  Nickname: Yup.string().required('Required'),
});

function checkLuhn(value) {
  let sum = 0;
  let shouldDouble = false;

  // Iterate over the string from the rightmost digit
  for (let i = value.length - 1; i >= 0; i--) {
    let digit = parseInt(value.charAt(i));

    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  // Valid if sum is a multiple of 10
  return sum % 10 === 0;
}

const ManagemenpaymentMethods = ({ open, onClose }) => {
  const user_id = new_store.getState().loadz_auth?.AuthReducer?.user?.user_id;
  const [company, setCompany] = useState({});
  const [showValue, setShowValue] = useState(false);
  const [dataMethodPayment, setDataMethodPayment] = useState({});
  const dispatch = useDispatch();

  const formatPhoneNumber = (value) => {
    const regex = /^\d{16}$/;
    const onlyNumbers = value.replace(/[^\d]/g, '');
    const lastFour = onlyNumbers.substr(onlyNumbers.length - 4);
    if (onlyNumbers.length === 16 || onlyNumbers.length === 15) {
      setShowValue(true);
    } else {
      setShowValue(false);
    }
    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(' ')
    );
  };

  async function getInfoCompany() {
    const id = new_store.getState().loadz_auth?.AuthReducer?.user?.company?.compacctnum;
    const response = await client.post(`/company/compacct`, { id });
    setCompany(response.data);
  }

  const savePaymentMethod = async (values) => {
    try {
      const cardType = getCardType(values.card_number);
      const data = {
        CardNumber: values.card_number,
        CardType: cardType,
        CVV: values.cv_code,
        ExpDate: values.exp_month + values.exp_year,
        Amount: '0',
        fees: {
          amountOriginal: 0,
          processingTotalFee: 0,
          serviceChargeTotal: 0,
          total: 0,
          typePayment: 'WEB CC',
        },
        FirstName: values.card_Name,
        LastName: values.card_LastName,
        EmailAddress: values.email,
        Address1: values.address1,
        Address2: '',
        City: values.city,
        State: values.state,
        Zip: values.zip,
        Province: '',
        Country: 'US',
        MerchantPayAccount: '',
        OfficeID: '',
        AdditionalSearch: '',
        AccountCode1: '',
        AccountCode2: '',
        AccountCode3: '',
        ConvFeeAmount: '0',
        secure3D: '',
        AuthOnly: true,
        TerminalID: '',
        MobileWalletType: '',
        MobileWalletCryptogram: '',
        MobileWalletECI: '',
        TrackData: '',
        DisableDuplicateCheck: '',
        referenceId: 'AFD9C66D-07AD-41FD-98DB-EB32B5A6147B', // TODO: aqui va a quien le estan pagando
        userId: user_id,
      };

      const response = await fetch(`${constants.URLSECURE}/api/usio/payment-cc`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          X_API_KEY: ipayNet.X_API_KEY,
          project: ipayNet.project,
          identifier: '95eefbe3-95b0-4871-a339-310c318e9ee6',
        },
        body: JSON.stringify(data),
      });

      if (response.status !== 200) {
        const errorMessage = await response.text();
        _showMessage(errorMessage || 'Failed to save payment method. Please try again.', 'error');
      }

      const result = await response.json();

      if (result.Status == 'success') {
        const savePaymentMethod = {
          confirmation: result.Confirmation,
          payment_method: 1,
          reference: values.Nickname,
          new_client_id: user_id,
          type_card: cardType,
        };

        createPaymentMethod(savePaymentMethod);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCardType = (numberCard) => {
    var numberClean = numberCard.replace(/[^\d]/g, '');

    if (/^5[1-5]/.test(numberClean)) {
      return 'MSTR';
    } else if (/^4/.test(numberClean)) {
      return 'VISA';
    } else if (/^3[47]/.test(numberClean)) {
      return 'AMEX';
    } else if (/^6(?:011|5)/.test(numberClean)) {
      return 'DISC';
    } else {
      return 'VISA';
    }
  };

  const createPaymentMethod = async (info) => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/payments/create-payment-method`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: null,
        },
        body: JSON.stringify(info),
      });

      const data = await response.json();

      if (data) {
        _showMessage('Payment method created successfully', 'success');
        getPaymentMethod(user_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const userId = user_id;
    getPaymentMethod(userId);
    getInfoCompany();
  }, [open]);

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const getPaymentMethod = async (userId) => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/payments/get-payment-method`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: null,
        },
        body: JSON.stringify({
          id: userId,
          payment_source: 1,
          all_method: true,
        }),
      });
      const data = await response.json();

      if (data.length > 0) {
        setDataMethodPayment(data);
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMethodPayment = async (id) => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/payments/delete-payment-method/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: null,
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();

      if (data) {
        _showMessage('Payment method deleted successfully', 'success');
        getPaymentMethod(user_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  const activePaymentMethod = async (id) => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/payments/active-payment-method/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: null,
        },
        body: JSON.stringify({
          client_id: user_id,
        }),
      });
      const data = await response.json();

      if (data) {
        _showMessage('Payment method activated successfully', 'success');
        setDataMethodPayment(data);
        // getPaymentMethod(user_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { minHeight: 'auto', maxHeight: 'auto', overflow: 'scroll' } }}
      className="p-10 new-modal"
    >
      <div className="p-14">
        <h3 className="text-lg font-bold">Payment Method</h3>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: 0, top: 0, padding: '25px' }}
          size="large"
        >
          <CloseIcon className="text-red-700" />
        </IconButton>

        <Formik
          initialValues={{
            card_Name: '',
            card_LastName: '',
            Nickname: '',
            card_number: '',
            exp_month: '',
            exp_year: '',
            cv_code: '',
            address1: company ? company.compaddress : '',
            city: company ? company.compcity : '',
            state: company ? company.compstate : '',
            zip: company ? company.compzip : '',
            email: company ? company.comp_email : '',
            phone: company ? company.compphone : '',
          }}
          validationSchema={methodPaymentSchema}
          onSubmit={(values) => {
            savePaymentMethod(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="user-profile-detail flex justify-between flex-wrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap">
                <div className="grid custom-border w-full  border-slate-200 xl:w-2/3 lg:w-4/5 md:w-4/5 xl:order-1 lg:order-1 md:order-1 order-2 border-r-2">
                  <div className="box pr-0 xl:pr-10 lg:pr-10 md:pr-10">
                    <div className="flex flex-wrap">
                      <div className="w-2/4 px-3.5 innerfeilds">
                        <div className="feildsoutter mb-14">
                          <label className="w-full">
                            Debit / Credit Card Number<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            name="card_number"
                            placeholder="123 4567 8900 0000"
                            maxLength="16"
                            inputMode="numeric"
                            type={showValue ? 'password' : 'text'}
                            className="w-full border p-12 rounded-md"
                            onInput={(e) => {
                              e.target.value = formatPhoneNumber(e.target.value);
                            }}
                            onFocus={() => setShowValue(false)}
                            onBlur={() => setShowValue(true)}
                          />
                          {errors.card_number &&
                          touched.card_number && <div>{errors.card_number}</div> ? (
                            <div style={{ color: 'red' }}>{errors.card_number}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="w-2/4 flex flex-col sm:flex-row p-3 innerfeilds">
                        <div className="feildsoutter mb-14 w-full sm:w-2/4 px-3.5">
                          <label className="w-full">
                            Exp month<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            name="exp_month"
                            maxLength="2"
                            type="text"
                            inputMode="numeric"
                            pattern="\d*"
                            className="w-full border p-12 rounded-md"
                            placeholder="MM"
                          />
                          {errors.exp_month &&
                          touched.exp_month && <div>{errors.exp_month}</div> ? (
                            <div style={{ color: 'red' }}>{errors.exp_month}</div>
                          ) : null}
                        </div>

                        <div className="feildsoutter mb-14 w-full sm:w-2/4 px-3.5">
                          <label className="w-full">
                            Exp year<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            name="exp_year"
                            maxLength={4}
                            type="text"
                            inputMode="numeric"
                            pattern="\d*"
                            className="w-full border p-12 rounded-md"
                            placeholder="YYYY"
                          />
                          {errors.exp_year && touched.exp_year && <div>{errors.exp_year}</div> ? (
                            <div style={{ color: 'red' }}>{errors.exp_year}</div>
                          ) : null}
                        </div>

                        <div className="feildsoutter mb-14 w-full sm:w-2/4 px-3.5">
                          <label className="w-full">
                            CVV code<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            name="cv_code"
                            maxLength={4}
                            type="text"
                            inputMode="numeric"
                            pattern="\d*"
                            placeholder="123"
                            className="w-full border p-12 rounded-md"
                          />
                          {errors.cv_code && touched.cv_code && <div>{errors.cv_code}</div> ? (
                            <div style={{ color: 'red' }}>{errors.cv_code}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="w-full px-3.5 innerfeilds">
                        <div className="feildsoutter mb-14">
                          <label className="w-full">
                            Nickname<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            type="text"
                            name="Nickname"
                            placeholder="Full Address"
                            className="w-full border p-12 rounded-md"
                          />
                          {errors.Nickname && touched.Nickname ? (
                            <div className="text-10 text-red">{errors.Nickname}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="w-1/2 px-3.5 innerfeilds">
                        <div className="feildsoutter mb-14">
                          <label className="w-full">
                            First Name<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            name="card_Name"
                            placeholder="Cardholder First name"
                            className="w-full border p-12 rounded-md"
                          />
                          {errors.card_Name && touched.card_Name ? (
                            <div style={{ color: 'red' }}>{errors.card_Name}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="w-1/2 px-3.5 innerfeilds">
                        <div className="feildsoutter mb-14">
                          <label className="w-full">
                            Last Name<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            name="card_LastName"
                            placeholder="Cardholder Last name"
                            className="w-full border p-12 rounded-md"
                          />
                          {errors.card_LastName && touched.card_LastName ? (
                            <div style={{ color: 'red' }}>{errors.card_LastName}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <h1 className="text-18 font-bold mb-14">Billing Address</h1>

                    <div className="flex flex-wrap">
                      <div className="w-1/2 px-3.5 innerfeilds">
                        <div className="feildsoutter mb-14">
                          <label className="w-full">
                            Address<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            type="text"
                            name="address1"
                            placeholder="Full Address"
                            className="w-full border p-12 rounded-md"
                          />
                          {errors.address1 && touched.address1 ? (
                            <div className="text-10 text-red">{errors.address1}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="w-1/2 px-3.5 innerfeilds">
                        <div className="feildsoutter mb-14">
                          <label className="">
                            City<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            type="text"
                            name="city"
                            placeholder="City"
                            className="w-full border p-12 rounded-md"
                          />

                          {errors.city && touched.city ? (
                            <div className="text-10 text-red">{errors.city}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="w-1/2 px-3.5 innerfeilds">
                        <div className="w-full feildsoutter mb-14">
                          <label className="w-full">
                            State<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            component="select"
                            name="state"
                            className="w-full border p-12 rounded-md "
                            defaultValue="Texas"
                          >
                            {states.map((state) => (
                              <option
                                key={state}
                                value={state}
                              >
                                {state}
                              </option>
                            ))}
                          </Field>
                          {errors.state && touched.state ? (
                            <div style={{ color: 'red' }}>{errors.state}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="w-1/2 px-3.5 innerfeilds">
                        <div className="w-full feildsoutter mb-14">
                          <label className="">
                            Zip<b style={{ color: 'red' }}>*</b>
                          </label>
                          <Field
                            type="text"
                            name="zip"
                            maxLength={5}
                            placeholder="zip"
                            className="w-full border p-12 rounded-md"
                          />

                          {errors.zip && touched.zip ? (
                            <div className="text-10 text-red">{errors.zip}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="input-fields flex item-center justify-between">
                      <div className="w-2/4 flex flex-col sm:flex-row p-3 innerfeilds"></div>
                    </div>
                    <div className="input-fields mb-20 flex item-center">
                      <div className="input-field w-1/2 mr-6">
                        <label className="">
                          Email<b style={{ color: 'red' }}>*</b>
                        </label>
                        <Field
                          type="text"
                          name="email"
                          placeholder="Email"
                          className="w-full border p-12 rounded-md"
                        />

                        {errors.email && touched.email ? (
                          <div className="text-10 text-red">{errors.email}</div>
                        ) : null}
                      </div>
                      <div className="input-field w-1/2 mr-6">
                        <label className="">Phone</label>
                        <Field
                          type="number"
                          name="phone"
                          placeholder="Phone Number"
                          className="w-full border p-12 rounded-md"
                        />

                        {errors.phone && touched.phone ? (
                          <div className="text-10 text-red">{errors.phone}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="box mt-32 flex w-full">
                    <button
                      type="submit"
                      className="rounded-none border-solid border-1 w-4/5 border-black py-10 px-32 text-white bg-black font-medium hover:bg-white hover:text-black ease-in duration-300 mx-auto"
                    >
                      Save
                    </button>
                  </div>
                </div>

                <div className="main-column  w-full xl:w-1/3 lg:w-2/4 md:w-2/5 xl:order-2 lg:order-2 md:order-1  order-1 ">
                  <div className="box pl-0 xl:pl-20 lg:pl-20 mb-20 ">
                    <div className="inputfields">
                      {dataMethodPayment.length > 0 ? (
                        <div>
                          {dataMethodPayment.map((data) => (
                            <List
                              className={`no-list-style bg-grey-100 rounded-12 mb-2`}
                              key={data.id}
                              style={{ padding: '5px', marginBottom: '15px' }}
                            >
                              <ListItem
                                alignItems="flex-start"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center', // Añadido para centrar
                                }}
                              >
                                <ListItemAvatar style={{ margin: 0, padding: 0 }}>
                                  {data?.type_card === 'VISA' && (
                                    <img
                                      src="https://app.loadz.com/assets/images/icons/visa.png"
                                      className="w-36 ml-7"
                                      alt="VISA"
                                    />
                                  )}
                                  {data?.type_card === 'MASTERCARD' && (
                                    <img
                                      src="https://app.loadz.com/assets/images/icons/mastercard.png"
                                      className="w-36 ml-7"
                                      alt="Mastercard"
                                    />
                                  )}
                                  {data?.type_card === 'DISCOVER' && (
                                    <img
                                      src="https://app.loadz.com/assets/images/icons/discover.png"
                                      className="w-36 ml-7"
                                      alt="Discover"
                                    />
                                  )}
                                  {data?.type_card === 'AMEX' && (
                                    <img
                                      src="https://app.loadz.com/assets/images/icons/amex.webp"
                                      className="w-36 ml-7"
                                      alt="AMEX"
                                    />
                                  )}
                                </ListItemAvatar>
                                <ListItemText
                                  primary={data?.reference}
                                  secondary={
                                    data.fail_payment ? (
                                      <>
                                        <div className="text-red-700">Failed</div>
                                        <div className="text-green-500">Retry</div>
                                      </>
                                    ) : null
                                  }
                                />

                                <ListItemSecondaryAction>
                                  <IconButton onClick={() => deleteMethodPayment(data.id)}>
                                    <DeleteIcon className="text-red-700" />
                                  </IconButton>
                                  <IconButton>
                                    <Switch
                                      checked={data.default}
                                      onClick={() => activePaymentMethod(data.id)}
                                    />
                                  </IconButton>
                                </ListItemSecondaryAction>

                                {/* data.default */}
                              </ListItem>
                            </List>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default ManagemenpaymentMethods;
