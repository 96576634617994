import { lazy } from 'react';
import NewCustomers from './NewCustomers';

const routeOrigin = '/customers';

const CustomersConfig = {
  settings: {},
  routes: [
    {
      path: `${routeOrigin}/new`,
      component: lazy(() => import('./customers-components/CustomersHelpers')),
    },
    {
      path: `${routeOrigin}/by-id/:id`,
      component: lazy(() => import('./customers-components/CustomersHelpers')),
    },
    {
      path: routeOrigin,
      component: () => <NewCustomers />,
    },
  ],
};

export default CustomersConfig;
