import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFormContext, Controller } from 'react-hook-form';
import { memo, useEffect, useState } from 'react';
import {
  Autocomplete,
  Divider,
  Fab,
  InputAdornment,
  InputLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { constants, states, formatter } from '../../../../constants';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatPhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber } from 'libphonenumber-js';
import ModalSubscriptionsPaywalls from 'app/main/subscriptionManagement/ModalSubscriptionsPayWalls';
import store from 'app/store';
const phoneregex = RegExp('[0-9]');

function CreateCustomers(props) {
  const { id, jsonGetById, prop_data, setSelectedCustomer } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem('token');
  const methods = useFormContext();
  const { watch, control, formState, setValue, setError, getValues, reset } = methods;
  const { errors } = formState;
  const [contact, setcontact] = useState(['contact-0']);
  const [disabled, setDisabled] = useState(false);
  const [fakephone, setfakephone] = useState('');
  const [subscriptionsPaywalls, setSubscriptionsPaywalls] = useState(false);
  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), title: '', name: '', email: '', phone: '' },
  ]);
  const [invoiceInputFields, setInvoiceInputFields] = useState([{ id: uuidv4(), email: '' }]);

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      setInputFields([...inputFields, { id: uuidv4(), title: '', name: '', email: '', phone: '' }]);
      setValue('contact', inputFields);
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleChangeInputInvoice = (id, event) => {
    const newInvoiceInputFields = invoiceInputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      setInvoiceInputFields([...invoiceInputFields, { id: uuidv4(), email: '' }]);
      setValue('invoice_contacts', invoiceInputFields);
      return i;
    });

    setInvoiceInputFields(newInvoiceInputFields);
  };

  const _setError = () => {
    setError('customer_name');
  };
  const handleAddFields = () => {
    setInputFields([...inputFields, { id: uuidv4(), title: '', name: '', email: '', phone: '' }]);
    setValue('contact', inputFields);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    setValue('contact', values);
  };

  const handleAddFieldsInvoice = () => {
    setInvoiceInputFields([...invoiceInputFields, { id: uuidv4(), email: '' }]);
    setValue('invoice_contacts', invoiceInputFields);
  };

  const handleRemoveFieldsInvoice = (id) => {
    const values = [...invoiceInputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInvoiceInputFields(values);
    setValue('invoice_contacts', values);
  };

  useEffect(async () => {
    if (props.jsonGetById.length > 0) {
      const {
        customers_id,
        customer_name,
        customer_email,
        location_id,
        customer_phone,
        contact,
        invoice_contacts,
        customer_website,
        customer_notes,
        customer_status,
        term,
        credit_limits,
        // l_name,
        l_address,
        l_city,
        l_state,
        l_zip,
        title,
        name,
        email,
        phone,
      } = props.jsonGetById[0];

      setfakephone(customer_phone);
      setValue('customer_name', customer_name);
      setValue('customer_email', customer_email);
      setValue('location_id', location_id);
      const newphone = formatPhoneNumber('+1' + customer_phone);
      setValue('customer_phone', newphone);
      setValue('customer_website', customer_website);
      setValue('customer_notes', customer_notes);
      setValue('customer_status', customer_status);
      // setValue('l_name', l_name)
      setValue('l_address', l_address);
      setValue('l_city', l_city);
      setValue('l_state', l_state);
      setValue('l_zip', l_zip);
      setValue('term', term);
      const credit_format = formatter.format(credit_limits);
      setValue('credit_limits', credit_format);

      if (contact && contact.length >= 1) {
        setInputFields(contact);
        setValue('contact', contact);
      }

      if (invoice_contacts && invoice_contacts.length >= 1) {
        setInvoiceInputFields(invoice_contacts);
        setValue('invoice_contacts', invoice_contacts);
      }
    }
  }, []);

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  async function handleUpdateCustomer() {
    try {
      console.log(getValues());
      var newValues = getValues();
      if (newValues.customer_phone) {
        newValues.customer_phone = parsePhoneNumber(newValues.customer_phone, 'US').nationalNumber;
        newValues.contact.forEach((elem) => {
          if (elem.phone) {
            elem.phone = parsePhoneNumber(elem.phone, 'US').nationalNumber;
          } else {
            elem.phone = '';
          }
        });
      } else {
        newValues.customer_phone = '';
      }
      setDisabled(true);
      if (!getValues('visibility')) {
        setValue('visibility', jsonGetById[0].c_visibility);
      }
      const response = await fetch(`${constants.URLLOCAL}/customers/update-customer/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          token: `${token}`,
        },
        body: JSON.stringify(newValues), // body data type must match "Content-Type" header
      });
      const res = await response.json();

      _showMessage(`Customer ${getValues('customer_name')} updated successfully!`, 'success'); //TODO  improve this message
      setSelectedCustomer(getValues());

      if (res.error) {
        _showMessage(
          `Failed to update customer ${getValues(
            'customer_name'
          )}. Please check your input and try again `,
          'error'
        ); //TODO  improve this message
        setDisabled(false);
        return;
      }

      setDisabled(false);
      _setError();
      // props.refreshCustomers()
      props.closeIt();
      prop_data ? null : history.push('/customers');
    } catch (error) {
      console.log(error);
      _showMessage(`An unexpected error occurred while updating the customer.`, 'error'); //TODO  improve this message
      setDisabled(false);
    }
  }

  async function handleSaveCustomer() {
    try {
      var newValues = getValues();
      if (newValues.customer_phone) {
        newValues.customer_phone = parsePhoneNumber(newValues.customer_phone, 'US').nationalNumber;
        newValues.contact.forEach((elem) => {
          if (elem.phone) {
            elem.phone = parsePhoneNumber(elem.phone, 'US').nationalNumber;
          } else {
            elem.phone = '';
          }
        });
      } else {
        newValues.customer_phone = '';
      }
      setDisabled(true);
      const response = await fetch(
        `${constants.URLLOCAL}/customers/new${prop_data ? '/' + prop_data?.compacctnum : ''}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: `${token}`,
          },
          body: JSON.stringify(newValues), // body data type must match "Content-Type" header
        }
      );
      const res = await response.json();

      if (res?.status === 'incomplete_expired') {
        _showMessage(res.message, 'error');
        paywallSubscriptions();
        return;
      }

      if (res.error) {
        _showMessage(
          `Failed to update customer ${getValues(
            'customer_name'
          )}. Please check your input and try again.`,
          'error'
        );
        setDisabled(false);
        return;
      }

      console.log(res);

      if (res.message) {
        _showMessage(res.message, 'error');
        setDisabled(false);
        return;
      }

      _showMessage(
        `The customers with name ${getValues('customer_name')} has been created correctly!`,
        'success'
      );
      setSelectedCustomer(getValues());

      setDisabled(false);
      _setError();
      // props.refreshCustomers()
      props.closeIt();
      prop_data ? null : history.push('/customers');
    } catch (error) {
      console.log(error);
      _showMessage(
        `An unexpected error occurred while updating the customer. Please try again.`,
        'error'
      );
      setDisabled(false);
    }
  }

  const paywallSubscriptions = () => {
    setSubscriptionsPaywalls(true);
  };

  return (
    <>
      {subscriptionsPaywalls ? (
        <ModalSubscriptionsPaywalls
          paywallSubscriptions={paywallSubscriptions}
          open={subscriptionsPaywalls}
          setSubscriptionsPaywalls={setSubscriptionsPaywalls}
          compacctnum={
            prop_data?.compacctnum
              ? prop_data?.compacctnum
              : store.getState().loadz_auth.AuthReducer.user.company.compacctnum
          }
        ></ModalSubscriptionsPaywalls>
      ) : null}

      <div className="customer_popup_new">
        <br />
        <div className="flex mt-8 mb-28  mx-2 new-customers new">
          <Controller
            name="customer_name"
            control={control}
            render={({ field }) => (
              <Tooltip
                title=""
                placement="right-start"
              >
                <TextField
                  {...field}
                  className="mt-8 mb-16 mx-2"
                  helperText={!!errors.customer_name ? 'Please fill with customer name' : ''}
                  label="Customer Name"
                  autoFocus
                  id="customer_name"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Tooltip>
            )}
          />

          <Controller
            name="customer_email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16 mx-2 "
                label="Customer Email"
                id="customer_email"
                variant="outlined"
                type={'email'}
                fullWidth
              />
            )}
          />

          <Controller
            name="customer_phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                className="mt-8 mb-16 mx-2"
                label="Customer Phone"
                id="customer_phone"
                variant="outlined"
                type={'name'}
                fullWidth
                onInput={(e) => {
                  if (!phoneregex.test(e.target.value.slice(-1))) {
                    e.target.value = e.target.value.slice(0, -1);
                  }
                  const newphone = formatPhoneNumber('+1' + e.target.value);

                  if (newphone.length > 1) {
                    e.target.value = newphone;

                    value = newphone;
                    onChange(value);
                  } else {
                    e.target.value = e.target.value;
                    onChange(e.target.value);
                  }
                }}
                inputProps={{ maxLength: 10 }}
              />
            )}
          />
        </div>

        <div className="pb-12">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
          >
            Address
          </Typography>
        </div>
        {/* <Controller
        name="location_id" 
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            className="mt-8 mb-16 mx-2"
            multiple
            freeSolo
            // value={value}
            // getOptionDisabled={(options) => (value ? value.length == 1 ? true : false : null)}
            options={loca}
            onChange={(event, newValue) => {
              setCreateLocation(false)
              newValue.map(r => {
                if (r.value == 0) {
                  setCreateLocation(true)
                }

                // }else{
                //   locationsOrigin.forEach(loc => {
                //     if(loc.l_id === r.value){
                //       console.log(loc)
                //       setLocationQuarry({
                //         name: loc.l_name,
                //         lat: loc.l_latitude,
                //         lng: loc.l_longitude
                //       })
                //       console.log(locationQuarry)
                //     }
                //   })

                // }
              })
              onChange(newValue);
            }}
            fullWidth

            renderInput={(params) => (
              <TextField
                {...params}
                id={loca}
                placeholder="Select location"
                label="Location"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              // error={value.length == 0 ? true : false}
              // helperText={value.length == 0 ? 'Please select quarry pit for this job' : ''}
              />
            )}
            limitTags={1}
          />
        )}
      /> */}

        <div className="flex new-customers-address mb-28 new">
          {/* <Controller
          name="l_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-8 mb-16 mx-4"
              error={!!errors.name}
              helperText={errors?.name?.message}
              label="Name"
              id="l_name"
              variant="outlined"
              fullWidth
            />
          )}
        /> */}

          <Controller
            name="l_address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16 mx-4"
                id="l_address"
                label="Address"
                variant="outlined"
                fullWidth
                // disabled={true}
              />
            )}
          />

          <Controller
            name="l_city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16 mx-4"
                id="l_city"
                placeholder="City"
                label="City"
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Controller
            name="l_state"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                freeSolo
                className="mt-8 mb-16 mx-4 state"
                options={states}
                // defaultValue={[states]}
                value={value}
                fullWidth
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select State"
                    label="State"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            )}
          />

          <Controller
            name="l_zip"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16 mx-4"
                id="l_zip"
                label="Zip"
                variant="outlined"
                fullWidth
                type={'name'}
                onInput={(e) => {
                  if (!phoneregex.test(e.target.value.slice(-1))) {
                    e.target.value = e.target.value.slice(0, -1);
                  }
                }}
                inputProps={{ maxLength: 5 }}
              />
            )}
          />
        </div>

        <div className="pb-12">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
          >
            Contact
          </Typography>
        </div>

        <Controller
          name="contact"
          control={control}
          render={({ field }) => (
            <>
              {inputFields.map((inputField) => (
                <div
                  key={inputField.id}
                  className="flex mx-6"
                >
                  <TextField
                    {...field}
                    name="title"
                    id="title"
                    className="mt-8 mb-16 mx-2"
                    label="Title"
                    type={'text'}
                    variant="outlined"
                    value={inputField.title}
                    onChange={(event) => handleChangeInput(inputField.id, event)}
                    fullWidth
                    InputProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    className="mt-8 mb-16 mx-2"
                    label="Name"
                    type={'text'}
                    name="name"
                    variant="outlined"
                    value={inputField.name}
                    onChange={(event) => handleChangeInput(inputField.id, event)}
                    fullWidth
                    InputProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    className="mt-8 mb-16 mx-2 email"
                    label="Email"
                    name="email"
                    type={'email'}
                    variant="outlined"
                    value={inputField.email}
                    onChange={(event) => handleChangeInput(inputField.id, event)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      shrink: true,
                    }}
                    fullWidth
                  />

                  <TextField
                    className="mt-8 mb-16 mx-2"
                    label="Phone"
                    name="phone"
                    type={'name'}
                    variant="outlined"
                    value={inputField.phone}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      shrink: true,
                    }}
                    onInput={(e) => {
                      if (!phoneregex.test(e.target.value.slice(-1))) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                      const newphone = formatPhoneNumber('+1' + e.target.value);

                      if (newphone.length > 1) {
                        e.target.value = newphone;
                      }
                      handleChangeInput(inputField.id, e);
                    }}
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                  />

                  <div
                    className="mt-8 mb-16 mx-2 addbtn"
                    style={{ alignSelf: 'center' }}
                  >
                    <InputAdornment position="start">
                      <Fab
                        color="primary"
                        size="small"
                        onClick={handleAddFields}
                      >
                        <Tooltip title="Add Contact">
                          <AddIcon />
                        </Tooltip>
                      </Fab>
                    </InputAdornment>
                  </div>

                  <div
                    className="mt-8 mb-16 mx-2 remove-btn"
                    style={{ alignSelf: 'center' }}
                  >
                    <InputAdornment position="start">
                      <Fab
                        color="danger"
                        size="small"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                      >
                        <Tooltip title="Remove Contact">
                          <RemoveIcon />
                        </Tooltip>
                      </Fab>
                    </InputAdornment>
                  </div>
                </div>
              ))}
            </>
          )}
        />

        {/* <div className='pb-12'>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
          >
            Invoice Contacts
          </Typography>
        </div>

        <Controller
          name="invoice_contacts"
          control={control}

          render={({ field }) => (
            <>
              {invoiceInputFields.map((inputField) => (
                <div
                  key={inputField.id}
                  className="flex mx-6"
                >
                  <TextField
                    {...field}
                    name="title"
                    id="title"
                    className="mt-8 mb-16 mx-2"
                    label="Title"
                    type={'text'}
                    variant="outlined"
                    value={inputField.title}
                    onChange={(event) => handleChangeInputInvoice(inputField.id, event)}
                    fullWidth
                    InputProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    className="mt-8 mb-16 mx-2"
                    label="Name"
                    type={'text'}
                    name="name"
                    variant="outlined"
                    value={inputField.name}
                    onChange={(event) => handleChangeInputInvoice(inputField.id, event)}
                    fullWidth
                    InputProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    className="mt-8 mb-16 mx-2 email"
                    label="Email"
                    name="email"
                    type={'email'}
                    variant="outlined"
                    value={inputField.email}
                    onChange={(event) => handleChangeInputInvoice(inputField.id, event)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      shrink: true,
                    }}
                    fullWidth
                  />

                  <TextField
                    className="mt-8 mb-16 mx-2"
                    label="Phone"
                    name="phone"
                    type={'name'}
                    variant="outlined"
                    value={inputField.phone}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      shrink: true,
                    }}
                    onInput={(e) => {
                      if (!phoneregex.test(e.target.value.slice(-1))) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                      const newphone = formatPhoneNumber('+1' + e.target.value);

                      if (newphone.length > 1) {
                        e.target.value = newphone;
                      }
                      handleChangeInputInvoice(inputField.id, e);
                    }}
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                  />

                  <div
                    className="mt-8 mb-16 mx-2 addbtn"
                    style={{ alignSelf: 'center' }}
                  >
                    <InputAdornment position="start">
                      <Fab
                        color="primary"
                        size="small"
                        onClick={handleAddFieldsInvoice}
                      >
                        <Tooltip title="Add Contact">
                          <AddIcon />
                        </Tooltip>
                      </Fab>
                    </InputAdornment>
                  </div>

                  <div
                    className="mt-8 mb-16 mx-2 remove-btn"
                    style={{ alignSelf: 'center' }}
                  >
                    <InputAdornment position="start">
                      <Fab
                        color="danger"
                        size="small"
                        disabled={invoiceInputFields.length === 1}
                        onClick={() => handleRemoveFieldsInvoice(inputField.id)}
                      >
                        <Tooltip title="Remove Contact">
                          <RemoveIcon />
                        </Tooltip>
                      </Fab>
                    </InputAdornment>
                  </div>
                </div>
              ))}
            </>
          )}
        />
 */}
        <Divider></Divider>

        <div className="flex mt-8 mb-16  mx-2 website">
          <Controller
            name="customer_website"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16 mx-2"
                label="Website"
                id="customer_website"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>

        <div className="flex mt-8 mb-16  mx-2">
          <FormControl fullWidth>
            <InputLabel>Term</InputLabel>
            <Controller
              id="term"
              name="term"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  variant="outlined"
                  className="mt-8 mb-16 mx-2 term"
                >
                  <MenuItem
                    key="1"
                    value={'1'}
                  >
                    Net 1
                  </MenuItem>
                  <MenuItem
                    key="2"
                    value={'3'}
                  >
                    Net 3
                  </MenuItem>
                  <MenuItem
                    key="3"
                    value={'7'}
                  >
                    Net 7
                  </MenuItem>
                  <MenuItem
                    key="4"
                    value={'15'}
                  >
                    Net 15
                  </MenuItem>
                  <MenuItem
                    key="5"
                    value={'30'}
                  >
                    Net 30
                  </MenuItem>
                  <MenuItem
                    key="6"
                    value={'45'}
                  >
                    Net 45
                  </MenuItem>
                  <MenuItem
                    key="7"
                    value={'60'}
                  >
                    Net 60
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <Controller
            name="credit_limits"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16 mx-2"
                label="Credit Limit"
                id="credit_limits"
                variant="outlined"
                // type={'number'}
                fullWidth
                InputProps={
                  {
                    // startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }
                }
              />
            )}
          />
        </div>

        <div className="flex mt-8 mb-16  mx-2">
          <Controller
            name="customer_notes"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16 mx-2"
                label="Customer Notes"
                id="customer_notes"
                variant="outlined"
                type={'number'}
                fullWidth
                multiline
                rows={3}
              />
            )}
          />
        </div>

        {/* <Button style={{color:"white"}}
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="third"
         
          onClick={id ? handleUpdateCustomer : handleSaveCustomer}
        >
          {id ? 'Update' : 'Save'}
        </Button> */}

        {/* <div className="flex flex-row flex-wrap">
        <div className="w-full md:w-1/2 p-16 min-h-420 h-420">
          <ReactApexChart
            options={[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]}
            series={[30, 40, 45, 50, 49, 60, 70, 91]}
            type='bar'
            height={500}
          />
        </div>
        <div className="flex w-full md:w-1/2 flex-wrap p-8">
          <div className="w-full sm:w-1/2 p-12">
            <Typography
              className="text-12 font-semibold whitespace-nowrap"
              color="textSecondary"
            >
              hola 
            </Typography>
            <Typography className="text-32 font-semibold tracking-tighter">
              hola 2
            </Typography>
            <div className="h-64 w-full overflow-hidden">
              <ReactApexChart
                options={[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]}
                series={[30, 40, 45, 50, 49, 60, 70, 91]}
                type='bar'
                height={500}
              />
            </div>
          </div>
        </div>
      </div> */}
        <Button
          style={{ color: 'white' }}
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="third"
          /**disabled={!isValid || disabled}**/
          onClick={id ? handleUpdateCustomer : handleSaveCustomer}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </div>
    </>
  );
}

export default memo(CreateCustomers);
