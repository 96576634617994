import FuseScrollbars from '@fuse/core/FuseScrollbars'
import { Checkbox, FormControlLabel, IconButton, Popover, Typography } from '@mui/material'
import { filter, findIndex, forEach, map } from 'lodash'
import { useState, useEffect } from 'react'
import PermissionAction from 'app/stores/loadz/redux/Permissions/actions'
import { connect } from 'react-redux'
import { blue } from '@mui/material/colors'
import PermissionPopOver from './PermissionPopOver'
const Permission = ({ permissions, getPermissions, setSelected, showError, assignedPermissions }) => {

  const [permissionModel, setPermissionModel] = useState([])

  useEffect(async () => {
    await getPermissions({ page: 1 }, (error) => {
      console.log(error)
    })
  }, [assignedPermissions])

  useEffect(async () => {
    if (permissions.length) {
      const module = filter(permissions, (model) => {
        if (model.permission.includes('*')) {
          model.name =
            model.permission === '*.*'
              ? 'ALL'
              : `${model.permission.split('.')[0].toUpperCase()} ALL`

          model.isChecked = assignedPermissions.includes(model.id) ? true : false
          return model
        }

        if (!model.permission.includes('.')) {
          console.log(model)
          model.isChecked = assignedPermissions.includes(model.id) ? true : false
          model.name= model.permission.replace(/_/g, ' ').toUpperCase()
          return model
        }
      })

      const result = filter(module, (item) => {
        if(item.permission.includes('.')) {
          var child = filter(permissions, (element) => {
            const searchKey = item.permission.split('.')[0]
            if (
              element.permission != item.permission &&
              element.permission.split('.')[0] === searchKey
            ) {
              element.name = `${element.permission
                .split('.')[0]
                .toUpperCase()} ${element.permission.split('.')[1].toUpperCase()}`

              element.isChecked = assignedPermissions.includes(element.id) ? true : false
              return element
            }
          })
          item.child = child ? child : []
        }
        return item
      })

      setPermissionModel(result)
    }
  }, [permissions])


  const onChecked = (module) => {
    console.log(module)
    const index = findIndex(permissionModel, (item) => item.id == module.id)
    if (index != -1) {
      permissionModel.splice(index, 1, module)
      setPermissionModel([...permissionModel])

    const result = []
    forEach(permissionModel, (model) => {
      if (model.isChecked) {
        result.push({
          id: model.id,
          permission: model.permission
        })
      } else {
        forEach(model.child, item => {
          if (item.isChecked) {
            result.push({
              id: item.id,
              permission: item.permission
            })
          }
        })
      }
    })
    console.log(result)
    setSelected(map(result, r => r.id))
    }

  }

  return (
    <div className="mr-8">
      <div className="text-base ">Permissions</div>
      {showError && <span style={{color: 'red', fontSize: '8px'}}>At least one permission must be selected.</span>}
      <div
        style={{
          maxHeight: '500px',
          border: '1px solid rgb(227 223 223)',
          borderRadius: '3px',
          background: '#ffffff',
        }}
      >
        <FuseScrollbars className="overflow-y-auto">
          <div
            style={{
              maxHeight: '500px',
            }}
            className="mx-10 border-0 p-10"
          >
            {permissionModel.map((module, index) => {
              return (
                <div>
                  <div>
                    <div className='flex items-center'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={index}
                            checked={module.isChecked}
                            onChange={(event) => {
                              module.isChecked = event.target.checked
                              onChecked(module)
                            }}
                          />
                        }
                        label={module.name}
                      />
                    <PermissionPopOver message={module.description} />
                   </div>
                    <div className="flex flex-col ml-32">
                      {module?.child &&
                        module?.child.map((child, index) => {
                          return (
                            <div className='flex items-center'>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    key={index}
                                    checked={module.isChecked || child.isChecked}
                                    onChange={(event) => {
                                      child.isChecked = event.target.checked
                                      onChecked(module)
                                    }}
                                  />
                                }
                                label={child.name}
                              />
                              <PermissionPopOver message={child.description} />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </FuseScrollbars>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    permissions: state.loadz_permission?.PermissionReducer?.permissions,
    loading: state.loadz_permission?.PermissionReducer?.loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPermissions: (payload, onError) =>
    dispatch(PermissionAction.getPermissions(payload, onError)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Permission)
