import { useEffect, useState, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import { getDataFromS3 } from '../../CompanyList/companyFunctions';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { red, grey } from '@mui/material/colors';
import * as React from 'react';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import { useHistory, useParams } from 'react-router-dom';
import { constants } from '../../../../constants';
import { showMessage } from 'app/store/fuse/messageSlice';
import ModalViewScale from './viewScale';
import DownloadIcon from '@mui/icons-material/Download';
import {
  AppBar,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TicketPopup from '../TicketPopup';
import TicketMultiplePrint from '../TicketMultiplePrint';
import ReactToPrint from 'react-to-print';
import ToggleButton from '@mui/material/ToggleButton';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { DateTimePicker, TimePicker } from '@mui/lab';
import { connect } from 'react-redux';
import withReducer from 'app/store/withReducer';
import JobTicketAction from 'app/stores/loadz/redux/JobsReport/actions';
import JobTicketReducer from 'app/stores/loadz/redux/JobsReport';
import JobAction from 'app/stores/loadz/redux/Jobs/actions';
import { LinearProgress } from '@material-ui/core';
import AuthAction from 'app/stores/loadz/redux/Auth/actions';
import { hasPermission } from 'app/main/loadz/utils';
import ErrorBoundary from 'app/main/ErrorBoundary';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import client from 'client';
import CircularLoader from 'app/main/loadz/components/CircularLoader';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ButtonLog from 'app/main/ButtonLog';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from '@mui/lab';
import _, { set } from 'lodash';
import { setDate } from 'date-fns';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme?.palette?.common?.black,
    color: theme?.palette?.common?.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme?.palette?.action?.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const defaultValues = {
  reason: '',
};

const clearForm = {
  reason: '',
  tk_start: '',
  tk_end: '',
};

function TicketsTable({
  ticketStatus,
  getAllTicketJobs,
  hasNextPage,
  searchJobTickets,
  loading,
  getAllJobs,
  hasNextJob,
  transferJobTickets,
  jobs,
  jobPage,
  page,
  jobTickets,
  jdestination,
  search,
  quarrypit,
  getDrivers,
  getTrucks,
  trucks,
  drivers,
  deleteJobTickets,
  editJobTicket,
  user,
  logout,
  searchJobs,
  data,
  job_id,
  onSearchDate,
  date1,
  date2,
  ticketSearch,
}) {
  const prop_data = data;
  const { id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [ticketPop, setTicketPop] = React.useState(null);
  const [compLogo, setCompLogo] = useState(null);
  const [selected, setSelected] = useState([]);
  const [tk_id, setDelete] = useState(0);
  const [tk_No, settkNo] = useState(0);
  const [tkstartFinal, setTkStart] = useState([]);
  const [tkendFinal, setTkEnd] = useState([]);
  const [tkwaitstart, setTkwaitstart] = useState([]);
  const [tkwaitend, setTkwaitend] = useState([]);
  const [timeend, setTimeEnd] = useState(null);
  const [timestart, setTimeStart] = useState(null);
  const [waittimeend, setWaitTimeEnd] = useState(null);
  const [waittimestart, setWaitTimeStart] = useState(null);
  const [sendEmails, setSendEmails] = useState(false);
  const [leaveTkAlone, setLeaveTkAlone] = useState(true);
  const [singleInvoice, setSingleInvoice] = useState(false);
  const [insertInvoice, setInsertInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const history = useHistory();
  const [showImageLoader] = useState(true);
  const token = localStorage.token;
  const listInnerRef = useRef();
  // const estatus = 1;
  const [open, setOpen] = useState(false);
  const [openedit, setOpenEdit] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const componentRef = React.forwardRef();
  const [jobTransfer, setJobTransfer] = useState(false);
  const [newticketjob, setNewTicketJob] = useState();
  const [scrollX, setScrollX] = useState(0);
  const comp_acct = prop_data ? prop_data.compacctnum : user.company.compacctnum;
  const [isOpen, setIsOpen] = useState(false);
  const [scaleImage, setScaleImage] = useState('');
  // const logoUrl = store.getState().photo ? store.getState().photo : user?.company?.logo
  const [logoUrl, setLogoUrl] = useState(null);

  const [tickettype, setTicketType] = useState('');
  const compacctnum = prop_data ? prop_data.compacctnum : user?.company?.compacctnum;

  const [openreq_edit, setopenreq_edit] = useState(false);
  const [openreq_delete, setopenreq_delete] = useState(false);
  const [openreq_transfer, setopenreq_transfer] = useState(false);
  const [req_reason, setreq_reason] = useState('');
  const [transfersearch, setTransferSearch] = useState('');
  const [truckDriverLoading, setTruckDriverLoading] = useState(false);
  const [loadingBar, setLoadingBar] = useState(false);

  const download = (name, e) => {
    console.log(e);
    fetch(e, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name + '.png'); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let key = prop_data ? prop_data?.logo : user?.company?.logo;
    if (key.includes('http')) {
      setLogoUrl(key);
    } else {
      setLogoUrl(`${constants.URLLOCAL}/aws/signed?image=${key}`);
    }
  }, [prop_data?.logo, user?.company?.logo]);

  //const [newticketjob, setNewTicketJob] = useState()
  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
          </>
        ), //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  let setjDestion = {
    jobDesination: jdestination && jdestination ? jdestination : null,
    jobQryPit: quarrypit && quarrypit ? quarrypit : null,
  };

  const { control, reset, setValue, getValues } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const getS3Location = async (key) => {
    if (key != null && key != '') {
      let data = {
        key: prop_data ? prop_data?.logo : user?.company?.logo,
      };
      return await getDataFromS3(data);
    }
  };
  function diff(start, end) {
    //I can add the clock in and clock out dates
    start = start.split(':');
    end = end.split(':');
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // 24 hours format
    if (hours < 0) hours = hours + 24;

    return (hours <= 9 ? '0' : '') + hours + ':' + (minutes <= 9 ? '0' : '') + minutes;
  }

  //function to put together a string from an array ['foo','bar'] = foobar
  function glue(pieces) {
    //glue the split back together
    return pieces[0] + ':' + pieces[1];
  }
  function getTimeDuration(timeData) {
    if (
      timeData &&
      timeData.tk_wait_start &&
      timeData.tk_wait_start.length > 0 &&
      timeData.tk_wait_end &&
      timeData.tk_wait_end.length > 0
    ) {
      try {
        console.log('WAIT TIMES > ', timeData);
        let total_hours = 0;
        let total_minutes = 0;
        if (timeData.tk_wait_start.length == timeData.tk_wait_end.length) {
          for (let i = 0; i < timeData.tk_wait_start.length; i++) {
            var start_time =
              moment.tz(timeData.tk_wait_start[i], moment.tz.guess(true)).format('HH:mm') ==
              'Invalid date'
                ? moment
                    .tz(
                      `${moment().format('YYYY-MM-DD')} ` + timeData.tk_wait_start[i],
                      moment.tz.guess(true)
                    )
                    .format('HH:mm:ss')
                : moment.tz(timeData.tk_wait_start[i], moment.tz.guess(true)).format('HH:mm:ss');
            var end_time =
              moment.tz(timeData.tk_wait_end[i], moment.tz.guess(true)).format('HH:mm') ==
              'Invalid date'
                ? moment
                    .tz(
                      `${moment().format('YYYY-MM-DD')} ` + timeData.tk_wait_end[i],
                      moment.tz.guess(true)
                    )
                    .format('HH:mm:ss')
                : moment.tz(timeData.tk_wait_end[i], moment.tz.guess(true)).format('HH:mm:ss');
            var time_diff = diff(start_time, end_time);
            time_diff = time_diff.split(':');
            console.log(time_diff);

            const hours = +time_diff[0];
            const minutes = +time_diff[1];
            console.log(hours);
            console.log(minutes);
            if (hours) total_hours = total_hours + hours;
            if (minutes) total_minutes = total_minutes + minutes;
          }
        }
        while (total_minutes >= 60) {
          total_minutes = total_minutes - 60;
          total_hours = total_hours + 1;
        }
        let tkTime = total_hours + ' Hours ' + total_minutes + ' Minutes';
        return tkTime;
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  function getTkStartEndTime(startEndTime) {
    if (startEndTime && startEndTime.tk_start && startEndTime.tk_end) {
      try {
        let total_hours = 0;
        let total_minutes = 0;
        for (let i = 0; i < startEndTime.tk_start.length; i++) {
          console.log('TIMES ', startEndTime.tk_start[i], startEndTime.tk_end[i]);
          const tkStart =
            moment.tz(startEndTime.tk_start[i], moment.tz.guess(true)).format('HH:mm:ss') ==
            'Invalid date'
              ? moment
                  .tz(
                    `${moment().format('YYYY-MM-DD')} ` + startEndTime.tk_start[i],
                    moment.tz.guess(true)
                  )
                  .format('HH:mm:ss')
              : moment.tz(startEndTime.tk_start[i], moment.tz.guess(true)).format('HH:mm:ss');
          const tkEnd =
            moment.tz(startEndTime.tk_end[i], moment.tz.guess(true)).format('HH:mm:ss') ==
            'Invalid date'
              ? moment
                  .tz(
                    `${moment().format('YYYY-MM-DD')} ` + startEndTime.tk_end[i],
                    moment.tz.guess(true)
                  )
                  .format('HH:mm:ss')
              : moment.tz(startEndTime.tk_end[i], moment.tz.guess(true)).format('HH:mm:ss');
          console.log('tkStart,tkEnd', tkStart, tkEnd);
          var time_diff = diff(tkStart, tkEnd);
          console.log('time_diff', time_diff);
          time_diff = time_diff.split(':');

          const hours = +time_diff[0];
          const minutes = +time_diff[1];
          if (hours) total_hours = total_hours + hours;
          if (minutes) total_minutes = total_minutes + minutes;
        }

        while (total_minutes >= 60) {
          total_minutes = total_minutes - 60;
          total_hours = total_hours + 1;
        }
        let tkTime = total_hours + ' Hours ' + total_minutes + ' Minutes';
        return tkTime;
      } catch (err) {
        console.log(err.message);
        return null;
      }
    } else {
      return null;
    }
  }
  // function getTkStartEndTime(startEndTime) {
  //   if (startEndTime && startEndTime.tk_start && startEndTime.tk_end) {
  //     try {
  //       const tkStart = moment(startEndTime.tk_start, 'HH:mm:ss')
  //       const tkEnd = moment(startEndTime.tk_end, 'HH:mm:ss')
  //       const duration = moment.duration(tkEnd.diff(tkStart))
  //       const hours = parseInt(duration.asHours())
  //       const minutes = parseInt(duration.asMinutes()) % 60
  //       let tkTime = hours + ' Hours ' + minutes + ' Minutes'
  //       return tkTime
  //     } catch (err) {
  //       return null
  //     }
  //   } else {
  //     return null
  //   }
  // }

  const [deleteTicketLoading, setDeleteTicketLoading] = useState(false);
  const deleteTicket = async () => {
    setDeleteTicketLoading(true)
    const reason = getValues('reason');
    await deleteJobTickets(
      { tk_id, reason, is_op: tickettype == 'OP' ? true : false, send_emails: sendEmails,user_id:user?.staff ? user?.staff?.staff_u_id : user?.user_id },
      async (result) =>{
        console.log('RESULT ON SUCCESS', result)
        if (result?.data?.err_message) 
        {
        _showMessage(result?.data?.err_message, 'error');
        }
        else if(result?.data == true)
        {
          _showMessage('Ticket Deleted', 'success');
        }


        await getAllTicketJobs(
          {
            page: 1,
            data: {
              id: job_id ? job_id : id,
              estatus: ticketStatus,
              compacctnum: prop_data ? prop_data?.compacctnum : user.company.compacctnum,
            },
          },
          (e) => {
            console.log(e);
          }
        );

        setDeleteTicketLoading(false)
      },
      (error) => {
        console.log(error);
        _showMessage('Error','error');
        setDeleteTicketLoading(false)
      }
    );
    reset(clearForm);
    handleClose();
    
  };

  const open_reqdelete = async (n) => {
    //let {id, compacctnum, request_info, type, date} = info
    setopenreq_delete(true);
    setDelete(n.tk_id);
  };
  const req_delete_ticket = async () => {
    await client.post(`/requests/`, {
      id: prop_data
        ? prop_data.id
        : user?.company?.staff_id == '0'
        ? user.user_id
        : user?.company?.staff_id,
      compacctnum: comp_acct,
      request_info: tk_id,
      type: 'clone ticket delete',
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:mm'),
      reason: req_reason,
    });
    setreq_reason('');
    setopenreq_delete(false);
    _showMessage('Request Created', 'success');
  };

  const open_reqedit = async (n) => {
    //let {id, compacctnum, request_info, type, date} = info
    setopenreq_edit(true);
    setDelete(n.tk_id);
  };
  const req_edit_ticket = async () => {
    if (req_reason.length <= 0) {
      return _showMessage('Please enter the details for the edit', 'warning');
    } else {
      await client.post(`/requests/`, {
        id: prop_data
          ? prop_data.id
          : user?.company?.staff_id == '0'
          ? user?.id
          : user?.company?.staff_id,
        compacctnum: comp_acct,
        request_info: tk_id,
        type: 'clone ticket edit',
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        reason: req_reason,
      });
      setreq_reason('');
      setopenreq_edit(false);
      _showMessage('Request Created', 'success');
    }
  };

  const open_reqtransfer = async (n) => {
    //let {id, compacctnum, request_info, type, date} = info
    setopenreq_transfer(true);
    setDelete(n.tk_id);
  };
  const req_transfer_ticket = async () => {//
    if (newticketjob == null || newticketjob == undefined) {
      return _showMessage('Please select a valid job', 'error');
    } else {
      await client.post(`/requests/`, {
        id: prop_data
          ? prop_data.id
          : user?.company?.staff_id == '0'
          ? user?.user_id
          : user?.company?.staff_id,
        compacctnum: comp_acct,
        request_info: tk_id,
        type: 'clone ticket transfer',
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        reason: req_reason,
        job_id: newticketjob.job_id,
      });
      setreq_reason('');
      setopenreq_transfer(false);
      _showMessage('Request Created', 'success');
    }
  };

  async function editTicket() {
    const {
      tk_qty: qty,
      tk_scale_no: scale_no,
      tk_datecreated,
      tk_wait_start,
      tk_wait_end,
      truck_id,
      tk_status,
      tk_job_no,
      driver_id,
      tk_start,
      tk_end,
    } = getValues();

    const data = {
      tk_id,
      qty,
      scale_no,
      tk_datecreated,
      truck_id: tickettype == 'EMP' ? truck_id : null,
      tk_status,
      tk_job_no,
      driver_id: tickettype == 'EMP' ? driver_id: null,
      is_op: tickettype == 'OP' ? true : false,
      send_emails: sendEmails,
    };

    data.tk_wait_start = tk_wait_start ? tk_wait_start : null;
    data.tk_wait_end = tk_wait_end ? tk_wait_end : null;
    data.tk_start = tk_start ? tk_start : null;
    data.tk_end = tk_end ? tk_end : null;

    const comp_acct = prop_data ? prop_data.compacctnum : user?.company?.compacctnum;
    data.comp_acct = comp_acct;

    data.user_id = prop_data ? prop_data.compacctnum : user?.staff ? user?.staff?.staff_u_id : user?.user_id;

    ButtonLog({
      button_name: `edit ticket id : ${tk_id} `,
    });

    await editJobTicket(
      data,
      async (result) => {
        if (result?.message) {
          _showMessage(`${result.message}`, 'success');
          setSendEmails(false);
        }

        if (result?.err_message) {
          _showMessage(`${result.err_message}`, 'error');
          setSendEmails(false);
        }

        if (result?.confirmation) {
          _showMessage(
            `the ticket with number ${result.tk_no} has been edited correctly!`,
            'success'
          );
          setSendEmails(false);
        }

        await getAllTicketJobs(
          {
            page: 1,
            data: {
              id: job_id ? job_id : id,
              estatus: ticketStatus,
              compacctnum: prop_data ? prop_data?.compacctnum : user.company.compacctnum,
            },
          },
          (e) => {
            console.log(e);
          }
        );
      },
      (error) => {
        console.log(error);

        _showMessage(`${error}`, 'error');
      }
    );

    handleCloseEdit();
    setTimeStart(null);
    setTimeEnd(null);
    reset(clearForm);
  }

  async function getScaleImage(data) {
    setIsOpen(true);
    setScaleImage(data);
  }
  async function getSingleTicketData(data) {
    if(ticketStatus != 3) 
   { let duration = await getTimeDuration(data);
    let tkDuration = await getTkStartEndTime(data);
    if (duration || tkDuration) {
      data.duration_time = duration;
      data.tk_start_end_duration = tkDuration;
    }
    setModalShow(true);
    setTicketPop(data);}
  }

  const handleClick = async (event, data) => {
    const selectedIndex = selected.findIndex((x) => x.tk_no == data.tk_no);
    let newSelected = [];

    if (selectedIndex === -1) {
      let duration = await getTimeDuration(data);
      let tkDuration = await getTkStartEndTime(data);
      if (duration || tkDuration) {
        data.duration_time = duration;
        data.tk_start_end_duration = tkDuration;
      }
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    //console.log('newSelected', newSelected);
    setSelected(newSelected);
  };

  const isSelectedElement = (ticket_id) => {
    //selected?.findIndex((x) => x.ticket_id == ticket_id) !== -1;
    //console.log("selected: ",selected?.findIndex((x) => x.ticket_id == ticket_id))
    if (selected?.findIndex((x) => x.ticket_id == ticket_id) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleClose = () => {
    setModalShow(false);
    setOpen(false);
    setSendEmails(false);
  };

  const handleClickOpen = (n) => {
    setOpen(true);
    setDelete(n.tk_id);
    setTicketType(n.ticket_owner_type);
  };

  const handleChangeStart = (position, starttime) => {
    let tk_start = getValues('tk_start');
    for (let i = 0; i < tk_start.length; i++) {
      if (position == i) {
        tk_start[i] = starttime;
      }
    }
    setTkStart(tk_start);
  };

  const handleChangeEnd = (position, endtime) => {
    let tk_end = getValues('tk_end');
    for (let i = 0; i < tk_end.length; i++) {
      if (position == i) {
        tk_end[i] = endtime;
      }
    }
    setTkEnd(tk_end);
  };

  const handleChangeWaitStart = (position, waitstarttime) => {
    let tk_wait_start = getValues('tk_wait_start');
    for (let i = 0; i < tk_wait_start.length; i++) {
      if (position == i) {
        tk_wait_start[i] = waitstarttime;
      }
    }
    setTkwaitstart(tk_wait_start);
  };

  const handleChangeWaitEnd = (position, waitendtime) => {
    let tk_wait_end = getValues('tk_wait_end');
    for (let i = 0; i < tk_wait_end.length; i++) {
      if (position == i) {
        tk_wait_end[i] = waitendtime;
      }
    }
    setTkwaitend(tk_wait_end);
  };

  const handleClickEdit = (n) => {
    setOpenEdit(true);
    setTicketType(n.ticket_owner_type);
    setDelete(n.tk_id);
    settkNo(n.tk_no);
    setValue('tk_qty', n.tk_qty);
    setValue('tk_scale_no', n.tk_scale_no);
    setValue('tk_datecreated', n.date_created);
    setValue('truck_id', n.truck_id);
    setValue('tk_status', n.tk_status);
    setValue('tk_job_no', n.tk_job_no);
    setValue('driver_id', n.driver_id);
    console.log('tk:: start', n.tk_start);
    if (n.tk_start) {
      for (let i = 0; i < n.tk_start.length; i++) {
        const format_start =
          moment.tz(n.tk_start[i], moment.tz.guess(true)).format('HH:mm:ss') == 'Invalid date'
            ? moment
                .tz(`${moment().format('YYYY-MM-DD')} ` + n.tk_start[i], moment.tz.guess(true))
                .format()
            : moment.tz(n.tk_start[i], moment.tz.guess(true)).format();
        console.log('format_start', format_start);
        n.tk_start[i] = format_start;
      }
      setTkStart(n.tk_start);
      setValue('tk_start', n.tk_start);
    }

    if (n.tk_end) {
      for (let i = 0; i < n.tk_end.length; i++) {
        const format_end =
          moment.tz(n.tk_end[i], moment.tz.guess(true)).format('HH:mm:ss') == 'Invalid date'
            ? moment
                .tz(`${moment().format('YYYY-MM-DD')} ` + n.tk_end[i], moment.tz.guess(true))
                .format()
            : moment.tz(n.tk_end[i], moment.tz.guess(true)).format();
        console.log('format_end', format_end);
        n.tk_end[i] = format_end;
      }
      setTkEnd(n.tk_end);
      setValue('tk_end', n.tk_end);
    }

    if (n.tk_wait_start) {
      for (let i = 0; i < n.tk_wait_start.length; i++) {
        const format_start =
          moment.tz(n.tk_wait_start[i], moment.tz.guess(true)).format('HH:mm:ss') == 'Invalid date'
            ? moment
                .tz(`${moment().format('YYYY-MM-DD')} ` + n.tk_wait_start[i], moment.tz.guess(true))
                .format()
            : moment.tz(n.tk_wait_start[i], moment.tz.guess(true)).format();
        n.tk_wait_start[i] = format_start;
      }
      setTkwaitstart(n.tk_wait_start);
      setValue('tk_wait_start', n.tk_wait_start);
    }

    if (n.tk_wait_end) {
      for (let i = 0; i < n.tk_wait_end.length; i++) {
        const format_end =
          moment.tz(n.tk_wait_end[i], moment.tz.guess(true)).format('HH:mm:ss') == 'Invalid date'
            ? moment
                .tz(`${moment().format('YYYY-MM-DD')} ` + n.tk_wait_end[i], moment.tz.guess(true))
                .format()
            : moment.tz(n.tk_wait_end[i], moment.tz.guess(true)).format();
        n.tk_wait_end[i] = format_end;
      }
      setTkwaitend(n.tk_wait_end);
      setValue('tk_wait_end', n.tk_wait_end);
    }
  };

  const handleCloseEdit = () => {
    setModalShow(false);
    setOpenEdit(false);
    setSendEmails(false);
  };

  const JobTransfer = async () => {
    console.log('Job Transfer', selected);
    if (newticketjob == null || newticketjob == undefined) {
      return _showMessage('Please select a valid job', 'error');
    }
    if (insertInvoice == true && selectedInvoice == null) {
      return _showMessage('Please select a valid invoice', 'error');
    }
    if(newticketjob.job_id == id)
    {
      return _showMessage(`Cannot transfer tickets to the same job`, 'error');
      
    }
    setJobTransfer(false);
    setLoadingBar(true);

    const button_log_tickets = selected.map((e) => {
      return{tk_id: e.tk_id, tk_no: e.tk_no, is_op: e.ticket_owner_type == 'OP' ? true : false, ticket_owner_type:e.ticket_owner_type}
    });


    ButtonLog({
      button_name: `ticket job transfer w/${
        leaveTkAlone
          ? ' not invoice ticket'
          : singleInvoice
          ? ' invoice alone'
          : insertInvoice
          ? ` insert into invoice: ${selectedInvoice.value}`
          : ' not invoice ticket'
      } --- transferring these tickets: ${JSON.stringify(button_log_tickets)} from job id ${id ? id : job_id} to job no. ${
        newticketjob.value
      } `,
    });

    for(const e of selected)
    {
      

      if(e.ticket_owner_type == 'SUB')
      {
        
        await client.post(`/requests/`, {
        id: prop_data
          ? prop_data.id
          : user?.company?.staff_id == '0'
          ? user?.user_id
          : user?.company?.staff_id,
        compacctnum: comp_acct,
        request_info: e.tk_id,
        type: 'clone ticket transfer',
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        reason: 'transferring ticket',
        job_id: newticketjob.job_id,
        });
      }

    }

    const tickets = selected.map((e) => {
      if(e.ticket_owner_type != 'SUB'){ return{tk_id: e.tk_id, tk_no: e.tk_no, is_op: e.ticket_owner_type == 'OP' ? true : false, ticket_owner_type:e.ticket_owner_type} };
    });

    if(tickets.length > 0)
    {

    

      await transferJobTickets(
        {
          tickets,
          job_no: newticketjob.value,
          comp_acct: prop_data ? prop_data.compacctnum : user?.company?.compacctnum,
          send_emails: sendEmails,
          invoice_id: selectedInvoice ? selectedInvoice.value : null,
          inv_option: leaveTkAlone ? 0 : singleInvoice ? 1 : insertInvoice ? 2 : 0,
          user_id: user?.staff ? user?.staff?.staff_u_id : user?.user_id,
        },
        async (result) => {
          console.log(result);
          if (
            result?.data?.complete &&
            result?.data?.reinvoice &&
            result.data.reinvoice.length == 0
          ) {
            _showMessage(`Job Transfer Complete`, 'success');
            setSendEmails(false);
          }

          if (result?.data?.err_message) {
            _showMessage(result.data.err_message, 'error');
            setSendEmails(false);
          }

          if (result?.data?.message) {
            _showMessage(result.data.message, 'error');
            setSendEmails(false);
          }

          if (result?.data?.complete && result?.data?.reinvoice && result.data.reinvoice.length > 0) {
            _showMessage(`Job Transfer Complete`, 'success');
            setSendEmails(false);
            //_showMessage(`Job Transfer Complete. Tickets Reinvoiced : ${result.data.reinvoice}`,'success')
          }

          setSelected([]);
          await getAllTicketJobs(
            {
              page: 1,
              data: {
                id: job_id ? job_id : id,
                estatus: ticketStatus,
                compacctnum: prop_data ? prop_data?.compacctnum : user.company.compacctnum,
              },
            },
            (e) => {
              console.log(e);
            }
          );
          setInsertInvoice(false);
          setLoadingBar(false);
          setNewTicketJob();
          setTransferSearch('');
          setLeaveTkAlone(true);
          setSingleInvoice(false);
          setSelectedInvoice(null);
          setJobInvoices({ job_id: null, invoices: [] });
        },
        (error) => {
          if (error) {
            console.log(error);
            // return _showMessage(error,'error')
            setLoadingBar(false);
          }
        }
      );
    }
    //must deselect selected and then call tickets to load again
  };

  useEffect(async () => {
    let key = prop_data ? prop_data?.logo : user.company?.logo;
    let logoRes = await getS3Location(key);
    if (logoRes && logoRes.data && logoRes.data.data) {
      logoRes.data.data.logoKey = key;
      setCompLogo(logoRes.data.data);
    }
  }, [prop_data?.logo, user.company?.logo]);

  const onScroll = async (event) => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight, scrollLeft, offsetHeight } =
        listInnerRef.current;
      const allow = scrollLeft > scrollX || scrollLeft < scrollX ? false : true;
      setScrollX(scrollLeft);
      if (offsetHeight + scrollTop >= scrollHeight && allow) {
        if (hasNextPage && !search && !date1 && !date2) {
          /* This will be triggered after hitting the last element. API call should be made here while implementing pagination. */
          await getAllTicketJobs(
            {
              page: page + 1,
              data: {
                id: job_id ? job_id : id,
                estatus: ticketStatus,
                compacctnum: prop_data ? prop_data?.compacctnum : user.company.compacctnum,
              },
            },
            (err) => {
              console.log(err);
            }
          );
        }
      }
    }
  };

  useEffect(async () => {
    setSelected([]);
    if (search && search.length > 2) {
      await searchJobTickets(
        {
          id: job_id ? job_id : id,
          searchText: search,
          accountNumber: compacctnum,
          selectedTab: ticketStatus,
        },
        (error) => {
          console.log(error);
        }
      );
    }

    if (!search) {
      if (date1 && date2) {
        onSearchDate();
      }
      else
      {
        await getAllTicketJobs(
          {
            page: 1,
            data: {
              id: job_id ? job_id : id,
              estatus: ticketStatus,
              compacctnum: prop_data ? prop_data?.compacctnum : user.company.compacctnum,
            },
          },
          (err) => {
            console.log(err);
          }
        ); 
      }
    }
  }, [search]);

  useEffect(async () => {
    setSelected([]);
    if (date1 && date2) {
      onSearchDate();
    }
    if (!date1 && !date2 && !search) {
      await getAllTicketJobs(
        {
          page: 1,
          data: {
            id: job_id ? job_id : id,
            estatus: ticketStatus,
            compacctnum: prop_data ? prop_data?.compacctnum : user.company.compacctnum,
          },
        },
        (err) => {
          console.log(err);
        }
      );
    }
    else if(!date1 && !date2 && search && search.length > 2)
    {
      await searchJobTickets(
        {
          id: job_id ? job_id : id,
          searchText: search,
          accountNumber: compacctnum,
          selectedTab: ticketStatus,
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [date1, date2, ticketSearch]);

  useEffect(async () => {
    setSelected([]);
    if (!date1 && !date2 && !search) {
      await getAllTicketJobs(
        {
          page: 1,
          data: {
            id: job_id ? job_id : id,
            estatus: ticketStatus,
            compacctnum: prop_data ? prop_data?.compacctnum : user.company.compacctnum,
          },
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [ticketStatus]);

  useEffect(async () => {
    if (jobTransfer) {
      await getAllJobs({ page: 1, filter: null, compacctnum }, (error) => {
        console.log(error);
      });
    }
  }, [jobTransfer]);

  useEffect(async () => {
    const small_comp_id = prop_data ? prop_data.company_id : user?.company?.company_id;
    const result = jobs.map((e) => {
      return { value: e.job_no, label: small_comp_id + '-' + e.job_no, job_id: e.job_info_id };
    });
    setAllJobs(result);
  }, [jobs]);

  useEffect(async () => {
    console.log('get drivers and trucks');
    setTruckDriverLoading(true);
    getDrivers({ compacctnum }, (error) => {
      console.log(error);
    });

    getTrucks({ comp_acct: prop_data ? prop_data.id : user?.user_id}, (error) => {
      console.log(error);
    });
    setTruckDriverLoading(false);
  }, []);

  const renderEditButton = (n) => {
    if (hasPermission('ticket.update') || prop_data) {
      return (
        <IconButton
         onClick={(event) =>
          n.ticket_owner_type == 'SUB'
        ? open_reqedit(n)
        : n.ticket_owner_type == 'OP'
        ? handleClickEdit(n)
        : handleClickEdit(n)
          }
        >
          <Icon style={{ color: '#edca33' }}>edit</Icon>
        </IconButton>
      );
    }
  };

  const renderDeleteButton = (n) => {
    if (hasPermission('ticket.delete') || prop_data) {
      return (
        <IconButton
        onClick={(event) =>
          n.ticket_owner_type == 'SUB'
            ? open_reqdelete(n)
            : n.ticket_owner_type == 'OP'
            ? handleClickOpen(n)
            : handleClickOpen(n)
        }
      >
        <Icon style={{ color: '#edca33' }}>delete</Icon>
      </IconButton>
      );
    }
  };
  
  const renderTicketAudit = (n) => {
    if (hasPermission('ticket.browse') || prop_data) {
      return (
        <IconButton
        onClick={() =>
          getTicketAudit(n)
        }
      >
        <Icon style={{ color: '#edca33' }}>info</Icon>
      </IconButton>
      );
    }
  };
  const handleSearch = async (e) => {
    console.log(e.target.value);
    setTransferSearch(e.target.value);
    if (e.target.value.length > 0) {
      if (e.target.value.replace(/\s/g, '').length > 0) {
        await searchJobs(
          { page: 1, text: e.target.value, search: 'archive', compacctnum },
          (error) => {
            console.log(error);
          }
        );
      }
    } else {
      getAllJobs({ page: 1, filter: null, compacctnum });
    }
  };

  const getFormatedTime = (tk_time) => {
    const newtk = [];
    if (tk_time) {
      tk_time.forEach(function (element, indice) {
        if (newtk.length > 0) {
          newtk.push(' - ');
        }
        if (element.length > 10) {
          newtk.push(moment(element).format('hh:mm:ss A'));
        } else {
          newtk.push(moment(element, 'HH:mm:ss').format('hh:mm:ss A'));
        }
      });
    }

    return newtk;
  };

  const [jobInvoices, setJobInvoices] = useState({ job_id: null, invoices: [] });

  useEffect(async () => {
    if (newticketjob?.job_id && insertInvoice && jobTransfer == true) {
      if (jobInvoices?.job_id != newticketjob?.job_id) {
        const result = await client.post('invoices/job-invoices', {
          job_id: newticketjob?.job_id,
          compacctnum: comp_acct,
        });
        console.log('result >> ', result.data);
        if (result.data.length == 0) {
          setJobInvoices({ job_id: newticketjob?.job_id, invoices: null });

          return _showMessage('No Invoices Found', 'warning');
        } else {
          const fixed_invoices = result.data.map((e) => {
            return {
              value: e.id,
              label: `Invoice No: ${e.invoice_no} Balance: ${e.invoice_balance}`,
            };
          });

          setJobInvoices({ job_id: newticketjob?.job_id, invoices: fixed_invoices });
        }
      }
    }

    if (!newticketjob?.job_id && insertInvoice && jobTransfer == true) {
      _showMessage('Select a Job', 'warning');
    }
  }, [insertInvoice, newticketjob]);

  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [invoiceTransfer, setInvoiceTransfer] = useState(false);

  const InvoiceTransfer = async () => {
    if (selectedInvoice == null) {
      return _showMessage('Please select an invoice', 'error');
    }
    console.log('Current Job Id >> ', id);
    console.log('Selected Tickets >> ', selected);
    let tickets = selected.map((e) => {
      if (Number(e.invoice_id) != selectedInvoice.value) {
        return e.tk_id;
      }
    });
    tickets = tickets.filter((e) => e != null);
    console.log('Selected Invoice >> ', selectedInvoice);
    const invoice_id = selectedInvoice.value;
    console.log('Send emails >> ', sendEmails);
    const sendEmail = sendEmails;
    ButtonLog({
      button_name: `ticket invoice transfer --- transferring these tickets: ${JSON.stringify(tickets)} in job id ${
        id ? id : job_id
      } to invoice id ${invoice_id}`,
    });
    const result = await client.post('invoices/move-tk-invoice', {
      tickets,
      invoice_id,
      sendEmail,
      user_id: user?.staff ? user?.staff?.staff_u_id : user?.user_id,
    });

    console.log('result >> ', result.data);
    if (result.data.message == 'success') {
      _showMessage('Success', 'success');
      setSendEmails(false);
      setInvoiceTransfer(false);
      setSelectedInvoice(null);
      setJobInvoices({ job_id: null, invoices: [] });
      setSelected([]);
    } else {
      _showMessage('Error', 'error');
      setSendEmails(false);
      setInvoiceTransfer(false);
      setSelectedInvoice(null);
      setJobInvoices({ job_id: null, invoices: [] });
      setSelected([]);
    }
  };

  useEffect(async () => {
    if (invoiceTransfer == true) {
      if (jobInvoices?.job_id != id) {
        setInvoicesLoading(true);
        const result = await client.post('invoices/job-invoices', {
          job_id: id,
          compacctnum: comp_acct,
        });
        console.log('result >> ', result.data);
        setInvoicesLoading(false);
        if (result.data.length == 0) {
          setJobInvoices({ job_id: id, invoices: null });

          return _showMessage('No Invoices Found', 'warning');
        } else {
          const fixed_invoices = result.data.map((e) => {
            return {
              value: e.id,
              label: `Invoice No: ${e.invoice_no} Balance: ${e.invoice_balance}`,
            };
          });

          setJobInvoices({ job_id: id, invoices: fixed_invoices });
        }
      }
    }
  }, [invoiceTransfer]);

  const checkJobTransfer = async () => {// used to prevent multi transfers for tickets taht belong to another company
   /*  let not_open = false;

    await Promise.all(
      selected.map((e) => {
        if (e.ticket_owner_type == 'SUB') {
          not_open = true;
        }
      })
    );

    if (not_open == true) {
      return _showMessage(
        'One or more of the tickets that you have selected belongs to a Subcontractor. If you want to transfer these tickets create a request.',
        'warning'
      );
    } else {
      setJobTransfer(true);
    } */

    setJobTransfer(true);
  };

  
  const [driverOptions,setDriverOptions] = useState([]);

  useEffect(() => {
    if(drivers && drivers.length > 0){
      drivers.sort((a, b) => {
        const nameA = a.user_fname.toUpperCase() + a.user_lname.toUpperCase(); // Ignore case
        const nameB = b.user_fname.toUpperCase() + b.user_lname.toUpperCase(); // Ignore case
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
    
      const driverOptions = drivers.map((driver) => {
        return {
          value: driver.dr_user_id,
          label: driver.user_fname+" "+driver.user_lname,
        };
      });

      //make sure there are no duplicates in driverOptions array
      const uniqueDriverOptions = driverOptions.filter((driver, index, self) =>
        index === self.findIndex((t) => (
          t.value === driver.value
        ))
      );
      
      setDriverOptions(uniqueDriverOptions);
    }
  },[drivers])

  const [ticketAudit,setTicketAudit] = useState(false);
  const [ticketAuditData,setTicketAuditData] = useState([]);
  const [auditLoading,setAuditLoading] = useState(false);
  const getTicketAudit = async (ticket) => {
    try {
      
    
    //console.log('Ticket info: ',ticket)
    setTicketAudit(true)
    setAuditLoading(true);

    const result = await client.post(`ticket/audit`,{tk_id:ticket.tk_id,compacctnum:ticket.c_id});
    //console.log('data: ',result)

    if(result.data.length)
    {
      setTicketAuditData(result.data);
    }
    else
    {
      setTicketAuditData([]);
    }
    setAuditLoading(false);
    } catch (error) {
      setAuditLoading(false);
      console.log(error)
      setTicketAuditData([]);
      _showMessage('Error','error');
    }
  }

  return (
    <div style={{ marginBottom: '5rem' }}>

     <Dialog open={deleteTicketLoading}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
        <div className='flex flex-row items-center align-center justify-center w-full h-full'>
          <CircularLoader/>
        </div>
        
    </Dialog>

      {/**Ticket Audit Data  */}
    <Dialog open={ticketAudit} maxWidth={'md'}>
      <DialogTitle sx={{boxShadow:'3'}}><Typography variant='h4'>Ticket Audit</Typography></DialogTitle>
      <DialogContent className='flex flex-col items-center text-center align-center justify-center overflow-y-auto' sx={{maxHeight:'500px'}}>
        {
          auditLoading ? <div className='pt-12'><CircularLoader/></div> :
          ticketAuditData.length == 0 ? 
          <div className='pt-12'><Typography variant='h6'>No Audit Data Found</Typography></div>
          
          :
          
          <Timeline sx={{height:'40vh'}}>
          {
            ticketAuditData.map((audit) => {
              return(<TimelineItem>
                <TimelineOppositeContent display={'none'}/>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <span>{audit.name+' made a change to the ticket:'}</span><br/><span>{audit.description}{' '}</span>on{' '}{moment(audit.date).format('MMMM Do YYYY, h:mm:ss a')}
                </TimelineContent>
              </TimelineItem>)
            })
          }
        </Timeline>
        }
        
      </DialogContent>
      <DialogActions sx={{boxShadow:'5'}}>
        <Button onClick={() => setTicketAudit(false)}>Close</Button>
      </DialogActions>
    </Dialog>


      <ModalViewScale
        scaleImage={scaleImage}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></ModalViewScale>

      <Dialog
        fullWidth={false}
        maxWidth={'sm'}
        open={jobTransfer}
        onClose={() => {
          setJobTransfer(false);
          setNewTicketJob();
          setTransferSearch('');
          setLeaveTkAlone(true);
          setSingleInvoice(false);
          setInsertInvoice(false);
          setSelectedInvoice(null);
          setJobInvoices({ job_id: null, invoices: [] });
        }}
      >
        <DialogTitle>Ticket Job Transfer</DialogTitle>
        <DialogContent
          className="flex flex-row"
          sx={{ width: 450 }}
        >
          <Autocomplete
            disableListWrap
            clearOnBlur={false}
            freeSolo={true}
            id="job-transfer-box"
            value={newticketjob}
            onChange={(e, newValue) => {
              console.log(newValue);
              setNewTicketJob(newValue);
            }}
            options={allJobs}
            loading={true}
            sx={{ width: 300, padding: 0 }}
            ListboxProps={{
              onScroll: async (event) => {
                const listboxNode = event.currentTarget;
                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                  if (jobTransfer && hasNextJob) {
                    //if search then scroll on search
                    if (transfersearch.length > 0) {
                      if (transfersearch.replace(/\s/g, '').length > 0) {
                        await searchJobs(
                          {
                            page: jobPage + 1,
                            text: event.target.value,
                            search: 'archive',
                            compacctnum,
                          },
                          (error) => {
                            console.log(error);
                          }
                        );
                      }
                    } else {
                      getAllJobs({ page: jobPage + 1, filter: null, compacctnum });
                    }
                  }
                }
              },
              style: { maxHeight: '15rem' },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Job"
                sx={{ padding: 0 }}
                onChange={(e) => handleSearch(e)}
              />
            )}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
          />

          <div className=" flex flex-row pl-16 items-center">
            <Tooltip title="Invoicing options only affect Owner Operator and Employee tickets, the rest by default will be un-invoiced" placement='right-start'>

              <Button
                variant="contained"
                sx={{ height: '45px' }}
                onClick={() => JobTransfer()}
              >
                <Typography fontWeight={'bold'}>Transfer</Typography>
              </Button>
            </Tooltip>
          </div>
        </DialogContent>
        <div className="flex flex-row w-full pl-24 pb-8">
          {insertInvoice && jobInvoices?.invoices && jobInvoices?.invoices.length > 0 ? (
            <Autocomplete
              disableListWrap
              clearOnBlur={false}
              freeSolo={false}
              id="invoice-transfer-box"
              value={selectedInvoice}
              onChange={(e, newValue) => {
                console.log(newValue);
                setSelectedInvoice(newValue);
              }}
              options={jobInvoices?.invoices}
              loading={true}
              sx={{ width: 300, padding: 0 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Invoice"
                  sx={{ padding: 0 }}
                />
              )}
              renderOption={(props, option) => <li {...props}>{option.label}</li>}
            />
          ) : null}
        </div>

        <div className="flex flex-row align-center items-center justify-end w-full pb-8 pr-16 text-black">
          <Checkbox
            className="p-0"
            checked={sendEmails}
            onChange={(e) => {
              setSendEmails(e.target.checked);
            }}
          />
          Send updated Invoice to customer
        </div>

        {/**look here */}
        <div className="flex flex-row align-center items-center justify-end w-full pb-8 pr-16 text-black">
          <Checkbox
            className="p-0"
            checked={leaveTkAlone}
            onChange={(e) => {
              setLeaveTkAlone(true);
              setInsertInvoice(false);
              setSingleInvoice(false);
              setSelectedInvoice(null);
            }}
          />
          Do Not Invoice Tickets
        </div>

        <div className="flex flex-row align-center items-center justify-end w-full pb-8 pr-16 text-black">
          <Checkbox
            className="p-0"
            checked={insertInvoice}
            onChange={(e) => {
              setInsertInvoice(true);
              setLeaveTkAlone(false);
              setSingleInvoice(false);
              setSelectedInvoice(null);
            }}
          />
          Add to Existing Invoice
        </div>

        <div className="flex flex-row align-center items-center justify-end w-full pb-8 pr-16 text-black">
          <Checkbox
            className="p-0"
            checked={singleInvoice}
            onChange={(e) => {
              setSingleInvoice(true);
              setLeaveTkAlone(false);
              setInsertInvoice(false);
              setSelectedInvoice(null);
            }}
          />
          Create New Invoice
        </div>

        <DialogActions>
          <Button
            onClick={() => {
              setSendEmails(false);
              setJobTransfer(false);
              setNewTicketJob();
              setSingleInvoice(false);
              setLeaveTkAlone(false);
              setInsertInvoice(false);
              setSelectedInvoice(null);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/**Invoice Transfer */}
      <Dialog
        fullWidth={false}
        maxWidth={'sm'}
        open={invoiceTransfer}
        onClose={() => {
          setSendEmails(false);
          setInvoiceTransfer(false);
          setSelectedInvoice(null);
          setJobInvoices({ job_id: null, invoices: [] });
        }}
      >
        <DialogTitle>Transfer to Existing Invoice</DialogTitle>
        <DialogContent
          className="flex flex-row"
          sx={{ width: 450 }}
        >
          {invoicesLoading == true ? (
            <CircularLoader />
          ) : invoiceTransfer && jobInvoices?.invoices && jobInvoices?.invoices.length > 0 ? (
            <Autocomplete
              disableListWrap
              clearOnBlur={false}
              freeSolo={false}
              id="invoice-transfer-box"
              value={selectedInvoice}
              onChange={(e, newValue) => {
                console.log(newValue);
                setSelectedInvoice(newValue);
              }}
              options={jobInvoices?.invoices}
              loading={true}
              sx={{ width: 300, padding: 0 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Invoice"
                  sx={{ padding: 0 }}
                />
              )}
              renderOption={(props, option) => <li {...props}>{option.label}</li>}
            />
          ) : (
            <div className="flex flex-row w-full items-center justify-center align-center">
              <Typography variant="h4">No Invoices Found</Typography>
            </div>
          )}

          {invoicesLoading == true ? null : invoiceTransfer &&
            jobInvoices?.invoices &&
            jobInvoices?.invoices.length > 0 ? (
            <div className=" flex flex-row pl-16 items-center">
              <Button
                variant="contained"
                sx={{ height: '45px' }}
                onClick={InvoiceTransfer}
              >
                <Typography fontWeight={'bold'}>Transfer</Typography>
              </Button>
            </div>
          ) : null}
        </DialogContent>
        {invoiceTransfer && jobInvoices?.invoices && jobInvoices?.invoices.length > 0 ? (
          <div className="flex flex-row align-center items-center justify-end w-full pb-8 pr-16 text-black">
            <Checkbox
              className="p-0"
              checked={sendEmails}
              onChange={(e) => {
                setSendEmails(e.target.checked);
              }}
            />
            Send updated Invoice to customers
          </div>
        ) : null}
        <DialogActions>
          <Button
            onClick={() => {
              setSendEmails(false);
              setInvoiceTransfer(false);
              setSelectedInvoice(null);
              setJobInvoices({ job_id: null, invoices: [] });
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={loadingBar}
        onClose={() => {
          setLoadingBar(false);
        }}
      >
        <FuseLoading />
      </Dialog>

      <Paper className="responsive-loads-tabless">
        {selected && selected.length > 0 ? (
          <div class="ticket-table-icon">
            <ReactToPrint
              trigger={() => (
                <ToggleButton aria-label="print">
                  <img
                    className="w-16"
                    alt="user photo"
                    src="assets/images/icons/print-icon.svg"
                  />
                </ToggleButton>
              )}
              content={() => componentRef.current}
            />
            <div
              style={{ paddingLeft: 20 }}
              className="inline-block"
            >
              <Tooltip
                title="Job Transfer"
                placement="top"
              >
                <IconButton
                  onClick={() => {
                    //open modal

                    //call function
                    checkJobTransfer();
                  }}
                >
                  {' '}
                  <LowPriorityIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div
              style={{ paddingLeft: 20 }}
              className="inline-block"
            >
              <Tooltip
                title="Transfer to Existing Invoice"
                placement="top"
              >
                <IconButton
                  onClick={() => {
                    //open modal
                    setInvoiceTransfer(true);
                  }}
                >
                  {' '}
                  <ReceiptIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ) : (
          ''
        )}

        <TableContainer
          style={{ maxWidth: '100%', maxHeight: '600px' }}
          onScroll={onScroll}
          ref={listInnerRef}
        >
          {React.useMemo(() => {
            return (
              <Table
                stickyHeader
                aria-label="sticky table"
              >
                <TableRow>
                  {ticketStatus == 3 ? null : <TableCell padding="checkbox"></TableCell>}
                  <StyledTableCell style={{ top: 57 }}>Ticket No.</StyledTableCell>
                  <StyledTableCell style={{ top: 57 }}>Qty.</StyledTableCell>
                  <StyledTableCell style={{ top: 57 }}>Material.</StyledTableCell>
                  <StyledTableCell style={{ top: 57 }}>Driver.</StyledTableCell>
                  <StyledTableCell style={{ top: 57 }}>Truck No.</StyledTableCell>
                  {jobTickets.length > 0 && jobTickets.some((ticket) => !ticket?.commissions) ? (
                    <StyledTableCell style={{ top: 57 }}>TK Start.</StyledTableCell>
                  ) : (
                    ''
                  )}

                  {jobTickets.length > 0 && jobTickets.some((ticket) => !ticket?.commissions) ? (
                    <StyledTableCell style={{ top: 57 }}>TK End.</StyledTableCell>
                  ) : (
                    ''
                  )}

                  <StyledTableCell style={{ top: 57 }}>Wait Time.</StyledTableCell>

                  <StyledTableCell style={{ top: 57 }}>{ticketStatus == 3 ? 'Deleted Date' : 'Date Time Updated.'}</StyledTableCell>

                  {jobTickets.length > 0 && jobTickets.some((ticket) => ticket.scale == true) ? (
                    <StyledTableCell style={{ top: 57 }}>Scale Ticket.</StyledTableCell>
                  ) : (
                    ''
                  )}

                  <StyledTableCell style={{ top: 57 }}>Received By.</StyledTableCell>

                  {ticketStatus == 3 ? <StyledTableCell style={{ top: 57 }}>Reason</StyledTableCell> : null}

                  {(ticketStatus == 1 || ticketStatus == 3) && (
                    <StyledTableCell style={{ top: 57 }}>Owner.</StyledTableCell>
                  )}

                  <StyledTableCell style={{ top: 57 }}></StyledTableCell>
                </TableRow>
                <TableBody>
                  {jobTickets.length ? (
                    <>
                      {jobTickets.map((n, i) => {
                        //console.log('n', n);
                        const isSelected = isSelectedElement(n.ticket_id);
                        return (
                          <ErrorBoundary>
                            <StyledTableRow  key={i}>

                              {ticketStatus == 3 ? null :
                              <StyledTableCell padding="checkbox">
                                <Checkbox
                                  checked={isSelected}
                                  onClick={(event) => handleClick(event, n)}
                                />
                              </StyledTableCell>}
                              
                              <StyledTableCell
                                onClick={() => getSingleTicketData(n)}
                                className="p-4 md:p-16 truncate"
                                component="th"
                                scope="row"
                                data-headtext="Ticket No."
                              >
                                <b>{n.tk_no}</b>
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => getSingleTicketData(n)}
                                className="p-3 md:p-12"
                                component="th"
                                scope="row"
                                data-headtext="	Qty."
                              >
                                <b>
                                  {n.tk_qty} {n.unit_name}
                                </b>
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => getSingleTicketData(n)}
                                className="p-4 md:p-16"
                                component="th"
                                scope="row"
                                data-headtext="Material."
                              >
                                <b>{n.name}</b>
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => getSingleTicketData(n)}
                                className="p-4 md:p-16"
                                component="th"
                                scope="row"
                                data-headtext="Driver."
                              >
                                <b>
                                  {n.user_fname} {n.user_lname}
                                </b>
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => getSingleTicketData(n)}
                                className="p-4 md:p-16"
                                component="th"
                                scope="row"
                                data-headtext="	Truck No."
                              >
                                <b>{n.t_no_truck}</b>
                              </StyledTableCell>

                              {jobTickets.length > 0 &&
                              jobTickets.some((ticket) => !ticket?.commissions) ? (
                                <StyledTableCell
                                  onClick={() => getSingleTicketData(n)}
                                  className="p-4 md:p-16"
                                  component="th"
                                  scope="row"
                                  data-headtext="TK Start."
                                >
                                  {/* <b>{n.tk_start}</b> */}
                                  {getFormatedTime(n.tk_start)}
                                </StyledTableCell>
                              ) : (
                                ''
                              )}

                              {jobTickets.length > 0 &&
                              jobTickets.some((ticket) => !ticket?.commissions) ? (
                                <StyledTableCell
                                  onClick={() => getSingleTicketData(n)}
                                  className="p-4 md:p-16"
                                  component="th"
                                  scope="row"
                                  data-headtext="TK End."
                                >
                                  {/* {n.tk_end} */}
                                  {getFormatedTime(n.tk_end)}
                                </StyledTableCell>
                              ) : (
                                ''
                              )}

                              <StyledTableCell
                                onClick={() => getSingleTicketData(n)}
                                className="p-4 md:p-16"
                                component="th"
                                scope="row"
                                data-headtext="Wait Time."
                              >
                                {n.wait_time ? (
                                  <PauseCircleOutlineIcon
                                    sx={{ color: red[600] }}
                                  ></PauseCircleOutlineIcon>
                                ) : (
                                  <PauseCircleOutlineIcon
                                    sx={{ color: grey[600] }}
                                  ></PauseCircleOutlineIcon>
                                )}
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => getSingleTicketData(n)}
                                className="p-4 md:p-16"
                                component="th"
                                scope="row"
                                data-headtext="Date Time Updated."
                              >
                                {moment(n.date_updated).format('LLL') != 'Invalid date' ? moment(n.date_updated).format('LLL') : n.date_updated}
                              </StyledTableCell>

                              {jobTickets.length > 0 &&
                              jobTickets.some((ticket) => ticket.scale == true) ? (
                                <StyledTableCell
                                  className="p-4 md:p-16 text-center items-center justify-center"
                                  component="th"
                                  scope="row"
                                  data-headtext="	Scale Ticket Image."
                                >
                                  {n.tk_scale_no ? (
                                    <>
                                      {n.scale_ticket_image ? (
                                        <div className="flex flex-row items-center justify-center align-center">
                                          <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                              window.open(
                                                `${constants.URLLOCAL}/aws/signed?image=${n.scale_ticket_image}`,
                                                '_blank'
                                              )
                                            }
                                          >
                                            <Typography color={'blue'}>{n.tk_scale_no}</Typography>
                                          </div>

                                          <DownloadIcon
                                            className="cursor-pointer"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              download(
                                                `job${n.job_no}_ticket${n.tk_no}_scale${n.tk_scale_no}`,
                                                `${constants.URLLOCAL}/aws/signed?image=${n.scale_ticket_image}`
                                              );
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <Typography>{n.tk_scale_no}</Typography>
                                      )}
                                    </>
                                  ) : (
                                    <span className="loading-img-list animate-pulse inline-block w-32 h-32 bg-gray-200">
                                      N/A
                                    </span>
                                  )}
                                </StyledTableCell>
                              ) : (
                                ''
                              )}

                              <StyledTableCell 
                                onClick={() => getSingleTicketData(n)}
                                className="p-4 md:p-16"
                                component="th"
                                scope="row"
                                data-headtext="Received By."
                              >

                                <div className='flex flex-row items-center align-center'>

                                
                                {n.tk_receievedby_mediaurl ? (
                                  <img
                                    width="50"
                                    height="70"
                                    src={
                                      n?.tk_receievedby_mediaurl?.includes('http')
                                        ? n.tk_receievedby_mediaurl
                                        : `${constants.URLLOCAL}/aws/signed?image=${n.tk_receievedby_mediaurl}`
                                    }
                                  />
                                ) : (
                                  <p>No Image</p>
                                )}
                                <Typography>{n.l_initial}</Typography>
                                </div>
                              </StyledTableCell>

                              {ticketStatus == 3 && (
                                <StyledTableCell
                                  className="p-4 md:p-16"
                                  component="th"
                                  scope="row"
                                  data-headtext="Reason"
                                >
                                  {<Typography>{n.reason}</Typography>}
                                </StyledTableCell>
                              )}

                              {(ticketStatus == 1 || ticketStatus == 3) && (
                                <StyledTableCell
                                  onClick={() => getSingleTicketData(n)}
                                  className="p-4 md:p-16"
                                  component="th"
                                  scope="row"
                                  data-headtext="ticket type."
                                >
                                  {<Typography>{n.ticket_owner_type}</Typography>}
                                </StyledTableCell>
                              )}

                              <StyledTableCell className='p-0 ' >
                                {ticketStatus != 3 && n.job_type == 'OG' && n.ticket_owner_type == 'SUB' ? (
                                  <Chip
                                    onClick={(event) => open_reqtransfer(n)}
                                    /* label="Single Transfer Request" */
                                    style={{ display: 'flex', alignItems: 'center',background:'none' }}
                                    icon={
                                      <Icon
                                        style={{
                                          color: '#edca33',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <LowPriorityIcon sx={{ fontSize: '18px' }} />
                                      </Icon>
                                    }
                                  />
                                ) : null}
                                {ticketStatus == 3 ? null : renderEditButton(n)}
                                {ticketStatus == 3 ? null : renderDeleteButton(n)}
                                {/*renderTicketAudit(n)*/}
                              </StyledTableCell>

                            </StyledTableRow>
                          </ErrorBoundary>
                        );
                      })}
                    </>
                  ) : null}
                </TableBody>
              </Table>
            );
          }, [jobTickets, search, jobTransfer, jobs, prop_data, jobPage, ticketStatus, selected])}
          {!loading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="flex flex-1 items-center justify-center h-full moble-content"
            >
              <Typography
                color="textSecondary"
                variant="h5"
              >
                No more Data
              </Typography>
            </motion.div>
          ) : null}
        </TableContainer>
        {loading && <LinearProgress />}
      </Paper>

      <TicketPopup
        getTicketAudit={getTicketAudit}
        open={modalShow}
        handleclose={handleClose}
        data={ticketPop}
        logo={logoUrl}
        newLoc={setjDestion}
      />
      <div style={{ display: 'none' }}>
        <TicketMultiplePrint
          ref={componentRef}
          dataList={selected}
          compLogo={compLogo}
          newLoc={setjDestion}
        />
      </div>

      {/* modal para eliminar el ticket */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'black',
            boxShadow: 'none',
          },
        }}
        classes={{
          paper: 'm-24',
        }}
        maxWidth={'md'}
      >
        <DialogContent>
          <DialogContentText color={'#FFFFFF'}>Are you sure you want to delete</DialogContentText>

          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16 "
                id="reason"
                label="reason"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                style={{ backgroundColor: '#FFFFFF' }}
              />
            )}
          />

          <div className="flex flex-row align-center items-center justify-end w-full pb-8 text-white">
            <Checkbox
              className="p-0"
              value={sendEmails}
              onChange={(e) => {
                setSendEmails(e.target.checked);
              }}
            />
            Send updated Invoice to customer
          </div>

          <div className="flex mx-6">
            <Button
              fullWidth
              style={{ backgroundColor: '#edca33' }}
              onClick={handleClose}
            >
              <Typography color={'black'}>No</Typography>
            </Button>
            <Button
              fullWidth
              style={{ backgroundColor: '#FF0000' }}
              onClick={deleteTicket}
            >
              <Typography color={'black'}>Yes</Typography>
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* modal para editar el ticket */}
      <Dialog
        open={openedit}
        onClose={handleCloseEdit}
      >
        {truckDriverLoading ? (
          <FuseLoading />
        ) : (
          <>
            <AppBar
              style={{ backgroundColor: '#edca33' }}
              position="static"
              elevation={0}
            >
              <Toolbar className="flex w-full">
                <Typography
                  variant="subType1"
                  color="black"
                >
                  Edit ticket No: {tk_No}
                </Typography>
              </Toolbar>
            </AppBar>

            <DialogContent>
              <div className="flex mx-6">
                <Controller
                  name="tk_qty"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-2"
                      label="QTY"
                      id="tk_qty"
                      variant="outlined"
                      fullWidth
                      type={'number'}
                    />
                  )}
                />

                <Controller
                  name="tk_scale_no"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16 mx-2"
                      label="Scale No"
                      id="tk_scale_no"
                      variant="outlined"
                      fullWidth
                      type={'number'}
                    />
                  )}
                />
              </div>

              <Divider></Divider>
              <br />
              <Typography className=" mb-12 text-16 font-700">Ticket Start - Ticket End</Typography>
              <br />
              <div className="flex mx-6">
                <div>
                  <Controller
                    name="tk_start"
                    control={control}
                    render={({ field }) => (
                      <>
                        {tkstartFinal.map((inputField, i) => (
                          <div key={inputField}>
                            <Controller
                              name="tk_start"
                              control={control}
                              render={({ field }) => (
                                <TimePicker
                                  label="ticket start"
                                  value={inputField}
                                  onChange={(newValue) => {
                                    let starttime = moment(newValue).format();
                                    handleChangeStart(i, starttime);
                                    setTimeStart(newValue);
                                  }}
                                  renderInput={(_props) => (
                                    <TextField
                                      className="mt-0 mb-16 mx-4"
                                      {..._props}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="tk_end"
                    control={control}
                    render={({ field }) => (
                      <>
                        {tkendFinal.map((inputField, i) => (
                          <div key={i}>
                            <Controller
                              name="tk_end"
                              control={control}
                              render={({ field }) => (
                                <TimePicker
                                  label="ticket end"
                                  value={inputField}
                                  onChange={(newValue) => {
                                    let endtime = moment(newValue).format();
                                    handleChangeEnd(i, endtime);
                                    setTimeEnd(newValue);
                                  }}
                                  renderInput={(_props) => (
                                    <TextField
                                      className="mt-0 mb-16 mx-4"
                                      {..._props}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>
              </div>

              <Divider></Divider>
              <br />
              <Typography className="mb-12 text-16 font-700">Wait Start - Wait End</Typography>
              <br />
              <div className="flex mx-6">
                <div>
                  <Controller
                    name="tk_wait_start"
                    control={control}
                    render={({ field }) => (
                      <>
                        {tkwaitstart.map((inputField, i) => (
                          <div key={i}>
                            <Controller
                              name="tk_wait_start"
                              control={control}
                              render={({ field }) => (
                                <TimePicker
                                  label="wait start"
                                  value={inputField}
                                  onChange={(newValue) => {
                                    let waitstarttime = moment(newValue).format();
                                    handleChangeWaitStart(i, waitstarttime);
                                    setWaitTimeStart(newValue);
                                  }}
                                  renderInput={(_props) => (
                                    <TextField
                                      className="mt-0 mb-16 mx-4"
                                      {..._props}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="tk_wait_end"
                    control={control}
                    render={({ field }) => (
                      <>
                        {tkwaitend.map((inputField, i) => (
                          <div key={i}>
                            <Controller
                              name="tk_wait_end"
                              control={control}
                              render={({ field }) => (
                                <TimePicker
                                  label="wait end"
                                  value={inputField}
                                  onChange={(newValue) => {
                                    let waitendtime = moment(newValue).format();
                                    handleChangeWaitEnd(i, waitendtime);
                                    setWaitTimeEnd(newValue);
                                  }}
                                  renderInput={(_props) => (
                                    <TextField
                                      className="mt-0 mb-16 mx-4"
                                      {..._props}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>
              </div>

              <Divider></Divider>
              <br />
              {tickettype == 'EMP' ? (
                <div className="flex mx-6">
                  <FormControl
                    className="mt-8 mb-16"
                    fullWidth
                  >
                    <FormLabel>Trucks: </FormLabel>
                    <Controller
                      name="truck_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          variant="outlined"
                          sx={{ height:'100%'}}
                        >
                          {trucks
                            ? trucks.map((field) => (
                                <MenuItem
                                  value={field.idx_trucks_p}
                                  key={field.idx_trucks_p}
                                >
                                  No. {field.t_no_truck}
                                </MenuItem>
                              ))
                            : []}
                        </Select>
                      )}
                    />
                  </FormControl>

                  <FormControl
                    className="mt-8 mb-16"
                    fullWidth
                  >
                    <FormLabel>Driver: </FormLabel>
                    <Controller
                      name="driver_id"
                      control={control}
                      render={({ field:{onChange,value}}) => (
                    <Autocomplete
                      options={driverOptions}
                      value={driverOptions.find(option => option.value === value) || null}
                      onChange={(event, newValue) => {
                        onChange(newValue ? newValue.value : null)
                      }}
                      sx={{
                        "& .MuiInputBase-root": { height: "65px" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Driver"
                          sx={{ padding: 0}}
                          
                        />
                      )}
                    />
                      )}
                    />
                  </FormControl>
                </div>
              ) : null}

              <FormControl
                className="mt-8 mb-12"
                fullWidth
              >
                <FormLabel>Status</FormLabel>
                <Controller
                  name="tk_status"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      variant="outlined"
                    >
                      <MenuItem
                        key="0"
                        value={'0'}
                      >
                        Progress
                      </MenuItem>
                      <MenuItem
                        key="2"
                        value={'2'}
                      >
                        Delayed
                      </MenuItem>
                      <MenuItem
                        key="1"
                        value={'1'}
                      >
                        Completed
                      </MenuItem>
                      

                    </Select>
                  )}
                />
              </FormControl>

              <FormControl
                className="mt-8 mb-12"
                fullWidth
              >
                <FormLabel>Ticket Created</FormLabel>
                <Controller
                  name="tk_datecreated"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                      value={value}
                      onChange={onChange}
                      fullWidth
                      renderInput={(_props) => (
                        <TextField
                          fullWidth
                          label="Date Created Ticket"
                          {..._props}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>

              <div className="flex flex-row align-center items-center justify-end w-full pb-8">
                <Checkbox
                  className="p-0"
                  value={sendEmails}
                  onChange={(e) => {
                    setSendEmails(e.target.checked);
                  }}
                />
                Send updated Invoice to customer
              </div>

              <div className="flex mx-6">
                <Button
                  fullWidth
                  style={{ backgroundColor: '#000000' }}
                  onClick={handleCloseEdit}
                >
                  <Typography color={'#FFFFFF'}>Cancel</Typography>
                </Button>
                <Button
                  fullWidth
                  style={{ backgroundColor: '#000000' }}
                  onClick={editTicket}
                >
                  <Typography color={'#FFFFFF'}>Update</Typography>
                </Button>
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>

      {/**modal for request edit ticket */}
      <Dialog
        open={openreq_edit}
        onClose={(e) => {
          setopenreq_edit(false);
          setModalShow(false);
        }}
        PaperProps={{
          style: {
            backgroundColor: 'black',
            boxShadow: 'none',
          },
        }}
        classes={{
          paper: 'm-24',
        }}
        maxWidth={'md'}
      >
        <DialogContent>
          <DialogContentText color={'#FFFFFF'}>Are you sure you want to edit</DialogContentText>
          <TextField
            value={req_reason}
            onChange={(e) => setreq_reason(e.target.value)}
            className="mt-8 mb-16 "
            id="reason"
            label="reason"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            style={{ backgroundColor: '#FFFFFF' }}
          />
          <div className="flex mx-6">
            <Button
              fullWidth
              style={{ backgroundColor: '#edca33' }}
              onClick={(e) => {
                setopenreq_edit(false);
                setModalShow(false);
              }}
            >
              <Typography color={'black'}>No</Typography>
            </Button>
            <Button
              fullWidth
              style={{ backgroundColor: '#FF0000' }}
              onClick={req_edit_ticket}
            >
              <Typography color={'black'}>Yes</Typography>
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/**modal for request delete ticket */}
      <Dialog
        open={openreq_delete}
        onClose={(e) => {
          setopenreq_delete(false);
          setModalShow(false);
        }}
        PaperProps={{
          style: {
            backgroundColor: 'black',
            boxShadow: 'none',
          },
        }}
        classes={{
          paper: 'm-24',
        }}
        maxWidth={'md'}
      >
        <DialogContent>
          <DialogContentText color={'#FFFFFF'}>Are you sure you want to delete</DialogContentText>
          <TextField
            value={req_reason}
            onChange={(e) => setreq_reason(e.target.value)}
            className="mt-8 mb-16 "
            id="reason"
            label="reason"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            style={{ backgroundColor: '#FFFFFF' }}
          />
          <div className="flex mx-6">
            <Button
              fullWidth
              style={{ backgroundColor: '#edca33' }}
              onClick={(e) => {
                setopenreq_delete(false);
                setModalShow(false);
              }}
            >
              <Typography color={'black'}>No</Typography>
            </Button>
            <Button
              fullWidth
              style={{ backgroundColor: '#FF0000' }}
              onClick={req_delete_ticket}
            >
              <Typography color={'black'}>Yes</Typography>
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/**modal for request transfer ticket */}
      <Dialog
        fullWidth={false}
        maxWidth={'sm'}
        open={openreq_transfer}
        onClose={() => {
          setopenreq_transfer(false);
          setNewTicketJob();
          setModalShow(false);
          setTransferSearch('');
        }}
      >
        <DialogTitle>Ticket Job Transfer</DialogTitle>
        <DialogContent
          className="flex flex-col"
          sx={{ width: 450 }}
        >
          <Autocomplete
            disableListWrap
            id="job-transfer-box"
            clearOnBlur={false}
            freeSolo={true}
            value={newticketjob}
            onChange={(e, newValue) => {
              setNewTicketJob(newValue);
            }}
            options={allJobs}
            loading={true}
            sx={{ width: 300, padding: 0 }}
            ListboxProps={{
              onScroll: async (event) => {
                const listboxNode = event.currentTarget;
                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                  if (openreq_transfer && hasNextJob) {
                    if (transfersearch.length > 0) {
                      if (transfersearch.replace(/\s/g, '').length > 0) {
                        await searchJobs(
                          {
                            page: jobPage + 1,
                            text: event.target.value,
                            search: 'archive',
                            compacctnum,
                          },
                          (error) => {
                            console.log(error);
                          }
                        );
                      }
                    } else {
                      getAllJobs({ page: jobPage + 1, filter: null, compacctnum });
                    }
                  }
                }
              },
              style: { maxHeight: '15rem' },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Job"
                sx={{ padding: 0 }}
                onChange={(e) => handleSearch(e)}
              />
            )}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
          />
          <TextField
            value={req_reason}
            onChange={(e) => setreq_reason(e.target.value)}
            className="mt-8 mb-16 "
            id="reason"
            label="reason"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            style={{ backgroundColor: '#FFFFFF' }}
          />
          <div className=" flex flex-row pl-16 items-center">
            <Button
              variant="contained"
              sx={{ height: '45px' }}
              onClick={req_transfer_ticket}
            >
              <Typography fontWeight={'bold'}>Transfer</Typography>
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setopenreq_transfer(false);
              setNewTicketJob();
              setModalShow(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// injectReducer(AuthReducer)
const mapStateToProps = (state) => {
  return {
    jobTickets: state.loadz_jobs_ticket?.JobTicketReducer?.jobTickets,
    hasNextPage: state.loadz_jobs_ticket?.JobTicketReducer?.hasNextPage,
    loading: state.loadz_jobs_ticket?.JobTicketReducer?.loading,
    page: state.loadz_jobs_ticket?.JobTicketReducer?.page,
    trucks: state.loadz_jobs_ticket?.JobTicketReducer?.trucks,
    drivers: state.loadz_jobs_ticket?.JobTicketReducer?.drivers,
    jobs: state.loadz_jobs?.JobReducer?.jobs,
    hasNextJob: state.loadz_jobs?.JobReducer?.hasNextPage,
    jobLoading: state.loadz_jobs?.JobReducer?.loading,
    jobPage: state.loadz_jobs?.JobReducer?.page,
    user: state.loadz_auth?.AuthReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllTicketJobs: (payload, onError) =>
    dispatch(JobTicketAction.getAllTicketJob(payload, onError)),
  searchJobTickets: (payload, onError) =>
    dispatch(JobTicketAction.searchJobTicket(payload, onError)),
  transferJobTickets: (payload, onSuccess, onError) =>
    dispatch(JobTicketAction.transferJobTickets(payload, onSuccess, onError)),
  getTrucks: (payload, onError) => dispatch(JobTicketAction.getTrucks(payload, onError)),
  getDrivers: (payload, onError) => dispatch(JobTicketAction.getDrivers(payload, onError)),
  deleteJobTickets: (payload, onSuccess,onError) =>
    dispatch(JobTicketAction.deleteJobTickets(payload, onSuccess, onError)),
  editJobTicket: (data, onSuccess, onError) =>
    dispatch(JobTicketAction.editJobTicket(data, onSuccess, onError)),
  getAllJobs: (payload, onError) => dispatch(JobAction.getJobs(payload, onError)),
  logout: () => dispatch(AuthAction.logout()),
  searchJobs: (payload, onError) => dispatch(JobAction.searchJobs(payload, onError)),
});

export default withReducer(
  'loadz_jobs_ticket',
  JobTicketReducer
)(connect(mapStateToProps, mapDispatchToProps)(TicketsTable));
