import {
  TableBody,
  Checkbox,
  styled,
  TableRow,
  TableCell,
  tableCellClasses,
  Chip,
  Icon,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000000',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const DataTableBody = ({ data, selectedRow, onRowSelection }) => {
  const history = useHistory()
  return (
    <TableBody>
      {data.map((row, index) => {
        const isSelected = selectedRow.indexOf(row.id) !== -1
        const labelId = `enhanced-table-checkbox-${index}`
        return (
          <StyledTableRow
            hover
            onClick={(event) => onRowSelection(event, row.id)}
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={row.id}
            selected={isSelected}
          >
            <StyledTableCell>
              <Checkbox
                color="default"
                checked={isSelected}
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </StyledTableCell>
            <StyledTableCell>{row.displayName}</StyledTableCell>
            <StyledTableCell align="center">{row.permissions}</StyledTableCell>
            <StyledTableCell align="center">{row.users}</StyledTableCell>
            <StyledTableCell align="center"
              onClick={() => history.push(`/roles-permissions/${row.id}`)}
            >
              <Chip
                className="p-0 cursor-pointer bg-none"
                label=""
                icon={<Icon style={{ color: '#000' }}>edit</Icon>}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
      })}
    </TableBody>
  )
}
export default DataTableBody
