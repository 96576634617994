import { takeLatest, put, fork, all, call } from 'redux-saga/effects';
import actions from './actions';
import { getErrorAction, getSuccessAction } from '../utility';
import axios from 'axios';
import { constants } from '../../../../../constants';
import client from 'customerPortalAxiosClient';

export function* watchSigninCustomerPortal() {
  yield takeLatest(actions.CUSTOMER_LOGIN, function* ({ payload, onError, onSuccess }) {
    try {
      const result = yield call(() => client.post(`${constants.URLLOCAL}/client/signin/`, payload));

      const token = result?.data?.token ? result.data.token : result.data.data.token;
      localStorage.setItem('client-token', token);
      client.defaults.headers.common['token'] = token;

      if (onSuccess) {
        onSuccess(result);
        console.log('success');
      }

      yield put({
        type: getSuccessAction(actions.CUSTOMER_LOGIN),
        payload: { client: result.data.client },
      });
    } catch (error) {
      if (onError) {
        onError(error);
      }
      yield put({
        type: getErrorAction(actions.CUSTOMER_LOGIN),
      });
    }
  });
}

export function* watchVerifyClientToken() {
  yield takeLatest(actions.VERIFY_TOKEN, function* ({ onSuccess, onError }) {
    try {
      const result = yield call(() => client.post(`/client/verify-token/`));

      yield put({
        type: getSuccessAction(actions.VERIFY_TOKEN),
        payload: { client: result.data },
      });

      if (onSuccess && result.status == 200) {
        onSuccess(result.data);
      }
    } catch (error) {
      console.log(error);
      yield put({
        type: getErrorAction(actions.VERIFY_TOKEN),
      });

      if (onError) {
        onError(error);
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(watchSigninCustomerPortal), fork(watchVerifyClientToken)]);
}
