import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import CounterProgress from './counters-components/CounterProgress';
import DateTime from './counters-components/DateTime';
import CounterDelayed from './counters-components/CounterDelayed';
import CounterCompleted from './counters-components/CounterCompleted';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';

function HomeTab(props) {

  const {
    progress,
    delayed,
    completed,
  } = props.counters

  const {
    progressTickets,
    delayedTickets,
    completedTickets
  } = props

  useEffect(() => {
    document.getElementById('progress').addEventListener('click', () => {
      // console.log('click')
      props.goTo('tableProgress')
    })

    document.getElementById('delayed').addEventListener('click', () => {
      // console.log('click')
      props.goTo('tableDelayed')
    })

    document.getElementById('completed').addEventListener('click', () => {
      // console.log('click')
      props.goTo('tableCompleted')
    })

  }, [])


  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };


  return (


    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2} >

      <Grid item xs={12} md={6}  className="mt-8">
        <CounterProgress counter={progress} tickets={progressTickets} />
        </Grid>
        <Grid item xs={12} md={6}  className="mt-8">
        <CounterDelayed counter={delayed} tickets={delayedTickets} />
        </Grid>
        <Grid item xs={12} md={6}  className="mt-8">
        <CounterCompleted counter={completed} tickets={completedTickets} />
        </Grid>
        <Grid item xs={12} md={6}  className="mt-8">
        <DateTime />
      </Grid>
 
    </Grid>
  );
}

export default HomeTab;
