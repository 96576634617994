import { lazy } from 'react';

const SubconConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [

    {
      path: '/sub-contractor',
      component: lazy(() => import('./NewSubcontractor/Subcontractors')),
    },
    
  ],
};

export default SubconConfig;
