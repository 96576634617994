import _ from '@lodash'
import Checkbox from '@mui/material/Checkbox'
import Icon from '@mui/material/Icon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { motion } from 'framer-motion'
import { memo, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import JobsTableHead from './JobsTableHeader'
import FuseMessage from '@fuse/core/FuseMessage'
import { showMessage } from 'app/store/fuse/messageSlice'
import { TableContainer } from '@mui/material'
import Chip from '@mui/material/Chip'
import { connect } from 'react-redux'
import withReducer from 'app/store/withReducer'
import JobAction from 'app/stores/loadz/redux/Jobs/actions'
import AuthAction from 'app/stores/loadz/redux/Auth/actions'
import JobReducer from 'app/stores/loadz/redux/Jobs'
import { LinearProgress } from '@material-ui/core'
import { hasPermission } from 'app/main/loadz/utils'
import ErrorBoundary from 'app/main/ErrorBoundary'
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading'
import { Button, Dialog, DialogContent, DialogContentText } from '@mui/material';
import client from 'client'
const JobsTable = ({
  getJobs,
  searchJobs,
  loading,
  hasNextPage,
  page,
  jobs,
  search,
  reData,
  setReData,
  jobFilter,
  logout,
  user,
  data,
  setJob_id,
  setEditJobOpen,
  setEditJobBillingOpen,
  setTicketTableOpen,
  setJobFilter,
  selected, setSelected
}) => {
  const dispatch = useDispatch()
  const listInnerRef = useRef()
  const history = useHistory()
  const [order, setOrder] = useState({ direction: 'asc', id: null })
  const [scrollX, setScrollX] = useState(0)
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteDraft, setDeleteDraft] = useState(null)
console.log(data)
  // var permission = false

  // if (user) {
  //   console.log(user)
  //   if (user.permissions.Jobs != null || user.permissions.Jobs != undefined) {
  //     if (
  //       user.permissions.Jobs.edit == true ||
  //       user.permissions.Jobs.edit == false
  //     ) {
  //       var permission = user.permissions.Jobs.edit
  //     } else {
  //       var permission = true
  //     }
  //   } else {
  //     var permission = true
  //   }
  // }

  function handleDeselect() {
    setSelected([])
  }

  function handleClick(job) {
    //need in a dialog set open dialog
    console.log("hello")
    data ? complist(job) : job?.job_billing ? history.push(`/jobs-drafts/billing/new/${job.job_info_id}`) : history.push(`/jobs-drafts/billing/by-id/${job.job_info_id}`)
    return
  }

  function complist(job){
    setJob_id(job.job_info_id) 
    setEditJobOpen(true)
  }

  function openTicketPage(job){
    setTicketTableOpen(true)
    setJob_id(job.job_info_id)

  }
  
  function handleTicket(job) {
    //need in a dialog set open dialog
    //data ?  openTicketPage(job) : history.push(`/jobs-drafts/ticket/${job.job_info_id}`)
    data ? complist(job) : job?.job_billing ? history.push(`/jobs-drafts/billing/new/${job.job_info_id}`) : history.push(`/jobs-drafts/billing/by-id/${job.job_info_id}`)

    return 
  }

  function selectItem(event, id) {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleSelectAllClick = (event) => {
    event.target.checked
      ? setSelected(jobs.map((n) => n.job_info_id))
      : setSelected([])
  }

  const handleRequestSort = (event, property) => {
    const id = property
    let direction = 'desc'
    if (order.id === property && order.direction === 'desc') {
      direction = 'asc'
    }
    setOrder({
      direction,
      id,
    })
  }

  useEffect(async () => {
    search
      ? await searchJobs({ page: 1, text: search, search:'archive',compacctnum: data ? data?.compacctnum : user.company.compacctnum,type:'drafts' }, (error) => {
          checkAuthError(error)
        })
      : await getJobs({ page: 1, filter: 'drafts',compacctnum: data ? data?.compacctnum : user.company.compacctnum}, (error) => {
          checkAuthError(error)
        })
  }, [search])

  const refreshJobs = async(e) => {
    console.log("refreshing jobs",e)
    await getJobs({ page: 1, filter: 'drafts',compacctnum: data ? data?.compacctnum : user.company.compacctnum}, (error) => {
      checkAuthError(error)
    })
  }


  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
          </>
        ),
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: color,
      })
    )
  }

  useEffect(async () => {
    await getJobs({ page: 1, filter: 'drafts',compacctnum: data ? data?.compacctnum : user.company.compacctnum }, (error) => {
      checkAuthError(error)
    })
  }, [jobFilter])

  const checkAuthError = (error) => {
    if (error?.response?.status == 401) {
      logout()
      history.push('/')
    }
  }

  const onScroll = async (event) => {
    if (listInnerRef.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
        scrollLeft,
        offsetHeight,
      } = listInnerRef.current
      const allow = scrollLeft > scrollX || scrollLeft < scrollX ? false : true
      setScrollX(scrollLeft)
      if (offsetHeight + scrollTop >= scrollHeight && allow) {
        if(!search)
        {if (hasNextPage) {
          /* This will be triggered after hitting the last element. API call should be made here while implementing pagination. */
          await getJobs({ page: page + 1, filter: 'drafts',compacctnum: data ? data?.compacctnum : user.company.compacctnum })
        }}
      }
    }
  }

  const handleDeleteDraft = async () => {
    try {
      
    
    let result = await client.post('jobs/delete-draft',{jobId:deleteDraft.job_info_id})
    result = result.data
    if(result?.delete == true)
    {
      setOpenDelete(false)
      setDeleteDraft(null)
      _showMessage('Draft Deleted','success')
      refreshJobs()
    }
    
  } catch (error) {
      setOpenDelete(false)
      setDeleteDraft(null)
      _showMessage('An Error Occurred','error')
      refreshJobs()
    }
  }

  if (jobs.length == 0 && loading == true) {
    return <FuseLoading />
  }

  if (jobs.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full moble-content"
      >
        <Typography color="textSecondary" variant="h5">
          There are no drafts!
        </Typography>
      </motion.div>
    )
  }

  console.log('jobs ::==:: ', jobs)

  return (
    <>
      <div className="responsive-loads-table">
        <FuseMessage />
        <TableContainer
          style={{ maxWidth: '100%', maxHeight: '700px' }}
          onScroll={onScroll}
          ref={listInnerRef}
        >
          <Table
            stickyHeader
            size="small"
            aria-labelledby="tableTitle"
            className="pb-32"
          >
            <JobsTableHead
              setJobFilter={setJobFilter}
              jobFilter={'drafts'}
              refreshJobs={refreshJobs}
              selectedJobs={selected}
              order={order}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={jobs.length}
              onMenuItemClick={handleDeselect}
              _showMessage={_showMessage}
              setReData={setReData}
            />
            <TableBody>
              {_.orderBy(
                jobs,
                [
                  (o) => {
                    switch (order.id) {
                      case 'unitTemp': {
                        return o.unitTemp[0]
                      }
                      case 'destination':
                        return o?.j_destination?.l_name
                      case 'material':
                        return o?.job_billing?.name
                      case 'ticketcompleted':
                        return o?.ticketcompleted
                      case 'Last_Activity':
                        return o?.recent_tk
                      case 'ticketqty':
                        return o?.ticketCounts
                      default: {
                        return o[order.id]
                      }
                    }
                  },
                ],
                [order.direction]
              ).map((n, index) => {
                const isSelected = selected.indexOf(n.job_info_id) !== -1
                return (
                  <ErrorBoundary>
                    <TableRow
                      className="h-72 cursor-pointer"
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isSelected}
                    >
                      <TableCell
                        className="w-40 md:w-64 text-center"
                        padding="none"
                      >
                        {n.visibility && (
                          <Checkbox
                            checked={isSelected}
                            onClick={(event) => {
                              event.stopPropagation()
                            }}
                            onChange={(event) => selectItem(event, n.job_info_id)}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16 truncate"
                        component="th"
                        scope="row"
                        onClick={(event) => handleTicket(n)}
                        data-headtext="Job No"
                      >
                        {
                          n?.job_billing && n?.job_billing?.pending_review == true ?
                          <b className='text-red'>
                            {n.company_id}-{n.job_no}
                          </b>
                          :
                          <b>
                            {n.company_id}-{n.job_no}
                          </b>
                        }
                        
                      </TableCell>
                      <TableCell
                        className="p-3 md:p-12"
                        component="th"
                        scope="row"
                        onClick={(event) => handleTicket(n)}
                        data-headtext="Customer "
                      >
                        <b>{n.customer_name}</b>
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                        onClick={(event) => handleTicket(n)}
                        data-headtext="Destination"
                      >
                        <b>{n.j_destination ? n.j_destination.l_name : '-'}</b>
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                        onClick={(event) => handleTicket(n)}
                        data-headtext="Material "
                      >
                        <b>{n.job_billing ? n.job_billing.name : '-'}</b>
                      </TableCell>
                      <TableCell
                        onClick={(event) => handleTicket(n)}
                        sx={{ minWidth: 150 }}
                        align="center"
                        className="p:20 md:p-20"
                        component="th"
                        scope="row"
                        data-headtext=" Qty"
                      >
                        <b>
                          {n.ticketcompleted ? n.ticketcompleted.toFixed(1) : '-'}{' '}
                          of {n.j_fullfillment_total} {n?.job_billing?.unit_name}
                        </b>
                      </TableCell>
                      {/* <TableCell className="p-4 md:p-16" component="th" scope="row" onClick={(event) => handleTicket(n)} data-headtext=" Unit">
                            <b>{n.job_billing ? n.job_billing.unit_name : "-"}</b>
                          </TableCell> */}
                      <TableCell
                        align="center"
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                        onClick={(event) => handleTicket(n)}
                        data-headtext=" Completed Tickets"
                      >
                        <b>{n?.ticketCounts ? n.ticketCounts : '-'}</b>
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                        onClick={(event) => handleTicket(n)}
                        data-headtext=" Last Activity"
                      >
                        {n.recent_tk == 0 ? <b>{'No Recent Activity'}</b> : <b>{moment(n.recent_tk).format('LLL')}</b>}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                        onClick={(event) => handleTicket(n)}
                        data-headtext=" Date created"
                      >
                        {moment(n.date_job).format('L')}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                        align="center"
                        data-headtext="Status"
                      >
                        {n.visibility ? (
                          <Icon className="text-green text-20">check_circle</Icon>
                        ) : (
                          <Icon className="text-red text-20">remove_circle</Icon>
                        )}
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16 "
                        align='center'
                        component="th"
                        scope="row"
                        data-headtext="In Progress"
                      >                        
                       <Chip
                            className="p-0 bg-none"
                            label=""
                            icon={
                              <Icon style={{ color: '#000' }}>
                                {
                                  n.job_billing?.lock == true ? 'lock' : 'lock_open'
                                }
                              </Icon>
                            }
                          />
                        
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                        data-headtext="Edit"
                      >
                        { hasPermission('job-billing.update')  &&
                          <Chip
                            onClick={(e) => {e.stopPropagation(); handleClick(n)}}
                            className="p-0 cursor-pointer bg-none"
                            label=""
                            icon={
                              <Icon style={{ color: '#000' }}>
                                edit
                              </Icon>
                            }
                          />
                        }
                      </TableCell>
                      <TableCell
                        className="p-4 md:p-16"
                        component="th"
                        scope="row"
                        data-headtext="Delete"
                      >
                        { hasPermission('jobs.delete')  &&
                          <Chip
                            onClick={(e) => {e.stopPropagation(); setOpenDelete(true), setDeleteDraft(n)}}
                            className="p-0 cursor-pointer bg-none"
                            label=""
                            icon={
                              <Icon style={{ color: '#000' }}>
                                delete 
                              </Icon>
                            }
                          />
                        }
                      </TableCell>                      
                    </TableRow>
                  </ErrorBoundary>
                )
              })}
            </TableBody>
          </Table>
          {loading && <LinearProgress />}

          {!loading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="flex flex-1 items-center justify-center h-full moble-content py-64"
            >
              <Typography color="textSecondary" variant="h5">
                No more Data
              </Typography>
            </motion.div>
          ) : null}
        </TableContainer>

        <Dialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          PaperProps={{
            style: {
              backgroundColor: "black",
              boxShadow: "none",
            },
          }}
          classes={{
            paper: "m-24",
          }}
          maxWidth={"md"}
        >
          <DialogContent>
            <DialogContentText className='flex flex-col h3 align-center justify-center items-center' color={"#FFFFFF"}>
              Are you sure you want to delete this draft. 
              <Typography className='h3' color={'red'}>This action cannot be undone.</Typography>
            </DialogContentText>
            <br />
            <br />
            <div className="flex mx-6">
              <Button
                fullWidth
                style={{ backgroundColor: "#edca33",marginRight:'10px' }}
                onClick={() => {setDeleteDraft(null); setOpenDelete(false)}}
              >
                <Typography color={"black"}>No</Typography>
              </Button>
              <Button
                fullWidth
                style={{ backgroundColor: "#FF0000",marginLeft:'10px' }}
                onClick={handleDeleteDraft}
              >
                <Typography color={"black"}>Yes</Typography>
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    jobs: state.loadz_jobs?.JobReducer?.jobs,
    hasNextPage: state.loadz_jobs?.JobReducer?.hasNextPage,
    loading: state.loadz_jobs?.JobReducer?.loading,
    page: state.loadz_jobs?.JobReducer?.page,
    user: state.loadz_auth?.AuthReducer?.user,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getJobs: (payload, onError) => dispatch(JobAction.getJobs(payload, onError)),
  searchJobs: (payload, onError) => dispatch(JobAction.searchJobs(payload, onError)),
  logout: () => dispatch(AuthAction.logout()),
})

export default withReducer(
  'loadz_jobs',
  JobReducer
)(memo(connect(mapStateToProps, mapDispatchToProps)(JobsTable)))
