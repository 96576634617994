import { getErrorAction, getSuccessAction } from "../../utility";
const actions = {
  COMPLETED_JOBS: 'completedJobs',
  
  getCompletedJobs: ({page, jobType, id}) =>{
    const payload = { params: {page}, data: { jobType, id }}
    return {
      type: actions.COMPLETED_JOBS,
      payload,
    }
  }
}

export default actions