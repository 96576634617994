export const blacklist = [
  'loadz_dashboard',
  'loadz_jobs',
  'loadz_jobs_ticket',
  'loadz_fuleLogs',
  'loadz_roles',
  'loadz_permission',
  'loadz_roles_permissions',
  'loadz_client_portal',
  'loadz_drivers',
  'companiesApp'
]
