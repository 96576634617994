import DialogContentText from '@mui/material/DialogContentText';
import {useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch} from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FuseLoading from '@fuse/core/FuseLoading';
import LinearProgress from '@mui/material/LinearProgress';
import store from 'app/store';
import { constants } from '../../../../../constants';
import { useHistory } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import EditSummaryTable from './EditSummaryTable';
import DialogActions  from '@mui/material/DialogActions';
import client from 'client';
 function SummaryModal({open ,setOpen,getData,id,data,setJob_id,setEditJobBillingOpen}) {
  const history = useHistory();
  const [BillingHistory, setBillingHistory] = useState([])

  useEffect(async() => {
    if(getData = true)
    {
      const state= store.getState()
      const user = state?.loadz_auth?.AuthReducer?.user
      const result = await client.get(`/jobs/billing-history/${id}/${data ? data.compacctnum : user?.company?.compacctnum}`)
      console.log(result)
      setBillingHistory(result?.data?.history ? [result?.data?.history[0]] : [])
    }
    
  },[getData])

  function JobBillingEdit() {
    setEditJobBillingOpen(false)
    setJob_id('')
  }
  
  return (
   <>
      <Dialog
        PaperProps={{
      style: {
        boxShadow: 'none',
      },
    }}
      classes={{
        paper: 'm-24',
      }}
      open={open}
      maxWidth={'lg'}
      >
      <DialogTitle>
        Summary
      </DialogTitle>
      <DialogContent sx={getData ==true ? {borderTop:2,borderBottom:2}: {}} className='flex flex-col items-center'>
          {
            getData == true &&  BillingHistory[0] != undefined ? 
            <EditSummaryTable BillingHistory={BillingHistory}/>
            //<Button onClick={() => {setOpen(false); history.push('/jobs')}}>Billing Data Has Loaded you can now close me </Button>
            :
            <>
               <Typography>Please Wait</Typography>
              <LinearProgress className="w-192 sm:w-320 max-w-full rounded-2" color="secondary" />
            </>
          }
      </DialogContent>
      <DialogActions className='flex flex-row justify-center'>
        {
          getData == true ? 
          <Button sx={{width:10}} variant='contained' onClick={() => {
            setOpen(false); 
            data ? JobBillingEdit() : history.push('/jobs')}}>Close</Button>
          :
          null
        }
      </DialogActions>
        
      </Dialog>
    
      </>
  );

 }
 
 export default SummaryModal;

