import { takeLatest,put, fork, all, call} from 'redux-saga/effects';
import actions from './actions'
import client from '../../../../../client';
import store from '../../../../main/ID_store/store'
import { getErrorAction, getSuccessAction } from "../utility";
import { has } from 'lodash';

export function* watchLogin() {
  yield takeLatest(actions.LOG_IN, function* ({type, payload, onSuccess, onError}) {
    try {
      const result = yield call(() => client.post('/auth/login/', payload))
      
      const token = result?.data?.token ? result.data.token : result.data.data.token
      localStorage.setItem('token', token)
      client.defaults.headers.common['token'] = token;

      const user = result?.data?.user ? result.data.user : result?.data?.data?.user
      yield put({
        type: getSuccessAction(actions.LOG_IN),
        payload: {user}
      })
      
      if (onSuccess) {
        onSuccess(result)
      }

    } catch(error) {

      if (onError) {
        onError(error)
      }

      yield put({
        type: getErrorAction(actions.LOG_IN),
      })
    }
  })
}

export function* watchGeneralRegistration() {
  yield takeLatest(actions.GENERAL_REGISTRATION, function* ({type, payload, onSuccess, onError}) {
    try {
      const result = yield call(() => client.post('/auth/register/', payload))

      if (!result?.data?.grad)
     { localStorage.setItem('token', result.data.token)
      client.defaults.headers.common['token'] = result.data.token;

      yield put({
        type: getSuccessAction(actions.GENERAL_REGISTRATION),
        payload: {user: result.data.user, token: result.data.token}
      })}

      if (onSuccess) {
        onSuccess(result)
      }

    } catch(e) {
      if (onError) {
        onError(e)
      }

      yield put({
        type: getErrorAction(actions.GENERAL_REGISTRATION),
      })
    }
  })
}

export function* watchRequestToCode() {
  yield takeLatest(actions.REQUEST_CODE, function* ({payload, onSuccess, onError}) {
    try {
      const body = {
       ...payload
      }

      const result = yield call(() => client.post('/auth/request-access-code/', body))

      if (result?.data?.action == 'OTP_SENT') {
        yield put({
          type: getSuccessAction(actions.REQUEST_CODE),
          payload: result.data,
        })
      } else {
        const token = result?.data?.token ? result.data.token : result.data.data.token
        localStorage.setItem('token', token)
        client.defaults.headers.common['token'] = token;
  
        const user = result?.data?.user ? result.data.user : result?.data?.data?.user
        yield put({
          type: getSuccessAction(actions.LOG_IN),
          payload: {user}
        })
      }

      if (onSuccess) onSuccess(result)

    } catch(error) {
      yield put({
        type: getErrorAction(actions.REQUEST_CODE),
        payload: error,
      })

      if (onError) onError(error)
    }
  })
}

export function* watchDoOnboarding() {
  yield takeLatest(actions.DO_ONBOARDING, function* ({ payload, step, onSuccess, onError}) {
    try {
      const result = yield call(() => client.post(`/auth/onboarding/company/${step}`, payload))
      console.log('step::', step)
      console.log('result::', result)
      if(has(result.data, 'token')) {
        localStorage.setItem('token', result.data.token)
        client.defaults.headers.common['token'] = result.data.token;
      }

      if(result.data.user && result.data.user.reg_step === 'complete') {
        yield put({
          type: getSuccessAction(actions.GENERAL_REGISTRATION),
          payload: {user: result.data.user, token: result.data.token}
        })

        if (onSuccess) {
          onSuccess(result)
        }
        return 
      }
      if(step == '5')
      {
        localStorage.setItem('project', 'loadz');
        store.dispatch({
          type: 'LOG_IN',
          payload: {
            id: result?.data,
          }
        })
      }
      
      yield put({
        type: getSuccessAction(actions.DO_ONBOARDING),
        payload: {
          data: payload,
          step
        }
      })

      if (onSuccess) {
        onSuccess(result)
      }
    } catch (error) {
      if (onError) {
        onError(error?.response?.data?.message)
      }
    }
  })
}



export function* watchUpdateCompanyLogo() {
  yield takeLatest(actions.SET_COMPANY_LOGO, function* ({ payload, onError}) {
    try {
      const result = yield call(() => client.put(`/company/updateCompanyLogo`, payload))
      console.log(result)
      if (result.status == 200) {
        yield put({
          type: getSuccessAction(actions.SET_COMPANY_LOGO),
          payload: {
            key: payload.logo
          }
        })
      }
    } catch (error) {
      if (onError) {
        onError(error)
      }
    }
  })
}

export function* watchUpdateUserProfileLogo() {
  yield takeLatest(actions.SET_USER_LOGO, function* ({ payload, onError}) {
    try {
      const result = yield call(() => client.put(`/company/updateProfileImg`, payload))
      if (result.status == 200) {
        yield put({
          type: getSuccessAction(actions.SET_USER_LOGO),
          payload: {
            key: payload.profile_img
          }
        })
      }
    } catch (error) {
      if (onError) {
        onError(error)
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchRequestToCode),
    fork(watchGeneralRegistration),
    fork(watchDoOnboarding),
    fork(watchUpdateCompanyLogo),
    fork(watchUpdateUserProfileLogo),
  ]);
}
