import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';
import { constants } from '../../../../../constants';
import client from 'client';
import ModalSubscriptionsPaywalls from 'app/main/subscriptionManagement/ModalSubscriptionsPayWalls';
import store from 'app/store';
import { set } from 'lodash';

function JobsHelpersHeader({
  id,
  jsonGetById,
  setEditJobOpen,
  data,
  job_id,
  setEditJobBillingOpen,
  setJob_id,
  onChangeText,
  onInputText,
}) {
  const dispatch = useDispatch();
  const methods = useFormContext();
  const [disabled, setDisabled] = useState(false);
  const [jobtype, setJobtype] = useState(null);
  const { formState, watch, getValues, setError, reset, setValue } = methods;
  const [subscriptionsPaywalls, setSubscriptionsPaywalls] = useState(false);
  const { isValid, dirtyFields, errors } = formState;
  const name = watch('name');
  const theme = useTheme();
  const history = useHistory();
  const token = localStorage.token;

  useEffect(async () => {
    if (jsonGetById.length > 0) {
      const { job_type } = jsonGetById[0];
      setJobtype(job_type);
    }
  }, []);

  const _setError = () => {
    setError('quarry_pit');
    setError('j_destination');
    setError('j_fullfillment_warning');
  };

  const clearForm = {
    job_c_id: [],
    quarry_pit: [],
    j_destination: [],
    j_fullfillment_warning: null,
    j_fullfillment_total: null,
    j_notes: '',
    po: null,
    j_status: true,
    scale: true,
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  async function handleUpdateJob() {
    try {
      if (!getValues().j_destination) {
        setValue('l_name', onInputText);
        setValue('route', null);
      }

      if (onChangeText != onInputText) {
        setValue('l_name', onInputText);
        setValue('route', null);
        setValue('j_destination', null);
      }

      if (getValues('j_fullfillment_warning') == '') {
        setValue('j_fullfillment_warning', null);
      }

      if (getValues('j_fullfillment_total') == '') {
        setValue('j_fullfillment_total', null);
      }

      setDisabled(true);
      // console.log(getValues());
      // if (!getValues('visibility')) {
      //   setValue('visibility', getValues('visibility') ? false : true);
      // }

      // console.log('getValues()', getValues());

      // return;
      const response = await client.put(`/jobs/update-job/${job_id ? job_id : id}`, getValues());
      const res = await response.data;

      // _showMessage(`The job with number ${getValues('job_no')} has been updated correctly!`, 'success')
      _showMessage(`Job updated successfully!`, 'success'); //TODO improved message

      if (res.error) {
        _showMessage(`There was an issue updating the job. Please try again`, 'error'); //TODO improved message
        setDisabled(false);
        return;
      }

      reset(clearForm);
      setDisabled(false);
      _setError();
      data ? setEditJobOpen(false) : history.push('/jobs');
    } catch (error) {
      console.log(error);
      _showMessage(`There was an issue updating the job. Please try again`, 'error'); //TODO improved message
      setDisabled(false);
    }
  }

  async function handleSaveJob() {
    try {
      if (!getValues().j_destination) {
        setValue('l_name', onInputText);
        setValue('route', null);
      }

      if (onChangeText != onInputText) {
        setValue('l_name', onInputText);
        setValue('route', null);
        setValue('j_destination', null);
      }

      if (getValues('j_fullfillment_warning') == '') {
        setValue('j_fullfillment_warning', null);
      }

      if (getValues('j_fullfillment_total') == '') {
        setValue('j_fullfillment_total', null);
      }

      setDisabled(true);
      const response = await client.post(`/jobs/new${data ? `/${data.id}` : ''}`, getValues());
      const res = await response.data;

      if (res.msg) {
        _showMessage(res.msg, 'error');
        return;
      }

      _showMessage(
        `Job #${res && res.length > 0 ? res[0].job_no : null} has been successfully created`,
        'success'
      ); //TODO improved message
      reset(clearForm);
      setDisabled(false);
      _setError();
      data ? handleSaveJobOpen(res) : history.push('/jobs/billing/new/' + res[0].job_info_id);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.status === 'incomplete_expired') {
        _showMessage(error.response.data.message, 'error');
        paywallSubscriptions();
        return;
      }
      _showMessage(`There was an issue saving the job. Please try again`, 'error'); //TODO improved message
      setDisabled(false);
    }
  }
  function handleSaveJobOpen(res) {
    console.log('RES', res);
    setEditJobBillingOpen(true);
    setEditJobOpen(false);
    setJob_id(res[0].job_info_id);
  }

  async function handleJobBilling() {
    if (id || job_id) {
      try {
        if (!getValues().j_destination) {
          setValue('l_name', onInputText);
          setValue('route', null);
        }

        if (onChangeText != onInputText) {
          setValue('l_name', onInputText);
          setValue('route', null);
          setValue('j_destination', null);
        }

        setDisabled(true);
        if (!getValues('visibility')) {
          setValue('visibility', jsonGetById[0].visibility);
        }
        const response = await fetch(
          `${constants.URLLOCAL}/jobs/update-job/${job_id ? job_id : id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              token: `${token}`,
            },
            body: JSON.stringify(getValues()), // body data type must match "Content-Type" header
          }
        );
        const res = await response.json();

        //_showMessage(`The job with number ${getValues('job_no')} has been updated correctly!`, 'success')
        _showMessage(`Job details have been successfully updated`, 'success'); //TODO improved message
        if (res.error) {
          _showMessage(
            `Failed to update the job. Please check your entries and try again.`,
            'error'
          ); //TODO improved message
          setDisabled(false);
          return;
        }

        reset(clearForm);
        setDisabled(false);
        _setError();
        data ? handleJobBillingOpen() : history.push('/jobs/billing/by-id/' + id);
      } catch (error) {
        console.log(error);
        _showMessage(`There was an unexpected error. Please try again`, 'error'); //TODO improved message
        setDisabled(false);
      }
    } else {
      data ? handleJobBillingOpen() : history.push('/jobs/billing/new/' + id);
    }
    //id ? history.push('/jobs/billing/by-id/' + id) : history.push('/jobs/billing/new/' + id)
  }

  function handleJobBillingOpen() {
    setEditJobOpen(false);
    setEditJobBillingOpen(true);
  }

  const paywallSubscriptions = () => {
    setSubscriptionsPaywalls(true);
  };

  return (
    <>
      {subscriptionsPaywalls ? (
        <ModalSubscriptionsPaywalls
          paywallSubscriptions={paywallSubscriptions}
          open={subscriptionsPaywalls}
          setSubscriptionsPaywalls={setSubscriptionsPaywalls}
          compacctnum={store.getState().loadz_auth.AuthReducer.user.company.compacctnum}
        ></ModalSubscriptionsPaywalls>
      ) : null}

      <div className="flex flex-1 w-full items-center justify-between job-edit-form pb-44 px-0 pt-0">
        <div className="flex flex-col items-start max-w-full min-w-0">
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
          >
            {data ? (
              <Typography
                className="flex items-center sm:mb-12"
                role="button"
                color="black"
                onClick={() => {
                  setEditJobOpen(false);
                  setEditJobBillingOpen(false);
                  setJob_id('');
                }}
              >
                <Icon className="text-20">
                  {theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}
                </Icon>
                <span className="sm:flex mx-4 font-medium">{'Back to Jobs Overview'}</span>
              </Typography>
            ) : (
              <Typography
                className="flex items-center sm:mb-12"
                component={Link}
                role="button"
                to="/jobs"
                color="black"
              >
                <Icon className="text-20">
                  {theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}
                </Icon>
                <span className="sm:flex mx-4 font-medium">{'Back to Jobs Overview'}</span>
              </Typography>
            )}
          </motion.div>

          <div className="flex items-center max-w-full">
            <div className="flex flex-col min-w-0 mx-8 sm:mc-16">
              <motion.div
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.3 } }}
              >
                <Typography
                  variant="h4"
                  color="black"
                  className="font-bold"
                >
                  {id || job_id
                    ? `Update Job No. ${
                        jsonGetById && jsonGetById.length > 0 ? jsonGetById[0].job_no : null
                      }`
                    : 'Add Job'}
                </Typography>
              </motion.div>
            </div>
          </div>
        </div>
        <motion.div
          className="flex job-edit-form-btns custom-update-btn"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
        >
          {(id || job_id) && (
            <Button
              style={{ color: 'white' }}
              className="whitespace-nowrap mx-4"
              variant="contained"
              color="third"
              onClick={handleJobBilling}
            >
              {id || job_id ? 'Update Job Billing' : 'Create Job Billing'}
            </Button>
          )}

          <Button
            style={{ color: 'black', background: 'none' }}
            disabled={jobtype == 'sub' ? true : false}
            className="whitespace-nowrap mx-0 p-0"
            variant="contained"
            color="third"
            onClick={id || job_id ? handleUpdateJob : handleSaveJob}
          >
            {id || job_id ? 'Update' : 'Save & Continue'}
            <Icon className="text-20 ml-5">
              {''}
              {'arrow_forward'}
            </Icon>
          </Button>
        </motion.div>
      </div>
    </>
  );
}

export default JobsHelpersHeader;
