import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Grid,
  Icon,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import { connect } from 'react-redux'
import withReducer from 'app/store/withReducer'
import PermissionAction from 'app/stores/loadz/redux/Permissions/actions'
import PermissionReducer from 'app/stores/loadz/redux/Permissions'
import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import TableHeader from './TableHeader'
import EmptyTableMessage from 'app/main/loadz/components/EmptyTableMessage'
import PermissionHeader from './PermissionHeader'
const Permissions = ({
  permissions,
  getPermissions,
  hasNextPage,
  page,
  loading,
}) => {
  const listInnerRef = useRef()
  const [scrollX, setScrollX] = useState(0)

  useEffect(async () => {
    await fetchPermission(1)
  }, [])

  const fetchPermission = async (page) => {
    await getPermissions({ page }, (error) => {
      console.log(error)
    })
  }

  const onScroll = async (event) => {
    if (listInnerRef.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
        scrollLeft,
        offsetHeight,
      } = listInnerRef.current
      const allow = scrollLeft > scrollX || scrollLeft < scrollX ? false : true
      setScrollX(scrollLeft)
      if (offsetHeight + scrollTop >= scrollHeight && allow) {
        if (hasNextPage) {
          await fetchPermission(page + 1)
        }
      }
    }
  }

  if (permissions.length == 0) {
    return <EmptyTableMessage data="Permissions" />
  }

  return (
    <div
      className={clsx('flex flex-1 flex-col m-16 px-36 mobile-p newjobdesign')}
    >
      <Box sx={{ width: '100%' }}>
        <PermissionHeader />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={10} className="grid-full">
            <Card className="jobtablecard">
              <CardContent className="jobstable">
                <div className="responsive-loads-table">
                  <TableContainer
                    style={{ maxWidth: '100%', maxHeight: '700px' }}
                    onScroll={onScroll}
                    ref={listInnerRef}
                  >
                    <Table
                      stickyHeader
                      size="small"
                      aria-labelledby="tableTitle"
                      className="pb-32"
                    >
                      <TableHeader />
                      <TableBody>
                        {permissions.map((role, index) => (
                          <TableRow className="h-72 cursor-pointer" key={index}>
                            <TableCell
                              className="w-40 md:w-64 text-center"
                              padding="none"
                            >
                              <Checkbox />
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              {index + 1}
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              {role.permission}
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              {role.created_at ? role.created_at : '-'}
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              {role.updated_at ? role.updated_at : '-'}
                            </TableCell>
                            <TableCell className="w-40 md:w-64 text-center">
                              <Chip
                                onClick={(event) => {}}
                                className="p-0 cursor-pointer bg-none"
                                label=""
                                icon={
                                  <Icon style={{ color: '#000' }}>
                                    edit <b>{role.id}</b>
                                  </Icon>
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    {loading && <LinearProgress />}
                    {!hasNextPage ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.1 } }}
                        className="flex flex-1 items-center justify-center h-full moble-content pb-16"
                      >
                        <Typography color="textSecondary" variant="h5">
                          No more Data
                        </Typography>
                      </motion.div>
                    ) : null}
                  </TableContainer>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    permissions: state.loadz_permission?.PermissionReducer?.permissions,
    hasNextPage: state.loadz_permission?.PermissionReducer?.hasNextPage,
    loading: state.loadz_permission?.PermissionReducer?.loading,
    page: state.loadz_permission?.PermissionReducer?.page,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPermissions: (payload, onError) =>
    dispatch(PermissionAction.getPermissions(payload, onError)),
})

export default withReducer(
  'loadz_permission',
  PermissionReducer
)(connect(mapStateToProps, mapDispatchToProps)(Permissions))
