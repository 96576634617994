import { Card, CardContent, TextField, Button, Container, Typography,   Modal } from '@mui/material';
import { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {formatePhoneNumber} from 'app/main/loadz/utils';
import { size } from 'lodash';

export const DriverDeleteAccount = () => {
  const [open, setOpen] = useState(false)
  const [showConfirmMessage, setShowConfirmMessage] = useState(false)
  const [phone, setPhone] = useState(null)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = () => {
    console.log(phone)
    if (!phone || phone.length < 10) {
      setShowError(true)
      setErrorMessage("Invalid phone number")
      return 
    }
    clearError()
    setOpen(true)
  }

  const clearError = () => {
    setShowError(false)
    setErrorMessage(null)
  }
  return ( <Container
    maxWidth="sm"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >

    { showConfirmMessage 
      ? <div className="h-full w-full flex flex-col justify-center items-center">
          <CheckCircleIcon color='success' sx={{ fontSize: 50 }} />
          <Typography variant="h6" align='center' className='mt-4' gutterBottom >
            Your request has been received and will be processed within seven working days.
          </Typography>
        </div>
      : <Card>
          <CardContent>
            <Typography>Please enter your register phone number.</Typography>
            <div className='flex items-center justify-between mt-16'>
              <TextField
                label="Phone Number"
                id="outlined-error-helper-text"
                value={formatePhoneNumber(phone)}
                onChange={(e) => {
                  if (e.target.value) {
                    clearError()
                  }
                  setPhone(e.target.value)
                }}
                error={showError}
                helperText={errorMessage}
                fullWidth
              />
              <Button
                className='ml-16'
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </CardContent>
        </Card>
    }
    
    <DeleteConfirmation open={open} handleClose={() => setOpen(false)} handleConfirmation={() => {
      setOpen(false)
      setShowConfirmMessage(true)
    }}/>
  </Container>)
}

const DeleteConfirmation = (props) => {
  const {open, handleClose, handleConfirmation} = props
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="delete-modal-title"
    aria-describedby="delete-modal-description"
  >
    <Card style={style}>
      <CardContent>
        <Typography variant="h5" id="delete-modal-title">
          Confirm Account Deletion
        </Typography>
        <Typography variant="body1" id="delete-modal-description" className='mt-16'>
          Are you sure you want to delete your account? This action cannot
          be undone.
        </Typography>
        <div className='flex w-full justify-end items-center mt-16'>
      
        <Button
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmation}
          className='ml-16'
        >
          Confirm
        </Button>
        </div>
      </CardContent>
    </Card>
  </Modal>
  )
}