import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import { ThemeProvider, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { memo, useEffect, useState } from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm } from 'react-hook-form';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Hidden from '@mui/material/Hidden';
import { hasPermission } from 'app/main/loadz/utils';

const SwitchColor = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function JobsHeader({ setSearch, changeJobFilter, data, setEditJobOpen, setJob_id }) {
  const mainTheme = useSelector(selectMainTheme);
  const user = useSelector(({ auth }) => auth.user.role);
  const dispatch = useDispatch();
  var permission = true;

  const [text, setText] = useState('');

  const [og, setog] = useState(false);
  const [sub, setsub] = useState(false);
  const [archive, setarchive] = useState(false);
  const [drafts, setDrafts] = useState(false);
  const [commissions, setCommissions] = useState(false);
  const [visibility, setVisivility] = useState(false);
  const [all, setall] = useState(true);

  useEffect(() => {
    if (text.length === 0) {
      setText('');
      setSearch('');
      return;
    }
    setText(text);
    setSearch(text);
  }, [text]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => console.log(data);

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: 'onBlur',
  });
  const onSubmitEmail = (data) => {
    console.log(data);
  };
  return (
    <>
      <div className="xl:flex lg:flex md:flex items-center justify-between header-jobs flex-col md:flex-row sm:block">
        <div className="xl:flex lg:flex md:flex items-center job-title-left w-full sm:w-auto sm:block">
          <div className="flex flex-col job-status w-0 sm:w-auto mb-10 md:mb-0">
            <Typography>Job Drafts</Typography>
          </div>
          
          
        </div>
        <div className="flex items-center job-right">
          <div className="job-srach">
            <ThemeProvider theme={mainTheme}>
              <motion.div
                className="new-job-btn desktop"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
              >
                <Paper
                  component={motion.div}
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                  className="flex items-center w-full  px-8 py-4"
                >
                  <Icon color="action">search</Icon>

                  <Input
                    placeholder="Search Job"
                    className="flex flex-1 mx-8"
                    disableUnderline
                    fullWidth
                    value={text}
                    type={'text'}
                    inputProps={{
                      'aria-label': 'Search Job',
                    }}
                    onChange={(ev) => {
                      ev.preventDefault();
                      setText(ev.target.value);
                    }}
                    autoFocus
                  />
                </Paper>
              </motion.div>
            </ThemeProvider>
          </div>
          <div className="job-add hidden">
            <motion.div
              className="new-job-btn mobile"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
            >
              {hasPermission('jobs.create') == true && data ? (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditJobOpen(true);
                    setJob_id('');
                  }}
                  className="whitespace-nowrap"
                  variant="contained"
                  color="secondary"
                >
                  <span className="hidden sm:flex  ">Add New Job</span>
                  <span className="flex sm:hidden">Add New Job</span>
                </Button>
              ) : (
                <Button
                  component={Link}
                  to="/jobs/new"
                  className="whitespace-nowrap"
                  variant="contained"
                  color="secondary"
                >
                  <span className="hidden sm:flex  ">Add New Job</span>
                  <span className="flex sm:hidden">Add New Job</span>
                </Button>
              )}
            </motion.div>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row  items-center w-full mob-mt mt-0 ">
        <div className="flex flex-row w-full">
          <div className="flex flex-1 items-center justify-center pr-12 srch-mobile"></div>
        </div>
      </div>
    </>
  );
}

export default memo(JobsHeader);
