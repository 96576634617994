import clsx from 'clsx';
import { useTimeout } from '@fuse/hooks';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useState } from 'react';

export default function Zero({value}) {
  return (
    <div
      className={clsx(
        'flex flex-1 flex-col items-center justify-center p-24',
        false && 'hidden'
      )}
    >
      <Typography className="text-13 sm:text-20 mb-16" color="textSecondary">
        There are no {value === 'Completed' ? 'tickets completed today' : value === 'Delayed' ? 'delayed tickets' : 'tickets in progress'}
      </Typography>
    </div>
  )
}