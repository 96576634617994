import { useState, useEffect, memo } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import client from '../../../../client';
import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FuseMessage from '@fuse/core/FuseMessage';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Typography } from '@mui/material';
import moment from 'moment';

const schema = yup.object().shape({
  unit_name: yup.string().required().nullable(false),
});

function ModalCreateUnits({ isOpen, setIsOpen,user_id }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      unit_name: '',
      unit_metric: '',
    },
    resolver: yupResolver(schema),
  });

  const clearForm = {
    unit_name: '',
    unit_metric: '',
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const { formState, watch, getValues, reset, control } = methods;
  const { isValid } = formState;

  const handleCreateUnits = async () => {
    try {
      const data = JSON.parse(JSON.stringify(getValues()))
      data.company_id = user_id
      data.unit_datecreated = moment().format('YYYY-MM-DD')
      const response = await client.post(`/unitTemps/`, data);

      if (response.data.message) {
        _showMessage(res.data.message, 'error');
        return;
      }

      _showMessage('The customer has been created!', 'success');

      setOpen(false);

      reset(clearForm);
      setIsOpen(false);
    } catch (error) {
      _showMessage('Something went wrong, please try again!', 'error');
    }
  };
  const { errors } = formState;

  return (
    <div>
      <FuseMessage />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Add new Units</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill this form with the information requested for create a Units
          </DialogContentText>
          <br />
          <FormProvider {...methods}>
            <div className="flex -mx-6">
              <Controller
                name="unit_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-8 mb-16 mx-4"
                    error={!!errors.name}
                    required
                    helperText={errors?.name?.message}
                    label="Unit Name"
                    autoFocus
                    id="unit_name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              <Controller
                name="unit_metric"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-8 mb-16 mx-4"
                    error={!!errors.name}
                    required
                    helperText={errors?.name?.message}
                    label="Unit Metric"
                    autoFocus
                    id="unit_metric"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!isValid}
            onClick={handleCreateUnits}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(ModalCreateUnits);
