import { useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Tooltip } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { constants, formatter } from '../../../../../constants';
import ModalGenerateInvoices from './ModalGenerateInvoices';
import ErrorBoundary from 'app/main/ErrorBoundary';
function TablePaginationActions(props) {


  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Row(props) {
  const {
    row,
    indexJob,
    totalMaterialCost,
    totalTicketCost,
    totalWaitTime,
    qtyTotal,
    finalToInvoice,
    setFinalToInvoice,
    setCheckBoxes,
    checkBoxes
  } = props;
  const [open, setOpen] = useState(false);
console.log("sdflisujdfs;iufjga;kadjgf",row)
  const [subTotalTicketCost, setSubTotalTicketCost] = useState(0);
  const [subTotalMaterialCost, setSubTotalMaterialCost] = useState(0);
  const [subTotalWaitTime, setSubTotalWaitTime] = useState(0);

  const handleOpen = (open, id) => {
    setOpen(!open)
  }

  const changeSelect = (object) => {
    const { i, indexJob, indexCheck } = object;

    const updatedCheckedState = checkBoxes.map((item, index) =>
      index === indexCheck ? !item : item
    );

    let final = finalToInvoice
    const { ticketCR, materialCR, waitCR } = final[indexJob].tickets[i]

    let tempSubTotalTicketCost = subTotalTicketCost
    let tempSubTotalMaterialCost = subTotalMaterialCost
    let tempSubTotalWaitTime = subTotalWaitTime

    if (updatedCheckedState[indexCheck]) {
      // to checked
      final[indexJob].tickets[i]['final'] = true

      tempSubTotalTicketCost += ticketCR ? Number(ticketCR.replace(/[^0-9\.]/g, '')) : 0
      tempSubTotalMaterialCost += materialCR ? Number(materialCR.replace(/[^0-9\.]/g, '')) : 0
      tempSubTotalWaitTime += waitCR ? Number(waitCR.replace(/[^0-9\.]/g, '')) : 0
    } else {
      // to unchecked
      final[indexJob].tickets[i]['final'] = false

      tempSubTotalTicketCost -= ticketCR ? Number(ticketCR.replace(/[^0-9\.]/g, '')) : 0
      tempSubTotalMaterialCost -= materialCR ? Number(materialCR.replace(/[^0-9\.]/g, '')) : 0
      tempSubTotalWaitTime -= waitCR ? Number(waitCR.replace(/[^0-9\.]/g, '')) : 0
    }

    setFinalToInvoice([...final])

    setCheckBoxes(updatedCheckedState);

    setSubTotalTicketCost(tempSubTotalTicketCost)
    setSubTotalMaterialCost(tempSubTotalMaterialCost)
    setSubTotalWaitTime(tempSubTotalWaitTime)
  }

  useEffect(() => {
    setSubTotalTicketCost(totalTicketCost)
    setSubTotalMaterialCost(totalMaterialCost)
    setSubTotalWaitTime(totalWaitTime)
  }, [])

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row"  align='left'>
          <Tooltip title={row.og_job_no? row.og_comp+"-"+row.og_job_no : ""} followCursor={true}>
          <Typography>Job No. {row.job}</Typography>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">

              </Typography>
              <ErrorBoundary>
              <Table size="small" aria-label="purchases" className='mb-32'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Job No.</TableCell>
                    <TableCell>Ticket No.</TableCell>
                    <TableCell >Qty</TableCell>
                    <TableCell ></TableCell>
                    <TableCell align="center">Hauling Sales</TableCell>
                    <TableCell align="center">Material Sales</TableCell>
                    <TableCell align="center">Wait Time Charges</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.tickets.map((ticket, i) => (
                    <>
                      {row.tickets.length + 1 === i + 1 ? (
                        <>

                        </>
                      ) : (
                        <ErrorBoundary>
                          <TableRow key={ticket.ticket}>
                            <TableCell>
                              <Tooltip title={`${checkBoxes[ticket.indexCheck] ? 'Remove' : 'Add'}`} placement="top">
                                <Checkbox
                                  color="primary"
                                  //indeterminate={numSelected > 0 && numSelected < rowCount}
                                  checked={checkBoxes[ticket.indexCheck]}
                                  onChange={() =>
                                    changeSelect({
                                      indexJob: indexJob,
                                      indexCheck: ticket.indexCheck,
                                      i: i
                                    })
                                  }
                                  inputProps={{
                                    'aria-label': 'select all desserts',
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell> <Tooltip title={row.og_job_no? row.og_comp+"-"+row.og_job_no : ""} followCursor={true}><Typography>{row.job}</Typography></Tooltip></TableCell>
                            <TableCell>{ticket.ticket}</TableCell>
                            <TableCell >{Number(ticket.qty).toFixed(2)}</TableCell>
                            <TableCell ></TableCell>
                            <TableCell align="center">{ticket.ticketCR ? ticket.ticketCR : `-`}</TableCell>
                            <TableCell align="center">{ticket.materialCR ? ticket.materialCR : `-`}</TableCell>
                            <TableCell align="center">{ticket.waitCR ? ticket.waitCR : `-`}</TableCell>
                          </TableRow>
                        </ErrorBoundary>
                      )}
                    </>
                  ))}
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell >{`${Number(qtyTotal).toFixed(2)}`}</TableCell>

                    <TableCell></TableCell>

                    <TableCell align="center">{`${formatter.format(Number(subTotalTicketCost).toFixed(2))}`}</TableCell>
                    <TableCell align="center">{`${formatter.format(Number(subTotalMaterialCost).toFixed(2))}`}</TableCell>
                    <TableCell align="center">{`${formatter.format(Number(subTotalWaitTime).toFixed(2))}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              </ErrorBoundary>
            </Box>
        </TableCell>
      </TableRow>
    </Fragment >
  );
}


export default function ModalToInvoice({
  openModal,
  tickets,
  infoToInvoice,
  setInfoToInvoice,
  rechargedAfterInvoices,
  setMessage
}) {
  const [open, setOpen] = useState(false);
  const [toTable, setTotable] = useState([]);
  const [checkBoxes, setCheckBoxes] = useState()

  const [finalToInvoice, setFinalToInvoice] = useState([]);
  const [uniqueJobs, setUniqueJobs] = useState([]);

  const openModalInvoices = useRef(null)

  const handleOpen = (open) => {
    setOpen(!open);
  };

  useEffect(async () => {
    openModal.current = handleOpen;
    if (tickets.length > 0) {
      const bigFilter = infoToInvoice.jobs.map(b => b.batches.map(i => i.info.filter(o => tickets.includes(o.ticketId)))).flat(3)
      /// ACOMODAR EL FILTRO DE LAS TICKETS PARQ LOS JOSB DE LOS CUSTOMERS

      const uniqueJobs = [...new Set(bigFilter.map((ticket) => ticket.job))];
      setUniqueJobs(uniqueJobs)

      let indexCheckBox = 0;
      const _rows = uniqueJobs.map(async (job, i) => {
        let obj = {
          og_job_no:null,
          og_comp:null,
          job: null,
          tickets: [],
          qtyTotal: 0,
          totalTicketCost: 0,
          totalMaterialCost: 0,
          totalWaitTime: 0,
          quarry: null
        };
        obj.job = job;
        bigFilter.forEach((jobs) => {
          if (job === jobs.job) {
            obj.og_job_no = jobs.og_job_no
            obj.og_comp = jobs.og_comp
            obj.tickets.push({
              indexCheck: indexCheckBox,
              ticket: jobs.ticket,
              ticketId: jobs.ticketId,
              qty: Number(jobs.qty),
              dateTime: jobs.dateTime,
              dateTimeUpdate: jobs.dateTimeUpdate,
              final: true,
              ticketCR: jobs.ticketCR,
              materialCR: jobs.materialCR,
              waitCR: jobs.waitCR,
            });
            obj.qtyTotal += Number(jobs.qty);

            obj.totalTicketCost += jobs.ticketCR ? Number(jobs.ticketCR.replace(/[^0-9\.]/g, '')) : 0
            obj.totalMaterialCost += jobs.materialCR ? Number(jobs.materialCR.replace(/[^0-9\.]/g, '')) : 0
            obj.totalWaitTime += jobs.waitCR ? Number(jobs.waitCR.replace(/[^0-9\.]/g, '')) : 0

            indexCheckBox++;
          }
        });
        return obj;
      });



      let checks = []
      let final = []
      tickets.forEach(e => {
        checks.push(true)
      })

      let toTable = await Promise.all(_rows)
      console.log(toTable)

      setCheckBoxes(checks)
      setFinalToInvoice(toTable)
      setTotable(toTable)
    }
  }, [tickets])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [isChecked, setIsChecked] = useState(true);
  function handleOnChange(event) {
    setIsChecked( !!event.target.checked);
  }

    console.log("ischecked",isChecked)
  return (
    <ErrorBoundary>
    <div>
      <ModalGenerateInvoices
        openModal={openModal}
        openModalInvoices={openModalInvoices}
        finalToInvoice={finalToInvoice}
        openModalTickets={open}
        uniqueJobs={uniqueJobs}
        infoToInvoice={infoToInvoice}
        tickets={tickets}
        rechargedAfterInvoices = {rechargedAfterInvoices}
        setMessage = {setMessage}
        isChecked={isChecked}
      />
      <BootstrapDialog
        maxWidth={'lg'}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleOpen(open)}>
          Tickets to Invoice
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {toTable.length === 0 ? (
            <div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="flex flex-1 items-center justify-center h-full"
            >
              <Typography color="textSecondary" variant="h5">
                Please select tickets to invoice!
              </Typography>
            </div>
          ) : (
            <TableContainer component={Paper} >
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? toTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : toTable
                  ).map((row, i) => (
                    <ErrorBoundary>
                    <Row
                      key={row.job}
                      row={row} i
                      indexJob={i}
                      setFinalToInvoice={setFinalToInvoice}
                      finalToInvoice={finalToInvoice}
                      setCheckBoxes={setCheckBoxes}
                      checkBoxes={checkBoxes}
                      qtyTotal={row.qtyTotal}
                      totalMaterialCost={row.totalMaterialCost}
                      totalTicketCost={row.totalTicketCost}
                      totalWaitTime={row.totalWaitTime} />
                      </ErrorBoundary>
                  ))}

                </TableBody>
                <TableFooter>
                  <TableRow>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}

        </DialogContent>

        <DialogActions>


        <div className="App">
          <lable>Send Later </lable>
      <input
        onChange={handleOnChange}
        type="checkbox"
        checked={isChecked}
      />
    </div>
{isChecked ?

          finalToInvoice.length > 0 &&
            <Button autoFocus onClick={() => openModalInvoices.current()}>
              Generate
            </Button>
          :
          finalToInvoice.length > 0 &&
            <Button autoFocus  onClick={() => openModalInvoices.current()}>
              Generate And Send
            </Button>
        }

          <Button autoFocus onClick={() => handleOpen(open)}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
    </ErrorBoundary>
  );
}
