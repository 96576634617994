import { Table, TableContainer, Paper, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import TableHeader from './Brows/TableHeader'
import DataTableBody from './Brows/DataTableBody'
import DataTableToolBar from './Brows/DataTableToolbar'
import withReducer from 'app/store/withReducer'
import RolesPermissionsReducer from 'app/stores/loadz/redux/RolesPermissions'
import RolesPermissionActions from 'app/stores/loadz/redux/RolesPermissions/actions'
import { motion } from 'framer-motion'

const RolesPermissions = ({
  data,
  getAll,
  clearRoleAssignPermission,
  deleteRole,
  getAssignedPermission
}) => {
  console.log('RolesPermissions')
  const [selected, setSelected] = useState([])
  const [displayMenu, setDisplayMenu] = useState(null)

  useEffect(async () => {
    await getAll({ page: 1 }, (error) => {
      console.log(error)
    })

    await getAssignedPermission({}, (error) => {
      console.log(error)
    })
    clearRoleAssignPermission()
  }, [])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedRow = data.map((n) => n.id)
      setSelected(selectedRow)
      return
    }
    setSelected([])
  }

  const onSelectionChange = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let selectedRow = []

    if (selectedIndex === -1) {
      selectedRow = selectedRow.concat(selected, id)
    } else if (selectedIndex === 0) {
      selectedRow = selectedRow.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      selectedRow = selectedRow.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      selectedRow = selectedRow.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(selectedRow)
  }

  const onDelete = () => {
    setDisplayMenu(null)
    deleteRole(
      { id: selected },
      (message) => {
        if (message) {
          getData()
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  const getData = async () => {
    await getAll({ page: 1 }, (error) => {
      console.log(error)
    })
    clearRoleAssignPermission()
  }

  return (
    <div className="p-32" style={{height : '100%'}}>
      <DataTableToolBar />
      { 
        data.length === 0 ?
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className='flex justify-center items-center'
            style={{height: '500px'}}
          >
            <Typography color="textSecondary" variant="h5">
              There are no Roles, please create one!
            </Typography>
          </motion.div> :

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHeader
                numSelected={selected.length}
                rowCount={data.length}
                onSelectAllClick={handleSelectAllClick}
                onDelete={onDelete}
                setDisplayMenu={setDisplayMenu}
                displayMenu={displayMenu}
              />
              <DataTableBody
                data={data}
                selectedRow={selected}
                onRowSelection={onSelectionChange}
              />
            </Table>
          </TableContainer>
      } 
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.loadz_roles_permissions.RolesPermissionsReducer.data,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAll: (payload, onError) =>
    dispatch(RolesPermissionActions.getAll(payload, onError)),
  clearRoleAssignPermission: () =>
    dispatch(RolesPermissionActions.clearRoleAssignPermission()),
  deleteRole: (payload, onSuccess, onError) =>
    dispatch(RolesPermissionActions.delete(payload, onSuccess, onError)),
  getAssignedPermission: (payload, onError) =>
    dispatch(RolesPermissionActions.getAssignedPermission(payload, onError)),
})
export default withReducer(
  'loadz_roles_permissions',
  RolesPermissionsReducer
)(connect(mapStateToProps, mapDispatchToProps)(RolesPermissions))
