const actions = {
  GET_PERMISSIONS: 'getPermissions',

  getPermissions: ({ page }, onError) => {
    const payload = { params: { page } }
    return {
      type: actions.GET_PERMISSIONS,
      payload,
      onError,
    }
  },
}

export default actions
