import { constants } from '../../../../constants';
import axios from 'axios'
import store from '../../../main/ID_store/store'
import client from 'client'


export const updateSettings = async (info) => {
    const result = await client.post(`/props/update-settings`, info);
    console.log(result.data.settings)
    const compacct = store.getState().comp_acct
    const id = store.getState().id
    const compname = store.getState().compname
    const comp_email = store.getState().comp_email
    const photo = store.getState().photo
    const staff_id = store.getState().staff_id
    const company_id = store.getState().small_comp_id
    const settings = result.data.settings
    store.dispatch({type: 'LOG_IN', payload: {comp_email,compname,staff_id,company_id,compacct,id,settings}})
    store.dispatch({type: 'PHOTO', payload: {photo}})
    console.log(store.getState())
}