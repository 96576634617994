import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import _ from '@lodash';
import ReactApexChart from 'react-apexcharts';
import { constants } from '../../../constants';
import { memo, useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import axios from 'axios';
import store from '../ID_store/store';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { blue, green, orange, red, grey, yellow } from '@mui/material/colors';
import Check_refresh from '../Check_refresh';
import client from 'client';
import { connect } from 'react-redux';
import { hasPermission } from 'app/main/loadz/utils';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Button } from '@mui/material';
import ManagemenpaymentMethods from '../account/ManagemenpaymentMethods';

const week = [
  moment().day('Sunday').format('MM/DD/YYYY'),
  moment().day('Monday').format('MM/DD/YYYY'),
  moment().day('Tuesday').format('MM/DD/YYYY'),
  moment().day('Wednesday').format('MM/DD/YYYY'),
  moment().day('Thursday').format('MM/DD/YYYY'),
  moment().day('Friday').format('MM/DD/YYYY'),
  moment().day('Saturday').format('MM/DD/YYYY'),
];
function formatDateArray(dateArray) {
  const formattedDates = [];
  dateArray.forEach((dateString) => {
    const date = moment(dateString, 'MM/DD/YYYY');
    const formattedDate = date.format('ddd MM/DD');
    formattedDates.push(formattedDate);
  });
  return formattedDates;
}
const formattedArray = formatDateArray(week);
// let data = {

//   series: [],
//   options: {
//     chart: {
//       height: 350,
//       type: 'area'
//     },
//     dataLabels: {
//       enabled: false
//     },
//     stroke: {
//       curve: 'smooth'
//     },
//     xaxis: {
//       type: 'datetime',
//       categories: week
//     },
//     tooltip: {
//       x: {
//         format: 'dd/MM/yy HH:mm'
//       },
//       tooltip: {
//         theme: "light",
//         followCursor: true,
//         //enabledOnSeries: true,
//         //fillSeriesColor:true,
//         style: {
//           fontSize: '13px',
//           fontFamily: 'poppins'
//         },
//       },
//     },
//   },

// };
const editNums = (num) => {
  var temp = num.toLocaleString();
  if (temp.split('.').length == 2) {
    if (temp.split('.')[1].length < 2) {
      temp = temp + '0';
    }
  }
  if (temp.includes(',')) {
    temp = temp.replace(',', '');
  }
  return temp;
};

function ChartCompleted({ refreshGraph, compacct, user }) {
  const { compname, comp_email, comp_acct } = store.getState();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [chartData, setChartData] = useState(true);
  const [series, setSeries] = useState([]);
  const [dataMethodPayment, setDataMethodPayment] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'area',
        offsetY: -0,
      },
      fill: {
        // type: 'gradient',
        gradient: {
          // opacityFrom: 0.6,
          // opacityTo: 0.8,
        },
        // colors: [green[500], blue[500], orange[500], grey[900], red[500],  yellow[500]]
      },
      dataLabels: {
        enabled: false,
        // colors: [green[500], blue[500], orange[500], grey[900], red[500],  yellow[500]]
      },
      stroke: {
        curve: 'smooth',
        // colors: [green[500], blue[500], orange[500], grey[900], red[500],  yellow[500]]
      },
      xaxis: {
        categories: formattedArray,
        labels: {
          datetimeFormatter: {
            day: 'ddd MM/dd',
          },
        },
      },
      yaxis: {
        decimalsInFloat: 0,
      },
      tooltip: {
        enabled: hasPermission('dashboard'),
        x: {
          format: 'dddd MM/dd',
        },
      },
      legend: {
        show: true,
        offsetY: 10,
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        labels: {
          useSeriesColors: true,
        },
      },
      markers: {
        // colors: [green[500], blue[500], orange[500], grey[900], red[500],  yellow[500]]
      },
    },
  });

  const token = localStorage.token;

  const getPaymentMethod = async (userId) => {
    try {
      const response = await fetch(`${constants.URLLOCAL}/payments/get-payment-method`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: null,
        },
        body: JSON.stringify({
          id: userId,
          payment_source: 1,
          all_method: true,
        }),
      });
      const data = await response.json();

      console.log('data', data);

      if (data.length > 0) {
        setDataMethodPayment(data);
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataForChart = async () => {
    try {
      // const dataForChart = await fetch(`${constants.URLLOCAL}/jobs/get-for-dashboard-graph/${compacct}`, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': `${token}`,
      //   }
      // })

      const { user_id, company } = user;
      const { compacctnum } = company;
      const dataForChart = await client.get(`/jobs/get-for-dashboard-graph/${compacctnum}`);
      await getPaymentMethod(user_id);
      console.log('aqui verificamos si tengo mi metodo de pago creado', user_id);

      const fuelResponse = await client.post(`/mileage/weekly-fuel`, { comp_id: user_id });
      const fuel_data = await fuelResponse.data.day_totals;

      const ticketResponse = await client.post(`/ticket/ticket-cost`, { comp_acct: compacctnum });
      const ticket_data = await ticketResponse.data.day_totals;

      const subconticketResponse = await client.post(`/ticket/subconticket-cost`, {
        comp_acct: compacctnum,
      });
      const subconticket_data = await subconticketResponse.data.day_totals;
      const dataForChartJson = dataForChart.data;

      if (dataForChartJson && dataForChartJson.msg) {
        return;
      }

      console.log(dataForChartJson);

      let _materialCr = [];
      let _ticketCr = [];
      let _waitTimeCr = [];
      let fuel_costs = [];
      let ticket_costs = [];
      let subconticket_costs = [];

      if (dataForChartJson && dataForChartJson.length > 0) {
        dataForChartJson.forEach((element) => {
          _materialCr.push(element.materialCr ? element.materialCr : 0);
          _ticketCr.push(element.ticketCr ? element.ticketCr : 0);
          _waitTimeCr.push(element.waitTime ? element.waitTime : 0);
        });
      }

      fuel_data.forEach((elem) => {
        fuel_costs.push(editNums(elem.total));
      });

      ticket_data.forEach((elem) => {
        ticket_costs.push(elem.total);
      });

      subconticket_data.forEach((elem) => {
        subconticket_costs.push(elem.total);
      });

      setSeries([
        {
          name: 'Hauling Sales',
          data: _ticketCr,
        },
        {
          name: 'Material Sales',
          data: _materialCr,
        },
        {
          name: 'Wait Time Charges',
          data: _waitTimeCr,
        },
        {
          name: 'Fuel Costs',
          data: fuel_costs,
        },
        {
          name: 'Employee Commissions',
          data: ticket_costs,
        },
        {
          name: 'Owner Operator Commissions',
          data: subconticket_costs ? subconticket_costs : 0,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: <Button onClick={() => managePaymentMethods()}>{msg}</Button>, //text or html
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: color,
      })
    );
  };

  const managePaymentMethods = async () => {
    setOpen(true);
  };

  // useEffect(() => {
  //   if (dataMethodPayment.length > 0) {
  //     _showMessage('Add Method Payment', 'info');
  //   }
  // }, [dataMethodPayment]);

  useEffect(() => {
    refreshGraph.current = getDataForChart;
    getDataForChart();
  }, []);

  if (!chartData) {
    return <></>;
  } else {
    return (
      <>
        {open && (
          <ManagemenpaymentMethods
            open={open}
            onClose={setOpen}
          ></ManagemenpaymentMethods>
        )}

        <Card
          className="w-full rounded-none"
          style={{ border: '3px solid #edca33', backgroundColor: '#FFFFFF' }}
        >
          <div className="p-20 pb-0">
            <Typography className="h2 font-Regular">
              {moment(week[0]).format('MM/DD/YYYY')} to {moment(week[6]).format('MM/DD/YYYY')}
            </Typography>

            <div className="flex flex-row flex-wrap items-center mt-12">
              <Typography className="text-48 font-Regular leading-none tracking-tighter"></Typography>

              <div className="flex flex-col mx-8"></div>
            </div>
          </div>
          <div className="h-300 w-100-p">
            <ReactApexChart
              options={data.options}
              series={series}
              type="area"
              height={350}
            />
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};

export default memo(connect(mapStateToProps, null)(ChartCompleted));
