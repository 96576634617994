import { useEffect, useRef, useState } from "react";
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const QuarryPitMap = (props) => {
  const {query, customerQuery, handleMaps} = props
  const mapRef = useRef(null);
  const methods = useFormContext();
  const { control, formState, setValue } = methods;
  const { errors } = formState;

  const [map, setMap] = useState(null);

  const marker = useRef(null)

  const checkLongName = (objData, id) => {
    if (
      objData &&
      objData.address_components &&
      objData.address_components.length &&
      objData.address_components[id] != undefined &&
      objData.address_components.length == id &&
      objData.address_components[id].long_name
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const newMap = new window.google.maps.Map(mapRef.current, {
      zoom: 12,
      center: { lat: 30.2426319, lng: -97.7542349 }, // Initial center
    });
    setMap(newMap);
  }, [mapRef.current]);


  useEffect(() => {
    if (map) {
      // Add event listener for map click
      map.addListener('click', onMapClick);
    }
  }, [map]);

  const onMapClick = (event) => {
    const clickedLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setValue('l_latitude', event.latLng.lat());
    setValue('l_longitude', event.latLng.lng());

    changeMarkerPosition(clickedLocation)
    map.panTo(clickedLocation);

    handleMaps({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    })
  }

  const changeMarkerPosition = (location) => {
    if (marker.current) marker.current.setMap(null)
    marker.current = new window.google.maps.Marker({
      position: location,
      map: map
    });
  }

  useEffect(() => {
    if (query && query?.l_latitude && query?.l_longitude) {
      const position = { lat: Number(query?.l_latitude), lng: Number(query?.l_longitude)}
      changeMarkerPosition(position)

    } else if (customerQuery && customerQuery?.l_latitude && customerQuery?.l_longitude) {
      const position = { lat: Number(customerQuery?.l_latitude), lng: Number(customerQuery?.l_longitude)}
      changeMarkerPosition(position)
    }

  }, [query, customerQuery])

  const search = () => {
    const input = document.getElementById('autocomplete-input');
    const autocomplete = new window.google.maps.places.Autocomplete(input);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry || !map) return;

      changeMarkerPosition(place.geometry.location)

      // Pan map to center around marker
      map.panTo(place.geometry.location);

      const dir = place.formatted_address;
      setValue('l_address', dir);
      const cit = checkLongName(place, 2) ? place.address_components[2].long_name : null;
      setValue('l_city', cit);
      const stat = checkLongName(place, 4) ? place.address_components[4].long_name : null;
      setValue('l_state', null ? null : stat);
      const zip = checkLongName(place, 6) ? place.address_components[6].long_name : null;
      setValue('l_zip', null ? null : zip);
      setValue('l_latitude', place.geometry.location.lat());
      setValue('l_longitude', place.geometry.location.lng());
      handleMaps({ latLng: {lat:place.geometry.location.lat(), lng: place.geometry.location.lng()}, dir, cit, stat, zip });
    });
  };


  return (
    <div className="w-500 h-512">
      <Controller
        name="l_address"
        control={control}
        render={({ field }) => (
          <TextField
            id="autocomplete-input"
            label="Search Address or Coordinates (latitude , longitude)"
            variant="outlined"
            fullWidth
            onChange={search}
          />
          )}
      />
      <div ref={mapRef} style={{ width: '550px', height: '500px', margin: '16px 0' }} />
    </div>
  )
}
export default QuarryPitMap
