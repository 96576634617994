import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const CatMaterialsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/catmaterials/categories/:categoryId/:categoryName?',
      component: lazy(() => import('./materialCategory/Category')),
    },
    {
      path: '/catmaterials/categories',
      component: lazy(() => import('./MaterialCategories/Category')),
    },
    {
      path: '/catmaterials',
      component: () => <Redirect to="/catmaterials/categories" />,
    },
  ],
};

export default CatMaterialsAppConfig;
