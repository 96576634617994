import { useState, useEffect, memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { constants } from '../../../../constants';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

function ModalViewScale({ scaleImage, isOpen, setIsOpen }) {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <IconButton
          style={{ color: '#edca33' }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <img src={`${constants.URLLOCAL}/aws/signed?image=${scaleImage}`} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default memo(ModalViewScale);
