import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import TextField from '@mui/material/TextField';
import { Divider, FormControl, Grid, Paper } from '@mui/material';
import moment from 'moment';
import { formatter, constants } from '../../../../../constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactToPrint from 'react-to-print';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ModelCustomPdf from '../../Uninvoiced/Modals/ModelCustomPdf';
import ModelInvoiceEmail from '../../Uninvoiced/Modals/ModelInvoiceEmail';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import store from 'app/store';
import Check_refresh from 'app/main/Check_refresh';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getDataFromS3 } from 'app/main/CompanyList/companyFunctions';
import axios from 'axios';
import FuseLoading from '@fuse/core/FuseLoading';
import client from 'client';

const TODAY = moment();

const item = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0 },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const token = localStorage.token;

function DatePickerTerms({ setTerms, terms, setDueDates, dueDates, dateCreated, index }) {
  const [date, setDate] = useState();

  useEffect(() => {
    setDate(dueDates[index]);
  }, [dueDates]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Due Date for Invoice"
        value={date}
        onChange={(valueFinal) => {
          //to due dates invoices per job
          let newDueDates = [...dueDates];
          let newTerms = terms;
          //final due date
          const startDate = moment(dateCreated, 'YYYY-MM-DD');
          const endDate = moment(moment(valueFinal), 'YYYY-MM-DD');
          //to terms
          const diffDays = moment.duration(endDate.diff(startDate)).asDays();

          let newDate = moment(valueFinal).format('MM/DD/YYYY');

          newTerms = Math.ceil(diffDays);
          newDueDates[index] = newDate;
          setTerms(newTerms);
          setDueDates(newDueDates);
          setDate(newDate);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ModalGenerateInvoiceEdit({
  openModalInvoices,
  openModal,
  finalToInvoice,
  rechargedAfterInvoices,
  setMessage,
  info,
  tickets,
  rechargedAfterChanges,
  prop_data,
  getPerCustomer
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [uniqueJobs, setUniqueJobs] = useState([]);
  const [toTable, setTotable] = useState([]);
  const [dataForPDF, setDataWithSignature] = useState([]);
  const [logoCompany, setLogoCompany] = useState('');
  const [notes, setNotes] = useState('');
  const [reconcile, setReconcile] = useState('');
  const [discounts, setDiscounts] = useState(0);
  const [dateStarted, setDateStarted] = useState(null);
  const [terms, setTerms] = useState(0);
  const [compacct, setCompacct] = useState('');
  const [compName, setCompName] = useState(null);
  const [ticketsNull, setTicketsNull] = useState(false);
  const [dueDates, setDueDates] = useState([]);
  const TableToTable = toTable[0]?.customer?.jobs[0]?.batches[0];
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [loaderData, setLoaderData] = useState(true);
  const [infoData, setInfoData] = useState(null);
  const state = store.getState();
  const handleOpen = (open) => {
    setOpen(!open);
    openModal.current();
  };

  useEffect(() => {
    openModalInvoices.current = handleOpen;
  }, []);

  useEffect(async () => {
    console.log('open');
    if (open) {
      await Check_refresh();
      const IDCOMPANY = prop_data
        ? prop_data.compacctnum
        : state?.loadz_auth?.AuthReducer?.user?.company?.compacctnum;
      const compName = prop_data
        ? prop_data.compname
        : state?.loadz_auth?.AuthReducer?.user?.company?.compname;
      setCompName(compName);
      setCompacct(IDCOMPANY);
      setReconcile('');
    }
  }, [open]);

  useEffect(() => {
    setTotable([]);
    console.log('WER ARE HERER ERERER')
    if (open && compacct) {

      getData();
    }
  }, [compacct, open]);

  const getImage = async (url) => {
    const awsImage = await fetch(`${constants.URLLOCAL}/aws/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: `${token}`,
      },
      body: JSON.stringify({
        key: url,
      }),
    });
    const img = await awsImage.json();
    return img.data?.fileLocation ? img.data.fileLocation : null;
  };

  const getData = async () => {
    try {
      // setZero(false)
      setLoaderData(true);
      let _rows = [];

      setInfoData(info);
      const tickets = finalToInvoice
        .map((b) =>
          b.tickets.map((i) => {
            if (i.final) {
              return i.ticketId;
            }
          })
        )
        .flat(3);

      const ticketsNull = finalToInvoice
        .map((b) =>
          b.tickets.map((i) => {
            if (i.final === false) {
              return i.ticketId;
            }
          })
        )
        .flat(3);

      setTicketsNull(ticketsNull);

      // const jobs = await fetch(`${constants.URLLOCAL}/invoices/tickets-invoices/${compacct}`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': `${token}`,
      //   },
      //   body: JSON.stringify({
      //     tickets: tickets
      //   })
      // })
      const jobs = await client.post(`/invoices/tickets-invoices/${compacct}`, { tickets });
      const jsonJobs = await jobs?.data;

      if (jsonJobs && jsonJobs?.length > 0) {
        jsonJobs[0].customer.jobs.forEach((job, index) => {
          let dueDateSet = moment(TODAY, 'MM/DD/YYYY')
            .add(jsonJobs[0].customer.customerTerm, 'days')
            .format('MM/DD/YYYY');
          console.log('dueDateSetdueDateSet => ', dueDateSet);
          jsonJobs[0].customer.jobs[index].newDueDate = dueDateSet;
        });
      }

      if (jsonJobs && jsonJobs.length > 0) {
        if (jsonJobs[0].customer && jsonJobs[0].customer.jobs) {
          if (jsonJobs[0].customer.companyLogo) {
            jsonJobs[0].customer['blobResLOGO'] = jsonJobs[0].customer.companyLogo;
          }
        }
      }

      if (jsonJobs.msg) {
        return;
      }
      _rows = jsonJobs;
      if (_rows && _rows.length > 0) {
        // const logo = await getImage(_rows[0].customer.companyLogo)
        const logo = _rows[0].customer.companyLogo;

        setLogoCompany(logo ? logo : 'assets/images/logos/loadzlogo.png');

        setTotable(_rows);

        const uniqueJob = [...new Set(_rows[0].customer.jobs.map((job) => job.job))];

        setUniqueJobs(uniqueJob);

        //getLastInvoice()

        const startDate = moment(info.dateCreated, 'YYYY-MM-DD');
        setDateStarted(info.dateCreated);
        const endDate = moment(info.dueDate, 'YYYY-MM-DD');
        const diffDays = moment.duration(endDate.diff(startDate)).asDays();
        setTerms(diffDays);
        setInvoiceNumber(info.invoice);
        setNotes(info.notes);
        setReconcile(info?.reconcile ? info.reconcile : '');
        console.log(
          '🚀 ~ file: ModalGenerateInvoiceEdit.js ~ line 298 ~ getData ~ info?.reconcile',
          info?.reconcile
        );
        setDiscounts(info.discount ? info.discount.toFixed(2) : 0);
        setDueDates(Array(uniqueJob.length).fill(moment(info.dueDate).format('MM/DD/YYYY')));
        setLoaderData(false);
        let dataWithSig = _rows;
        for (let j = 0; j < dataWithSig[0].customer.jobs.length; j++) {
          let tempIndex = 0;
          let ele = dataWithSig[0].customer.jobs[j];
          if (ele.batches && ele.batches.length > 0) {
            for (let index = 0; index < ele.batches.length; index++) {
              let batch = ele.batches[index];
              if (batch.info && batch.info.length > 0) {
                for (let i = 0; i < batch.info.length; i++) {
                  let ticket = batch.info[i];
                  ticket.printIndex = tempIndex;
                  tempIndex++;
                  let data = {
                    key: ticket.tkSignature,
                  };
                  let resp = await getDataFromS3(data);
                  if (resp) {
                    batch.info[i].newSignUrl = resp.data.data.fileLocation;
                    let blobRes = await getImageBlobFromsignature(resp.data.data.fileLocation);
                    if (blobRes) {
                      batch.info[i]['blobUrl'] = blobRes;
                    }
                  }
                }
              }
            }
          }
        }
        setDataWithSignature(dataWithSig);
      }
    } catch (e) {
      setLoaderData(false);
    }
  };
  const componentRef = useRef();

  const [openEmail, setOpenEmail] = useState(false);
  const emailhandleOpen = () => setOpenEmail(false);

  const handleChange = (e, index) => {
    const { value } = e.target;

    setNotes(value);
  };

  const handleChangeReconcile = (e, index) => {
    const { value } = e.target;
    console.log(value);
    setReconcile(value ? value : '');
  };

  const handleChangeDiscount = (e, index) => {
    const { value } = e.target;

    setDiscounts(value);
    toTable[0].customer.jobs.forEach((job, index) => {
      job['discount'] = Number(value);
    });
  };

  const handleChangeTerms = (e, index) => {
    const { value } = e.target;

    setTerms(value);
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 20000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const sendData = async () => {
    //here put the send email with pdf
    let addIndex = toTable;
    addIndex[0].customer.jobs.forEach((job, index) => {
      job['discount'] = discounts;
      job['notes'] = notes;
      job['dueDate'] = dueDates[index];
      job['invoice_no'] = invoiceNumber;
      job['reconcile'] = reconcile;
    });

    const customerName = addIndex[0].customer.customerName;

    try {
      // const invoices = await fetch(`${constants.URLLOCAL}/invoices/update-invoice/${compacct}`, {
      //   method: 'PUT',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': `${token}`,
      //   },
      // body: JSON.stringify({
      //   invoices: addIndex,
      //   tickets: ticketsNull
      // })
      // })

      const invoices = await client.put(`/invoices/update-invoice/${compacct}`, {
        invoices: addIndex,
        tickets: ticketsNull,
      });
      if (invoices.status === 500) {
        _showMessage(
          `Oops! There was an issue generating an invoice for ${customerName}. Please try again.`,
          'error'
        ); //TODO improve message
        return;
      }

      setOpen(false);
      openModal.current(true);
      //rechargedAfterInvoices.current()
      rechargedAfterChanges.current();
      _showMessage(
        `Invoice successfully edited for ${customerName}. Details: Invoice ${invoiceNumber} / Job No. ${uniqueJobs[0]}.`,
        'success'
      ); //TODO Message iproved AND Job Info displayed needs to be Number.
      getPerCustomer();
    } catch (error) {
      console.log(error);
      _showMessage(
        `Oops! There was an issue generating an invoice for ${customerName}. Please try again.`,
        'error'
      ); //TODO improve message
      getPerCustomer();
    }
  };
  const [blobLogoForUNVoice, setBlobLogoForUNVoice] = useState(null);

  const [storeLogo, setStoreLogo] = useState(null);
  const getS3Location = async (key) => {
    if (key != null && key != '') {
      let data = {
        key: prop_data ? prop_data.logo : state?.loadz_auth?.AuthReducer?.user?.company?.logo,
      };
      return await getDataFromS3(data);
    }
  };

  useEffect(async () => {
    let key = prop_data ? prop_data.logo : state?.loadz_auth?.AuthReducer?.user?.company?.logo;
    let logoRes = await getS3Location(key);
    if (logoRes && logoRes.data && logoRes.data.data) {
      logoRes.data.data.logoKey = key;
      setStoreLogo(logoRes.data.data);
    }
  }, [state?.loadz_auth?.AuthReducer?.user?.company?.logo]);

  async function getImageBlobFromUrl(url) {
    try {
      const image = await axios
        .get(url, {
          responseType: 'blob',
        })
        .then((response) => window.URL.createObjectURL(new Blob([response.data])));
      return image;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  // React.useEffect(async () => {
  //   if (storeLogo && storeLogo.fileLocation) {
  //     const image = await getImageBlobFromUrl(storeLogo.fileLocation);
  //     setBlobLogoForUNVoice(image);
  //   }
  // }, [storeLogo]);

  async function getImageBlobFromsignature(url) {
    try {
      const image = await axios
        .get(url, {
          responseType: 'blob',
        })
        .then((response) => window.URL.createObjectURL(new Blob([response.data])));
      return image;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  return (
    <div>
      <BootstrapDialog
        maxWidth={'xl'}
        // onClose={() => handleOpen(open)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        className={loaderData ? 'customloader' : ''}
      >
        <div>
          {/* <PDFViewer style={{ height: "1000px", width: "100%", }}>
            <ModelCustomPdf toTable={toTable} />
        </PDFViewer> */}
        </div>
        {loaderData ? (
          <div>
            <FuseLoading />
          </div>
        ) : (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => handleOpen(open)}
            >
              <div
                className="invoicePrintbtns"
                style={{ marginTop: '15px' }}
              >
                <ReactToPrint
                  trigger={() => (
                    <div>
                      <button
                        className="invoicePrintbtn"
                        type="button"
                      >
                        <img
                          width="20px"
                          height="20px"
                          src="/assets/images/icons/print-icon-white.svg"
                        />
                      </button>
                    </div>
                  )}
                  content={() => componentRef.current}
                />
                <div>
                  {/* <PDFDownloadLink
              document={<ModelCustomPdf toTable={toTable} />}
              fileName={"PDF_REPORT.pdf"}
            >{({ blob, url, loading, error }) =>
              loading ? "Report loading..." : "Report ready to download"
              }
            </PDFDownloadLink> */}
                  {dataForPDF && dataForPDF.length > 0 ? (
                    <PDFDownloadLink
                      document={
                        <ModelCustomPdf
                          toTable={dataForPDF}
                          compName={compName}
                          TableToTable={TableToTable}
                          notes={notes}
                          blobLogoForUNVoice={blobLogoForUNVoice}
                          invoiceNumber={invoiceNumber}
                          dueDates={dueDates}
                          terms={terms}
                          dateStarted={dateStarted}
                          discounts={discounts ? discounts : 0}
                        />
                      }
                      fileName={`${TableToTable?.info[0]?.job}-${
                        TableToTable?.info[0]?.job.length > 0 ? invoiceNumber + 1 : invoiceNumber
                      }-${moment().format('MM/DD/YYYY hh:mm:ss')}`}
                    >
                      <div
                        className="invoicePrintbtn"
                        style={{ marginRight: '0' }}
                      >
                        <img
                          width="20px"
                          height="20px"
                          src="/assets/images/icons/pdf-icon-white.svg"
                        />
                      </div>
                    </PDFDownloadLink>
                  ) : (
                    <div
                      className="invoicePrintbtn"
                      style={{ marginRight: '0' }}
                    >
                      <CircularProgress color="success" />
                    </div>
                  )}
                </div>
                <div
                  className="invoicePrintbtn"
                  onClick={() => setOpenEmail(true)}
                >
                  <img
                    width="20px"
                    height="20px"
                    src="/assets/images/icons/email-icon-white.svg"
                  />
                </div>
              </div>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <div className="flex w-full max-w-screen-sx ModalGenerateInvoices-sm">
                <div className="w-1/3 justify-center items-center">
                  <Card
                    component={motion.div}
                    variants={item}
                  >
                    <Paper
                    // style={{ maxHeight: 750, overflow: 'auto' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          paddingRight: '30px',
                          paddingLeft: '20px',
                          paddingTop: '10px',
                        }}
                      >
                        {uniqueJobs.map((job, index) => (
                          <div>
                            <Typography
                              variant="h6"
                              gutterBottom
                            >
                              Job No. {job}
                            </Typography>

                            <FormControl
                              fullWidth
                              sx={{ m: 1 }}
                            >
                              {/* <TextField
                              id={Math.random()}
                              label={`Due Date for ${job}`}
                              onChange={(e) => handleChangeTerms(e, index)}
                              variant="outlined"
                              value={terms}
                              type={'number'} /> */}

                              <DatePickerTerms
                                setTerms={setTerms}
                                terms={terms}
                                setDueDates={setDueDates}
                                dueDates={dueDates}
                                dateCreated={info.dateCreated}
                                index={index}
                              />
                            </FormControl>
                            <FormControl
                              fullWidth
                              sx={{ m: 1 }}
                            >
                              <TextField
                                id={Math.random()}
                                label={'Discount'}
                                ariant="outlined"
                                onChange={(e) => handleChangeDiscount(e, index)}
                                value={discounts}
                                type={'number'}
                              />
                            </FormControl>
                            {/* <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                              id={Math.random()}
                              label={'Deductions'}
                              variant="outlined"
                              type={'number'} />
                          </FormControl> */}
                            <FormControl
                              fullWidth
                              sx={{ m: 1 }}
                            >
                              <TextField
                                id={Math.random()}
                                label={'Notes'}
                                variant="outlined"
                                value={notes}
                                onChange={(e) => handleChange(e, index)}
                                multiline
                                maxRows={4}
                              />
                            </FormControl>

                            <FormControl
                              fullWidth
                              sx={{ m: 1 }}
                            >
                              <TextField
                                id={Math.random()}
                                label={'Reconcile Reference'}
                                variant="outlined"
                                value={reconcile ? reconcile : ''}
                                onChange={(e) => handleChangeReconcile(e, index)}
                                multiline
                                maxRows={4}
                              />
                            </FormControl>
                            <Divider />
                          </div>
                        ))}
                      </Box>
                    </Paper>
                  </Card>
                </div>

                <div
                  className="w-full ModalGenerateInvoicesPrints"
                  style={{ padding: '10px' }}
                >
                  <Card
                    ref={componentRef}
                    component={motion.div}
                    variants={item}
                    style={{ border: '0', borderRadius: '0', padding: '20px', boxShadow: 'none' }}
                  >
                    <Paper className="InvoicesPrint">
                      {toTable[0]?.customer?.jobs?.map((job, index) => (
                        <div id={`pdf-invoice-${job.job}`}>
                          <Grid
                            className="mainpdfview"
                            container
                            spacing={2}
                            style={{ padding: '10px' }}
                          >
                            <Grid
                              className="cstPdf"
                              item
                              xs={12}
                            >
                              <Grid
                                container
                                spacing={2}
                              >
                                {/* <Grid item xs={6}
                                sx={{
                                  margin: 'auto'
                                }}>
                                <img className="w-full-s"
                                  style={{ Width: "auto", height: "130px", }}
                                  src={logoCompany}
                                  alt="logo" />


                                <div>
                                  <h3>{compName}</h3>
                                  <h4>{job.comp_address},
                                    {job.comp_city},
                                    {job.comp_state},
                                    {job.comp_zip}
                                  </h4>
                                </div>
                              </Grid> */}
                                {/* <Grid item xs={6} sx={{
                                textAlign: 'end'
                              }}>
                                <Typography variant="h6" gutterBottom component="div" className="font-sm">
                                  Invoice # {index === 0 ? invoiceNumber : invoiceNumber + index}
                                </Typography>
                                <Typography variant="h6" gutterBottom component="div">
                                  Invoice Date: {moment(info.dateCreated).format('MM/DD/YYYY')}
                                </Typography>
                                <Typography variant="h6" gutterBottom component="div">
                                  Invoice Total: {
                                    formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait)
                                  }
                                </Typography>
                                <Typography variant="body1" >
                                  Terms: {terms}


                                </Typography>
                                <Typography variant="body1" >
                                  Due Date: {dueDates[index]}
                                </Typography>
                                <Typography variant="body1" >

                                </Typography>
                                <Typography variant="body1" >
                                  Hauling Total: {formatter.format(job.jobTotalHauling)}
                                </Typography>
                                <Typography variant="body1" >
                                  Material Total: {formatter.format(job.jobTotalMaterial)}
                                </Typography>
                                <Typography variant="body1" >
                                  Wait Charge: {formatter.format(job.jobTotalWait)}
                                </Typography>
                              </Grid> */}
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  sx={{ margin: 'auto' }}
                                >
                                  <img
                                    className="w-full-s"
                                    style={{ Width: 'auto', height: '95px' }}
                                    src={logoCompany}
                                    alt="logo"
                                  />
                                  <Typography variant="body1">{compName}</Typography>
                                  <Typography variant="body1">
                                    {job.comp_address}, {job.comp_city}, {job.comp_state},{' '}
                                    {job.comp_zip}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{ textAlign: 'end' }}
                                >
                                  <Typography
                                    variant="h2"
                                    gutterBottom
                                    component="div"
                                    className="font-bold m-0 mb-10 uppercase big-title"
                                    style={{ fontSize: '45px' }}
                                  >
                                    Invoice # {index === 0 ? invoiceNumber : invoiceNumber + index}
                                  </Typography>
                                  {/* <Typography variant="h6" gutterBottom component="div">Invoice Date: {moment(info.dateCreated).format('MM/DD/YYYY')}</Typography> */}
                                  <Typography
                                    variant="h5"
                                    gutterBottom
                                    component="div"
                                    className="flex justify-end items-end text-sm font-bold"
                                    style={{ fontSize: '16px' }}
                                  >
                                    Total:{' '}
                                    <Typography
                                      variant="h4"
                                      gutterBottom
                                      component="div"
                                      className="font-bold m-0 ml-10 sub-title"
                                      style={{ fontSize: '25px', lineHeight: '1' }}
                                    >
                                      {formatter.format(
                                        (job?.jobTotalHauling ? job.jobTotalHauling : 0) +
                                          (job?.jobTotalMaterial ? job?.jobTotalMaterial : 0) +
                                          (job?.jobTotalWait ? job?.jobTotalWait : 0)
                                      )}
                                    </Typography>
                                  </Typography>
                                  {/* <Typography variant="body1" >Terms: {terms}</Typography>
                                    <Typography variant="body1" >Due Date: {moment(info.dueDate).format('MM/DD/YYYY')}</Typography>
                                    <Typography variant="body1" ></Typography>
                                    <Typography variant="body1" >Hauling Total: {formatter.format(job.jobTotalHauling)}</Typography>
                                    <Typography variant="body1" >Material Total: {formatter.format(job.jobTotalMaterial)}</Typography>
                                    <Typography variant="body1" >Wait Charge: {formatter.format(job.jobTotalWait)}</Typography> */}
                                </Grid>
                              </Grid>

                              <br />
                              <Divider style={{ border: '1px solid #000' }}></Divider>
                              <br></br>
                              {/* <Grid container>
                              <Grid item xs={4} >
                                <Typography variant="h6" gutterBottom component="div">
                                  Customer:
                                </Typography>
                                <Typography variant="body1" >
                                  {toTable[0]?.customer?.customerName}
                                </Typography>
                                <Typography variant="body1" >
                                {toTable[0]?.customer?.locationId},{toTable[0]?.customer?.customerCity},{toTable[0]?.customer?.customerState},{toTable[0]?.customer?.customerZip}
                                </Typography>
                              </Grid>

                              <Grid item xs={4}
                                sx={{
                                  textAlign: 'center'
                                }}>
                                <Typography variant="h6" gutterBottom component="div">
                                  {`Project (Job Name):`}
                                </Typography>
                                <Typography variant="body1" >
                                  {job.jobDestination}
                                </Typography>
                                <Typography variant="body1" >
                                  Job No.: {job.job}
                                </Typography>
                              </Grid>

                              <Grid item xs={4}
                                sx={{
                                  textAlign: 'end'
                                }}>
                                <Typography variant="h6" gutterBottom component="div">
                                  {`Origin / Source:`}
                                </Typography>
                                <Typography variant="body1" >
                                  {job.jobQuarry}
                                </Typography>
                              </Grid>
                            </Grid> */}
                              <Grid
                                container
                                spacing={3}
                              >
                                <Grid
                                  item
                                  xs={4}
                                  sx={{ margin: 'auto' }}
                                >
                                  <Typography
                                    variant="body1"
                                    className="font-bold"
                                  >
                                    Bill to:
                                  </Typography>
                                  <Typography variant="body1">
                                    {toTable[0]?.customer?.customerName}
                                  </Typography>
                                  <Typography variant="body1">
                                    {toTable[0]?.customer?.locationId},
                                    {toTable[0]?.customer?.customerCity},
                                    {toTable[0]?.customer?.customerState},
                                    {toTable[0]?.customer?.customerZip}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{ margin: 'auto' }}
                                >
                                  <Typography variant="body1">
                                    <strong className="font-bold">Job No: </strong>
                                    {job.job}
                                  </Typography>
                                  <Typography variant="body1">
                                    <strong className="font-bold">Job Site: </strong>
                                    {job.jobDestination}
                                  </Typography>
                                  <Typography variant="body1">
                                    <strong className="font-bold">Origin: </strong>
                                    {job.jobQuarry}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{ textAlign: 'end' }}
                                >
                                  <Typography variant="body1">
                                    Date: {moment(info.dateCreated).format('MM/DD/YYYY')}
                                  </Typography>
                                  <Typography variant="body1">
                                    Terms: {Number(terms).toFixed(0)}
                                  </Typography>
                                  <Typography variant="body1">
                                    Due Date: {moment(info.dueDate).format('MM/DD/YYYY')}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <>
                                {job.batches.map((batch) => (
                                  <>
                                    <br></br>
                                    <Divider style={{ border: '1px solid #000' }}></Divider>
                                    <br />

                                    {/* <Grid container>
                                      <Grid item xs={4} >
                                        <Typography variant="body1" >
                                          Batch No.: {batch.batch}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={2} >
                                        <Typography variant="body1" >
                                          Date: {moment(batch.batchDateTime).format('MM/DD/YYYY')}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={6}>
                                        <Typography variant="body1" >
                                          Material: {batch.material}
                                        </Typography>
                                      </Grid>
                                    </Grid> */}

                                    <Grid
                                      container
                                      className="topbatchno"
                                    >
                                      <Grid
                                        item
                                        xs={4}
                                      >
                                        <Typography variant="body1">
                                          Batch No.: {batch.batch}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={4}
                                      >
                                        <Typography
                                          variant="body1"
                                          style={{ textAlign: 'center' }}
                                        >
                                          Date:{batch.batchDateTimeReal}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={4}
                                      >
                                        <Typography
                                          variant="body1"
                                          style={{ textAlign: 'right' }}
                                        >
                                          Material: {batch.material}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    {/* <br></br>
                                    <Divider></Divider> */}
                                    <br></br>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        className="newinvoice-ui"
                                      >
                                        <Paper
                                          sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            boxShadow: 'none',
                                          }}
                                        >
                                          <Table
                                            style={{
                                              width: '100%',
                                              tableLayout: 'auto',
                                              border: '2px solid #000',
                                            }}
                                          >
                                            <TableBody>
                                              {['Hauling', 'Material', 'Wait Charge'].map(
                                                (type, typeIndex) => (
                                                  <>
                                                    <></>
                                                    {batch.info.map((ticket, i) => (
                                                      <>
                                                        {batch.info.length + 1 === i + 1 ? (
                                                          <></>
                                                        ) : (
                                                          <>
                                                            {typeIndex === 0 ? (
                                                              <>
                                                                {i === 0 &&
                                                                  batch.info.some(
                                                                    (x) => x.ticketCR > 0
                                                                  ) && (
                                                                    <>
                                                                      <TableRow key={typeIndex}>
                                                                        <TableCell
                                                                          align="left"
                                                                          width="100%"
                                                                          colSpan={9}
                                                                          style={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '25px',
                                                                            padding: '10px 20px',
                                                                            border:
                                                                              '2px solid #000',
                                                                          }}
                                                                        >
                                                                          {type}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                      <TableHead
                                                                        width="100%"
                                                                        style={{
                                                                          display: 'contents',
                                                                        }}
                                                                      >
                                                                        <TableRow>
                                                                          <TableCell
                                                                            align="center"
                                                                            width="11.11%"
                                                                          >
                                                                            Ticket No.
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="center"
                                                                            width="11.11%"
                                                                          >
                                                                            Scale No.
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="center"
                                                                            width="11.11%"
                                                                          >
                                                                            Truck
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="center"
                                                                            width="11.11%"
                                                                          >
                                                                            Rate
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="center"
                                                                            width="11.11%"
                                                                          >
                                                                            Qty
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="center"
                                                                            width="11.11%"
                                                                          >
                                                                            Unit
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="center"
                                                                            width="11.11%"
                                                                          >
                                                                            TAX
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="center"
                                                                            width="11.11%"
                                                                          >
                                                                            Total
                                                                          </TableCell>
                                                                          {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                                        </TableRow>
                                                                      </TableHead>
                                                                    </>
                                                                  )}
                                                                {ticket.ticketCR &&
                                                                ticket.ticketCR > 0 ? (
                                                                  <TableRow key={ticket.ticket}>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.ticket}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.scale}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.truck}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {job.jobUnitRate}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.qty}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {typeIndex != 2
                                                                        ? batch.unit
                                                                        : 'H'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {'N'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {formatter.format(
                                                                        ticket.ticketCR
                                                                      )}
                                                                    </TableCell>
                                                                    {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                                  </TableRow>
                                                                ) : null}
                                                              </>
                                                            ) : typeIndex === 1 ? (
                                                              <>
                                                                {i === 0 &&
                                                                  batch.info.some(
                                                                    (x) => x.materialCR > 0
                                                                  ) && (
                                                                    <TableHead
                                                                      width="100%"
                                                                      style={{
                                                                        display: 'contents',
                                                                      }}
                                                                    >
                                                                      <TableRow key={typeIndex}>
                                                                        <TableCell
                                                                          align="left"
                                                                          width="100%"
                                                                          colSpan={9}
                                                                          style={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '25px',
                                                                            padding: '10px 20px',
                                                                            border:
                                                                              '2px solid #000',
                                                                          }}
                                                                        >
                                                                          {type}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                      <TableRow>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Ticket No.
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Scale No.
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Truck
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Rate
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Qty
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Unit
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          TAX
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Total
                                                                        </TableCell>
                                                                        {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                                      </TableRow>
                                                                    </TableHead>
                                                                  )}
                                                                {ticket.materialCR &&
                                                                ticket.materialCR > 0 ? (
                                                                  <TableRow key={ticket.ticket}>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.ticket}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.scale}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.truck}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {job.jobMaterialRate}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.qty}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {typeIndex != 2
                                                                        ? batch.unit
                                                                        : 'H'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {job.jobTaxable ? 'T' : 'N'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {formatter.format(
                                                                        ticket.materialCR
                                                                      )}
                                                                    </TableCell>
                                                                    {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                                  </TableRow>
                                                                ) : null}
                                                              </>
                                                            ) : typeIndex === 2 ? (
                                                              <>
                                                                {i === 0 &&
                                                                  batch.info.some(
                                                                    (x) => x.waitCR > 0
                                                                  ) && (
                                                                    <TableHead
                                                                      width="100%"
                                                                      style={{
                                                                        display: 'contents',
                                                                      }}
                                                                    >
                                                                      <TableRow key={typeIndex}>
                                                                        <TableCell
                                                                          align="left"
                                                                          width="100%"
                                                                          colSpan={9}
                                                                          style={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '25px',
                                                                            padding: '10px 20px',
                                                                            border:
                                                                              '2px solid #000',
                                                                          }}
                                                                        >
                                                                          {type}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                      <TableRow>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Ticket No.
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Scale No.
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Truck
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Rate
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Qty
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Unit
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          TAX
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="center"
                                                                          width="11.11%"
                                                                        >
                                                                          Total
                                                                        </TableCell>
                                                                        {/* <TableCell align="center" width="11.11%">Type</TableCell> */}
                                                                      </TableRow>
                                                                    </TableHead>
                                                                  )}
                                                                {ticket.waitCR &&
                                                                ticket.waitCR > 0 ? (
                                                                  <TableRow key={ticket.ticket}>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.ticket}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.scale}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {ticket.truck}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {job.waitrate}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {Number(
                                                                        ticket.waitTime
                                                                      ).toFixed(2)}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {'H'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {'N'}
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="center"
                                                                      width="11.11%"
                                                                    >
                                                                      {formatter.format(
                                                                        ticket.waitCR
                                                                      )}
                                                                    </TableCell>
                                                                    {/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
                                                                  </TableRow>
                                                                ) : null}
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    ))}
                                                  </>
                                                )
                                              )}

                                              {/* <TableRow>
                                                <TableCell>Sub Totals</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="center">{`${Number(batch.qtyTotal).toFixed(2)}`}</TableCell>

                                                <TableCell></TableCell>
                                                <TableCell></TableCell>

                                                <TableCell align="center">{`${formatter.format(batch.totalMaterialCost + batch.totalTicketCost + batch.totalWaitTime)}`}</TableCell>

                                                <TableCell></TableCell>
                                              </TableRow> */}
                                            </TableBody>
                                          </Table>
                                        </Paper>
                                      </Grid>
                                    </Grid>
                                  </>
                                ))}
                              </>
                              {/* <br></br>
                            <Divider></Divider> */}
                              <br></br>
                              <Grid
                                container
                                spacing={2}
                                className="new-invoice-bottmtable"
                              >
                                <Grid
                                  item
                                  xs={7}
                                  className="new-invoice-bottmleft"
                                >
                                  <Typography variant="body1">Notes to customer:</Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ wordBreak: 'break-all' }}
                                  >
                                    {' '}
                                    {notes}{' '}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                  className="new-invoice-bottmright"
                                >
                                  <Table>
                                    <TableRow>
                                      <TableCell align="left">Total QTY</TableCell>
                                      <TableCell align="left">{`${Number(
                                        job.jobTotalTickets
                                      ).toFixed(2)}`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="left">Total Wait Time</TableCell>
                                      <TableCell align="left">
                                        {`${Number(job.jobTotalWait ? job.jobTotalWait : 0).toFixed(
                                          2
                                        )}`}
                                        {/* {`${formatter.format(
                                       job.jobTotalWait
                                      )}`} */}
                                        H
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="left">Sub Total Invoice</TableCell>
                                      <TableCell align="left">{`${formatter.format(
                                        (job.jobTotalHauling ? job.jobTotalHauling : 0) +
                                          ((job.jobTotalMaterial ? job.jobTotalMaterial : 0) -
                                            (job.jobTotalTaxesMaterial
                                              ? job.jobTotalTaxesMaterial
                                              : 0)) +
                                          (job.jobTotalWait ? job.jobTotalWait : 0)
                                      )}`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="left">
                                        Tax{' '}
                                        {job.jobTaxable
                                          ? job.jobTax > 0 || job.jobTax
                                            ? job.jobTax
                                            : `8.25%`
                                          : 0}
                                        % Material
                                      </TableCell>
                                      <TableCell align="left">{`${formatter.format(
                                        job.jobTotalTaxesMaterial ? job.jobTotalTaxesMaterial : 0
                                      )}`}</TableCell>
                                    </TableRow>
                                    {job && job.discount > 0 ? (
                                      <>
                                        <TableRow>
                                          <TableCell
                                            align="left"
                                            style={{ fontWeight: 'bold' }}
                                          >
                                            Discount Total
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{ fontWeight: 'bold' }}
                                          >{` (${formatter.format(
                                            job.discount ? job.discount : 0
                                          )} )`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            align="left"
                                            style={{ fontWeight: 'bold' }}
                                          >
                                            {' '}
                                            Invoice Balance
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{ fontWeight: 'bold' }}
                                          >{`${formatter.format(
                                            (job.jobTotalHauling ? job.jobTotalHauling : 0) +
                                              (job.jobTotalMaterial ? job.jobTotalMaterial : 0) +
                                              (job.jobTotalWait ? job.jobTotalWait : 0) -
                                              (job.discount ? job.discount : 0)
                                          )}`}</TableCell>
                                        </TableRow>
                                      </>
                                    ) : (
                                      <TableRow>
                                        <TableCell
                                          align="left"
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          Total Invoice
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{ fontWeight: 'bold' }}
                                        >{`${formatter.format(
                                          (job.jobTotalHauling ? job.jobTotalHauling : 0) +
                                            (job.jobTotalMaterial ? job.jobTotalMaterial : 0) +
                                            (job.jobTotalWait ? job.jobTotalWait : 0)
                                        )}`}</TableCell>
                                      </TableRow>
                                    )}
                                  </Table>
                                </Grid>
                              </Grid>
                              {/* <Grid container>
                              <Grid item xs={12}>
                                <Paper sx={{ width: '100%' }}>
                                  <Table style={{ width: "auto", tableLayout: "auto" }}>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>

                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%">Total QTY</TableCell>

                                        <TableCell align="center" width="22.22%">{`${Number(job.jobTotalTickets).toFixed(2)}`}</TableCell>

                                        <TableCell align='center' width="0%"></TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>

                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%">Total Wait Time</TableCell>

                                        <TableCell align="center" width="22.22%">{`${Number(job.jobTotalWaitTime).toFixed(2)}`} H</TableCell>

                                        <TableCell align='center' width="0%"></TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell align="center" width="11.11%"></TableCell>
                                        <TableCell align="center" width="11.11%"></TableCell>
                                        <TableCell align="center" width="11.11%"></TableCell>
                                        <TableCell align="center" width="11.11%"></TableCell>
                                        <TableCell align="center" width="11.11%"></TableCell>

                                        <TableCell align="center" width="0%"></TableCell>
                                        <TableCell align="center" width="22.22%">Sub Total Invoice</TableCell>

                                        <TableCell align="center" width="22.22%">{`${formatter.format(
                                          job.jobTotalHauling + (job.jobTotalMaterial - job.jobTotalTaxesMaterial) + job.jobTotalWait
                                        )}`}</TableCell>

                                        <TableCell align="center" width="0%"></TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>

                                        <TableCell align='center' width="0%"></TableCell>
                                        <TableCell align='center' width="22.22%">Tax {job.jobTaxable ? job.jobTax > 0 || job.jobTax ? job.jobTax : `8.25%` : 0}% Material</TableCell>

                                        <TableCell align="center" width="22.22%">{`${formatter.format(
                                          job.jobTotalTaxesMaterial
                                        )}`}</TableCell>

                                        <TableCell align='center' width="0%"></TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%"></TableCell>

                                        <TableCell align='center' width="11.11%"></TableCell>
                                        <TableCell align='center' width="11.11%">Total Invoice</TableCell>

                                        <TableCell align="center" width="22.22%">{`${formatter.format(
                                          job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait
                                        )}`}</TableCell>

                                        <TableCell align='center' width="0%"></TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Paper>

                              </Grid>
                            </Grid> */}
                              {/* <br></br>
                            <Divider></Divider>
                            <Divider></Divider>
                            <br></br> */}
                              {/* <Grid container>
                              <Grid item xs={12}>
                                <Paper sx={{
                                  width: '100%',
                                  padding: '1rem'
                                }}>
                                  <Typography variant="h6">
                                    Notes to customer:
                                  </Typography>

                                  <Typography variant="body1" sx={{
                                    wordBreak: 'break-all',
                                  }}>
                                    {notes}
                                  </Typography>
                                </Paper>
                              </Grid>
                            </Grid> */}
                              {/* <br></br>
                            <Divider></Divider>
                            <Divider></Divider>
                            <br></br> */}

                              {/* <Grid container>
                              <Grid item xs={12} sx={{
                                textAlign: '-webkit-right'
                              }}>
                                <Typography variant="h6">
                                  Powered by
                                </Typography>
                                <img
                                  src='assets/images/logos/loadzlogo.png'
                                  alt="logo"
                                  style={{
                                    width: '20%',
                                  }} />
                              </Grid>
                            </Grid> */}

                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    textAlign: '-webkit-right',
                                  }}
                                  className="flex justify-end items-end"
                                >
                                  <Typography
                                    variant="h6"
                                    style={{ fontSize: '14px' }}
                                  >
                                    Powered by
                                    {/* TODO Configurable Text. Invoice Powered by Text */}
                                  </Typography>
                                  <img
                                    src="assets/images/logos/loadzlogo.png" //TODO Configurable Variable.
                                    alt="logo"
                                    style={{
                                      width: '140px',
                                      marginLeft: '10px',
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              {/* <br></br>
                                  <br></br>
                                  <Divider style={{border: '1px solid #ccc',}}></Divider>
                                  <br></br> */}
                            </Grid>
                          </Grid>
                          {/* <div className='break-before-all'></div>
                        <div className='break-after-all'></div>
                        <br></br>
                        <br></br> */}
                        </div>
                      ))}
                    </Paper>
                  </Card>
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              {true && (
                <Button
                  autoFocus
                  onClick={() => sendData()}
                >
                  Generate
                </Button>
              )}
              <Button
                autoFocus
                onClick={() => handleOpen(open)}
              >
                Cancel
              </Button>
            </DialogActions>
            {openEmail ? (
              <ModelInvoiceEmail
                open={openEmail}
                emailhandleOpen={emailhandleOpen}
                compName={compName}
                toTable={toTable}
                terms={terms}
                invoiceNo={invoiceNumber}
                infoData={infoData}
                discounts={discounts}
              />
            ) : null}
          </>
        )}
      </BootstrapDialog>
    </div>
  );
}

export default ModalGenerateInvoiceEdit;
