import { Page, Text, Document, StyleSheet, View, Image } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  page: {
    padding: '5px',
  },
  pageRed: {
    padding: '5px',
  },
  main: {
    width: '33.33%',
    float: 'left',
    backgroundColor: '#fff',
    padding: '2px',
    border: '1px solid #ccc',
  },
  mainRed: {
    width: '33.33%',
    float: 'left',
    backgroundColor: '#000',
    padding: '2px',
    paddingBottom: '0px',
  },
  header: {
    textAlign: 'left',
    border: '1px solid #EDCA33',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  topheading: {
    fontWeight: 'bold',
    fontSize: '5px',

    textAlign: 'center',
    width: '100%',
    color: '#000',
  },
  subheader: {
    textAlign: 'center',
    backgroundColor: '#EDCA33',
    padding: '3px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '3',
    color: '#fff',
  },
  subheaderRed: {
    textAlign: 'center',
    backgroundColor: 'red',
    padding: '3px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '3px',
    color: '#fff',
  },
  th: {
    fontWeight: 'bold',
    padding: '2px 0px',
    fontSize: '5px',
  },
  td: {
    fontWeight: 'normal',
    padding: '2px 0px',
    fontSize: '5px',
  },
  addres: {
    padding: '2px 0',
    textAlign: 'center',
  },
  thborder: {
    fontWeight: 'bold',
    padding: '2px 0px',
    fontSize: '5px',
    flex: '1',
  },
  tdborder: {
    fontWeight: 'normal',
    padding: '3px 0px',
    borderBottom: '1px solid #ccc',
    fontSize: '5px',
  },
  bgtext: {
    fontWeight: 'bold',
    fontSize: '5px',
    textAlign: 'center',
  },
  table: {
    textAlign: 'left',
    border: '1px solid #EDCA33',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: '#0000011c 0px 6px 10px',
    backgroundColor: 'white',
  },
  tableb: {
    marginTop: '3px',
  },
  tabletr: {
    flex: '1',
  },
  tabletrb: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: '#0000011c 0px 6px 10px',
  },
  thborderb: {
    textAlign: 'center',
    backgroundColor: '#EDCA33',
    padding: '2px 5px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: '1',
    fontSize: '5px',
    color: '#000',
  },
  thborderbRed: {
    textAlign: 'center',
    backgroundColor: 'red',
    padding: '2px 5px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: '1',
    fontSize: '5px',
    color: '#fff',
  },
  tdb: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: '1',
    fontSize: '5px',
    width: '100%',
    justifyContent: 'center',
  },

  tdbvalue: {
    border: '1px solid #111111',
    backgroundColor: '#f4f4f4',
    color: '#000',
    borderRadius: '2px',
    padding: '3px 5px',
  },
  tabletrbb: {
    padding: '2px 0',
    border: '1px solid #EDCA33',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: '#0000011c 0px 6px 10px',
    backgroundColor: 'white',
  },
  logoimg: {
    border: '1px solid #EDCA33',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: '#0000011c 0px 6px 10px',
    height: '50px',
    marginTop: '3px',
    backgroundColor: 'white',
  },
  EndWait: {
    color: 'white',
  },
  adr: {
    color: 'black',
    fontSize: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '3px',
  },
  adrRed: {
    color: 'white',
    fontSize: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '3px',
  },
  mainendwaitTime: {
    display: 'flex',
    padding: '2px 0',
    paddingBottom: '0px',
    marginTop: '0px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  endwaitTime: {
    display: 'flex',
    padding: '2px 0px',
    marginTop: '3px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    backgroundColor: 'red',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
  },
  EndWait: {
    color: '#fff',
    fontSize: '8px',
  },
  endwaitTimeline: {
    width: '100%',
    height: '2px',
    borderRadius: '2px',
    backgroundColor: '#fff',
    margin: '2px 0',
  },
  topheadingREd: {
    fontWeight: 'bold',
    fontSize: '5px',
    textAlign: 'center',
    width: '100%',
    color: '#fff',
  },
  logo: {
    textAlign: 'center',
  },
  thred: {
    fontWeight: 'bold',
    padding: '2px 0px',
    fontSize: '5px',
    color: 'red',
  },
  thborderred: {
    fontWeight: 'bold',
    padding: '2px 0px',

    fontSize: '5px',
    color: 'blue',
    flex: '1',
  },
  brdstyle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'start',
    borderBottom: '1px solid #ccc',
  },
});
const token = localStorage.token;
export const TestTicketPdf = (props) => {
  console.log('propspropsooo => ', props);
  // const [popUpSignaure,setPopUpSignaure]= useState(null)

  // const getS3Signature = async (keyData) => {
  //   if(keyData && keyData){
  //     let data = {
  //       key: keyData,
  //     };
  //     return await getDataFromS3(data);
  //   }
  // }

  // useEffect(async()=>{
  //   const tk_imges = await getS3Signature(props.data.ticket_image)
  //   if(tk_imges && tk_imges.data && tk_imges.data.data){
  //     setPopUpSignaure(tk_imges.data.data.fileLocation)
  //   }
  // },[props])
  // blobUrl
  return (
    <>
      {props && props.data && props.data != null ? (
        <Document>
          <Page
            wrap
            size="A4"
            style={props && props.isWaitEndTimeActive ? styles.pageRed : styles.page}
          >
            <Text
              style={{
                fontSize: '12px',
                width: '98%',
                textAlign: 'right',
                paddingTop: '5px',
                color: '#949398',
              }}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />

            <View style={props && props.isWaitEndTimeActive ? styles.mainRed : styles.main}>
              <View style={styles.header}>
                <View style={styles.tabletr}>
                  <View style={styles.logo}>
                    <Image
                      src={props.logoImage}
                      crossorigin="anonymous"
                      style={{ width: '40px' }}
                    />
                  </View>
                </View>
                <View style={[styles.tabletr, styles.th]}>
                  <Text style={styles.th}>Ticket Date: </Text>
                  <Text style={styles.th}>Truck #: </Text>
                  <Text style={styles.th}>Job: </Text>
                  <Text style={styles.thred}>Delivery Ticket #: </Text>
                </View>
                <View style={styles.tabletr}>
                  <Text style={styles.th}>
                    {''}
                    {moment(props.data.date_updated.slice(0, -1)).format('MMM, DD YYYY')}
                  </Text>
                  <Text style={styles.th}>{props.data.t_no_truck}</Text>
                  <Text style={styles.th}>{props.data.job_no}</Text>
                  <Text style={styles.thred}>{props.data.ticket_no}</Text>
                </View>
              </View>
              <View style={styles.addres}>
                <Text style={props && props.isWaitEndTimeActive ? styles.adrRed : styles.adr}>
                  {props.isWaitEndTimeActive ? (
                    <Image
                      src="../assets/images/icons/pinWhite.png"
                      style={{ width: '5px', height: '4px', position: 'relative', top: '5px' }}
                    />
                  ) : (
                    <Image
                      src="../assets/images/icons/pin.png"
                      style={{ width: '5px', height: '4px', position: 'relative', top: '5px' }}
                    />
                  )}
                  {props.data.compaddress},{props.data.compcity},{props.data.compstate},
                  {props.data.compzip}
                </Text>
              </View>
              <View
                style={props && props.isWaitEndTimeActive ? styles.subheaderRed : styles.subheader}
              >
                <View style={styles.bgtext}>
                  <Text
                    style={
                      props && props.isWaitEndTimeActive ? styles.topheadingREd : styles.topheading
                    }
                  >
                    Delivery Ticket
                  </Text>
                </View>
              </View>
              <View style={styles.table}>
                {/* <View style={styles.tabletr}></View> */}
                <View style={styles.tabletr}>
                  <View style={styles.brdstyle}>
                    <Text style={styles.thborder}>Customer: </Text>
                    <Text style={styles.thborder}>{props.data.customer_name}</Text>
                  </View>
                  <View style={styles.brdstyle}>
                    <Text style={styles.thborder}>Quarry Name: </Text>
                    <Text style={styles.thborderred}>
                      <Image
                        src="../assets/images/icons/pin.png"
                        style={{
                          width: '5px',
                          height: '4px',
                          marginTop: '12px',
                          position: 'relative',
                          top: '5px',
                        }}
                      />
                      <Text>
                        {props.newLoc && props.newLoc.jobQryPit
                          ? props.newLoc.jobQryPit.l_name
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View style={styles.brdstyle}>
                    <Text style={styles.thborder}>Destination Name: </Text>
                    <Text style={styles.thborderred}>
                      <Image
                        src="../assets/images/icons/pin.png"
                        style={{
                          width: '5px',
                          height: '4px',
                          marginTop: '12px',
                          position: 'relative',
                          top: '5px',
                        }}
                      />
                      <Text>
                        {props && props.newLoc && props.newLoc.jobDesination
                          ? props.newLoc.jobDesination.l_name
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View style={styles.brdstyle}>
                    <Text style={styles.thborder}>Driver Name: </Text>
                    <Text style={styles.thborder}>
                      <Text>
                        {props.data.user_fname} {props.data.user_lname}
                      </Text>
                    </Text>
                  </View>
                  <View style={styles.brdstyle}>
                    <Text style={styles.thborder}>Ticket Date: </Text>
                    <Text style={styles.thborder}>
                      <Text>
                        {moment(props.data.date_updated.slice(0, -1)).format('MMM, DD YYYY')}
                      </Text>
                    </Text>
                  </View>
                  {props.isWaitEndTimeActive ? null : (
                    <>
                      {props.data.commissions == true ? (
                        <>
                          {props?.data?.duration_time ? (
                            <View style={styles.brdstyle}>
                              <Text style={styles.thborder}>Ticket Duration: </Text>
                              <Text style={styles.thborder}>
                                <Text style={styles.dateTime}>
                                  <div className="w-1/2 text-left p-0">
                                    <Text style={styles.dateTime}> {props.data.duration_time}</Text>
                                  </div>
                                </Text>
                              </Text>
                            </View>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {props?.data?.tk_start ? (
                            <View style={styles.brdstyle}>
                              <Text style={styles.thborder}>Ticket Start: </Text>
                              <Text style={styles.thborder}>
                                <Text style={styles.dateTime}>
                                  <Text style={styles.dateTime}>{props.data.tk_start}</Text>
                                </Text>
                              </Text>
                            </View>
                          ) : null}

                          {props?.data?.tk_end ? (
                            <View style={styles.brdstyle}>
                              <Text style={styles.thborder}>Ticket End: </Text>
                              <Text style={styles.thborder}>
                                <Text style={styles.dateTime}>
                                  <Text style={styles.dateTime}>{props.data.tk_end}</Text>
                                </Text>
                              </Text>
                            </View>
                          ) : null}
                          {props?.data?.tk_start_end_duration ? (
                            <View style={styles.brdstyle}>
                              <Text style={styles.thborder}>Total Ticket Duration: </Text>
                              <Text style={styles.thborder}>
                                <Text style={styles.dateTime}>
                                  <Text style={styles.dateTime}>
                                    {props.data.tk_start_end_duration}
                                  </Text>
                                </Text>
                              </Text>
                            </View>
                          ) : null}
                        </>
                      )}
                    </>
                  )}

                  <View style={styles.brdstyle}>
                    <Text style={styles.thborder}>Status: </Text>
                    <Text style={styles.thborder}>
                      {(() => {
                        if (props.data.tk_status == 0) {
                          return <span>IN-progress</span>;
                        } else if (props.data.tk_status == 1) {
                          return <span>Completed</span>;
                        } else if (props.data.tk_status == 2) {
                          return <span>Delayed</span>;
                        } else {
                          return <span>N/A</span>;
                        }
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableb}>
                <View style={styles.tabletrb}>
                  <Text
                    style={
                      props && props.isWaitEndTimeActive ? styles.thborderbRed : styles.thborderb
                    }
                  >
                    {' '}
                    QTY{' '}
                  </Text>
                  <Text
                    style={
                      props && props.isWaitEndTimeActive ? styles.thborderbRed : styles.thborderb
                    }
                  >
                    {' '}
                    UNITS{' '}
                  </Text>
                  {props && props.data.scale === true ? (
                    <Text
                      style={
                        props && props.isWaitEndTimeActive ? styles.thborderbRed : styles.thborderb
                      }
                    >
                      {' '}
                      Scale No.{' '}
                    </Text>
                  ) : (
                    ''
                  )}
                  <Text
                    style={
                      props && props.isWaitEndTimeActive ? styles.thborderbRed : styles.thborderb
                    }
                  >
                    {' '}
                    MATERIAL{' '}
                  </Text>
                </View>
                <View style={styles.tabletrbb}>
                  <View style={styles.tdb}>
                    <Text style={styles.tdbvalue}> {props.data.tk_qty} </Text>
                  </View>
                  <View style={styles.tdb}>
                    <Text>{props.data.unit_name}</Text>
                  </View>
                  <View style={styles.tdb}>
                    {props && props.data.scale === true ? (
                      <Text>{props.data.tk_scale_no}</Text>
                    ) : (
                      ''
                    )}
                  </View>
                  <View style={styles.tdb}>
                    <Text>{props.data.name}</Text>
                  </View>
                </View>
              </View>

              {props.data.blobUrl ? (
                <View style={styles.logoimg}>
                  <Image
                    style={{ width: 'auto', height: '40px', maxWidth: '100%' }}
                    source={props.data.blobUrl}
                    crossorigin="anonymous"
                  />
                </View>
              ) : null}

              {props.data.blobUrl ? (
                <View
                  style={
                    props && props.isWaitEndTimeActive ? styles.subheaderRed : styles.subheader
                  }
                >
                  <View style={styles.bgtext}>
                    <Text
                      style={
                        props && props.isWaitEndTimeActive
                          ? styles.topheadingREd
                          : styles.topheading
                      }
                    >
                      Received By {props.data.tk_receivedby}
                    </Text>
                  </View>
                </View>
              ) : null}
              <View>
                {props.isWaitEndTimeActive ? (
                  <>
                    <View style={styles.mainendwaitTime}>
                      <View style={styles.endwaitTime}>
                        <Text style={styles.EndWait}>End Wait Time</Text>
                        <View style={styles.endwaitTimeline}></View>
                      </View>
                    </View>
                  </>
                ) : (
                  ''
                )}
              </View>
            </View>
          </Page>
        </Document>
      ) : (
        <Document>
          <Text>No Record Found!</Text>
        </Document>
      )}
    </>
  );
};

export default TestTicketPdf;
