import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';

export default function ModalAfterPay({ open, handleClose, balanceReciptdata, formatter, isCustomer, prop_data }) {
  return (
    <div>
      <Dialog
        maxWidth={'lg'}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title" className='font-bold text-center'>
              {"Receipt's Coming Soon...."}
            </DialogTitle> */}
        <DialogContent style={{ padding: '65px 100px' }}>
          {/* <img src='assets/images/b-recipt.png' style={{margin: "0 auto", width:"500px"}} /> */}
          <table
            className=" text-left table-fixed"
            width="700"
            border="0"
            align="center"
            cellPadding="0"
            cellspacing="0"
            style={{ border: '1px solid #000000', margin: '0px auto' }}
          >
            <thead>
              <tr align="center">
                <td
                  className=""
                  style={{ padding: '15px 0px', background: '#000' }}
                >
                  <img
                    src="assets/images/logos/yellow_loadz_logo.svg"
                    style={{ width: '130px', margin: '0 auto' }}
                  />
                </td>
              </tr>
            </thead>
            <tbody style={{ padding: '30px' }}>
              <tr align="center">
                <td
                  style={{
                    padding: '20px 0px 20px',
                    color: '#000',
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '16px',
                  }}
                >
                  <h4 style={{ fontSize: '14px', fontWeight: '600', margin: '0' }}>Total Payment Amount</h4>
                  <h2 style={{ fontSize: '36px', fontWeight: '700', margin: '4px 0 4px -40px' }}>
                    <img
                      src="assets/images/icons/tick.svg"
                      style={{ maxWidth: '36px', marginRight: '4px', display: 'inline-block' }}
                    />
                    {`${formatter.format(
                      balanceReciptdata && balanceReciptdata.data && balanceReciptdata.data.amountReceivedToApply
                        ? balanceReciptdata.data.amountReceivedToApply
                        : ''
                    )}`}
                  </h2>
                  {isCustomer ? (
                    <h3 style={{ fontSize: '14px', fontWeight: '700', margin: '0' }}>{` To ${
                      balanceReciptdata &&
                      balanceReciptdata.otherDetail &&
                      balanceReciptdata.otherDetail.compDetails &&
                      balanceReciptdata.otherDetail.compDetails.compName
                    }`}</h3>
                  ) : (
                    <h3 style={{ fontSize: '14px', fontWeight: '700', margin: '0' }}>{` To ${
                      balanceReciptdata &&
                      balanceReciptdata.data &&
                      balanceReciptdata.data.dataClientReceipt &&
                      balanceReciptdata.data.dataClientReceipt.clientName
                    }`}</h3>
                  )}
                </td>
              </tr>
              {balanceReciptdata &&
              balanceReciptdata.data &&
              balanceReciptdata.data.dataClientReceipt &&
              balanceReciptdata.data.dataClientReceipt.payments.filter((user) => user.select === true).length !== 1 ? (
                <tr>
                  <td
                    align="center"
                    style={{ borderTop: '1px solid #D4D2D2', padding: '10px 0' }}
                  >
                    <table
                      width="445"
                      border="0"
                      style={{ borderSpacing: '0', marginLeft: '0' }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontSize: '16px',
                              fontWeight: '700',
                              textAlign: 'left',
                              padding: '0 0 4px',
                            }}
                          >
                            Payment Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="">
                              <div className="flex justify-between">
                                <div
                                  className="w-3/5"
                                  style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                                >
                                  Invoice No.
                                </div>
                                <div
                                  className="w-2/5"
                                  style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                                >
                                  Payment Applied
                                </div>
                              </div>

                              {balanceReciptdata &&
                                balanceReciptdata.data &&
                                balanceReciptdata.data.dataClientReceipt &&
                                balanceReciptdata.data.dataClientReceipt.payments.length > 0 &&
                                balanceReciptdata.data.dataClientReceipt.payments.map((element, index) => {
                                  element && element.payment && element.payment.length
                                    ? (element.payment = element.payment.toString())
                                    : (element.payment = '');
                                  return (
                                    <>
                                      {element.select === true ? (
                                        <div className="flex justify-between">
                                          <div
                                            className="w-3/5"
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              textAlign: 'left',
                                            }}
                                          >
                                            {element.invoiceNo}
                                          </div>
                                          {element && element.payment.length && element.payment.startsWith('$') ? (
                                            <div
                                              className="w-2/5"
                                              style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                textAlign: 'left',
                                              }}
                                            >
                                              ${element.payment.split('$')[1].replace(/^0+/, '')}
                                            </div>
                                          ) : (
                                            <div
                                              className="w-2/5"
                                              style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                textAlign: 'left',
                                              }}
                                            >
                                              ${element.payment.replace(/^0+/, '')}
                                            </div>
                                          )}
                                        </div>
                                      ) : null}
                                    </>
                                  );
                                })}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flex justify-between">
                              <div
                                className="w-3/5"
                                style={{ fontSize: '16px', fontWeight: '400', textAlign: 'left' }}
                              >
                                Sub Total
                              </div>
                              <div
                                className="w-2/5"
                                style={{ fontSize: '16px', fontWeight: '400', textAlign: 'left' }}
                              >{`${
                                balanceReciptdata && balanceReciptdata.data && balanceReciptdata.data.amountReceivedToApply
                                  ? formatter.format(balanceReciptdata.data.amountReceivedToApply)
                                  : ''
                              }`}</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flex justify-between">
                              <div
                                className="w-3/5"
                                style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                              >
                                Total Paid:
                              </div>
                              <div
                                className="w-2/5"
                                style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                              >{`${
                                balanceReciptdata && balanceReciptdata.data && balanceReciptdata.data.amountReceivedToApply
                                  ? formatter.format(balanceReciptdata.data.amountReceivedToApply)
                                  : ''
                              }`}</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td
                    align="center"
                    style={{ borderTop: '1px solid #D4D2D2', padding: '10px 0' }}
                  >
                    <table
                      width="500"
                      border="0"
                      style={{ borderSpacing: '0', margin: '0 auto' }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontSize: '16px',
                              fontWeight: '700',
                              textAlign: 'left',
                              padding: '0 0 4px',
                            }}
                          >
                            Payment Details
                          </th>
                        </tr>
                      </thead>
                      {balanceReciptdata &&
                        balanceReciptdata.data &&
                        balanceReciptdata.data.dataClientReceipt &&
                        balanceReciptdata.data.dataClientReceipt.payments.length > 0 &&
                        balanceReciptdata.data.dataClientReceipt.payments.map((element, index) => {
                          return (
                            <>
                              {element.select === true ? (
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        padding: '0 5px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      Job No. :
                                    </td>
                                    <td
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        padding: '0 5px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {element.jobNo}{' '}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        padding: '0 5px',
                                        textAlign: 'left',
                                        width: '271px',
                                      }}
                                    >
                                      Invoice No.:
                                    </td>
                                    <td
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        padding: '0 5px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {element.invoiceNo}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        padding: '0 5px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      Total Amount:
                                    </td>
                                    <td
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        padding: '0 5px',
                                        textAlign: 'left',
                                      }}
                                    >{`${formatter.format(
                                      balanceReciptdata && balanceReciptdata.data && balanceReciptdata.data.amountReceivedToApply
                                        ? balanceReciptdata.data.amountReceivedToApply
                                        : ''
                                    )}`}</td>
                                  </tr>
                                </tbody>
                              ) : null}
                            </>
                          );
                        })}
                    </table>
                  </td>
                </tr>
              )}
              <tr>
                <td
                  align="center"
                  style={{
                    borderTop: '1px solid #D4D2D2',
                    borderBottom: '1px solid #D4D2D2',
                    padding: '10px 0',
                  }}
                >
                  <table
                    width="500"
                    border="0"
                    style={{ borderSpacing: '0', margin: '0 auto' }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                            textAlign: 'left',
                            padding: '0 0 4px',
                          }}
                        >
                          Payment Status:
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          Status:
                        </td>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          Paid
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          Payment Method:
                        </td>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          {balanceReciptdata &&
                            balanceReciptdata.otherDetail &&
                            balanceReciptdata.otherDetail.data &&
                            balanceReciptdata.otherDetail.data.name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                            width: '271px',
                          }}
                        >
                          Authentication ID:
                        </td>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          {balanceReciptdata && balanceReciptdata.otherDetail && balanceReciptdata.otherDetail.confirmation_ID
                            ? balanceReciptdata.otherDetail.confirmation_ID
                            : 'APSO58JUID'}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          Payment Date:
                        </td>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          {balanceReciptdata && balanceReciptdata.data && balanceReciptdata.data.datePayment
                            ? moment(balanceReciptdata.data.datePayment).format('MM/DD/YY')
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          Payment Time:
                        </td>
                        <td
                          style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            padding: '0 5px',
                            textAlign: 'left',
                          }}
                        >
                          {balanceReciptdata && balanceReciptdata.data && balanceReciptdata.data.datePayment
                            ? moment(balanceReciptdata.data.datePayment).format('hh:mm A')
                            : ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td align="center">
                  <table
                    border="0"
                    style={{
                      background: '#fff',
                      borderSpacing: '0',
                      width: '90%',
                      padding: '15px 65px',
                      margin: '0 auto',
                      textAlign: 'center',
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontSize: '13px',
                            textAlign: 'center ',
                            lineHight: '19px',
                            fontWeight: '600',
                            paddingBottom: '10px',
                            paddingTop: '5px',
                          }}
                        >
                          If you need help regarding this invoice please contact your vendor directly
                        </td>
                      </tr>
                      {/* <tr>
                        <td style={{ fontSize: "13px", textAlign: "center", lineHeight: "19px" }}>
                          Loadz is committed to preventing fraudulent emails. Please verify you are recieving this email from an email recipient you know. This is an automated email, please don’t reply.
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot style={{ padding: '0 0 16px' }}>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <img
                    src="assets/images/logos/loadzlogo.png"
                    style={{ width: '130px', margin: '0 auto' }}
                  />
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  style={{
                    padding: '18px 0px 10px',
                    fontSize: '14px',
                    color: '#000',
                    fontWeight: '600',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  We Make Paper Ticketing Obsolete -{' '}
                  <a
                    href="#"
                    style={{ textDecoration: 'none' }}
                  >
                    Learn More
                  </a>
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  style={{
                    padding: '0px 0 20px',
                    fontSize: '10px',
                    color: '#000',
                    fontWeight: '700',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  Copyright © 2022 Loadz.com, Inc. All rights reserved.{' '}
                </td>
              </tr>
            </tfoot>
          </table>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
