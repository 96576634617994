import { createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import navigationConfig from 'app/fuse-configs/navigationConfig';
import FuseUtils from '@fuse/utils';
import i18next from 'i18next';
import _ from '@lodash';

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(emptyInitialState, navigationConfig);

export const appendNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());
  return dispatch(setNavigation(FuseUtils.appendNavItem(navigation, item, parentId)));
};

export const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());
  return dispatch(setNavigation(FuseUtils.prependNavItem(navigation, item, parentId)));
};

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());
  return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());
  return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.fuse.navigation);

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
});

export const { setNavigation, resetNavigation } = navigationSlice.actions;

const getUserRole = (state) => {
  // return state.auth.user.role
  const permissions = state.loadz_auth.AuthReducer?.user?.permission;
  return permissions;
};

const userType = (state) => {
  return state.loadz_auth.AuthReducer?.user?.user_type;
};

export const selectNavigation = createSelector(
  //maybe remove here //////////////////////////////////////
  [selectNavigationAll, ({ i18n }) => i18n.language, getUserRole, userType],
  (navigation, language, userRole, userType) => {
    //  if (userRole?.includes('*.*')) {
    //   return navigation
    // }

    const data = navigation.filter((item) => FuseUtils.hasPermission(item.url, userRole, userType));
    const result = data.map((item) => {
      if (item?.children?.length > 0) {
        let filterChildren = item.children.filter((child) =>
          FuseUtils.hasPermission(child.url, userRole, userType)
        );

        filterChildren = filterChildren.map((child) => {
          if (child?.children && child?.children.length > 0) {
            const children = child?.children.filter((item) =>
              FuseUtils.hasPermission(item.url, userRole, userType)
            );
            return { ...child, children: children.length > 0 ? children : [] };
          }
          return child;
        });

        return { ...item, children: filterChildren.length > 0 ? filterChildren : [] };
      }
      return item;
    });
    return result;
  }
);

export const selectFlatNavigation = createSelector([selectNavigation], (navigation) =>
  FuseUtils.getFlatNavigation(navigation)
);

export default navigationSlice.reducer;
