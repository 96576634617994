import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const UnitTempsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/tickets/status/:tkReferenceId/:tkReferenceName?',
      component: lazy(() => import('./tkReference/TicketStatus')),
    },
    {
      path: '/tickets/status',
      component: lazy(() => import('./tkReferences/TicketStatus')),
    },
    {
      path: '/tickets',
      component: () => <Redirect to="/tickets/status" />,
    },
  ],
};

export default UnitTempsAppConfig;
