import { combineReducers } from '@reduxjs/toolkit';
import request from './requestsSlice';
import sidebar from './sidebarSlice'

const reducer = combineReducers({
  request,
  sidebar,
});

export default reducer;
