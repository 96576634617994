import client from 'client';

export const updateCompany = async (info) => {
  const result = await client.put(`/company/update`, info);
  // console.log(result)
  return result;
};

export const getcompanyOne = async (info) => {
  const result = await client.get(`/company/getcompanyOne`, info);
  // console.log(result)
  return result;
};

export const putcompanyOne = async (info) => {
  const result = await client.put(`/company/putcompanyOne`, info);
  // console.log(result)
  return result;
};

export const deletecompanyOne = async (info) => {
  const result = await client.delete(`/company/deletecompanyOne`, info);
  // console.log(result)
  return result;
};

export const updateCompanyLogo = async (info) => {
  const result = await client.put(`/company/updateCompanyLogo`, info);
  // console.log(result)
  return result;
};

export const updateProfileImg = async (info) => {
  const result = await client.put(`/company/updateProfileImg`, info);
  // console.log(result)
  return result;
};

export const getDataFromS3 = async (info) => {
  const result = await client.post(`/aws/get`, info);
  // console.log(result)
  return result;
};
