import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Icon,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { motion } from 'framer-motion'
import { Link, useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import Permission from './Permissions'
import RolesPermissionAction from 'app/stores/loadz/redux/RolesPermissions/actions'
const CreateUpdateData = ({
  role,
  assignedPermissions,
  createRole,
  updateRole,
  getRole
}) => {

  const routeParams = useParams()
  const history = useHistory()
  const [selectedPermission, setSelectedPermission] = useState([])
  const [permissionError, setPermissionError] = useState(false)
  const [roleError, setRoleError] = useState(false)
  const [roleName, setRoleName] = useState("")

  useEffect(async () => {
    if (routeParams.id !== 'new') {
      await getRole(routeParams.id, (error) => {
        console.log(error)
      })
    }
  }, [routeParams])

  useEffect(() => {
    if (role) {
      setRoleName(role.displayName)
    }
  }, [role])

  const handleSubmit = async () => {
    if (!roleName) {
      setRoleError(true)
      return
    }

    if (selectedPermission.length == 0) {
      setPermissionError(true)
      return
    }

    let body
    if (routeParams.id === 'new') {
      body = {
        name: roleName,
        permissions: selectedPermission
      }
      await createRole(body, (message) => {
        history.push('/roles-permissions')
      }, (error) => {
        console.log(error)
      })
    } else {
      body = {
        role_id: routeParams.id,
        permissions: selectedPermission
      }

      await updateRole(body, (message) => {
        history.push('/roles-permissions')
      }, (error) => {
        console.log(error)
      })
    }
  }

  return (
    <div className="flex flex-col m-32 w-100">
      <div className="flex justify-between job-right w-full">
        <div>
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
          >
            <Typography
              className="flex items-center sm:mb-12"
              component={Link}
              role="button"
              to="/roles-permissions"
              color="black"
            >
              <Icon className="text-20">arrow_back</Icon>
              <span
                className="sm:flex text-base mx-4 font-medium"
                color="black"
              >
                Back to Roles Permissions Overview
              </span>
            </Typography>
          </motion.div>
        </div>
        <div className="flex">
          <motion.div
            className="desktop"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
          >
            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              <span>{routeParams.id === 'new' ? 'Create' : 'Save'}</span>
            </Button>
          </motion.div>
        </div>
      </div>
      <div className="my-32 md:my-32 w-full">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={0} aria-label="basic tabs example">
              <Tab label="Role and Permission Assignment"></Tab>
            </Tabs>
          </Box>
          <div className="my-32 w-full">
            <div className="flex w-full">
              <div className="w-1/2 newjob-form ml-10">
                <div className=" px-5">
                  <div className="text-base">Role Name</div>
                  <TextField
                    placeholder="Role Name"
                    value={roleName}
                    fullWidth
                    onChange={(event) => {
                      roleName ? setRoleError(false) : setRoleError(true)
                      setRoleName(event.target.value)
                    }}
                    disabled={role ? true: false}
                  />
                  {roleError &&
                    <span style={{color: 'red', fontSize: '8px'}}>
                      Role can not be empty
                    </span>
                  }
                </div>
              </div>
              <div className='w-1/2'>
              <Permission
                setSelected={(permission) => {
                  if (permission.length > 0) setPermissionError(false)
                  setSelectedPermission(permission)
                }}
                assignedPermissions={assignedPermissions}
                showError={permissionError} />
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    role: state.loadz_roles_permissions.RolesPermissionsReducer?.role,
    assignedPermissions: state.loadz_roles_permissions.RolesPermissionsReducer?.assignedPermissions,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createRole: (payload, onSuccess, onError) =>
    dispatch(RolesPermissionAction.create(payload, onSuccess, onError)),

  getRole: (id, onError) =>
    dispatch(RolesPermissionAction.getRole(id, onError)),

  updateRole: (payload, onSuccess, onError) =>
    dispatch(RolesPermissionAction.update(payload, onSuccess, onError)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateData)
