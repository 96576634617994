import CloseIcon from '@material-ui/icons/Close';
import CheckCircle from '@mui/icons-material/CheckCircle';
import client from 'client';
import { changeCurrency, formatter } from '../../../constants';
import { useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
const {
  Dialog,
  Grid,
  Card,
  Typography,
  Box,
  CardContent,
  CardHeader,
  Button,
  Divider,
  IconButton,
} = require('@mui/material');
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';

const ModalSubscriptionsPaywalls = ({ open, setSubscriptionsPaywalls, compacctnum }) => {
  const [subscriptionsBill, setSubscriptionsBill] = useState([]);
  const [subscriptionInfo, setSubscriptionInfo] = useState([]);
  const history = useHistory();

  const paymentSubscription = async () => {
    history.push(`/subscriptions/apply-payment-view/${compacctnum}`);
  };

  const downgradeSubscription = async () => {
    history.push(`/subscriptions-company`);
  };

  useEffect(() => {
    if (compacctnum) {
      const subscriptionCompany = async () => {
        try {
          const response = await client.get(`/subscription/subscription-company/${compacctnum}`);
          setSubscriptionInfo(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      const getSubscriptionsBill = async () => {
        const response = await client.get(`/subscription/get-bills-subscription/${compacctnum}`);
        setSubscriptionsBill(response.data);
      };
      subscriptionCompany();
      getSubscriptionsBill();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setSubscriptionsPaywalls(false)}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { minHeight: 'auto', maxHeight: 'auto', overflow: 'scroll' } }}
      className="p-12 new-modal bg-transparent"
    >
      {subscriptionsBill.length > 0 && subscriptionInfo.length > 0 ? (
        <div className="p-14">
          <h3 className="text-lg font-bold"> Paywall - {subscriptionsBill[0].compname}</h3>
          <IconButton
            onClick={() => setSubscriptionsPaywalls(false)}
            style={{ position: 'absolute', right: 0, top: 0, padding: '25px' }}
            size="large"
          >
            <CloseIcon className="text-red-700" />
          </IconButton>

          <div className=" user-profile-detail flex justify-between flex-wrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap">
            <div className="grid custom-border w-full  border-slate-200 xl:w-2/3 lg:w-4/5 md:w-4/5 xl:order-1 lg:order-1 md:order-1 order-2 border-r-2">
              <Box sx={{ my: 1, mx: 1 }}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={7}
                    lg={7}
                  >
                    <div className="box pr-0 xl:pr-5 lg:pr-5 md:pr-3">
                      <div className=" bg-white	shadow-lg rounded-2xl mt-0">
                        <div className="invoice-wrapper p-10">
                          <div className="flex justify-between py-2">
                            <p className="font-bold">Invoice No:</p>
                            <p> {subscriptionsBill[0].bill_no}</p>
                          </div>

                          <div className="flex justify-between py-2">
                            <p className="font-bold">Subscriptions:</p>
                            <p> {subscriptionInfo[0].marketing_title}</p>
                          </div>
                          <div className="flex justify-between py-2">
                            <p className="font-bold">Period:</p>
                            <p>
                              {moment(subscriptionsBill[0].current_period_start).format(
                                'MM-DD-YYYY'
                              )}{' '}
                              to{' '}
                              {moment(subscriptionsBill[0].current_period_end).format('MM-DD-YYYY')}
                            </p>
                          </div>

                          <div className="flex justify-between py-2">
                            <p className="font-bold">Due date:</p>
                            <p>
                              {moment(subscriptionsBill[0].subscription_cancel_at)
                                // .add(subscriptionsBill[0].terms, 'days')
                                .format('MMMM D, YYYY')}
                            </p>
                          </div>

                          <div className="flex justify-between py-2">
                            <p className="font-bold">Balance</p>
                            <p>{formatter.format(subscriptionsBill[0].bill_balance)}</p>
                          </div>

                          <div className="flex justify-between py-2">
                            <p className="font-bold">Invoice Amount:</p>
                            {formatter.format(subscriptionsBill[0].bill_total)}
                          </div>

                          <div className="flex justify-between py-2">
                            <p className="font-bold">Status</p>
                            <p>{subscriptionInfo[0].status}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={5}
                    lg={5}
                  >
                    <div className="w-full text-center">
                      <h1 className="text-5xl font-bold">
                        {formatter.format(changeCurrency(subscriptionsBill[0].bill_balance))}
                      </h1>
                    </div>

                    <div className="w-full text-center">
                      <h1 className="text-xl">Amount Due</h1>
                    </div>

                    <div className="box mt-32 flex w-full justify-center">
                      <button
                        onClick={() => paymentSubscription()}
                        className="rounded-none border-solid border-1 w-4/5 border-black py-10 px-32 text-white bg-black font-medium hover:bg-white hover:text-black ease-in duration-300 mx-auto"
                      >
                        Pay Now
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <br />
              <br />
              <Divider />
              <div className="box pr-0 xl:pr-10 lg:pr-10 md:pr-10">
                <h5 className="font-bold">Pay now and continue enjoying the following benefits:</h5>
                <ul style={{ paddingLeft: '16px', paddingRight: '16px', marginTop: '5px' }}>
                  <Box sx={{ my: 1, mx: 1 }}>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                      >
                        <div>
                          <li>
                            <CheckCircle className="text-green-700" /> Create New Customer
                          </li>
                          <li>
                            <CheckCircle className="text-green-700" /> Create New Job
                          </li>
                          <li>
                            <CheckCircle className="text-green-700" /> Create New Driver
                          </li>
                          <li>
                            <CheckCircle className="text-green-700" /> Create New Truck
                          </li>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                      >
                        <div>
                          <li>
                            <CheckCircle className="text-green-700" /> Create New Trailer
                          </li>
                          <li>
                            <CheckCircle className="text-green-700" /> Create New Owner Operator
                          </li>
                          <li>
                            <CheckCircle className="text-green-700" /> Create New Subcontractor
                          </li>
                          <li>
                            <CheckCircle className="text-green-700" /> View/Print In-House Driver
                            Earnings Report
                          </li>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </ul>
              </div>
            </div>

            <div className="main-column w-full xl:w-1/3 lg:w-2/4 md:w-2/5 xl:order-2 lg:order-2 md:order-1 order-1 flex h-full">
              <div className="box pl-0 xl:pl-20 lg:pl-20 mb-20  w-full h-full flex justify-center  items-center flex-col text-center ">
                <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div class="px-4 py-5 sm:px-6 flex justify-between items-center">
                    <div>
                      <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Subscription Expiring Soon - Options to Consider
                      </h3>
                      <br />
                      <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Your free trial is ending soon. Downgrade to the free tier to keep using
                        some basic features of the app.
                      </p>
                    </div>
                  </div>
                  <div class="box mt-32 flex w-full justify-center">
                    <button
                      type="button"
                      onClick={() => downgradeSubscription()}
                      class="rounded-none border-solid border-1 w-4/5 border-black py-10 px-32 text-white bg-black font-medium hover:bg-white hover:text-black ease-in duration-300 mx-auto"
                    >
                      Downgrade to Free
                    </button>
                  </div>
                  <div class="w-full text-center p-20">
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Want to unlock all features and continue using the app uninterrupted?{' '}
                      <a href="#">Renew your subscription</a>.
                      <br />
                      Need help deciding? <a href="#">Contact Support</a>.
                    </p>
                  </div>
                </div>

                {/* <div className="inputfields flex flex-col items-center">
                  <div className="w-full text-center">
                    <h1 className="text-5xl font-bold">
                      {formatter.format(changeCurrency(subscriptionsBill[0].bill_balance))}
                    </h1>
                  </div>

                  <div className="w-full text-center">
                    <h1 className="text-xl">Amount Due</h1>
                  </div>

                  <div className="box mt-32 flex w-full justify-center">
                    <button
                      onClick={() => paymentSubscription()}
                      className="rounded-none border-solid border-1 w-4/5 border-black py-10 px-32 text-white bg-black font-medium hover:bg-white hover:text-black ease-in duration-300 mx-auto"
                    >
                      Pay Now
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FuseLoading />
      )}
    </Dialog>
  );
};

export default ModalSubscriptionsPaywalls;
