import { Fragment, memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import ImageListItem from '@mui/material/ImageListItem'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { constants, formatter } from '../../../../constants';
import FuseLoading from '@fuse/core/FuseLoading';
import moment from 'moment'
import { Checkbox, FormControlLabel, FormGroup, Grid, Paper, TableContainer } from '@mui/material';
import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClientsApp from './Clients/ClientsApp';
import Tooltip from '@mui/material/Tooltip';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ModalToInvoice from './Modals/ModalToInvoice';
import { isArray } from 'lodash';
import Check_refresh from '../../Check_refresh';
import store from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { faWeight } from '@fortawesome/free-solid-svg-icons';
import client from 'client';
import ErrorBoundary from 'app/main/ErrorBoundary';

let jobsToFill = []
function TablePaginationActions(props) {


  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


function RowBatches(props) {
  const {
    row,
    infoBatch,
    openModal,
    setTickets,
    tickets,
    selectAllTicketJob,
    indexJob,
    batchToDOM,
    setSelectTicketCount,
    setSelectedTotal,
    selectTicketCount,
    selectedTotal
  } = props;



  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false)

  const handleOpen = (open) => {
    setOpen(!open)
  }

  const [checkBoxes, setCheckBoxes] = useState()

  const [checkBatches, setCheckBatches] = useState()
  const [ticketsToInvoice, setTicketsToInvoice] = useState([])

  useEffect(() => {
    let checks = Array(row.length).fill(false)

    setCheckBoxes(checks)
    setCheckBatches(false)

  }, [row])

  useEffect(() => {
    setUpdate(true)
  }, [ticketsToInvoice])

  const changeSelect = (all, object) => {
    let newSelectTotalCount = selectTicketCount
    let newSelectTotal = selectedTotal

    if (all) {

      let toInvoice = []
      let newTickets = tickets

      if (checkBatches) {
        //to unchecked
        const updatedCheckedState = checkBoxes.map((item, index) =>
          false
        );
        setCheckBoxes(updatedCheckedState);

        newTickets = newTickets.filter(e => !ticketsToInvoice.includes(e))

        let sum = 0
        let count = 0

        const newRows = row.filter(e => !ticketsToInvoice.includes(e))

        console.log('newRows', newRows)

        newRows.forEach(e => {
          sum += e.materialTax + e.materialCost + e.ticketCost + e.waitTimeCost
        })

        newSelectTotalCount[indexJob] -= newRows.length
        newSelectTotal[indexJob] -= sum


      } else {
        //to checked

        console.log(checkBoxes)
        console.log(ticketsToInvoice)

        let lastChecked = ticketsToInvoice

        const updatedCheckedState = checkBoxes.map((item, index) =>
          true
        );

        setCheckBoxes(updatedCheckedState);
        // setUnSelect([])
        let sum = 0


        let newRows = row.filter(e => !lastChecked.includes(e.ticketId))

        console.log('newRows', newRows)

        newRows.forEach(e => {
          toInvoice.push(e.ticketId)
          newTickets.push(e.ticketId)
          sum += e.materialTax + e.materialCost + e.ticketCost + e.waitTimeCost
        })

        newSelectTotalCount[indexJob] += newRows.length
        newSelectTotal[indexJob] += sum
      }

      setCheckBatches(!checkBatches)
      setTicketsToInvoice(toInvoice)
      setTickets([...newTickets])

    } else {

      const updatedCheckedState = checkBoxes.map((item, index) =>
        index === object.i ? !item : item
      );
      setCheckBoxes(updatedCheckedState);
      let toInvoice = ticketsToInvoice
      let newTickets = tickets
      let sum = 0

      row.forEach(e => {
        if (e.ticketId === object.ticket) {
          sum = e.materialTax + e.materialCost + e.ticketCost + e.waitTimeCost
        }
      })


      if (updatedCheckedState[object.i]) {
        // to checked
        toInvoice.push(object.ticket)

        newTickets.push(object.ticket)

        newSelectTotalCount[indexJob] += 1
        newSelectTotal[indexJob] += sum

      } else {
        toInvoice = toInvoice.filter(e => e !== object.ticket)
        newTickets = newTickets.filter(e => e !== object.ticket)

        newSelectTotalCount[indexJob] -= 1
        newSelectTotal[indexJob] -= sum
      }



      row.length === toInvoice.length ? setCheckBatches(true) : setCheckBatches(false)

      console.log('toInvoice', toInvoice)
      console.log('newTickets', newTickets)

      setTicketsToInvoice(toInvoice)
      setTickets([...newTickets])
    }
  }



  return (
    <>
      {
        update ? (
          <Fragment>
            <TableRow className='cstm-table-down' sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell className='ui-table-down'>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  className='pr-0 text-black'
                  onClick={() => handleOpen(open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              
              <TableCell component="th" scope="row" className='ui-table-down'>
                Batch No. {infoBatch?.batchNo}
              </TableCell>
              <TableCell align='center' className='ui-table-down'>
                {`Ticket Count (${row?.length})`}
              </TableCell>
              <TableCell align='center' className='ui-table-down'>
                {`Package Hauling Sales  ${formatter.format(infoBatch?.totalTicketCost)}`}
              </TableCell>
              <TableCell align='center' className='ui-table-down'>
                {`Package Material Sales ${formatter.format(infoBatch?.totalMaterialCost)}`}
              </TableCell>
              <TableCell align='center' className='ui-table-down'>
                {`Package Wait Time Sales ${formatter.format(infoBatch?.totalWaitTime)}`}
              </TableCell>

              <TableCell padding="checkbox" className='ui-table-down'>

                <Tooltip title="Select all tickets to invoice" placement="top">
                  <Checkbox
                    color="primary"
                    checked={checkBatches}
                    onChange={() => changeSelect(true, {
                      batch: infoBatch.batch
                    })}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}

                    id={batchToDOM}

                  />
                </Tooltip>

              </TableCell>

               <TableCell padding="checkbox" align="right">
               
                {
                  tickets.length > 0 ? (
                    <Tooltip title="Generate Invoice for Selected" placement="top">
                    <ImageListItem onClick={() => openModal.current(false)}>
                 <img   src="/assets/images/download.svg"/>
                </ImageListItem>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              </TableCell>


            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">

                    </Typography>
                    <TableContainer component={Paper} className="responsive-loads-table">
                      <Table size="small" aria-label="purchases" class="main-inner-table table-fixed w-full">
                        <TableHead>
                          <TableRow>
                            <TableCell className='cst-table-data' align="center">&nbsp;</TableCell>
                            <TableCell className='cst-table-data' align="center">Ticket No.</TableCell>
                            <TableCell className='cst-table-data' align="center">Qty</TableCell>
                            <TableCell className='cst-table-data' align="center">Driver</TableCell>
                            <TableCell className='cst-table-data' align="center">Truck No.</TableCell>
                            <TableCell className='cst-table-data' align="center">Scale</TableCell>
                            <TableCell className='cst-table-data custom_width' align="center">Date/Time Created</TableCell>
                            <TableCell className='cst-table-data custom_width' align="center">Date/Time Updated</TableCell>
                            <TableCell className='cst-table-data' align="center">Hauling Sales</TableCell>
                            <TableCell className='cst-table-data' align="center">Material Sales</TableCell>
                            <TableCell className='cst-table-data' align="center">Wait Time Charges</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.map((ticket, i) => (
                            <>
                              {row.length + 1 === i + 1 ? (
                                <>

                                </>
                              ) : (
                                <ErrorBoundary>
                                  <TableRow key={ticket.ticket}>
                                  <TableCell>
                                  <Tooltip title="Select ticket to invoice" placement="top">
                                        <Checkbox
                                          color="primary"
                                          checked={checkBoxes[i]}
                                          onChange={() =>
                                            changeSelect(null, {
                                              ticket: ticket.ticketId,
                                              i: i
                                            })
                                          }
                                          inputProps={{
                                            'aria-label': 'select all desserts',
                                          }}
                                        />
                                      </Tooltip>
                                      </TableCell>
                                    <TableCell data-headtext="Ticket No." className='cst-table-data' align="center" >{ticket?.ticket}</TableCell>
                                    <TableCell data-headtext="Qty"className='cst-table-data' align="center" >{Number(ticket?.qty).toFixed(2)}</TableCell>
                                    <TableCell data-headtext="Driver" className='cst-table-data' align="center" >{ticket?.driver}</TableCell>
                                    <TableCell data-headtext="Truck No." className='cst-table-data' align="center" >{ticket?.truck}</TableCell>
                                    <TableCell data-headtext="Scale" className='cst-table-data' align="center" >{ticket?.scale}</TableCell>
                                    <TableCell data-headtext="Date Time Updated"  className='cst-table-data' align="center" >{moment(ticket?.dateTimeUpdate).format('LLL')}</TableCell>
                                    <TableCell data-headtext="Date Time Updated"  className='cst-table-data' align="center" >{moment(ticket?.dateTimeUpdate).format('LLL')}</TableCell>
                                    <TableCell data-headtext="Hauling Sales" className='cst-table-data' align="center">{ticket?.ticketCR ? ticket.ticketCR : `-`}</TableCell>
                                    <TableCell data-headtext="Material Sales" className='cst-table-data' align="center">{ticket?.materialCR ? ticket.materialCR : `-`}</TableCell>
                                    <TableCell data-headtext="Wait Time Charges" className='cst-table-data' align="center">{ticket?.waitCR ? ticket.waitCR : `-`}</TableCell>
                                  </TableRow>
                                </ErrorBoundary>
                              )}
                            </>
                          ))}
                          
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment >
        ) : (
          <> </>
        )


      }
    </>
  );
}

export function Row(props) {
  const { row, all, openModal, infoToInvoice, setInfoToInvoice, setTickets, tickets, setTotalTicketToInvoice } = props;
  const [open, setOpen] = useState(false);
  const [checkJobs, setCheckJobs] = useState([])

  const [totalToInvoice, setTotalToInvoice] = useState([])//to totals per job
  const [selectedTotal, setSelectedTotal] = useState([])//total select when click en checks
  const [totalTicketCount, setTotalTicketCount] = useState([])//total ticket count
  const [selectTicketCount, setSelectTicketCount] = useState([])// total select ticket couunt




  const allSelectFromJob = (index) => {
    let checkBoxes = checkJobs
    checkBoxes[index] = !checkBoxes[index]
    setCheckJobs(checkBoxes)

    const bigFilter = row.jobs[index].batches.map(b => b.batchToDOM).flat(3)

    console.log(bigFilter)

    bigFilter.forEach(b => {
      setTimeout(() => {
        document.getElementById(`${b}`).click()
      }, 10);
    })
  }

  const handleOpen = (open) => {
    setOpen(!open)
  }



  useEffect(() => {
    let checks = Array(row.jobs.length).fill(false)
    setCheckJobs(checks)

    const elements = document.getElementsByClassName('MuiAccordionSummary-content MuiAccordionSummary-contentGutters')
    console.log(elements)
    Array.from(elements).forEach(i => {
      i.style.alignItems = 'center'
      i.style.justifyContent = 'space-between'
    })

  }, [row])

  useEffect(() => {

    let totals = []
    let totalTickets = []
    let totalSelectTickets = Array(row?.jobs?.length).fill(0)
    let totalSelect = Array(row?.jobs?.length).fill(0)

    row?.jobs?.forEach((batch, i) => {
      let sum = 0
      let tickets = 0
      batch?.batches?.forEach(b => {
        sum += (
          b.totalMaterialCost +
          b.totalTicketCost +
          b.totalWaitTime +
          b.totalTaxesMaterial
        )
        tickets += b.info.length
      })
      totals.push(sum)
      totalTickets.push(tickets)
    })
    console.log("THE ROWS >>>>>>>>>>>>>>>>>>>>",row)

    setTotalToInvoice(totals)
    setTotalTicketCount(totalTickets)
    setSelectTicketCount([...totalSelectTickets])
    setSelectedTotal([...totalSelect])
  }, [row])


  return (
    <>
     
          <Fragment>
            <div className='cst__table new-invoice-table customnew-heading'>
              <table className='w-full'>
                  <thead>
                      <tr>
                        <th className='top-head'>&nbsp;</th>
                        <th className='top-head'>Job No.</th>
                        <th className='top-head'>Total Invoice</th>
                        <th className='top-head'>Total Tickets</th>
                        <th className='top-head'>Total Material w/Tax </th>
                        <th className='top-head'>Selected Total</th>
                        <th className='top-head'>Invoice</th>
                        <th className='w-1/4'></th>
                      </tr>
                    </thead>
                    </table>

            {row?.jobs?.map((job, indexJob) => (
             <Accordion key={job.job}>
                <table className='w-full'>
                    <AccordionSummary

                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className='flex-row-reverse	gap-40'
                    >
                    <tbody>
                      <ErrorBoundary>
                      <tr className='text-center'>
                        <td className='top-data job__data'>
                          <Typography sx={{}}>{<ExpandMoreIcon />}</Typography>
                        </td>
                        <td className='top-data job__data'>
                          <Tooltip followCursor={true} title={job.og_job_no ? job.og_comp+"-"+job.og_job_no : ""} placement={"right"}><Typography sx={{}}>{`${job.job}`}</Typography></Tooltip>
                        </td>
                        <td className='top-data'>
                          <Typography sx={{}}> {totalToInvoice ? formatter.format(totalToInvoice[indexJob]): '-'}</Typography>
                        </td>
                        <td className='top-data'>
                          <Typography sx={{}}>{totalTicketCount[indexJob]}</Typography>
                        </td>
                        <td className='top-data'>
                          <Typography sx={{}}>{selectedTotal ? formatter.format(selectedTotal[indexJob]):'-'}</Typography>
                        </td>
                        <td className='top-data'>
                          <Typography sx={{}}>{selectTicketCount[indexJob]}</Typography>
                        </td>
                        <td className='top-data'>
                          <Tooltip title="Select job to invoice" placement="top">
                            <FormGroup>
                              <FormControlLabel className="justify-center ml-0 mr-0" control={

                                <Checkbox
                                  color="primary"
                                  id={`${job.jobToDOM}`}
                                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                                  checked={checkJobs[indexJob]}
                                  onChange={() => {
                                    allSelectFromJob(indexJob)
                                  }}
                                  inputProps={{
                                    'aria-label': 'select all desserts',
                                  }}

                                />

                              }
                                label={``} />
                            </FormGroup>
                          </Tooltip>
                        </td>
                        <td></td>
                      </tr>
                      </ErrorBoundary>
                    </tbody>
                   </AccordionSummary>
                  </table>

                  <AccordionDetails>
                  <div className={clsx(
                    'flex flex-1 flex-col p-5 bg-grey-CSTGREY'
                  )}>
                    <Grid item xs={24}>
                      {
                        job?.batches?.map((batches, i) => (
                          <ErrorBoundary>
                          <RowBatches
                            row={batches.info}
                            setTickets={setTickets}
                            tickets={tickets}
                            setTotalTicketToInvoice={setTotalTicketToInvoice}

                            indexJob={indexJob}
                            batchToDOM={batches.batchToDOM}

                            openModal={openModal} infoBatch={{
                              batchNo: batches.batch,
                              qtyTotal: batches.qtyTotal,
                              material: batches.material,
                              quarry: batches.quarry,
                              destination: batches.destination,
                              unit: batches.unit,
                              fullfillmentTotal: batches.fullfillmentTotal,
                              totalMaterialCost: batches.totalMaterialCost,
                              totalTicketCost: batches.totalTicketCost,
                              totalWaitTime: batches.totalWaitTime,
                            }}
                            setSelectTicketCount={setSelectTicketCount}
                            setSelectedTotal={setSelectedTotal}
                            selectTicketCount={selectTicketCount}
                            selectedTotal={selectedTotal}
                          ></RowBatches>
                          </ErrorBoundary>
                        ))
                      }
                    </Grid>
                  </div>
                </AccordionDetails>
                </Accordion>
        ))
      }
              
      </div>
          </Fragment >
    </>
  );
}


function Batches(props) {
  const [rows, setRows] = useState([])
  const [loading,setLoading] = useState(false)
  const [zero, setZero] = useState(false)

  const openModalInvoice = useRef(null)
  const rechargedAfterInvoices = useRef(null)
  const setMessage = useRef(null)
  const [isAllJobs, setIsAllJobs] = useState(false)
  const [tickets, setTickets] = useState([])
  const [jobsToInvoice, setJobsToInvoice] = useState([])
  const [infoToInvoice, setInfoToInvoice] = useState([])

  const [totalTicketToInvoice, setTotalTicketToInvoice] = useState([])

  const [infoCustomer, setInfoCusomter] = useState([])

  const dispatch = useDispatch();

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (<>
          <Typography>{msg}</Typography>
        </>),//text or html
        autoHideDuration: 20000,//ms

        anchorOrigin: {
          vertical: 'top',//top bottom
          horizontal: 'right'//left center right
        },
        variant: color //success error info warning null
      }))
  }

  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [address, setAddress] = useState(null)
  const [city, setCity] = useState(null)
  const [zip, setZip] = useState(null)
  const [creditLimit, setCreditLimit] = useState(null)
  const [phone, setPhone] = useState(null)
  const [customerTerm, setCustomerTerm] = useState(null)
  const [customerName, setCustomerName] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [avatar, setAvatar] = useState(null)  
  const [balance, setBalance] = useState(null)

  const [ticket,setTicket] = useState('')


  const token = localStorage.token
  const getData = async () => {
    setLoading(true)

    setName(null)
    setEmail(null)
    setAddress(null)
    setCity(null)
    setZip(null)
    setCreditLimit(null)
    setPhone(null)
    setCustomerTerm(null)
    setCustomerName(null)
    setCustomer(null)
    setAvatar(null)
    setBalance(null)

    setZero(false)
    let _rows = []
    setInfoToInvoice([])
    setRows([])
    setInfoCusomter([])
    setTickets([])
    setJobsToInvoice([])
    setTotalTicketToInvoice([])

    await Check_refresh()
    const state = store.getState()
    const {compacctnum} = state?.loadz_auth?.AuthReducer?.user?.company
   
    try {
      const result = await client.get(`/batch/get-all-batches/${compacctnum}${ticket.length > 0 ? `/${ticket}` : ''}`)

      setLoading(false)
      if (result.data.msg) {
        setZero(true)
        setRows([])
      }
      else
      {
        setInfoToInvoice(result.data)
        setRows(result.data)
      }

      
    } catch (error) {
      console.log(error)
      setLoading(false)}
   

  }

  const handleTicketSearch = () => {
    if(ticket.length > 0)
    { 
      getData()
    }
  }

  useEffect(() => {
    setMessage.current = _showMessage
    rechargedAfterInvoices.current = getData
  }, [])

  useEffect(() => {
    if(ticket.length == 0)
    {
      getData()
    }
  }, [ticket])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /* if (zero) {
    return (
      <div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="textSecondary" variant="h5">
          There are no batches!
        </Typography>
      </div>
    );
  } */

  
    return (
      <ErrorBoundary>
        <ModalToInvoice
          openModal={openModalInvoice}
          isAllJobs={isAllJobs}
          tickets={tickets}
          jobsToInvoice={jobsToInvoice}
          infoToInvoice={infoCustomer}
          rechargedAfterInvoices={rechargedAfterInvoices}
          setMessage={setMessage}
        />
        <ClientsApp
          rows={rows}
          loading={loading}
          openModal={openModalInvoice}
          isAllJobs={isAllJobs}
          setIsAllJobs={setIsAllJobs}
          tickets={tickets}
          setTickets={setTickets}
          jobsToInvoice={jobsToInvoice}
          setJobsToInvoice={setJobsToInvoice}
          totalTicketToInvoice={totalTicketToInvoice}
          setTotalTicketToInvoice={setTotalTicketToInvoice}
          infoToInvoice={infoCustomer}
          setInfoToInvoice={setInfoCusomter}

          setName={setName}
          setEmail={setEmail}
          setAddress={setAddress}
          setCity={setCity}
          setZip={setZip}
          setCreditLimit={setCreditLimit}
          setPhone={setPhone}
          setCustomerTerm={setCustomerTerm}
          setCustomerName={setCustomerName}
          setCustomer={setCustomer}
          setAvatar={setAvatar}
          setBalance={setBalance} 

          name={name}
          email={email}
          address={address}
          city={city}
          zip={zip}
          creditLimit={creditLimit}
          phone={phone}
          customerTerm={customerTerm}
          customerName={customerName}
          customer={customer}
          avatar={avatar}
          balance={balance}

          ticket={ticket}
          setTicket={setTicket}

          handleTicketSearch={handleTicketSearch}


        />
      </ErrorBoundary>
    )

  
}

export default memo(Batches)