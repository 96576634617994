import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const DriverChatAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/drivers',
      component: lazy(() => import('./DriverChatApp')),
    },
    
  ],
};

export default DriverChatAppConfig;
