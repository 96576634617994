import {
  TableHead,
  TableRow,
  Checkbox,
  styled,
  TableCell,
  tableCellClasses,
  Box,
  IconButton,
  Icon,
  Menu,
  MenuList,
  ListItemIcon,
  MenuItem,
  ListItemText,
} from '@mui/material'
import { useEffect, useState } from 'react'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(12 12 12 / 25%)',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
const TableHeader = ({
  numSelected,
  rowCount,
  onSelectAllClick,
  onDelete,
  closeMenuPopup,
  displayMenu,
  setDisplayMenu,
}) => {
  const tableHeading = [
    {
      name: 'Name',
      align: 'left',
    },
    {
      name: 'Permissions',
      align: 'center',
    },
    {
      name: 'Users',
      align: 'center',
    },
    {
      name: 'Edit',
      align: 'center',
    },
  ]

  useEffect(() => {
    setDisplayMenu(null)
  }, [closeMenuPopup])

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>
          <Checkbox
            color="default"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />

          {numSelected > 0 && (
            <Box>
              <IconButton
                aria-owns={displayMenu ? 'selectedUnitTempsMenu' : null}
                aria-haspopup="true"
                onClick={(event) => {
                  setDisplayMenu(event.currentTarget)
                }}
                size="large"
              >
                <Icon>more_horiz</Icon>
              </IconButton>
              <Menu
                id="selectedUnitTempsMenu"
                anchorEl={displayMenu}
                open={Boolean(displayMenu)}
                onClose={() => setDisplayMenu(null)}
              >
                <MenuList>
                  <MenuItem onClick={onDelete}>
                    <ListItemIcon className="min-w-40">
                      <Icon>delete</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )}
        </StyledTableCell>

        {tableHeading.map((item, index) => (
          <StyledTableCell key={index} align={item.align}>
            {item.name}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
export default TableHeader
