import { createStore } from "redux";

const initialState = {
  //add admin flag
  compname:'',
  comp_email:'',
  id: "",
  comp_acct: "",
  photo: "",
  settings: {},
  staff_id : "",
  small_comp_id : ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOG_IN": {
      return {
        ...state,
        compname: action.payload?.compname,
        comp_email: action.payload?.comp_email,
        id: action.payload.id,
        comp_acct: action.payload?.compacct,
        settings: action.payload?.settings,
        staff_id : action.payload?.staff_id,
        small_comp_id : action.payload?.company_id
      };
    }
    case "LOG_OUT": {
      return { ...state, compname:"", comp_email:"" ,id: "", comp_acct: "", photo: "" };
    }
    case "PHOTO": {
      return { ...state, photo: action.payload.photo };
    }
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
