import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const LocationsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/locations/locals/:locationId/:locationName?',
      component: lazy(() => import('./location/Location')),
    },
    {
      path: '/locations/locals',
      component: lazy(() => import('./locations/Locations')),
    },
    {
      path: '/locations',
      component: () => <Redirect to="/locations/locals" />,
    },
  ],
};

export default LocationsAppConfig;
