export const getSuccessAction = (type) => {
    return `${type}_SUCCESS`
  };
  
  export const getErrorAction = (type) => {
    return `${type}_ERROR`
  };
  
  export const update = (type) => {
    return `${type}_UPDATE`
  };