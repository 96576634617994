import { configureStore } from '@reduxjs/toolkit'
import createReducer from './rootReducer'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../stores/loadz/sagas'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { blacklist } from './BlackListReducer'

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer.createReducer())
  })
}

const middlewares = []

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`)
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  })

  middlewares.push(logger)
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [...blacklist],
}

const sagaMiddleWare = createSagaMiddleware()
middlewares.push(sagaMiddleWare)

const persistedReducer = persistReducer(persistConfig, createReducer())

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'getAllJob',
          'getAllTicketJob',
          'getFuelLog',
          'editJobTicket',
          'transferJobTickets',
          'generalRegistration',
          'doOnboarding',
          'request_to_code',
          'login',
          'getFuleLogs',
          'getRoles',
          'getPermissions',
          'getAll',
          'getRole',
          'update',
          'search',
          'getJobs',
          'delete',
          'create',
          'getEnableDrivers',
          'getDriverSalary',
          'customerLogin',
          'verifyToken',
          'setCompanyLogo',
          'setUserLogo',
          'GET_DRIVERS_IN_TIMESHEET_SIDEBAR'
        ],
      },
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
})

sagaMiddleWare.run(rootSaga)

store.asyncReducers = {}

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false
  }
  store.asyncReducers[key] = reducer
  //store.replaceReducer(createReducer(store.asyncReducers));
  store.replaceReducer(
    persistReducer(persistConfig, createReducer(store.asyncReducers))
  )

  return store
}

export default store
