import actions from './actions'
import { getErrorAction, getSuccessAction } from '../utility'

const initState = {
  permissions: [],
  page: 1,
  loading: false,
  hasNextPage: true,
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.GET_PERMISSIONS:
      return {
        ...state,
        loading: true,
      }
    case getSuccessAction(actions.GET_PERMISSIONS):
      return {
        ...state,
        permissions:
          action.payload.page === 1
            ? action.payload.permissions
            : [...state.permissions, ...action.payload.permissions],
        page: action.payload.page,
        hasNextPage: action.payload.hasNextPage,
        loading: false,
      }
    case getErrorAction(actions.GET_PERMISSIONS):
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
