import { memo, useEffect, useState } from "react";
import clsx from "clsx";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import FusePageCarded from "@fuse/core/FusePageCarded";
import withReducer from "app/store/withReducer";
import { styled } from "@mui/material/styles";
import reducer from "./store";
import JobsHeader from "./jobs-components/JobsHeader";
import JobsTable from "./jobs-components/JobsTable";
import { constants } from "../../../constants";
import { Box, Card, CardContent, Grid, Paper } from "@mui/material";
import JobsHelpers from "./jobs-components/jobs-helpers/JobsHelpers";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import JobsHelpersBilling from "./jobs-components/jobs-helpers-billing/JobsHelpersBilling";
import TicketPage from "../Ticket_jobs/TicketPage";

function Jobs(props) {
  const {data} = props
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const ramdon = () => {
    return Math.floor(Math.random() * 1000000)
  }

  const [search, setSearch] = useState("");
  const [ticketText, setTicketText] = useState('');
  const [reData, setReData] = useState(false)
  const [jobFilter, setJobFilter] = useState('')
  const [editJobOpen,setEditJobOpen] = useState(false)
  const [editJobBillingOpen,setEditJobBillingOpen] = useState(false)
  const [ticketTableOpen,setTicketTableOpen] = useState(false)


  const [job_id,setJob_id] = useState('')
  const [selected, setSelected] = useState([])

  const changeFilter = (x) => {
    //console.log("changing the filter this is the new filter: ", x)
    setJobFilter(x)
    setSelected([])
  }

  useEffect(() => {
    console.log(search);
  }, [search]);

  return (
    <div className={clsx(
      'flex flex-1 flex-col p-24 mobile-p newjobdesign jobstable'
    )}>
      <Box sx={{ width: '100%' }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={12}>

          </Grid>
        </Grid>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {/* <Grid item xs={2} sm={2} md={2} className="top-full-grid empty"></Grid> */}
          <Grid item xs={12} sm={12} md={12} className="top-full-grid">
            <Card sx={{ minWidth: 275}} className="w-full firstsec" >
              <CardContent className="topjobcard">
                <JobsHeader ticketText={ticketText} setTicketText={setTicketText} setJob_id={setJob_id} setEditJobBillingOpen={setEditJobBillingOpen} setEditJobOpen={setEditJobOpen} data={data} setSearch={setSearch} jobFilter={jobFilter} changeJobFilter={changeFilter}></JobsHeader>
              </CardContent>
            </Card>
          </Grid>
          {/* <Grid item xs={2} sm={2} md={2} className="top-full-grid empty"></Grid> */}
        </Grid>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{ paddingTop: 20 }}
        >
          <Grid item xs={12} sm={12} md={12}>

          </Grid>
        </Grid>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={10} className="grid-full">
            <Card className="jobtablecard">
              <CardContent className="jobstable">
                <JobsTable ticketText={ticketText} setTicketText={setTicketText} selected={selected} setSelected={setSelected} setJobFilter={setJobFilter} setTicketTableOpen={setTicketTableOpen} setEditJobBillingOpen={setEditJobBillingOpen} setEditJobOpen={setEditJobOpen} job_id={job_id} setJob_id={setJob_id} data={data} search={search} reData={reData} setReData={setReData} jobFilter={jobFilter}></JobsTable>
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      </Box>

      {/**job info for companies list view */
        data ? 
        <Dialog
        open={editJobOpen}
        onClose={() => {setEditJobOpen(false)}}
        fullScreen
        >
          <DialogTitle sx={{background:'black'}}><Typography fontSize={20} color={"#edca33"}>Jobs</Typography></DialogTitle>
          <DialogContent className="p-0"><JobsHelpers setJob_id={setJob_id} setEditJobBillingOpen={setEditJobBillingOpen} setEditJobOpen={setEditJobOpen} job_id={job_id} data={data}/></DialogContent>

          <DialogActions sx={{background:'black'}}><Button onClick={() => setEditJobOpen(false)}>Close</Button></DialogActions>
        </Dialog>
      :
      null
      }

      {/**job billing for companies lsit view */
        data ? 
        <Dialog
        open={editJobBillingOpen}
        onClose={() => {setEditJobBillingOpen(false)}}
        fullScreen
        >
          <DialogTitle sx={{background:'black'}}><Typography fontSize={20} color={"#edca33"}>Job Billing</Typography></DialogTitle>
          <DialogContent className="p-0"><JobsHelpersBilling setJob_id={setJob_id} setEditJobBillingOpen={setEditJobBillingOpen} setEditJobOpen={setEditJobOpen} job_id={job_id} data={data}/></DialogContent>

          <DialogActions sx={{background:'black'}}><Button onClick={() => setEditJobBillingOpen(false)}>Close</Button></DialogActions>
        </Dialog>
        :
        null
      }

      {/**ticket table for companies list view */
        data ? 
        <Dialog
        open={ticketTableOpen}
        onClose={() => {setTicketTableOpen(false)}}
        fullScreen
        >
          <DialogTitle sx={{background:'black'}}><Typography fontSize={20} color={"#edca33"}>Job Billing</Typography></DialogTitle>
          <DialogContent className="p-0"><TicketPage setJob_id={setJob_id} setTicketTableOpen={setTicketTableOpen} setEditJobBillingOpen={setEditJobBillingOpen} setEditJobOpen={setEditJobOpen} job_id={job_id} data={data}/></DialogContent>

          <DialogActions sx={{background:'black'}}><Button onClick={() => setTicketTableOpen(false)}>Close</Button></DialogActions>
        </Dialog>
        :
        null
      }
      
      
    </div>
  );
}

export default memo(Jobs);
