import { lazy } from 'react';
import Batches from './Batches';

//sets the pages display settings

const BatchesConfig = {
  settings: {
    layout: {
      style: 'layout1',
    },
  },
  routes: [
    {
      path: '/uninvoiced',
      component: Batches,
    },

  ],
};

export default BatchesConfig;
