import { lazy } from 'react';

const ApplyPaymentSubscriptionsConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/subscriptions/apply-payment-view/:id',
      component: lazy(() => import('./ApplyPaymentViewSubscriptions')),
    },
    {
      path: '/subscriptions/confirm-payment/:id',
      component: lazy(() => import('./ConfirmPayment')),
    },
  ],
};

export default ApplyPaymentSubscriptionsConfig;
