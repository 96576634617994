import React, { useEffect } from 'react'
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import TextField from '@mui/material/TextField';
import { Divider, FormControl, Grid, Paper } from '@mui/material';
import moment from 'moment';
import { formatter, constants } from '../../../../../constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from "lodash";
import { ModalGenerateInvoicesCss } from "./ModalGenerateInvoices.css";
import { fontSize, height } from '@mui/system';
const item = {
	hidden: { opacity: 0, y: 40 },
	show: { opacity: 1, y: 0 },
}

const TODAY = moment()

const ModelUninvoiceForPrint = React.forwardRef((props, ref) => {
	return (
		<div ref={ref}>
			{props && props.toTable && props.toTable.length > 0 ? (
				<>
					<DialogContent dividers-0 style={{ padding: '0px', }}>
						<div className="w-full ModalGenerateInvoicesPrints" style={{ padding: '0px', }}>
							<Card component={motion.div} style={{ border: '0', borderRadius: '0', padding: '10px', boxShadow: 'none' }}>
								<Paper className='InvoicesPrintS'>
									{
										props.toTable[0]?.customer?.jobs?.map((job, index) => (
											<div id={`pdf-invoice-${job.job}`}>
												{index > 0 ? (
													<div style={{ pageBreakAfter: "auto" }}>
														<h1 style={{ opacity: "0" }} >Print</h1>
													</div>) : null}
												<Grid className='mainpdfviewS' container spacing={2} style={{ padding: '0px' }}>
													<Grid className='' item xs={12} >
														<Grid container spacing={2}>
															<Grid item xs={6} sx={{ margin: '10px auto' }}>
																<img className="w-full-s" style={{ Width: "auto", height: "95px", }} src={props?.logo} alt="logo" />
																<Typography variant="body1">{props.compName}</Typography>
																<Typography variant="body1">{job.comp_address}, {job.comp_city}, {job.comp_state}, {job.comp_zip}</Typography>
															</Grid>
															<Grid item xs={6} sx={{ textAlign: 'end' }}>
																<Typography variant="h2" gutterBottom component="div" className='font-bold m-0 mb-10 uppercase big-title'>Invoice # {index === 0 ? props?.invoiceNo : props?.invoiceNo + index}</Typography>
																{/* <Typography className='padding-0' variant="h6" gutterBottom component="div">Invoice Date: {moment().format('MM/DD/YYYY')}</Typography> */}
																<Typography variant="h5" gutterBottom component="div" className='flex justify-end items-center text-sm font-bold'>Total: <Typography variant="h4" gutterBottom component="div" className='font-bold m-0 ml-10 sub-title' >{formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait)}</Typography></Typography>
																{/* <Typography variant="body1" >Terms: {props?.terms[index]}</Typography>
                                                                {props && props.dueDates ?
                                                                <Typography variant="body1" >Due Date: {props.dueDates[index]}</Typography>
                                                                :null}
                                                                <Typography variant="body1" ></Typography>
                                                                <Typography variant="body1" >Hauling Total: {formatter.format(job.jobTotalHauling)}</Typography>
                                                                <Typography variant="body1" >Material Total: {formatter.format(job.jobTotalMaterial)}</Typography>
                                                                <Typography variant="body1" >Wait Charge: {formatter.format(job.jobTotalWait)}</Typography> */}
															</Grid>
														</Grid>
														<Grid container spacing={3}>
															<Grid item xs={4} sx={{ margin: 'auto' }}>
																<Typography variant="body1" className='font-bold'>Bill to:</Typography>
																<Typography variant="body1">{props?.toTable[0]?.customer?.customerName}</Typography>
																<Typography variant="body1">{props.toTable[0]?.customer?.locationId},{props.toTable[0]?.customer?.customerCity},{props.toTable[0]?.customer?.customerState},{props.toTable[0]?.customer?.customerZip}</Typography>
															</Grid>
															<Grid item xs={4} sx={{ margin: 'auto' }}>
																<Typography variant="body1"><strong className='font-bold'>Job No:</strong> {job.job}</Typography>
																<Typography variant="body1"><strong className='font-bold'> Job Site: </strong> {job.jobDestination}</Typography>
																<Typography variant="body1"><strong className='font-bold'> Origin:</strong> {job.jobQuarry}</Typography>
															</Grid>
															<Grid item xs={4} sx={{ textAlign: 'end' }}>
																{/* <Typography variant="body1">Date: </Typography> */}
																<Typography variant="body1">Date: {moment(job.dateCreated).format('MM/DD/YYYY')}</Typography>
																<Typography variant="body1">Terms: {Number(props?.terms[index]).toFixed(0)}</Typography>
																{props && props.dueDates ?
																	<Typography variant="body1"> Due Date:  {props.dueDates[index]}</Typography>
																	: null}
															</Grid>
														</Grid>
														<br></br>
														<Divider style={{ borderBottom: '1px solid #000', height:"0" }}></Divider>
														<br />
														{/* <Grid container sx={{margin: '8px auto'}}>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h6" gutterBottom component="div">Customer:</Typography>
                                                                <Typography variant="body1" >{props?.toTable[0]?.customer?.customerName}</Typography>
                                                                <Typography variant="body1" >{props.toTable[0]?.customer?.locationId},{props.toTable[0]?.customer?.customerCity},{props.toTable[0]?.customer?.customerState},{props.toTable[0]?.customer?.customerZip}</Typography>
                                                            </Grid>

                                                            <Grid item xs={4} sx={{textAlign: 'center'}}>
                                                                <Typography variant="h6" gutterBottom component="div">{`Project (Job Name):`}</Typography>
                                                                <Typography variant="body1" >{job.jobDestination}</Typography>
                                                                <Typography variant="body1" >Job No.: {job.job}</Typography>
                                                            </Grid>

                                                            <Grid item xs={4} sx={{textAlign: 'end'}}>
                                                                <Typography variant="h6" gutterBottom component="div">{`Origin / Source:`}</Typography>
                                                                <Typography variant="body1" >{job.jobQuarry}</Typography>
                                                            </Grid>
                                                        </Grid> */}
														<>
															{
																job.batches.map(batch => (
																	<>
																		<Grid sx={{ breakInside: batch.index > 0 ? '' : "" }}>
																			{/* <Grid container sx={{ padding: '8px 0', margin:'6px 0' }}>
                                                                                <Grid item xs={4} >
                                                                                    <Typography variant="body1" >
                                                                                        Batch No.: {batch.batch}
                                                                                    </Typography>
                                                                                </Grid>

                                                                                <Grid item xs={4} >
                                                                                    <Typography variant="body1" style={{ textAlign: "center" }}>
                                                                                        Date:{moment(batch.batchDateTime).format('MM/DD/YYYY')}
                                                                                    </Typography>
                                                                                </Grid>

                                                                                <Grid item xs={4}>
                                                                                    <Typography variant="body1" style={{ textAlign: "right" }}>
                                                                                        Material: {batch.material}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid> */}
																			<Grid container>
																				<Grid item xs={4} >
																					<Typography variant="body1" > Batch No.: {batch.batch}</Typography>
																				</Grid>
																				<Grid item xs={4} >
																					<Typography variant="body1" style={{ textAlign: "center" }}>
																						Date: {batch.batchDateTimeReal}
																					</Typography>
																				</Grid>
																				<Grid item xs={4}>
																					<Typography variant="body1" style={{ textAlign: "right" }}>Material: {batch.material}</Typography>
																				</Grid>
																			</Grid>
																			<br></br>
																			<Grid container>
																				<Grid item xs={12} style={{ padding: "0", marginBottom: "10px" }}>
																					{/* <Paper sx={{ width: '100%'}} > */}
																					<Table sx={{ width: "100%", tableLayout: "auto", border: "1px solid #000", padding: "0", borderCollapse:"collapse" }}>
																						<TableBody style={{ padding: "0", margin:"0" }}>
																							<TableRow style={{ padding: "0", margin:"0" }}>
																								<TableCell width="100%" colSpan={9} style={{ padding: "0", margin:"0" }}>
																									{
																										['Hauling', 'Material', 'Wait Charge'].map((type, typeIndex) => (
																											<>
																												{batch.info.map((ticket, i) => (
																													<>
																														{batch.info.length + 1 === i + 1 ? (
																															''
																														) : (
																															<>
																																{
																																	(typeIndex === 0) ? (
																																		<Table sx={{ width: "100%", tableLayout: "auto", padding: "0", borderCollapse:"collapse" }}>
																																			{
																																				i === 0 && batch.info.some(x => x.ticketCR > 0) && (
																																					<>
																																						<TableHead>
																																							<TableRow key={typeIndex}>
																																								<TableCell align="left" width="100%" colSpan={9} style={{ fontWeight: 'bold', fontSize: '18px', padding: '10px 20px', borderBottom:'1px solid #000', }}>
																																									{type}
																																								</TableCell>
																																							</TableRow>
																																							<TableRow>
																																								<TableCell align="center" width="11.11%">Ticket No.</TableCell>
																																								<TableCell align="center" width="11.11%">Scale No.</TableCell>
																																								<TableCell align="center" width="11.11%">Truck</TableCell>
																																								<TableCell align="center" width="11.11%">Rate</TableCell>
																																								<TableCell align="center" width="11.11%">Qty</TableCell>
																																								<TableCell align="center" width="11.11%">Unit</TableCell>
																																								<TableCell align="center" width="11.11%">TAX</TableCell>
																																								<TableCell align="center" width="11.11%">Total</TableCell>
																																								{/* <TableCell align="center" width="11.11%">Type</TableCell> */}
																																							</TableRow>
																																						</TableHead>
																																					</>
																																				)
																																			}
																																			{ticket.ticketCR && ticket.ticketCR > 0 ? (
																																				<TableRow style={{borderBottom: "1px solid #ccc"}} key={ticket.ticket}>
																																					<TableCell align="center" width="11.11%">{ticket.ticket}</TableCell>
																																					<TableCell align="center" width="11.11%">{ticket.scale}</TableCell>
																																					<TableCell align="center" width="11.11%">{ticket.truck}</TableCell>
																																					<TableCell align="center" width="11.11%">{job.jobUnitRate}</TableCell>
																																					<TableCell align="center" width="11.11%">{ticket.qty}</TableCell>
																																					<TableCell align="center" width="11.11%">{typeIndex != 2 ? batch.unit : 'H'}</TableCell>
																																					<TableCell align="center" width="11.11%">{'N'}</TableCell>
																																					<TableCell align="center" width="11.11%">{formatter.format(ticket.ticketCR)}</TableCell>
																																					{/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
																																				</TableRow>

																																			) : <></>}

																																		</Table>
																																	) : (typeIndex === 1) ? (
																																		<Table>
																																			{
																																				i === 0 && batch.info.some(x => x.materialCR > 0) && (
																																					<>
																																						<TableHead>
																																							<TableRow key={typeIndex}>
																																								<TableCell align="left" width="100%" colSpan={9} style={{ fontWeight: 'bold', fontSize: '18px', padding: '10px 20px', borderBottom:'1px solid #000', }}>
																																									{type}
																																								</TableCell>
																																							</TableRow>

																																							<TableRow>
																																								<TableCell align="center" width="11.11%">Ticket No.</TableCell>
																																								<TableCell align="center" width="11.11%">Scale No.</TableCell>
																																								<TableCell align="center" width="11.11%">Truck</TableCell>
																																								<TableCell align="center" width="11.11%">Rate</TableCell>
																																								<TableCell align="center" width="11.11%">Qty</TableCell>
																																								<TableCell align="center" width="11.11%">Unit</TableCell>
																																								<TableCell align="center" width="11.11%">TAX</TableCell>
																																								<TableCell align="center" width="11.11%">Total</TableCell>
																																								{/* <TableCell align="center" width="11.11%">Type</TableCell> */}
																																							</TableRow>
																																						</TableHead>
																																					</>



																																				)
																																			}
																																			{ticket.materialCR && ticket.materialCR > 0 ? (
																																				<TableRow style={{borderBottom: "1px solid #ccc"}} key={ticket.ticket}>
																																					<TableCell align="center" width="11.11%">{ticket.ticket}</TableCell>
																																					<TableCell align="center" width="11.11%">{ticket.scale}</TableCell>
																																					<TableCell align="center" width="11.11%">{ticket.truck}</TableCell>
																																					<TableCell align="center" width="11.11%">{job.jobMaterialRate}</TableCell>
																																					<TableCell align="center" width="11.11%">{ticket.qty}</TableCell>
																																					<TableCell align="center" width="11.11%">{typeIndex != 2 ? batch.unit : 'H'}</TableCell>
																																					<TableCell align="center" width="11.11%">{job.jobTaxable ? 'T' : 'N'}</TableCell>
																																					<TableCell align="center" width="11.11%">{formatter.format(ticket.materialCR)}</TableCell>
																																					{/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
																																				</TableRow>

																																			) : <></>}
																																		</Table>

																																	) : (typeIndex === 2) ? (
																																		<Table>
																																			{i === 0 && batch.info.some(x => x.waitCR > 0) && (
																																				<>
																																					<TableHead>
																																						<TableRow key={typeIndex}>
																																							<TableCell align="left" width="100%" colSpan={9} style={{ fontWeight: 'bold', fontSize: '18px', padding: '10px 20px', borderBottom:'1px solid #000', }}>
																																								{type}
																																							</TableCell>
																																						</TableRow>

																																						<TableRow>
																																							<TableCell align="center" width="11.11%">Ticket No.</TableCell>
																																							<TableCell align="center" width="11.11%">Scale No.</TableCell>
																																							<TableCell align="center" width="11.11%">Truck</TableCell>
																																							<TableCell align="center" width="11.11%">Rate</TableCell>
																																							<TableCell align="center" width="11.11%">Qty</TableCell>
																																							<TableCell align="center" width="11.11%">Unit</TableCell>
																																							<TableCell align="center" width="11.11%">TAX</TableCell>
																																							<TableCell align="center" width="11.11%">Total</TableCell>
																																							{/* <TableCell align="center" width="11.11%">Type</TableCell> */}
																																						</TableRow>
																																					</TableHead>
																																				</>

																																			)}
																																			{ticket.waitCR && ticket.waitCR > 0 ? (
																																				<TableRow style={{borderBottom: "1px solid #ccc"}} key={ticket.ticket}>
																																					<TableCell align="center" width="11.11%">{ticket.ticket}</TableCell>
																																					<TableCell align="center" width="11.11%">{ticket.scale}</TableCell>
																																					<TableCell align="center" width="11.11%">{ticket.truck}</TableCell>
																																					<TableCell align="center" width="11.11%">{job.waitrate}</TableCell>
																																					<TableCell align="center" width="11.11%">{Number(ticket.waitTime).toFixed(2)}</TableCell>
																																					<TableCell align="center" width="11.11%">{'H'}</TableCell>
																																					<TableCell align="center" width="11.11%">{'N'}</TableCell>
																																					<TableCell align="center" width="11.11%">{formatter.format(ticket.waitCR)}</TableCell>
																																					{/* <TableCell align="center" width="11.11%">{type}</TableCell> */}
																																				</TableRow>
																																			) : <></>}

																																		</Table>
																																	) : (
																																		<></>
																																	)
																																}

																															</>
																														)}
																													</>
																												))}
																											</>
																										))
																									}
																								</TableCell>
																							</TableRow>


																							{/* <TableRow>
                                                                                                    <TableCell>Sub Totals</TableCell>
                                                                                                    <TableCell></TableCell>
                                                                                                    <TableCell></TableCell>
                                                                                                    <TableCell></TableCell>
                                                                                                    <TableCell align="center">{`${Number(batch.qtyTotal).toFixed(2)}`}</TableCell>

                                                                                                    <TableCell></TableCell>
                                                                                                    <TableCell></TableCell>

                                                                                                    <TableCell align="center">{`${formatter.format(batch.totalMaterialCost + batch.totalTicketCost + batch.totalWaitTime)}`}</TableCell>

                                                                                                    <TableCell></TableCell>
                                                                                                </TableRow> */}
																						</TableBody>
																					</Table>
																					{/* </Paper> */}
																				</Grid>
																			</Grid>
																		</Grid>
																	</>
																))
															}
														</>
														{/* <Divider></Divider> */}
														{/* <Grid className='mt-20' container>
                                                            <Grid item xs={12}>
                                                                <Paper sx={{ width: '100%' }}>
                                                                    <Table style={{ width: "100%", tableLayout: "auto" }}>
                                                                        <TableBody>
                                                                            <TableRow style={{ breakInside: '' }}>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>

                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%">Total QTY</TableCell>

                                                                                <TableCell align="center" width="22.22%">{`${Number(job.jobTotalTickets).toFixed(2)}`}</TableCell>

                                                                                <TableCell align='center' width="0%"></TableCell>
                                                                            </TableRow>

                                                                            <TableRow style={{ breakInside: '' }}>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>

                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%">Total Wait Time</TableCell>

                                                                                <TableCell align="center" width="22.22%">{`${Number(job.jobTotalWaitTime).toFixed(2
                                                                                )}`} H</TableCell>

                                                                                <TableCell align='center' width="0%"></TableCell>
                                                                            </TableRow>

                                                                            <TableRow style={{ breakInside: '' }}>
                                                                                <TableCell align="center" width="11.11%"></TableCell>
                                                                                <TableCell align="center" width="11.11%"></TableCell>
                                                                                <TableCell align="center" width="11.11%"></TableCell>
                                                                                <TableCell align="center" width="11.11%"></TableCell>
                                                                                <TableCell align="center" width="11.11%"></TableCell>

                                                                                <TableCell align="center" width="0%"></TableCell>
                                                                                <TableCell align="center" width="22.22%">Sub Total Invoice</TableCell>

                                                                                <TableCell align="center" width="22.22%">{`${formatter.format(
                                                                                    job.jobTotalHauling + (job.jobTotalMaterial - job.jobTotalTaxesMaterial) + job.jobTotalWait
                                                                                )}`}</TableCell>

                                                                                <TableCell align="center" width="0%"></TableCell>
                                                                            </TableRow>

                                                                            <TableRow style={{ breakInside: '' }}>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>

                                                                                <TableCell align='center' width="0%"></TableCell>
                                                                                <TableCell align='center' width="22.22%">Tax {job.jobTaxable ? job.jobTax > 0 || job.jobTax ? job.jobTax : 8.25 : 0}% Material</TableCell>

                                                                                <TableCell align="center" width="22.22%">{`${formatter.format(
                                                                                    job.jobTotalTaxesMaterial
                                                                                )}`}</TableCell>

                                                                                <TableCell align='center' width="0%"></TableCell>
                                                                            </TableRow>

                                                                            <TableRow style={{ breakInside: '' }}>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%"></TableCell>

                                                                                <TableCell align='center' width="11.11%"></TableCell>
                                                                                <TableCell align='center' width="11.11%">Total Invoice</TableCell>

                                                                                <TableCell align="center" width="22.22%">{`${formatter.format(
                                                                                    job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait
                                                                                )}`}</TableCell>

                                                                                <TableCell align='center' width="0%"></TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </Paper>

                                                            </Grid>
                                                        </Grid>
                                                        <Divider></Divider> */}

														<Grid container spacing={2} className='new-invoice-bottmtable'>
															<Grid item xs={7} className='new-invoice-bottmleft'>
																<Typography variant="body1">Notes to customer:</Typography>
																<Typography variant="body1">{props?.notes[index]}</Typography>
															</Grid>
															<Grid item xs={5} className='new-invoice-bottmright'>
																<Table>
																	<TableRow>
																		<TableCell align='left'>Total QTY:</TableCell>
																		<TableCell align='left'>{`${Number(job.jobTotalTickets).toFixed(2)}`}</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align='left'>Total Wait Time:</TableCell>
																		<TableCell align='left'>{`${Number(job.jobTotalWaitTime).toFixed(2)}`} H</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align='left'>Sub Total Invoice:</TableCell>
																		<TableCell align='left'>{`${formatter.format(job.jobTotalHauling + (job.jobTotalMaterial - job.jobTotalTaxesMaterial) + job.jobTotalWait)}`}</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align='left'>Tax {job.jobTaxable ? job.jobTax > 0 || job.jobTax ? job.jobTax : 8.25 : 0}% Material:</TableCell>
																		<TableCell align='left'>{`${formatter.format(job.jobTotalTaxesMaterial)}`}</TableCell>
																	</TableRow>
																	{/* <TableRow>
																		<TableCell align='left' style={{ fontWeight: 'bold' }}>Total Invoice:</TableCell>
																		<TableCell align='left' style={{ fontWeight: 'bold' }}>{`${formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait)}`}</TableCell>
																	</TableRow> */}






																	{ job && job.discount > 0 ?
                                      <>
                                    <TableRow>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>Discount Total</TableCell>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>{` (${formatter.format(job.discount)} )`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}> Invoice Balance</TableCell>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>{`${formatter.format(job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait - job.discount)}`}</TableCell>
                                    </TableRow>
                                    </>
                                    :
                                    <TableRow>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>Total Invoice</TableCell>
                                      <TableCell align='left' style={{ fontWeight: 'bold' }}>{`${formatter.format(
                                        job.jobTotalHauling + job.jobTotalMaterial + job.jobTotalWait
                                      )}`}</TableCell>
                                    </TableRow>

                                     }












																</Table>
															</Grid>
														</Grid>

														{/* <Grid className='mt-20' container>
                                                            <Grid item xs={12}>
                                                                <Paper sx={{
                                                                    width: '100%',
                                                                    padding: '1rem'
                                                                }}>
                                                                    <Typography  variant="h6">
                                                                        Notes to customer:
                                                                    </Typography>

                                                                    <Typography variant="body1" sx={{
                                                                        // wordBreak: 'break-all',
                                                                    }}>
                                                                        {props?.notes[index]}
                                                                    </Typography>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider></Divider> */}


														{/* <Grid container>
                                                            <Grid item xs={12} sx={{
                                                                textAlign: '-webkit-right'
                                                            }}>
                                                                <Typography variant="h6">
                                                                    Powered by
                                                                </Typography>
                                                                <img
                                                                    src='assets/images/logos/loadzlogo.png'
                                                                    alt="logo"
                                                                    style={{
                                                                        width: '20%',
                                                                    }} />
                                                            </Grid>
                                                        </Grid> */}
														<Grid container style={{ marginTop: '50px' }}>
															<Grid item xs={12} sx={{
																textAlign: '-webkit-right'
															}} className='flex justify-end items-end'>
																<Typography variant="h6" style={{ fontSize: '14px' }}>
																	Powered by
																</Typography>
																<img
																	src='assets/images/logos/loadzlogo.png'
																	alt="logo"
																	style={{
																		width: '140px', marginLeft: '10px'
																	}} />
															</Grid>
														</Grid>
													</Grid>
												</Grid>
												<div style={{ pageBreakAfter: "always" }}></div>
												<div style={{ paddingTop: "0px", paddingLeft: "5px" }}>
													{job?.batches.map((tkBatch, index) => (
														<>
															{tkBatch?.info.map((current, i) => {
																return (
																	<>
																		{current.printIndex % 9 == 0 ? (
																		<div style={{ marginTop: "0px", display:"none", clear: "both", }}>
																			<h1 style={{ opacity: "0", fontSize:"8px", }} >Page Break</h1>
																		</div>) : null}
																		<div className="ticket mainPage for-print-page" key={i} style={{width: "100%", maxWidth: "32%", float: "left", border: "1px solid #000", padding: "4px", margin: "2px", breakAfter:"avoid", breakInside:"avoid", }} >
																			<div className="box-pop-details mt-0">
																				<div className="first-row flex flex-wrap px-2 py-5"
																					style={{ border: "1px solid rgb(233, 201, 23)", }} >
																					<div className="w-1/3 text-center py-5 flex flex-wrap justify-center items-center">
																						<div className="imgbox printsizelogo">
																							<img alt="Logo" src={props?.logo} style={{ height: "20px", width: "50px", }} />
																						</div>
																					</div>
																					<div className="w-1/3 text-left py-5 px-2 printsizefont">
																						<p className="ticket-date" style={{ fontSize: "7px" }}><strong>Ticket Date: </strong></p>
																						<p className="truck" style={{ fontSize: "7px" }}><strong>Truck #: </strong></p>
																						<p className="job" style={{ fontSize: "7px" }}><strong>Job: </strong></p>
																						<p className="delivery-t" style={{ fontSize: "7px" }}><strong className="text-red">Delivery Ticket #: </strong></p>
																					</div>
																					<div className="w-1/3 text-left py-5 px-2 printsizefont">
																						<p className="ticket-date" style={{ fontSize: "7px" }}><span>{moment(current.dateTime).format("MMM, DD YYYY")}</span></p>
																						<p className="truck" style={{ fontSize: "7px" }}><span>{current.truck}</span></p>
																						<p className="job" style={{ fontSize: "7px" }}><span> {current.job}</span></p>
																						<p className="delivery-t" style={{ fontSize: "7px" }}><span className="text-red">{current.ticket}</span></p>
																					</div>
																				</div>
																				<div className="first-row flex flex-wrap mt-5 mb-5 printsizeaddress">
																					<div className="text-center w-full p-0 mt-1 mb-0" style={{ fontSize: "7px", }}>
																						<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet"
																							style={{ display: "inline-block",}} >
																							<g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
																								<path
																									d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30 -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204 114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174 -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
																								></path>
																							</g>
																						</svg>
																						{job.comp_address}, {job.comp_city}, {job.comp_state}, {job.comp_zip} 
																					</div>
																				</div>
																				<div className="second-row flex flex-wrap mb-5 printsizeheading" style={{border: "1px solid rgb(233, 201, 23)", }} >
																					<div className="w-full text-center p-5 printsizeh"
																						style={{
																							background: "rgb(237, 202, 51) none repeat scroll 0% 0%",
																							fontSize: "10px",
																						}}
																					>Delivery Ticket</div>
																					<div className="w-full flex flex-wrap p-2 pb-2 pt-2 printsizefont printsizedt">
																						<div className="w-full flex flex-wrap mb-3" style={{ borderBottom: "1px solid rgb(0, 0, 0)", }} >
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}><strong>Customer: </strong></p>
																							</div>
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}><span>{props.toTable[0]?.customer?.customerName}</span></p>
																							</div>
																						</div>
																						<div className="w-full flex flex-wrap mb-3" style={{ borderBottom: "1px solid rgb(0, 0, 0)", }} >
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}><strong>Quarry Name: </strong></p>
																							</div>
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}>
																									<span>
																										<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet"
																											style={{ display: "inline-block", }} >
																											<g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
																												<path
																													d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30 -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204 114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174 -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
																												></path>
																											</g>
																										</svg>
																										<span className="text-blue" style={{ color: "blue" }}>{job.jobQuarry}</span>
																									</span>
																								</p>
																							</div>
																						</div>
																						<div className="w-full flex flex-wrap mb-3"
																							style={{ borderBottom: "1px solid rgb(0, 0, 0)", }} >
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}><strong>Destination Name: </strong></p>
																							</div>
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}>
																									<span>
																										<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet"
																											style={{ display: "inline-block", }} >
																											<g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
																												<path
																													d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30 -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204 114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174 -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
																												></path>
																											</g>
																										</svg>
																										<span className="text-blue" style={{ color: "blue" }}>{job.jobDestination}</span>
																									</span>
																								</p>
																							</div>
																						</div>
																						<div className="w-full flex flex-wrap mb-3" style={{ borderBottom: "1px solid rgb(0, 0, 0)", }} >
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}><strong>Driver Name: </strong></p>
																							</div>
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}><span>{current.driver}</span></p>
																							</div>
																						</div>
																						<div className="w-full flex flex-wrap mb-3" style={{ borderBottom: "1px solid rgb(0, 0, 0)", }} >
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}><strong>Ticket Update Date: </strong></p>
																							</div>
																							<div className="w-1/2 text-left p-0">
																								<p className="py-3" style={{ fontSize: "7px" }}><span>{moment(current.dateTimeUpdate).format("MMM, DD YYYY")}</span></p>
																							</div>
																						</div>
																					</div>
																				</div>
																				<div className="second-row flex flex-wrap mb-5 printsizetab" style={{ border: "1px solid rgb(233, 201, 23)", }} >
																					<div className="w-full flex flex-wrap p-0 printsizetab2">
																						<table className="w-full">
																							<tbody className="w-full">
																								<tr className="w-full text-center py-10" style={{ background: "rgb(237, 202, 51) none repeat scroll 0% 0%", fontSsize: "16px", }} >
																									<th className="py-3 w-1/4" style={{ fontSize: "7px" }}>QTY</th>
																									<th className="py-3 w-1/4" style={{ fontSize: "7px" }}>UNITS</th>
																									{current.scale ?
																										<th className="py-3 w-1/4" style={{ fontSize: "7px" }}>Scale No</th> : null}
																									<th className="py-3 w-1/4" style={{ fontSize: "7px" }}>MATERIAL</th>
																								</tr>
																								<tr className="w-full text-center py-4">
																									<td className="py-5 w-1/4" style={{ fontSize: "7px" }}>
																										<span className="py-1 px-3" style={{ border: "0.2px solid rgb(17, 17, 17)", background: "rgb(244, 244, 244)", borderRadius: "1px", padding: "0 3px", }} >
																											{current.qty}
																										</span>
																									</td>
																									<td className="py-5 w-1/4" style={{ fontSize: "7px" }}>{current.unit}</td>
																									{current.scale ?
																									<td className="py-5 w-1/4" style={{ fontSize: "7px" }}>{current.scale}</td> : null}
																									<td className="py-5 w-1/4" style={{ fontSize: "7px" }}>{current.material}</td>
																								</tr>
																							</tbody>
																						</table>
																					</div>
																				</div>
																				<div className="second-row flex flex-wrap mb-5 p-2 text-center justify-center printsizesignature"
																					style={{ border: "1px solid rgb(237, 202, 51)", borderRadius: "1px", }} >
																					<img alt="Signature-tag" src={current && current.newSignUrl ? current.newSignUrl : null} style={{ height: "40px", }} />
																				</div>
																				<div className="userframe text-center" style={{ background: "background: rgb(237, 202, 51) none repeat scroll 0% 0%", }} >
																					<div className="w-full text-center p-5 userframe-inner font-n-bold" style={{ background: "rgb(237, 202, 51) none repeat scroll 0% 0%", fontSize: "10px", }} >
																						<span>Received By</span><span style={{ paddingLeft: "3px" }}>{current.tk_receivedby}</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</>
																)
															})}
														</>
													))}
												</div>
											</div>
										))
									}
								</Paper>
							</Card>
						</div>
					</DialogContent>
				</>
			) : null}
		</div>
	)
})
export default ModelUninvoiceForPrint





